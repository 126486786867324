import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFile, IFileState } from 'app/models/txtanalyser/IFIle';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {
  private _files: IFileDownload[] = [];
  public readonly onFileComplete = new BehaviorSubject<IFileResult | undefined>(undefined);
  public readonly onFileError = new BehaviorSubject<any>(undefined);

  public isComplete(): boolean {
    for (const file of this._files) {
      const state = file.file.state;
      if (state !== IFileState.Complete && (state !== IFileState.Error || !file.acknowledgeError)) {
        return false;
      }
    }
    return true;
  }

  public hasUnacknowledgedErrors(): boolean {
    for (const file of this._files) {
      if (file.file.state === IFileState.Error && !file.acknowledgeError) {
        return true;
      }
    }
    return false;
  }

  public acknowledgeErrors(): void {
    for (const file of this._files) {
      if (file.file.state === IFileState.Error && !file.acknowledgeError) {
        file.acknowledgeError = true;
      }
    }
  }

  public getCompletedFiles(): IFileResult[] {
    return this._files
      .filter(x => x.result && x.file.state === IFileState.Complete)
      .map(x => ({
        file: x.file,
        result: x.result!
      }));
  }

  public getFiles(): IFile[] {
    return this._files.map(x => x.file);
  }

  public addFile(file: IFile): void {
    const index = this._getFileIndex(file);
    if (index !== -1) return;

    const fileDownloader: IFileDownload = {
      file,
      result: undefined,
      downloadSubscription: undefined,
      acknowledgeError: false
    };

    
    fileDownloader.downloadSubscription = file.readAsArrayBuffer()
      .subscribe(x => {
        fileDownloader.result = x;
        this.onFileComplete.next({
          file,
          result: x
        });
      }, err => {
        this.onFileError.next(err);
      });

    this._files.push(fileDownloader);
  }

  public removeFile(file: IFile): void {
    const index = this._getFileIndex(file);
    if (index === -1) return;

    const downloader = this._files[index];
    this._files.splice(index, 1);

    if (downloader.downloadSubscription) {
      downloader.downloadSubscription.unsubscribe();
    }
  }

  public removeFiles(): void {
    for (const file of this._files) {
      if (file.downloadSubscription) {
        file.downloadSubscription.unsubscribe();
      }
    }
    this._files = [];
  }

  private _getFileIndex(file: IFile): number {
    for (let i = 0; i < this._files.length; i++) {
      if (this._files[i].file === file) {
        return i;
      }
    }
    return -1;
  }
}

export interface IFileResult {
  file: IFile;
  result: ArrayBuffer;
}

interface IFileDownload {
  result: ArrayBuffer | undefined;
  downloadSubscription: Subscription | undefined;
  file: IFile;
  acknowledgeError: boolean;
}

function base64ArrayBuffer(arrayBuffer: ArrayBuffer) {
  let base64 = '';
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a: number;
  let b: number;
  let c: number;
  let d: number;
  let chunk: number;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63;               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }
  
  return base64
}