import { Component, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

export interface IDatePeriod {
  start?: Date,
  end?: Date
}

@Component({
  selector: 'app-date-period-dialog',
  templateUrl: './date-period-dialog.component.html',
  styleUrls: ['./date-period-dialog.component.scss']
})
export class DatePeriodDialogComponent implements OnDestroy {
  public onDate?: Subject<IDatePeriod> = new Subject();
  public onCancel?: Subject<boolean> = new Subject();

  public fromInput?: string;
  public toInput?: string;

  constructor(
    private dialogRef: MatDialogRef<DatePeriodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IDatePeriod
  ) {
    if (data && data.start) {
      this.fromInput = moment(data.start).format('YYYY-MM-DD');
    }
    if (data && data.end) {
      this.toInput = moment(data.end).format('YYYY-MM-DD');
    }
  }

  get valid(): boolean {
    return !!this.fromInput || !!this.toInput;
  }

  submit() {
    if (!this.valid) return;

    if (this.onDate) {
      this.onDate.next({
        start: this.fromInput ? new Date(this.fromInput) : undefined,
        end: this.toInput ? new Date(this.toInput) : undefined
      });
      this.onDate.complete();
    }

    this.dialogRef.close();
  }

  cancel() {
    if (this.onCancel) {
      this.onCancel.next(true);
      this.onCancel.complete();
    }
    
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.onDate) {
      this.onDate.unsubscribe();
    }
    if (this.onCancel) {
      this.onCancel.unsubscribe();
    }
  }
}
