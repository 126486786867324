import { MediaQueryService } from './../../services/media-query.service';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Group, GroupRole, GroupType } from '../../models/groups';

import { GroupsCreateGroupDialogComponent } from '../groups-create-group-dialog/groups-create-group-dialog.component';
import { GroupsJoinGroupDialogComponent } from '../groups-join-group-dialog/groups-join-group-dialog.component';
import { GroupsEditGroupDialogComponent } from '../groups-edit-group-dialog/groups-edit-group-dialog.component';
import { GroupsDeleteGroupDialogComponent } from '../groups-delete-group-dialog/groups-delete-group-dialog.component';
import { GroupsQuitGroupDialogComponent } from '../groups-quit-group-dialog/groups-quit-group-dialog.component';

interface IRouteData {
  groups: Group[];
  joinGroup?: Group;
}

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {
  public groups: Group[] = [];
  public ownedGroups: Group[] = [];

  /** We need to expose the GroupType enum for the switch case in template */
  public GroupType = GroupType;

  private routeSubscription?: Subscription;
  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _zone: NgZone,
    private _mediaQueryService: MediaQueryService
  ) {}
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');
    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.groups = [];
      this.ownedGroups = [];

      for (let i = 0; i < data.groups.length; i++) {
        if (data.groups[i].role === GroupRole.OWNER) {
          this.ownedGroups.push(data.groups[i]);
        } else {
          this.groups.push(data.groups[i]);
        }
      }

      if (data.joinGroup) {
        this.joinGroup(data.joinGroup);
      }
    });
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
  }
  
  createGroup() {
    const ref = this.dialog.open(GroupsCreateGroupDialogComponent, {
      width: '750px'
    });
    if (ref.componentInstance.onCreated) {
      ref.componentInstance.onCreated.subscribe(
        (group: Group) => {
          this.ownedGroups.push(group);
        }
      );
    }
  }
  
  joinGroup(group?: Group) {
    this.dialog.open(GroupsJoinGroupDialogComponent, {
      data: group,
      width: '750px'
    });
  }
  
  quitGroup(group?: Group) {
    const ref = this.dialog.open(GroupsQuitGroupDialogComponent, {
      data: group,
      width: '750px'
    });

    if (ref.componentInstance.onQuit) {
      ref.componentInstance.onQuit.subscribe(
        (group: Group) => {
          const index = this.groups.indexOf(group);
          if (index === -1) return;

          this.groups.splice(index, 1);
        }
      );
    }
  }

  deleteGroup(group: Group): void {
    const ref = this.dialog.open(GroupsDeleteGroupDialogComponent, {
      data: group,
      width: '750px'
    });

    if (ref.componentInstance.onDeleted) {
      ref.componentInstance.onDeleted.subscribe(
        (group: Group) => {
          const index = this.ownedGroups.indexOf(group);
          if (index === -1) return;

          this.ownedGroups.splice(index, 1);
        }
      );
    }
  }

  editGroup(group: Group): void {
    const ref = this.dialog.open(GroupsEditGroupDialogComponent, {
      data: group,
      width: '750px'
    });

    if (ref.componentInstance.onUpdated) {
      ref.componentInstance.onUpdated.subscribe(
        (updatedGroup: Group) => {
          const index = this.ownedGroups.indexOf(group);
          if (index === -1) return;

          this.ownedGroups.splice(index, 1, updatedGroup);
        }
      );
    }
  }
}
