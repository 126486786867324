import { LoginErrorService } from './../../services/login-error.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AuthToken } from '../../models/auth-token';

@Component({
  selector: 'app-login-feide',
  templateUrl: './login-feide.component.html',
  styleUrls: ['./login-feide.component.scss']
})
export class LoginFeideComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginErrorService: LoginErrorService
  ) { }

  ngOnInit() {
    const search: string = window.location.search;
    if (search.substring(0, 1) !== "?") {
      window.location.href = this.authService.getFeideLogin();
      return;
    }
    
    const parameters: {[key: string]: string} = {};
    
    const tokens: string[] = search.substring(1).split("&");
    for (var i = 0; i < tokens.length; i++) {
      const [ name, value ] = tokens[i].split("=");
      
      parameters[decodeURIComponent(name)] = decodeURIComponent(value);
    }
    
    this.authService.setToken(new AuthToken(parameters["sessionId"], parameters["machineId"], parameters["PHPSessionId"]));
    
    return this.authService.getUser()
      .subscribe(
        () => {
          this.authService.redirectBack();
        },
        err => {
          console.error(err);
          this.loginErrorService.setUnknownError(true);

          this.router.navigateByUrl("/login");
        }
      )
    ;
  }
}