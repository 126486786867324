<div *ngIf="area.id !== 'summary' && chartType !== 'spaced-table' && !area.isStandalone">
  <h2 *ngIf="!href">{{ title }}</h2>
  <h2 *ngIf="href"><a [routerLink]="href" queryParamsHandling='merge'>{{ title }}</a></h2>
  <mat-card>
    <mat-card-content>
      <div class="header-actions">
        <div class="header-actions__left">
          <mat-form-field *ngIf="charts.length > 1">
            <mat-select [(ngModel)]="selectedChartIndex">
              <mat-option [value]="i" *ngFor="let chart of charts; let i = index">{{ chart.title }}</mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="charts.length === 1">
            <span class="header-actions--middle-valign"></span>
            <span>{{ chart!.title }}</span>
          </ng-container>
        </div>
      </div>
      <app-stats-chart [chart]="chart" [zoom]="zoom" [language]="language" [startDate]="startDate" [endDate]="endDate"></app-stats-chart>
    </mat-card-content>
  </mat-card>
  <div class="dataOrigin">
    <span *ngIf="!preview" class="link" (click)="viewDataOrigin()" i18n="@@Stats_SeeWhereDataComesFrom">
      See where the data comes from
    </span> 
  </div>
</div>
<div *ngIf="area.isStandalone">
  <app-stats-chart [chart]="chart" [zoom]="zoom" [language]="language" [startDate]="startDate" [endDate]="endDate"></app-stats-chart>
</div>
<div class="preview" *ngIf="area.id === 'summary' && chartType !== 'spaced-table'">
  <mat-card *ngFor="let charts of groupedCharts" [class.colspan-2]="charts.length > 1">
    <mat-card-title *ngIf="!getHrefByChart(charts[0])">
      <span *ngIf="charts.length === 1">{{ getTitleByChart(charts[0]) }}</span>
      <span *ngIf="charts.length > 1">{{ charts[0].groupedChartTitle }}</span>
      <span>{{ summaryDatePeriod }}</span>
    </mat-card-title>
    <mat-card-title *ngIf="getHrefByChart(charts[0])">
      <a [routerLink]="getHrefByChart(charts[0])" queryParamsHandling='merge'>
        <ng-container *ngIf="charts.length === 1">{{ getTitleByChart(charts[0]) }}</ng-container>
        <ng-container *ngIf="charts.length > 1">{{ charts[0].groupedChartTitle }}</ng-container>
      </a>
      <span>{{ summaryDatePeriod }}</span>
    </mat-card-title>
    <mat-card-content>
      <div class="group-chart" *ngIf="charts.length > 1">
        <ng-container *ngFor="let chart of charts; let i = index">
          <div *ngIf="i > 0" class="group-separator"></div>
          <div class="group-container">
            <h3>{{ getTitleByChart(chart) }}</h3>
            <app-stats-chart [chart]="chart"></app-stats-chart>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="charts.length === 1">
        <app-stats-chart [chart]="charts[0]"></app-stats-chart>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="chartType === 'spaced-table'">
  <h1 class="page">{{ chart!.title }}</h1>
  <app-stats-chart [chart]="chart"></app-stats-chart>
</div>