import { ILocalization } from "app/interfaces/ILocalization";

export class Localization_svSE implements ILocalization {
  public getPageXOfY(x: number, y: number) {
    return `Sida ${x} av ${y}`;
  }

  public getItemsPerPage() {
    return "Visningar per sida:";
  }

  public getNextPage() {
    return "Nästa sida";
  }

  public getPreviousPage() {
    return "Föregående sida";
  }

  public getErrorType() {
    return "Kategori";
  }

  public getPercentage() {
    return "Andel av feltyper";
  }

  public getAmount() {
    return "Antal";
  }
}