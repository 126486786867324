<div class="content" [title]="title">
  <span #border class="border">
    <mat-icon *ngFor="let star of starsArr" [style.width]="size" [style.height]="size" [style.fontSize]="size">star_border</mat-icon>
  </span>

  <span class="fill" [style.width]="fillWidth">
    <mat-icon *ngFor="let star of starsArr" [style.width]="size" [style.height]="size" [style.fontSize]="size">star</mat-icon>
  </span>

  <span class="events" *ngIf="!readonly && !disabled">
    <mat-icon
      *ngFor="let star of starsArr"
      [style.width]="size"
      [style.height]="size"
      [style.fontSize]="size"
      (click)="setStarIndex(star)"
      (mouseenter)="setActiveStar(star)"
      (mouseleave)="setActiveStar(-1)">star_border</mat-icon>
  </span>
</div>