<mat-card class="login-wrapper">
    <mat-card-title i18n="@@Login_Title">Login</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onLogin()" [formGroup]="form" novalidate>
        <mat-form-field class="form-field" hideRequiredMarker="true">
          <mat-placeholder i18n="@@Login_UsernameOrEmail">Username or email</mat-placeholder>
          <input #usernameInput matInput name="username" formControlName="username" (input)="resetUsernameErrors()" focus>
          <mat-error *ngIf="errorUsername?.incorrect" i18n="@@Login_UsernameExists">Username doesn't exist</mat-error>
          <mat-error *ngIf="errorUsername?.empty" i18n="@@Login_EnetrUsername">Enter a username</mat-error>
          <mat-error *ngIf="errorUsername?.inactive" i18n="@@Login_UserInactive">The user is inactive</mat-error>
          <mat-error *ngIf="errorUsername?.disabled" i18n="@@Login_UserIsDisabled">The user is disabled</mat-error>
        </mat-form-field>
        <mat-form-field class="form-field" hideRequiredMarker="true">
          <mat-placeholder i18n="@@Login_Password">Password</mat-placeholder>
          <input #passwordInput matInput name="password" formControlName="password" (input)="resetPasswordErrors()" type="password">
          <mat-error *ngIf="errorPassword?.incorrect" i18n="@@Login_IncorrectPassword">Incorrect password</mat-error>
          <mat-error *ngIf="errorPassword?.empty" i18n="@@Login_EnterPassword">Enter a password</mat-error>
          <mat-error *ngIf="errorPassword?.expired" i18n="@@Login_PasswordExpired">The password has expired</mat-error>
        </mat-form-field>
        <div class="error" *ngIf="unexpectedError" i18n="@@Login_UnexcpectedError">An unexpected error occurred.</div>
        <div class="space-between">
          <div class="remember-me">
            <mat-checkbox name="remember" formControlName="remember">
              <label class="checkbox__label" i18n="@@Login_RememberMe">Remember me</label>
            </mat-checkbox>
          </div>
          <div>
            <a routerLink="/forgotPassword" i18n="@@Login_ForgotPassword">Forgot password?</a>
          </div>
        </div>
        <div class="submit-wrapper">
          <button mat-raised-button color="primary" [disabled]="form.disabled" i18n="@@Login_btnLogin">Login</button>
        </div>
      </form>
      <div class="create-user">
        <div i18n="@@Login_DontHavanAnAccountInfo">Do you not have an account?</div>
        <a routerLink="/createUser" i18n="@@Login_btnCreateAccount">Create one here</a>
      </div>
    </mat-card-content>
  </mat-card>

