import { IgnoreDocument } from '../../models/txtanalyser/IgnoreDocument';
import { TxtAnalyserErrorReportingSnackbarComponent, TxtAnalyserErrorreportingSnackbarType } from './../txtanalyser-errorreporting-snackbar/txtanalyser-errorreporting-snackbar.component';
import { Component, Inject, NgZone, OnInit, OnDestroy, ViewChild, AfterViewInit, LOCALE_ID } from '@angular/core';
import { ErrorReporting } from '../../models/txtanalyser/errorreporting';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { MediaQueryService } from '../../services/media-query.service';
import { Subscription } from 'rxjs';
import { Language } from '../../models/language';
import { IgnoreDocumentsDataSource } from '../../models/txtanalyser/IgnoreDocumentsDataSource';
import { TxtAnalyserDocumentIgnores2DeleteDialogComponent } from '../txtanalyser-document-ignores2-delete-dialog/txtanalyser-document-ignores2-delete-dialog.component';

interface IRouteData {
  errorReporting: ErrorReporting;
  documents: IgnoreDocument[];
  languages: Language[];
}

@Component({
  selector: 'app-txtanalyser-errorreporting',
  templateUrl: './txtanalyser-errorreporting.component.html',
  styleUrls: ['./txtanalyser-errorreporting.component.scss']
})
export class TxtAnalyserErrorReportingComponent implements OnInit, AfterViewInit, OnDestroy {
  // none, errorOnly, errorAndType, errorTypeAndCorrections, errorTypeAndCorrectionsNoReplace
  public errorReporting?: ErrorReporting;

  public documents: IgnoreDocument[] = [];
  public loadingDocuments: {[key: string]: boolean} = {};

  public languages: Language[] = [];
  public language: string|undefined;

  public userId?: number;
  public groupId?: number;

  private routeSubscription?: Subscription;

  @ViewChild(MatSort) private _sort?: MatSort;
  public dataSource?: IgnoreDocumentsDataSource;

  public get displayedColumns(): string[] {
    return ["name", "ignores", "actions"];
  };
  
  public updateErrorReportingSubscription?: Subscription;
  public loadingErrorReporting = false;

  public config = {
    "none": {
      "space": false,
      "expand": false
    },
    "errorOnly": {
      "space": true,
      "expand": false
    },
    "errorAndType": {
      "space": true,
      "expand": false
    },
    "errorTypeAndCorrectionsNoReplace": {
      "space": true,
      "expand": false
    },
    "errorTypeAndCorrections": {
      "space": true,
      "expand": false
    }
  };
  public expanding = [false, false, false, false];

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    @Inject(ITxtAnalyserServiceToken) private txtanalyserService: ITxtAnalyserService,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    private _dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) {
    
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    if (this.route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this.route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this.route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this.route.snapshot.paramMap.get('userId')!, 10);
    }

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.errorReporting = data.errorReporting;
      this.documents = data.documents;

      this.languages = data.languages;
      this.language = this.getLanguage();

      this.dataSource = new IgnoreDocumentsDataSource(this.txtanalyserService, this.language || "da-DK", this.groupId, this.userId);
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this._sort;
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
    if (this.updateErrorReportingSubscription) {
      this.updateErrorReportingSubscription.unsubscribe();
      this.updateErrorReportingSubscription = undefined;
    }
  }

  getLanguage(): string|undefined {
    if (!this.languages) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        return this.languages[i].code;
      }
    }
    return undefined;
  }

  getFullLanguagePath(language?: string): string|undefined {
    if (!this.languages || !language) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        tokens[tokens.length - 1] = language;
        break;
      }
    }
    return tokens.join("/");
  }
  
  onLanguageChange(): void {
    this.router.navigate([this.getFullLanguagePath(this.language)]);
  }

  onErrorReportingChange(type: string): void {
    if (!this.errorReporting) return;
    if (this.updateErrorReportingSubscription) {
      this.updateErrorReportingSubscription.unsubscribe();
    }

    this.loadingErrorReporting = true;

    const errorReporting = this.errorReporting.clone();
    errorReporting.value = type;

    this.updateErrorReportingSubscription = this.txtanalyserService.updateErrorReporting(errorReporting)
      .subscribe(undefined, () => {
        this.loadingErrorReporting = false;

        this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
          duration: 4000,
          data: TxtAnalyserErrorreportingSnackbarType.Error
        });
      }, () => {
        if (this.errorReporting) {
          this.errorReporting.value = type;
        }
        this.loadingErrorReporting = false;

        this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
          duration: 4000,
          data: TxtAnalyserErrorreportingSnackbarType.Success
        });
      });
  }

  removeDocument(document: IgnoreDocument) {
    const ref = this._dialog.open(TxtAnalyserDocumentIgnores2DeleteDialogComponent);
    ref.componentInstance.onDelete.subscribe(x => {
      if (this.dataSource) {
        this.dataSource.removeItem(document);
      }
      this.loadingDocuments[document.id] = true;
      this.txtanalyserService
        .deleteIgnoreDocument(document.id, document.language, document.groupId, document.impersonatedUserId)
        .subscribe(
          undefined,
          () => {
            this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
              duration: 4000,
              data: TxtAnalyserErrorreportingSnackbarType.Error
            });
            this.loadingDocuments[document.id] = false;
          },
          () => {
            this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
              duration: 4000,
              data: TxtAnalyserErrorreportingSnackbarType.DocumentSuccess
            });
            this.loadingDocuments[document.id] = false;

            if (this.dataSource) {
              this.dataSource.update();
            }
          }
        );
    });
  }

  getDocumentLinkById(id: string) {
    return this.router.url + "/ignores/" + encodeURIComponent(id);
  }
}
