export class Category {
  constructor(
    public id: string,
    public language: string,

    public name: string,
    public description: string,
    public enabled: boolean,
    public indeterminate: boolean,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}

  clone(): Category {
    return new Category(this.id, this.language, this.name, this.description,
      this.enabled, this.indeterminate, this.groupId, this.impersonatedUserId);
  }
}