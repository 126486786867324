import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Group } from '../models/groups';
import { IGroupsService } from '../interfaces/igroups.service';
import { IGroupsServiceToken } from '../interfaces/igroups.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class GroupResolver implements Resolve<Group> {
  constructor(
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Group> {
    const id = parseInt(route.paramMap.get('groupId')!, 10);

    return this.groupsService.getGroupById(id);
  }
}
