<app-header></app-header>

<div class="mat-elevation-z2">
  <div class="table__header">
    <div class="header__row header__space__between">
      <div class="header__start">
        <mat-form-field class="header-start__filter">
          <input matInput [(ngModel)]="dataSource!.filter" placeholder="Search for glossaries" i18n-placeholder="@@SearchGlossaries_searchForGlossaries">
          <button mat-button *ngIf="dataSource!.filter" matSuffix mat-icon-button i18n-aria-label="@@SearchGlossaries_clear" aria-label="Clear" (click)="dataSource.filter=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <!--<div class="header__separator"></div>
        <mat-checkbox labelPosition="before" [(ngModel)]="dataSource!.includePublisherGlossaries" i18n="@@SearchGlossaries_fromPublisher">From publisher</mat-checkbox>
        <div class="header__spacer"></div>
        <mat-checkbox labelPosition="before" [(ngModel)]="dataSource!.includeUserGlossaries" i18n="@@SearchGlossaries_fromOtherUsers">From other users</mat-checkbox>-->
      </div>
      <div class="header__end">
        <div>
          <span i18n="@@SearchGlossaries_itemsPerPage">Items per page</span>
          <mat-form-field class="header-end__page-size">
            <mat-select [(ngModel)]="dataSource!.pageSize">
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="100">100</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="header__row">
      <mat-form-field class="header__filter__item">
        <mat-label i18n="@@SearchGlossaries_targetAudience">Target audience</mat-label>
        <mat-select [(ngModel)]="dataSource!.targetAudience">
          <mat-option i18n="@@glossary_filter_any">All</mat-option>
          <mat-option *ngFor="let target of targetAudiences" [value]="target.value">{{ target.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="dataSource!.showSubject()" class="header__filter__item" >
        <mat-label i18n="@@SearchGlossaries_subject">Subject</mat-label>
        <mat-select [(ngModel)]="dataSource!.subject">
          <mat-option i18n="@@glossary_filter_any">All</mat-option>
          <mat-option *ngFor="let subject of subjects" [value]="subject.value">{{ subject.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="header__filter__item" >
        <mat-label i18n="@@SearchGlossaries_language">Language</mat-label>
        <mat-select [(ngModel)]="dataSource!.language">
          <mat-option i18n="@@glossary_filter_any">All</mat-option>
          <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="header__filter__item" >
        <mat-label i18n="@@SearchGlossaries_publisher">Publisher</mat-label>
        <mat-select [(ngModel)]="dataSource!.publisher">
          <mat-option i18n="@@glossary_filter_any">All</mat-option>
          <mat-option *ngFor="let publisher of publishers" [value]="publisher.value">{{ publisher.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_glossary">Glossary</mat-header-cell>
      <mat-cell *matCellDef="let element"><span><a class="glossary-view" (click)="viewGlossary(element)">{{element.name}}</a></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef i18n="@@SearchGlossaries_description">Description</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{element.description}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="language">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_language">Language</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{getLanguageByCode(element.language)}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="targetAudience">
      <mat-header-cell *matHeaderCellDef class="header-target" mat-sort-header i18n="@@SearchGlossaries_targetAudience">Target Audience</mat-header-cell>
      <mat-cell *matCellDef="let element" class="cell-target"><span>{{getTargetAudienceByCode(element.targetAudience)}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="subject">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_subject">Subject</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{getSubjectByCode(element.subject)}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="subscribers">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_subscribers">Subscribers</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{element.subscribers}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="ownerDisplayName">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_ownerDisplayName">Made by</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.ownerDisplayName === undefined" class="unknown-owner" i18n="@@SearchGlossaries_UnknownOwner">- Unknown -</span>
        <span *ngIf="element.ownerDisplayName !== undefined">{{element.ownerDisplayName}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rating">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchGlossaries_rating">Rating</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-rating [rating]="element.rating" [readonly]="true"></app-rating>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="subscribed">
      <mat-header-cell *matHeaderCellDef class="header-subscribe" i18n="@@SearchGlossaries_subscribe">Subscribe</mat-header-cell>
      <mat-cell *matCellDef="let element" class="cell-subscribe">
        <mat-checkbox *ngIf="!subscribeCheckboxDisabled[element.id]" [disabled]="(element.ownedByMe || element.sharedByGroupId !== undefined) && (!groupId || userId)" [checked]="element.subscribed" (change)="setSubscribed(element, $event.checked)"></mat-checkbox>
        <mat-spinner *ngIf="subscribeCheckboxDisabled[element.id]" diameter="20"></mat-spinner>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    [length]="dataSource!.totalItems"
    [pageIndex]="dataSource!.pageIndex"
    [pageSize]="dataSource!.pageSize"
    (page)="dataSource!.pageIndex = $event.pageIndex"
    [hidePageSize]="true">
  </mat-paginator>
</div>
