import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription ,  Subject } from 'rxjs';

import { GroupMember } from '../../models/groups';
import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

@Component({
  selector: 'app-group-remove-member-dialog',
  templateUrl: './group-remove-member-dialog.component.html',
  styleUrls: ['./group-remove-member-dialog.component.scss']
})
export class GroupRemoveMemberDialogComponent implements OnDestroy {
  public onDeleted: Subject<GroupMember> = new Subject();
  private subscription?: Subscription;

  public unexpectedError: boolean = false;
  public disabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GroupRemoveMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public member: GroupMember,
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) { }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  get name(): string {
    if (!this.member.firstName) {
      return this.member.email;
    }
    return this.member.firstName + (this.member.lastName ? (" " + this.member.lastName) : "");
  }

  remove(): void {
    if (this.disabled) return;
    this.disabled = true;
    this.unexpectedError = false;

    this.subscription = this.groupsService.removeMember(this.member)
      .subscribe(() => {
        this.onDeleted.next(this.member);
      }, (err) => {
        this.disabled = false;
        this.unexpectedError = true;
        console.error(err);
      }, () => {
        this.onDeleted.complete();
        this.dialogRef.close();
      });
  }
}
