import { catchError, map } from 'rxjs/operators';
import { LingappsError } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';

@Injectable()
export class CollectionService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public createCollection(path: string): BaseCollection {
    return new BaseCollection(this.httpClient, path);
  }

  public createCollectionByJsonString(json: string): Collection {
    const obj = JSON.parse(json);

    return Collection.parse(this.httpClient, obj['collection']);
  }
  
  public getCollection(path: string): Observable<Collection> {
    return this.httpClient.get<Object>(environment.collectionUrl + path).pipe(
      map((json: any) => {
        return Collection.parse(this.httpClient, json['collection']);
      }));
  }

  public getBaseCollection(path: string): BaseCollection {
    return new BaseCollection(this.httpClient, path);
  }
}

export class BaseCollection {
  constructor(
    protected httpClient: HttpClient,
    public href: string
  ) {}

  private _handleError(err: HttpErrorResponse): LingappsError {
    const obj = JSON.parse(err.error);

    const collection = Collection.parse(this.httpClient, obj['collection']);
    
    if (collection.error) {
      return new LingappsError(collection.error.code, collection.error.message);
    }

    return new LingappsError("EMPTY_ERROR", "Server returned with an empty error");
  }
  
  public getHttpClient(): HttpClient {
    return this.httpClient;
  }
  
  public post(template: Template): Observable<string> {
    const obj: any = {};
    obj["template"] = {};
    obj["template"]["data"] = [];
    for (let i = 0; i < template.data.length; i++) {
      obj["template"]["data"].push({
        "name": template.data[i].name,
        "value": template.data[i].value
      });
    }
    
    const jsonData = JSON.stringify(obj);

    return this.httpClient.post(environment.collectionUrl + this.href, jsonData, {
      responseType: 'text',
      headers: {
        'Content-Type': "application/vnd.collection+json"
      }
    }).pipe(
    catchError((err): string => {
      throw this._handleError(err);
    }));
  }
  
  public put(template: Template): Observable<string> {
    const obj: any = {};
    obj["template"] = {};
    obj["template"]["data"] = [];
    for (let i = 0; i < template.data.length; i++) {
      obj["template"]["data"].push({
        "name": template.data[i].name,
        "value": template.data[i].value
      });
    }
    
    const jsonData = JSON.stringify(obj);

    return this.httpClient.put(environment.collectionUrl + this.href, jsonData, {
      responseType: 'text',
      headers: {
        'Content-Type': "application/vnd.collection+json"
      }
    }).pipe(
    catchError((err): string => {
      throw this._handleError(err);
    }));
  }
  
  public delete(): Observable<string> {
    return this.httpClient.delete(environment.collectionUrl + this.href, {
      responseType: 'text'
    }).pipe(
    catchError((err): string => {
      throw this._handleError(err);
    }));
  }
  
  public follow(): Observable<Collection> {
    return this.json<any>().pipe(
    map(json => Collection.parse(this.httpClient, json['collection'])),
    catchError((err): Observable<Collection> => {
      throw this._handleError(err);
    }),);
  }
  
  public json<T>(): Observable<T> {
    return this.httpClient.get<T>(environment.collectionUrl + this.href).pipe(
    catchError((err): Observable<T> => {
      throw this._handleError(err);
    }));
  }
}

export class Collection extends BaseCollection {
  constructor(
    httpClient: HttpClient,
    public version: string,
    href: string,
    public links: Link[],
    public queries: Query[],
    public items: Item[],
    public template: Template,
    public error?: CollectionError
  ) {
    super(httpClient, href);
  }
  
  public static parse(http: HttpClient, json: any): Collection {
    const links: Link[] = new Array<Link>();
    const queries: Query[] = new Array<Query>();
    const items: Item[] = new Array<Item>();
    
    if (json['links']) {
      for (let i = 0; i < json['links'].length; i++) {
        links.push(Link.parse(http, json['links'][i]));
      }
    }
    
    if (json['queries']) {
      for (let i = 0; i < json['queries'].length; i++) {
        queries.push(Query.parse(http, json['queries'][i]));
      }
    }
    
    if (json['items']) {
      for (let i = 0; i < json['items'].length; i++) {
        items.push(Item.parse(http, json['items'][i]));
      }
    }

    let error: CollectionError|undefined;
    if (json['error']) {
      error = new CollectionError(json['error']['code'], json['error']['message']);
    }
    
    return new Collection(http, json['version'], json['href'], links, queries, items, Template.parse(json['template']), error);
  }
  
  public hasLink(rel: string): boolean {
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].rel === rel) {
        return true;
      }
    }
    return false;
  }
  
  public getLink(rel: string): Link|undefined {
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].rel === rel) {
        return this.links[i];
      }
    }
    return undefined;
  }
  
  public getLinks(rel: string): Link[] {
    let links: Link[] = [];
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].rel === rel) {
        links.push(this.links[i]);
      }
    }
    return links;
  }
  
  public getQuery(rel: string): Query|undefined {
    for (let i = 0; i < this.queries.length; i++) {
      if (this.queries[i].rel === rel) {
        return this.queries[i];
      }
    }
    return undefined;
  }
}

export class CollectionError {
  constructor(
    public code: string,
    public message: string
  ) {}
}

export class Item extends BaseCollection {
  constructor(
    httpClient: HttpClient,
    href: string,
    public data: Data[],
    public links: Link[],
    public queries?: Query[],
    public template?: Template
  ) {
    super(httpClient, href);
    if (!queries) {
      this.queries = new Array<Query>();
    }
  }
  
  public static parse(http: HttpClient, json: any): Item {
    const href: string = json['href'];
    const data: Data[] = new Array<Data>();
    const links: Link[] = new Array<Link>();
    const queries: Query[] = new Array<Query>();
    const template: Template = Template.parse(json['template']);
    
    if (json['data']) {
      for (let i = 0; i < json['data'].length; i++) {
        data.push(Data.parse(json['data'][i]));
      }
    }
    
    if (json['links']) {
      for (let i = 0; i < json['links'].length; i++) {
        links.push(Link.parse(http, json['links'][i]));
      }
    }
    
    if (json['queries']) {
      for (let i = 0; i < json['queries'].length; i++) {
        queries.push(Query.parse(http, json['queries'][i]));
      }
    }
    
    return new Item(http, href, data, links, queries, template);
  }

  public hasData(name: string): boolean {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].name === name) {
        return true;
      }
    }
    return false;
  }
  
  public getData(name: string): Data|undefined {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].name === name) {
        return this.data[i];
      }
    }
    return undefined;
  }

  public getDataAsArray<T>(name: string): T[] {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    throw new Error(`The data named '${name}' is undefined.`);
  }

  public getDataAsObject<T>(name: string): T {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    throw new Error(`The data named '${name}' is undefined.`);
  }
  
  public getDataAsString(name: string): string {
    const data = this.getData(name);
    if (data) {
      return data.value.toString();
    }
    throw new Error(`The data named '${name}' is undefined.`);
  }
  
  public getDataAsStringOrUndefined(name: string): string|undefined {
    const data = this.getData(name);
    if (data && data.value !== null && data.value !== undefined) {
      return data.value.toString();
    }
    return undefined;
  }

  public getDataAsBoolean(name: string): boolean {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    throw new Error(`The data named '${name}' is undefined.`);
  }

  public getDataAsBooleanOrDefault(name: string, defaultValue: boolean): boolean {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    return defaultValue;
  }

  public getDataAsInteger(name: string): number {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    throw new Error(`The data named '${name}' is undefined.`);
  }

  public getDataAsIntegerOrUndefined(name: string): number|undefined {
    const data = this.getData(name);
    if (data) {
      return data.value;
    }
    return undefined;
  }

  public getDataAsFloat(name: string): number {
    return this.getDataAsInteger(name);
  }

  public getDataAsDate(name: string): Date {
    const value = this.getDataAsString(name);

    return moment(value, 'YYYY-MM-DD').toDate();
  }
  
  public getLink(rel: string): Link|undefined {
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].rel === rel) {
        return this.links[i];
      }
    }
    return undefined;
  }
  
  public getLinks(rel: string): Link[] {
    const links: Link[] = [];
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].rel === rel) {
        links.push(this.links[i]);
      }
    }
    return links;
  }
  
  public getQuery(rel: string): Query|undefined {
    if (!this.queries)
      return undefined;
    for (let i = 0; i < this.queries.length; i++) {
      if (this.queries[i].rel === rel) {
        return this.queries[i];
      }
    }
    return undefined;
  }
}

export class Data {
  constructor(
    public name: string,
    public value: any,
    public prompt?: string
  ) { }
  
  public clone(): Data {
    return new Data(this.name, this.value, this.prompt);
  }
  
  public static parse(json: any): Data {
    let value;
    if (json.hasOwnProperty('array')) {
      value = json['array'];
    } else if (json.hasOwnProperty('object')) {
      value = json['object'];
    } else {
      value = json['value'];
    }
    return new Data(json['name'], value, json['prompt']);
  }
}

export class Link extends BaseCollection {
  constructor(
    httpClient: HttpClient,
    public rel: string,
    href: string,
    public prompt: string,
    public render?: string,
    public name?: string
  ) {
    super(httpClient, href);
  }
  
  public static parse(http: HttpClient, json: any): Link {
    return new Link(http, json['rel'], json['href'], json['prompt'], json['render'], json['name']);
  }
}

export class Query {
  constructor(
    private httpClient: HttpClient,
    public rel: string,
    public href: string,
    public prompt: string,
    public data: Data[]
  ) { }
  
  public static parse(http: HttpClient, json: any): Query {
    const data: Data[] = new Array<Data>();
    if (json['data']) {
      for (let i = 0; i < json['data'].length; i++) {
        data.push(Data.parse(json['data']));
      }
    }
    
    return new Query(http, json['rel'], json['href'], json['prompt'], data);
  }
}

export class Template {
  constructor(
    public data: Data[]
  ) { }
  
  public setStrictValue(name: string, value: string): void {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].name === name) {
        this.data[i].value = value;
        return;
      }
    }
    throw new Error("Value with name doesn't exist.");
  }
  
  public getStrictValue(name: string): string {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].name === name) {
        return this.data[i].value;
      }
    }
    throw new Error("Name doesn't exist.");
  }
  
  /**
   * Returns the difference between the two templates.
   */
  public diff(template: Template): Template {
    if (this.data.length !== template.data.length) throw new Error("Templates are not the same length.");
    const diffTemplate = new Template([]);
    for (let i = 0; i < this.data.length; i++) {
      const value = template.getStrictValue(this.data[i].name);
      if (value !== this.data[i].value) {
        diffTemplate.data.push(new Data(this.data[i].name, value));
      }
    }
    
    return diffTemplate;
  }
  
  public clone(): Template {
    const data: Data[] = [];
    for (let i = 0; i < this.data.length; i++) {
      data.push(this.data[i].clone());
    }
    
    return new Template(data);
  }
  
  public static parse(json: any): Template {
    const data: Data[] = [];
    if (json && json['data']) {
      for (let i = 0; i < json['data'].length; i++) {
        data.push(Data.parse(json['data'][i]));
      }
    }
    return new Template(data);
  }
}