import { Subscription } from 'rxjs';
import { DataTable, IChart, RenderType } from './../../models/stats';
import { Component, OnInit, Input, OnDestroy, OnChanges, ChangeDetectorRef, NgZone } from '@angular/core';

@Component({
  selector: 'app-stats-chart',
  templateUrl: './stats-chart.component.html',
  styleUrls: ['./stats-chart.component.scss']
})
export class StatsChartComponent implements OnInit, OnDestroy, OnChanges {
  private _dataSubscription?: Subscription;
  private _chart?: IChart;
  public data?: DataTable;
  public loading: boolean = false;

  private _zoom?: string;
  private _language?: string;
  private _startDate?: Date;
  private _endDate?: Date;

  private _changesDetected: boolean = false;
  
  @Input()
  get zoom(): string|undefined { return this._zoom; }
  set zoom(zoom: string|undefined) {
    if (this._zoom !== zoom) {
      this._zoom = zoom;

      this._changesDetected = true;
    }
  }
  
  @Input()
  get language(): string|undefined { return this._language; }
  set language(language: string|undefined) {
    if (this._language !== language) {
      this._language = language;

      this._changesDetected = true;
    }
  }
  
  @Input()
  get startDate(): Date|undefined { return this._startDate; };
  set startDate(date: Date|undefined) {
    if (date !== this._startDate) {
      this._startDate = date;

      this._changesDetected = true;
    }
  }
  
  @Input()
  get endDate(): Date|undefined { return this._endDate; };
  set endDate(date: Date|undefined) {
    if (date !== this._endDate) {
      this._endDate = date;

      this._changesDetected = true;
    }
  }

  @Input()
  get chart(): IChart|undefined { return this._chart; };
  set chart(chart: IChart|undefined) {
    if (chart !== this._chart) {
      this._chart = chart;

      this._changesDetected = true;
    }
  }

  public hasChartLink(): boolean {
    return !!(this.chart && this.chart.link);
  }

  public get linkHref(): string|undefined {
    if (!this.chart || !this.chart.link) return undefined;

    return this.chart.link.href;
  }

  public get linkText(): string|undefined {
    if (!this.chart || !this.chart.link) return undefined;

    return this.chart.link.text;
  }

  private _init: boolean = false;

  private _loadData() {
    if (this._dataSubscription) {
      this._dataSubscription.unsubscribe();
      this._dataSubscription = undefined;
    }
    if (!this._init) return;

    if (this._chart) {
      this.loading = true;
      this._dataSubscription = this._chart.getData(this.language, this.zoom, this.startDate, this.endDate)
        .subscribe(data => {
          this.data = data;
        }, (err) => {
          console.error(err);
          this.loading = false;
        }, () => {
          this.loading = false;
        });
    } else {
      this.data = new DataTable();
    }
  }

  isStackedColumnType(): boolean {
    if (!this._chart) return false;
    return this._chart.chartType === 'stacked-column';
  }
  
  isColumnType(): boolean {
    if (!this._chart) return false;
    return this._chart.chartType === 'column' || this._chart.chartType === 'stacked-column';
  }

  isTableType(): boolean {
    if (!this._chart) return false;
    return this._chart.chartType === 'table';
  }

  isGroupedTableType(): boolean {
    if (!this._chart) return false;
    return this._chart.chartType === 'grouped-table';
  }

  isSpacedTableType(): boolean {
    if (!this._chart) return false;
    return this._chart.chartType === 'spaced-table';
  }

  includePieChart(): boolean {
    if (!this._chart) return false;
    return this._chart.includePieChart;
  }

  ngOnInit(): void {
    this._init = true;
    
    this._loadData();
  }

  ngOnChanges(): void {
    if (this._changesDetected) {
      this._changesDetected = false;
      this._loadData();
    }
  }

  ngOnDestroy(): void {
    this._init = false;
    
    if (this._dataSubscription) {
      this._dataSubscription.unsubscribe();
      this._dataSubscription = undefined;
    }
  }
}
