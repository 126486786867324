import { Subscription ,  Observable ,  Subject } from 'rxjs';
import { Component, OnInit, TemplateRef, Input, ViewContainerRef, ViewChild, AfterViewInit, OnChanges, AfterViewChecked, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-virtual-scroll-list',
  templateUrl: './virtual-scroll-list.component.html',
  styleUrls: ['./virtual-scroll-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollListComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
  @Input('template')
  public template?: TemplateRef<any>;

  @Input('items')
  public items: any[] = [];

  private lastItemLength = 0;

  /**
   * @todo Implement ngDoCheck() instead of using update.
   */
  @Input('update')
  public update?: Observable<any>;

  private updateSubscription?: Subscription;

  @ViewChild('scroll', { read: ViewContainerRef, static: true })
  private scrollContainerRef?: ViewContainerRef;

  public _data: Subject<any[]> = new Subject();
  public _resize: Subject<any> = new Subject();

  private scrollLeft: number = 0;
  private scrollTop: number = 0;

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.update) {
      this.updateSubscription = this.update.subscribe(items => this.redraw());
    }
    this._data.next(this.items);
  }

  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.redraw();
  }

  ngAfterViewInit() {
    if (this.scrollContainerRef) {
      this.renderer.setProperty(this.scrollContainerRef.element.nativeElement, 'scrollLeft', this.scrollLeft);
      this.renderer.setProperty(this.scrollContainerRef.element.nativeElement, 'scrollTop', this.scrollTop);
      }
    this.redraw();
  }

  ngAfterViewChecked() {
    if (this.lastItemLength !== this.items.length) {
      this.lastItemLength = this.items.length;
      this.redraw();
    }
  }

  redraw() {
    this._data.next([]);
    this._data.next(this.items);
    this._resize.next();

    this.changeDetectorRef.detectChanges();
  }

  resize() {
    this._resize.next();

    if (this.scrollContainerRef) {
      this.renderer.setProperty(this.scrollContainerRef.element.nativeElement, 'scrollLeft', this.scrollLeft);
      this.renderer.setProperty(this.scrollContainerRef.element.nativeElement, 'scrollTop', this.scrollTop);
      }

    this.changeDetectorRef.detectChanges();
  }

  onScroll(left: number, top: number) {
    this.scrollLeft = left;
    this.scrollTop = top;
  }
}
