import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onedrive-file-picker-redirect',
  templateUrl: './onedrive-file-picker-redirect.component.html',
  styleUrls: ['./onedrive-file-picker-redirect.component.scss']
})
export class OneDriveFilePickerRedirectComponent implements OnInit {
  public ngOnInit() {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://js.live.net/v7.2/OneDrive.js");

    (document.head || document.body || document.documentElement).appendChild(script);
  }
}
