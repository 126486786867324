import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject ,  Subscription } from 'rxjs';

import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

import { Group } from '../../models/groups';

interface IError {
  [key: string]: boolean|undefined;
  empty?: boolean;
}

function errorValidator(error: IError): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}

@Component({
  selector: 'app-groups-edit-group-dialog',
  templateUrl: './groups-edit-group-dialog.component.html',
  styleUrls: ['./groups-edit-group-dialog.component.scss']
})
export class GroupsEditGroupDialogComponent {
  public onUpdated?: Subject<Group> = new Subject();
  private subscription?: Subscription;

  public error: IError = {};
  public unexpectedError: boolean = false;
  
  public form = new FormGroup({
    'name': new FormControl(this.group.name, errorValidator(this.error))
  });

  constructor(
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService,
    @Inject(MAT_DIALOG_DATA) private group: Group,
    public dialogRef: MatDialogRef<GroupsEditGroupDialogComponent>
  ) {}

  resetErrors() {
    this.unexpectedError = false;
    for (let key in this.error) {
      this.error[key] = false;
    }
  }

  editGroup() {
    if (this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    let name: string = this.form.get('name')!.value;

    if (!name) {
      this.error.empty = true;
      this.form.updateValueAndValidity();
      this.form.enable();
      return;
    }

    if (name === this.group.name) {
      if (this.onUpdated) {
        this.onUpdated.complete();
      }
      this.dialogRef.close();
      return;
    }

    if (this.group.id === undefined) return;

    this.subscription = this.groupsService.updateGroupNameById(this.group.id, name)
      .subscribe((group: Group) => {
        if (this.onUpdated) {
          this.onUpdated.next(group);
        }
      }, (err) => {
        console.error(err);
        this.unexpectedError = true;
        this.form.enable();
      }, () => {
        if (this.onUpdated) {
          this.onUpdated.complete();
        }
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.onUpdated) {
      this.onUpdated.unsubscribe();
      this.onUpdated = undefined;
    }
  }
}
