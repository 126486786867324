import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IMathSolverSettings,
  IMathSolverSettingsService,
  MathSolverSetting
} from '../../interfaces/imathsolver-settings.service';
import { Router } from '@angular/router';
import { CollectionService, Data, Item, Template } from '../collection.service';
import { map, pluck } from 'rxjs/operators';

@Injectable()
export class MathSolverSettingsService implements IMathSolverSettingsService {
  constructor(
    private collectionService: CollectionService,
    private router: Router
  ) {
  }

  getSettings(): Observable<any> {
    return this.collectionService.getCollection(`${this.router.url}/setting`)
    .pipe(
      pluck('items'),
      map((items: Item[]) => {
        return items.map((item: Item) => item.data
          .reduce((obj, data) => ({...obj, [data.name]: data.value}) , {})
        )
      }),
    )
  }

  setSettings(request: IMathSolverSettings): Observable<any> {
    const collection = this.collectionService.createCollection(`${this.router.url}/setting`);
    const template = new Template([
      new Data('name', MathSolverSetting.ALLOW_SOLVE),
      new Data('allowed', request.allowSolve ? 'True' : 'False')
    ]);
    return collection.put(template);
  }
}
