import { Component, Input, Output, ViewChild, ElementRef, AfterViewInit, OnDestroy, EventEmitter, Renderer2, HostListener } from '@angular/core';

function containsCoordinate(rect: IRect, x: number, y: number) {
  return x >= rect.left && x <= rect.left + rect.width &&
        y >= rect.top && y <= rect.top + rect.height;
}

@Component({
  selector: 'app-readonly-document',
  templateUrl: './readonly-document.component.html',
  styleUrls: ['./readonly-document.component.scss']
})
export class ReadonlyDocumentComponent implements AfterViewInit, OnDestroy {
  @Input()
  public get text(): string {
    return this._text;
  }
  public set text(text: string) {
    this._text = text;

    this._redrawLayout();
  }
  private _text: string = "";

  @Input()
  public get tokens(): IToken[] {
    return this._tokens;
  }
  public set tokens(tokens: IToken[]) {
    this._tokens = tokens;

    this._redrawLayout();
  }
  private _tokens: IToken[] = [];

  @Input()
  public clickableElements: Node[] = [];

  @Output()
  public selected: EventEmitter<IToken | undefined> = new EventEmitter();

  @Output()
  public hover: EventEmitter<IToken | undefined> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
  ) { }

  @ViewChild("content", { static: true })
  private _contentElementRef?: ElementRef<HTMLElement>;

  @ViewChild("text", { static: true })
  private _textElementRef?: ElementRef<HTMLElement>;

  @ViewChild("tokens", { static: true })
  private _tokensElementRef?: ElementRef<HTMLElement>;

  private _currentNodes: any[] = [];
  private _currentListeners: Function[] = [];
  private _rects: IRect[][] = [];

  private _renderedTokens: IRenderedToken[] = [];

  private _lastScrollCoordinate: ICoordinate | undefined;
  private _currentViewOffsetCoordinate: ICoordinate = {
    left: 0,
    top: 0
  };

  private _hoveredRenderedToken: IRenderedToken | undefined;
  private _selectedRenderedToken: IRenderedToken | undefined;

  public ngAfterViewInit() {
    this._redrawLayout();
  }

  public ngOnDestroy() {
    this._cleanup();
  }

  public onMouseOver(e: MouseEvent) {
    this._updateOffsetCoordinates();

    this._updateHoveredTokenByCoordinates(e.clientX - this._currentViewOffsetCoordinate.left, e.clientY - this._currentViewOffsetCoordinate.top);
  }

  public onMouseMove(e: MouseEvent) {
    if (!this._contentElementRef) return;

    this._updateOffsetCoordinates();

    this._updateHoveredTokenByCoordinates(e.clientX - this._currentViewOffsetCoordinate.left, e.clientY - this._currentViewOffsetCoordinate.top);
  }

  public onMouseOut() {
    if (this._hoveredRenderedToken) {
      this._removeClassToToken(this._hoveredRenderedToken, "token--hover");
    }
    this._hoveredRenderedToken = undefined;
    this.hover.emit(undefined);
  }

  @HostListener('document:scroll', [])
  public onScroll() {
    if (!this._currentViewOffsetCoordinate || !this._lastScrollCoordinate) {
      this._updateOffsetCoordinates();
    } else {
      const currentScrollCoordinate = {
        left: window.pageXOffset || document.documentElement!.scrollLeft,
        top: window.pageYOffset || document.documentElement!.scrollTop
      };

      const dX = this._lastScrollCoordinate.left - currentScrollCoordinate.left;
      const dY = this._lastScrollCoordinate.top - currentScrollCoordinate.top;

      this._currentViewOffsetCoordinate.left += dX;
      this._currentViewOffsetCoordinate.top += dY;
    }

    this.onMouseOut();
  }

  @HostListener('document:click', ['$event'])
  public onClick(e: MouseEvent) {
    if (!this._contentElementRef) return;
    for (const node of this.clickableElements) {
      if (node.contains(e.target as Node)) {
        return;
      }
    }

    if (this._selectedRenderedToken) {
      this._removeClassToToken(this._selectedRenderedToken, "token--selected");
    }

    const contentElement = this._contentElementRef.nativeElement as HTMLElement;
    if (!e.target || !contentElement.contains(e.target as Node)) {
      this.selected.emit(undefined);
      return;
    }

    this._currentViewOffsetCoordinate = contentElement.getBoundingClientRect();

    const x = e.clientX - this._currentViewOffsetCoordinate.left;
    const y = e.clientY - this._currentViewOffsetCoordinate.top;

    const detail = this._getRenderedTokenByCoordinates(x, y);
    this._updateSelectedToken(detail);
  }

  private _updateSelectedToken(detail: IRenderedToken | undefined): void {
    if (this._selectedRenderedToken) {
      this._removeClassToToken(this._selectedRenderedToken, "token--selected");
    }

    this._selectedRenderedToken = detail;
    if (detail) {
      detail.container.focus();
      this._addClassToToken(detail, "token--selected");
      this.selected.emit(detail.token);
    } else {
      this.selected.emit(undefined);
    }
  }

  private _updateOffsetCoordinates() {
    if (!this._contentElementRef) return;
    const contentElement = this._contentElementRef.nativeElement as HTMLElement;
    const rect = contentElement.getBoundingClientRect();
    this._currentViewOffsetCoordinate = {
      left: rect.left,
      top: rect.top
    };
    this._lastScrollCoordinate = {
      left: window.pageXOffset || document.documentElement!.scrollLeft,
      top: window.pageYOffset || document.documentElement!.scrollTop
    };
  }

  private _addClassToToken(token: IRenderedToken, className: string): void {
    if (token) {
      for (const element of token.viewElements) {
        this.renderer.addClass(element, className);
      }
    }
  }

  private _removeClassToToken(token: IRenderedToken, className: string): void {
    if (token) {
      for (const element of token.elements) {
        this.renderer.removeClass(element, className);
      }
    }
  }

  private _updateHoveredTokenByCoordinates(x: number, y: number): void {
    if (this._hoveredRenderedToken) {
      this._removeClassToToken(this._hoveredRenderedToken, "token--hover");
    }

    const detail = this._getRenderedTokenByCoordinates(x, y);
    this._updateHoverToken(detail);
  }

  private _updateHoverToken(detail: IRenderedToken | undefined): void {
    this._hoveredRenderedToken = detail;
    if (detail) {
      this._addClassToToken(detail, "token--hover");
      this.hover.emit(detail.token);
    } else {
      this.hover.emit(undefined);
    }

    if (this._contentElementRef) {
      if (detail) {
        this.renderer.addClass(this._contentElementRef.nativeElement, "content--hover");
      } else {
        this.renderer.removeClass(this._contentElementRef.nativeElement, "content--hover");
      }
    }
  }

  private _getRenderedTokenByCoordinates(x: number, y: number): IRenderedToken | undefined {
    for (const detail of this._renderedTokens) {
      for (const rect of detail.rects) {
        if (containsCoordinate(rect, x, y)) {
          return detail;
        }
      }
    }
    return undefined;
  }

  private _cleanup(): void {
    for (const [parent, child] of this._currentNodes) {
      this.renderer.removeChild(parent, child);
    }
    this._currentNodes = [];

    for (const unlisten of this._currentListeners) {
      unlisten();
    }
    this._currentListeners = [];

    this._renderedTokens = [];
  }

  @HostListener('window:resize', [])
  private _updateLayout(): void {
    if (!this._contentElementRef || !this._tokensElementRef) return;

    const contentElement = this._contentElementRef.nativeElement as HTMLElement;
    const tokensElement = this._tokensElementRef.nativeElement as HTMLElement;

    // All positions are calculated relative to this rect. Only the position is
    // needed. The actual dimensions are discarded.
    const viewRect = contentElement.getBoundingClientRect();

    for (const renderedToken of this._renderedTokens) {
      const range = renderedToken.range;
      const rects = Array.from(range.getClientRects());
      renderedToken.rects = rects.map(x => ({
        width: x.width,
        height: x.height,
        left: x.left - viewRect.left,
        top: x.top - viewRect.top
      }));

      for (let i = 0; i < this._currentNodes.length; i++) {
        const [parent, child] = this._currentNodes[i];
        if (renderedToken.elements.indexOf(child) !== -1) {
          this.renderer.removeChild(parent, child);
          this._currentNodes.splice(i, 1);
          i--;
        }
      }

      for (let i = 0; i < this._currentListeners.length; i++) {
        const unlisten = this._currentListeners[i];
        if (renderedToken.listeners.indexOf(unlisten) !== -1) {
          unlisten();
          this._currentListeners.splice(i, 1);
          i--;
        }
      }

      renderedToken.elements = [];
      renderedToken.viewElements = [];

      let groupTop = renderedToken.rects.map(x => x.top)
        .reduce((prev, next) => Math.min(prev, next));
      let groupLeft = renderedToken.rects.map(x => x.left)
        .reduce((prev, next) => Math.min(prev, next));

      const groupElement = renderedToken.container = this.renderer.createElement("div") as HTMLElement;
      this.renderer.addClass(groupElement, "token-group");
      this.renderer.setAttribute(groupElement, "tabindex", "0");
      this.renderer.setStyle(groupElement, "left", groupLeft + "px");
      this.renderer.setStyle(groupElement, "top", groupTop + "px");

      const listener1 = this.renderer.listen(groupElement, "focus", () => this._updateSelectedToken(renderedToken));
      const listener2 = this.renderer.listen(groupElement, "blur", () => this._updateSelectedToken(undefined));

      this._currentListeners.push(listener1);
      this._currentListeners.push(listener2);

      renderedToken.listeners.push(listener1);
      renderedToken.listeners.push(listener2);

      this.renderer.appendChild(tokensElement, groupElement);
      this._currentNodes.push([tokensElement, groupElement]);

      renderedToken.elements.push(groupElement);

      const tokenText = this.renderer.createElement("div") as HTMLElement;
      this.renderer.addClass(tokenText, "token-text");
      this.renderer.appendChild(tokenText, this.renderer.createText(this.text.substring(renderedToken.token.startIndex, renderedToken.token.endIndex)));

      this.renderer.appendChild(groupElement, tokenText);
      this._currentNodes.push([groupElement, tokenText]);

      renderedToken.elements.push(tokenText);

      for (const rect of renderedToken.rects) {
        const tokenElement = this.renderer.createElement("div") as HTMLElement;
        this.renderer.addClass(tokenElement, "token");
        if (this._hoveredRenderedToken === renderedToken) {
          this.renderer.addClass(tokenElement, "token--hover");
        }
        if (this._selectedRenderedToken === renderedToken) {
          this.renderer.addClass(tokenElement, "token--selected");
        }
        this.renderer.setStyle(tokenElement, "left", (rect.left - groupLeft) + "px");
        this.renderer.setStyle(tokenElement, "top", (rect.top - groupTop) + "px");
        this.renderer.setStyle(tokenElement, "width", rect.width + "px");
        this.renderer.setStyle(tokenElement, "height", rect.height + "px");

        this.renderer.appendChild(groupElement, tokenElement);
        this._currentNodes.push([groupElement, tokenElement]);

        renderedToken.elements.push(tokenElement);
        renderedToken.viewElements.push(tokenElement);
      }
    }
  }

  private _redrawLayout(): void {
    if (!this._contentElementRef || !this._textElementRef || !this._tokensElementRef) return;

    const contentElement = this._contentElementRef.nativeElement as HTMLElement;
    const textElement = this._textElementRef.nativeElement as HTMLElement;
    const tokensElement = this._tokensElementRef.nativeElement as HTMLElement;

    // Cleanup existing nodes
    this._cleanup();

    // All positions are calculated relative to this rect. Only the position is
    // needed. The actual dimensions are discarded.
    const viewRect = contentElement.getBoundingClientRect();
    
    // Start by rendering the text so that later the rects of the ranges of the
    // text can be calculated.
    const textNode = this.renderer.createText(this.text) as Text;
    this.renderer.appendChild(textElement, textNode);
    this._currentNodes.push([textElement, textNode]);

    for (const token of this._tokens) {
      // Create range for token
      const range = document.createRange();
      range.setStart(textNode, token.startIndex);
      range.setEnd(textNode, token.endIndex);

      // Calculate rect for token
      const tokenRects = Array.from(range.getClientRects());
      const relRects = tokenRects.map(x => ({
        width: x.width,
        height: x.height,
        left: x.left - viewRect.left,
        top: x.top - viewRect.top
      }));
      const renderedTokenDetails = {
        token,
        range,
        rects: relRects,
        container: this.renderer.createElement("div") as HTMLElement,
        elements: [],
        listeners: [],
        viewElements: []
      } as IRenderedToken;
      this._rects.push(relRects);

      let groupTop = relRects.map(x => x.top)
        .reduce((prev, next) => Math.min(prev, next));
      let groupLeft = relRects.map(x => x.left)
        .reduce((prev, next) => Math.min(prev, next));

      const groupElement = renderedTokenDetails.container;
      this.renderer.addClass(groupElement, "token-group");
      this.renderer.setAttribute(groupElement, "tabindex", "0");
      this.renderer.setStyle(groupElement, "left", groupLeft + "px");
      this.renderer.setStyle(groupElement, "top", groupTop + "px");

      const listener1 = this.renderer.listen(groupElement, "focus", () => this._updateSelectedToken(renderedTokenDetails));
      const listener2 = this.renderer.listen(groupElement, "blur", () => this._updateSelectedToken(undefined));

      this._currentListeners.push(listener1);
      this._currentListeners.push(listener2);

      renderedTokenDetails.listeners.push(listener1);
      renderedTokenDetails.listeners.push(listener2);

      this.renderer.appendChild(tokensElement, groupElement);
      this._currentNodes.push([tokensElement, groupElement]);

      renderedTokenDetails.elements.push(groupElement);

      const tokenText = this.renderer.createElement("div") as HTMLElement;
      this.renderer.addClass(tokenText, "token-text");
      this.renderer.appendChild(tokenText, this.renderer.createText(this.text.substring(token.startIndex, token.endIndex)));

      this.renderer.appendChild(groupElement, tokenText);
      this._currentNodes.push([groupElement, tokenText]);

      renderedTokenDetails.elements.push(tokenText);

      for (const rect of relRects) {
        const tokenElement = this.renderer.createElement("div") as HTMLElement;
        this.renderer.addClass(tokenElement, "token");
        this.renderer.setStyle(tokenElement, "left", (rect.left - groupLeft) + "px");
        this.renderer.setStyle(tokenElement, "top", (rect.top - groupTop) + "px");
        this.renderer.setStyle(tokenElement, "width", rect.width + "px");
        this.renderer.setStyle(tokenElement, "height", rect.height + "px");

        this.renderer.appendChild(groupElement, tokenElement);
        this._currentNodes.push([groupElement, tokenElement]);

        renderedTokenDetails.elements.push(tokenElement);
        renderedTokenDetails.viewElements.push(tokenElement);
      }

      this._renderedTokens.push(renderedTokenDetails);
    }
  }
}

export interface IRenderedToken {
  rects: IRect[];
  range: Range;
  token: IToken;
  container: HTMLElement;
  elements: any[];
  viewElements: any[];
  listeners: Function[];
}

export interface IToken {
  startIndex: number;
  endIndex: number;
}

export interface IRect {
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface ICoordinate {
  left: number;
  top: number;
}