import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ErrorReporting } from '../models/txtanalyser/errorreporting';
import { ITxtAnalyserService } from '../interfaces/itxtanalyser.service';
import { ITxtAnalyserServiceToken } from '../interfaces/itxtanalyser.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class TxtAnalyserErrorReportingResolver implements Resolve<ErrorReporting> {
  constructor(
    @Inject(ITxtAnalyserServiceToken) private txtAnalyserService: ITxtAnalyserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ErrorReporting> {
    const language = route.paramMap.get('language')!;
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.txtAnalyserService.getErrorReporting(language, groupId, userId);
  }
}