import { Component, OnInit, EventEmitter, Output, NgZone, Input } from '@angular/core';

@Component({
  selector: 'app-local-file-picker',
  templateUrl: './local-file-picker.component.html',
  styleUrls: ['./local-file-picker.component.scss']
})
export class LocalFilePickerComponent {
  public acceptedMimeTypes = [
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];

  @Input()
  public disabled = false;

  @Output()
  public onFile = new EventEmitter<File>();

  constructor(
    private _zone: NgZone
  ) { }

  public handleFiles(form: HTMLFormElement, input: HTMLInputElement): void {
    const files = input.files;
    if (!files) return;

    this._zone.run(() => {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) continue;

        this.onFile.emit(file);
      }

      form.reset();
    });
  }
}
