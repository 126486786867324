import { Component, Input } from '@angular/core';
import { DataTable, IDataColumn, IDataRow } from '../../models/stats';

@Component({
  selector: 'app-stats-spaced-table',
  templateUrl: './stats-spaced-table.component.html',
  styleUrls: ['./stats-spaced-table.component.scss']
})
export class StatsSpacedTableComponent {
  @Input('data')
  public data?: DataTable;

  constructor() { }

  public get columns(): IDataColumn[] {
    if (!this.data) return [];

    return this.data.cols;
  }

  public get rows(): IDataRow[] {
    if (!this.data) return [];

    return this.data.rows;
  }
}
