<app-header></app-header>

<div class="mat-elevation-z2">
    <div class="table__header">
      <div class="header__start">
        <span>{{ document!.name }}</span>
      </div>
      <div class="header__end">
        <div>
          <span i18n="@@TxtAnalyserDocumentIgnores_itemsPerPage">Items per page</span>
          <mat-form-field class="header-end__page-size">
            <mat-select [(ngModel)]="dataSource!.pageSize">
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="100">100</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@TxtAnalyserDocumentIgnores_category">Category</mat-header-cell>
        <mat-cell *matCellDef="let element"><span>{{element.category}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="subcategory">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@TxtAnalyserDocumentIgnores_subcategory">Sub category</mat-header-cell>
        <mat-cell *matCellDef="let element"><span>{{element.subCategory}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="text">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@TxtAnalyserDocumentIgnores_text">Text</mat-header-cell>
        <mat-cell *matCellDef="let element"><span>{{element.text}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="header-actions"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-actions">
            <button mat-button color="primary" [disabled]="loadingIgnores[element.id]" (click)="removeIgnore(element)">
              <mat-icon>clear</mat-icon>
              <span i18n="@@TxtAnalyserDocumentIgnores_remove">Remove</span>
            </button>
          </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  
    <mat-paginator
      [length]="dataSource!.totalItems"
      [pageIndex]="dataSource!.pageIndex"
      [pageSize]="dataSource!.pageSize"
      (page)="dataSource!.pageIndex = $event.pageIndex"
      [hidePageSize]="true">
    </mat-paginator>
  </div>