import { LoginErrorService } from './../../services/login-error.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthService, LingappsError } from '../../services/auth.service';

interface IError {
  [key: string]: boolean|undefined;
  incorrect?: boolean;
  empty?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  expired?: boolean;
}

function errorValidator(error: IError): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }

    if (rError) {
      return { 'customError': rError };
    }

    return {};
  };
}

@Component({
  selector: 'app-login-wizkids',
  templateUrl: './login-wizkids.component.html',
  styleUrls: ['./login-wizkids.scss']
})
export class LoginWizkidsComponent implements OnInit {
  public errorUsername: IError = {};
  public errorPassword: IError = {};
  public unexpectedError = false;

  public form = new FormGroup({
    'username': new FormControl('', errorValidator(this.errorUsername)),
    'password': new FormControl('', errorValidator(this.errorPassword)),
    'remember': new FormControl(false)
  });

  constructor(
    public authService: AuthService,
    private loginErrorService: LoginErrorService
  ) {}

  public resetErrors() {
    this.unexpectedError = false;
    for (let key in this.errorUsername) {
      this.errorUsername[key] = false;
    }
    for (let key in this.errorPassword) {
      this.errorPassword[key] = false;
    }
  }

  public resetUsernameErrors() {
    let foundError = false;
    for (let key in this.errorUsername) {
      if (this.errorUsername[key]) {
        foundError = true;
      }
      this.errorUsername[key] = false;
    }

    if (foundError) {
      this.form.updateValueAndValidity();
      this.form.patchValue({ 'username': this.form.get('username')!.value });
    }
  }

  public resetPasswordErrors() {
    let foundError = false;
    for (let key in this.errorPassword) {
      if (this.errorPassword[key]) {
        foundError = true;
      }
      this.errorPassword[key] = false;
    }

    if (foundError) {
      this.form.updateValueAndValidity();
      this.form.patchValue({ 'password': this.form.get('password')!.value });
    }
  }

  public ngOnInit() {
    if (this.authService.isAuthenticated())
      this.authService.redirectBack();

    if (this.loginErrorService.isUnknownError()) {
      this.unexpectedError = true;
      this.loginErrorService.setUnknownError(false);
    } else {
      this.unexpectedError = false;
    }
  }

  public onLogin() {
    if (this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    const username = this.form.get('username')!.value;
    const password = this.form.get('password')!.value;
    const remember = this.form.get('remember')!.value;

    if (!username) {
      this.errorUsername.empty = true;

      this.form.updateValueAndValidity();
      this.form.enable();
      this.form.patchValue({ 'username': "" });

      return;
    } else if (!password) {
      this.errorPassword.empty = true;

      this.form.updateValueAndValidity();
      this.form.enable();
      this.form.patchValue({ 'password': "" });

      return;
    }

    this.authService.authenticate(username, password, remember)
    .subscribe(data => {
      this.form.enable();
      this.authService.redirectBack();
    }, (err: LingappsError) => {
      if (!err) {
        this.unexpectedError = true;
        this.form.updateValueAndValidity();
        this.form.enable();
        return;
      }

      if (err.code === "USER_LOGIN_FAILED") {
        this.authService.isUsernameAvailable(username)
        .subscribe(usernameAvailable => {
          const reset: {[key: string]: string} = {};
          if (usernameAvailable) {
            reset['username'] = "";
            reset['password'] = "";
            this.errorUsername.incorrect = true;
          } else {
            reset['password'] = "";
            this.errorPassword.incorrect = true;
          }
          this.form.updateValueAndValidity();
          this.form.enable();
          this.form.patchValue(reset);
        }, (err: LingappsError) => {
          const reset: {[key: string]: string} = {};
          if (err.code === "USER_USERNAME_NOT_SET") {
            reset['username'] = "";
            this.errorUsername.empty = true;
          } else {
            console.error(err);
          }
          this.form.updateValueAndValidity();
          this.form.enable();
          this.form.patchValue(reset);
        }, () => {
          this.form.updateValueAndValidity();
          this.form.enable();
        });
      } else {
        const reset: {[key: string]: string} = {};
        if (err.code === "USER_USERNAME_NOT_SET") {
          reset['username'] = "";
          this.errorUsername.empty = true;
        } else if (err.code === "USER_PASSWORD_NOT_SET") {
          reset['password'] = "";
          this.errorPassword.empty = true;
        } else {
          reset['password'] = "";

          if (err.code === "USER_USER_INACTIVE") {
            this.errorUsername.inactive = true;
          } else if (err.code === "USER_USER_DISABLED") {
            this.errorUsername.disabled = true;
          } else if (err.code === "USER_LOGIN_PASSWORD_EXPIRED") {
            this.errorPassword.expired = true;
          }
        }

        this.form.updateValueAndValidity();
        this.form.enable();
        this.form.patchValue(reset);
      }
    });
  }
}
