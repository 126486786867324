import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageNavigationProgressService } from '../../services/page-navigation-progress.service';
import { AuthService, LingappsError } from '../../services/auth.service';
import { FormControl, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

function errorValidator(error: {[key: string]: boolean}): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public success: boolean = false;
  public errors: {[key: string]: boolean} = {};
  
  public form = new FormGroup({
    email: new FormControl('', errorValidator(this.errors))
  });

  constructor(
    private pageNavigationProgress: PageNavigationProgressService,
    private authService: AuthService,
    private router: Router
  ) {}
  
  ngOnInit() {
    this.pageNavigationProgress.complete();
  }
  
  private validateEmail(email: string): boolean {
    return /^.+@.+$/.test(email);
  }
  
  send() {
    if (this.form.disabled) return;
    this.form.disable();
    this.success = false;
    
    for (let key in this.errors) {
      this.errors[key] = false;
    }
    
    let email = this.form.get('email')!.value;
    
    if (email === "") {
      this.errors["USER_EMAIL_NOT_SET"] = true;
      this.form.enable();
      return;
    }
    
    let trimmedEmail = email.trim();
    if (!this.validateEmail(trimmedEmail)) {
      this.errors['USER_EMAIL_INVALID'] = true;
      this.form.enable();
      return;
    }
    
    this.authService.forgotUsernameOrPassword(trimmedEmail)
    .subscribe(() => {
      this.success = true;
    }, (error: LingappsError) => {
      if (error.code) {
        this.errors[error.code] = true;
      } else {
        this.errors["UNEXPECTED"] = true;
      }
      this.form.enable();
    }, () => {
      this.form.enable();
    });
  }
}
