import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Category } from '../models/spelling';
import { ISpellingService } from '../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../interfaces/ispelling.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class SpellingCategoriesResolver implements Resolve<Category[]> {
  constructor(
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Category[]> {
    const language = route.paramMap.get('language')!;
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.spellingService.getCategories(language, groupId, userId);
  }
}
