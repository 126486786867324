import { VisibleTextComponent } from './../visible-text/visible-text.component';
import { Component, Input, OnInit, OnChanges, HostListener, ElementRef,
  ViewChild, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { DataTable, IDataCell, CellType } from '../../models/stats';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-stats-column',
  templateUrl: './stats-column.component.html',
  styleUrls: ['./stats-column.component.scss']
})
export class StatsColumnComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input('data')
  public data?: DataTable;

  @Input('annotation')
  public annotation: boolean = true;

  @Input('stacked')
  public stacked: boolean = false;

  @ViewChildren(VisibleTextComponent)
  public visibleTexts?: QueryList<VisibleTextComponent>;

  public axis?: LooseAxis;
  public rowsPercentage?: IDataCellSorted[][];
  public isRtL = false;

  private hostWidth: number = 0;
  private axisWidth: number = 0;

  public hoverCellVisible: boolean = false;
  public hoverCellLabel?: string;
  public hoverCellValue: any;

  public hoverCellLeft?: number;
  public hoverCellTop?: number;

  public unitWidth?: number;

  public get numberOfColumns(): number {
    if (!this.data || this.data.numberOfColumns === undefined) return -1;

    return this.data.numberOfColumns;
  }

  @ViewChild('axisRef', { static: true })
  public axisElement?: ElementRef;

  constructor(
    private hostElement: ElementRef,
    private direction: Directionality
  ) {
    this.isRtL = direction.value === 'rtl'
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(): void {
    this.hostWidth = this.hostElement.nativeElement.offsetWidth;

    if (this.axisElement) {
      this.axisWidth = this.axisElement.nativeElement.offsetWidth;
    }
  }

  getValue(i: number, j: number): number {
    if (!this.data) return 0;
    return this.data.rows[i].cells[j].value;
  }

  getLinesOfValue(value: string): string[] {
    return value.split("\n");
  }

  isCellValueType(cell: IDataCell): boolean {
    return cell.type === CellType.value;
  }

  isCellAnnotationType(cell: IDataCell): boolean {
    return cell.type === CellType.annotation;
  }

  setHoverCell(cell: IDataCellSorted, index: number, element: HTMLElement): void {
    const rect = element.getBoundingClientRect();
    const left = rect.left + rect.width + 18;
    const top = rect.top;

    this.hoverCellVisible = true;
    if (this.data) {
      if (this.data.cols[cell.index]) {
        this.hoverCellLabel = this.data.cols[cell.index].label;
      } else {
        this.hoverCellLabel = this.data.cols[0].label;
      }
      this.hoverCellValue = this.data.rows[index].cells[cell.index].value;
    }

    this.hoverCellLeft = left;
    this.hoverCellTop = top;
  }

  clearHoverCell(): void {
    this.hoverCellVisible = false;
    this.hoverCellLabel = undefined;
    this.hoverCellValue = undefined;
    this.hoverCellLeft = undefined;
    this.hoverCellTop = undefined;
  }

  getMinValue(): number {
    if (!this.data) return 0;

    let min = Infinity; // Infinity is larger than the countable numbers.
    for (const row of this.data.rows) {
      for (const cell of row.cells) {
        if (cell.type === CellType.value) {
          min = Math.min(cell.value, min);
        }
      }
    }
    if (min === Infinity) min = 0;
    return min;
  }

  getMaxValue(): number {
    if (!this.data) return 0;

    let max = 0; // We shouldn't go below zero.
    for (const row of this.data.rows) {
      let m = 0;
      for (const cell of row.cells) {
        if (cell.type === CellType.value) {
          m += cell.value;
        }
      }
      max = Math.max(m, max);
    }
    return max;
  }

  get unit(): string|undefined {
    if (!this.data) return undefined;

    if (this.data.cols.length === 2) {
      return this.data.cols[1].label + "/" + this.data.cols[0].label;
    } else if (this.data.cols.length === 1) {
      return this.data.cols[0].label;
    } else {
      return "";
    }
  }

  getQueryParams(cell: IDataCell): any {
    let query: {[key: string]: string} = {};
    if (cell && cell.hrefQuery) {
      query = Object.assign({}, cell.hrefQuery);
    }
    if (this.data && this.data.language) {
      query['language'] = this.data.language;
    }

    return query;
  }

  ngOnInit(): void {
    this.updateData();

    this.onResize();

    this.updateColumnAxisVisibility();
    setTimeout(() => this.updateColumnAxisVisibility(), 7);
  }

  ngOnChanges(): void {
    this.updateData();

    this.onResize();

    this.updateColumnAxisVisibility();
    setTimeout(() => this.updateColumnAxisVisibility(), 7);
  }

  ngAfterViewChecked(): void {
    this.onResize();
  }

  hasLegend(): boolean {
    return this.stacked;
  }

  getAxisHeight(): number {
    if (this.isSlim()) {
      let width = 100;
      if (this.annotation) width += 45;

      // Rotate point (width, 0) 45 degrees around origin (0, 0) to get the
      // height of the axis.
      return width*Math.sin(45 * Math.PI / 180);
    } else {
      return 20;
    }
  }

  isSlim(): boolean {
    const minWidth = 56;

    let rowsPercentageLength = 0;
    if (this.rowsPercentage) {
      rowsPercentageLength = this.rowsPercentage.length;
    }

    const idealMinWidth = this.axisWidth + rowsPercentageLength*minWidth;

    return this.hostWidth < idealMinWidth;
  }

  hasAnnotations(): boolean {
    if (!this.data) return false;
    for (const row of this.data.rows) {
      for (const cell of row.cells) {
        if (cell.type === CellType.annotation) {
          return true;
        }
      }
    }
    return false;
  }

  updateColumnAxisVisibility(): void {
    const visible = this.isColumnAxisVisible();
    if (!this.visibleTexts) return;
    const texts = this.visibleTexts.toArray();
    for (let i = 0; i < texts.length; i++) {
      texts[i].visible = visible;
    }
  }

  isColumnAxisVisible(): boolean {
    if (!this.visibleTexts) return false;
    const texts = this.visibleTexts.toArray();
    if (texts.length === 0) return false;

    for (let i = 0; i < texts.length; i++) {
      if (!texts[i].isVisible()) return false;
    }
    return true;
  }

  getFirstCellAnnotation(cells: IDataCellSorted[]): IDataCellSorted|undefined {
    for (let i = 0; i < cells.length; i++) {
      if (this.isCellAnnotationType(cells[i]))
        return cells[i];
    }
    return undefined;
  }

  hasCellAnnotationType(cells: IDataCellSorted[]): boolean {
    for (let i = 0; i < cells.length; i++) {
      if (this.isCellAnnotationType(cells[i]))
        return true;
    }
    return false;
  }

  updateData(): void {
    let min = this.getMinValue();
    let max = this.getMaxValue();

    // Min and max can't be the same value when calculating the loose axis.
    if (min === max) {
      max += 10;
    }
    if (max === 1) max = 10;

    this.axis = getLooseAxis(min, max);
    this.axis.values.reverse(); // Reverse the values due to going from bottom.

    this.rowsPercentage = [];

    if (!this.data) return;
    for (let i = 0; i < this.data.rows.length; i++) {
      let cells: IDataCellSorted[] = [];

      // Extend the interface with index, because we're sorting it and we still
      // want to keep the information about the original order intact.
      let rowCopy = this.data.rows[i];
      for (let i = 0; i < rowCopy.cells.length; i++) {
        cells.push({
          href: rowCopy.cells[i].href,
          hrefQuery: rowCopy.cells[i].hrefQuery,
          value: rowCopy.cells[i].value,
          type: rowCopy.cells[i].type,
          index: i
        });
      }

      for (let i = 1; i < cells.length; i++) {
        // TODO consider that some values can be strings. Currently we're just
        // skipping index 0 due to it always being a string in this case.
        if (cells[i].type !== CellType.value) continue;

        cells[i].value = cells[i].value/this.axis.max;
      }

      let values = cells.splice(1, cells.length - 1);
      values.reverse();
      cells.push(...values);

      this.rowsPercentage.push(cells);
    }
  }
}

interface IDataCellSorted extends IDataCell {
  index: number
}

class LooseAxis {
  constructor(
    /** The values on the axis */
    public values: number[],

    /** The fractional digits to show */
    public nfrac: number,

    /** The minimum value on the axis */
    public min: number,

    /** The maximum value on the axis */
    public max: number,

    /** The interval between every axis tick */
    public tickInterval: number
  ) {}
}

function getLooseAxis(min: number, max: number, desiredTicks: number = 5): LooseAxis {
  if (min === max) throw new Error("min and max can't be the same value");

  const range: number = nicenum(max - min, false);

  /** The tick mark spacing */
  const d: number = nicenum(range/(desiredTicks - 1), true);

  const graphmin: number = Math.floor(min/d)*d;
  const graphmax: number = Math.ceil(max/d)*d;

  /** # of fractional digits to show */
  const nfrac = Math.max(-Math.floor(Math.log10(d)), 0);

  const values: number[] = [];
  for (let i = graphmin; i < graphmax + 0.5*d; i += d) {
    if (i === 0) continue;
    values.push(Math.round(i*100)/100);
  }

  return new LooseAxis(values, nfrac, graphmin, graphmax, d);
}

function expt(a: number, n: number): number {
  let x: number = 1;
  if (n > 0)
    for (; n > 0; n--) x *= a;
  else
    for (; n < 0; n++) x /= a;
  return x;
}

function nicenum(x: number, round: boolean): number {
  /** Exponent of x */
  const expv: number = Math.floor(Math.log10(x));

  /** fractional part of x */
  const f: number = x/expt(10, expv);

  /** nice, rounded fraction */
  let nf: number;

  if (round) {
    if (f < 1.5) nf = 1;
    else if (f < 3) nf = 2;
    else if (f < 7) nf = 5;
    else nf = 10;
  } else {
    if (f <= 1) nf = 1;
    else if (f <= 2) nf = 2;
    else if (f <= 5) nf = 5;
    else nf = 10;
  }

  return nf * expt(10, expv);
}
