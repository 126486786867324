<app-page-navigation-progress></app-page-navigation-progress>
<nav class="pinned-top">
  <mat-toolbar class="mat-elevation-z2">
    <div class="toolbar__wrapper">
      <div class="toolbar__align">
        <span class="fill-left-space">
          <a [routerLink]="routerParent!.url" [queryParams]="routerParent!.query" *ngIf="routerParent && routerParent!.url">
            <div class="back__wrapper">
              <mat-icon mat-list-icon svgIcon="app:back" class="back__icon"></mat-icon>
              <span class="back__label desktop" i18n="@@App_Back">Back</span>
            </div>
          </a>
        </span>
        <a routerLink="/" class="logo"><img src="/assets/logo.png"></a>
        <span class="fill-right-space">
          <app-user-info [user]="authService.user" *ngIf="authService.isAuthenticated()"></app-user-info>
        </span>
      </div>
    </div>
  </mat-toolbar>
</nav>

<main>
  <div class="main__content">
    <router-outlet *ngIf="pageNavigationProgressService.isCompleted()"></router-outlet>
  </div>
</main>

<footer>
  <div class="inner-footer">
    <img src="/assets/logo.png">
    <a href="https://www.wizkids.dk/privatlivspolitik/" *ngIf="isDanishLocale()">Privatlivspolitik</a>
    <a href="https://www.wizkids.co.uk/privacy-policy/" *ngIf="isEnglishLocale() || !isDanishLocale() && !isSwedishLocale()">Privacy Policy</a>
    <a href="https://www.wizkids.se/sekretesspolicy/" *ngIf="isSwedishLocale()">Sekretesspolicy</a>
  </div>
</footer>