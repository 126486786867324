import { Observable } from 'rxjs';

export enum RenderType {
  table, column, 'spaced-table'
}

export enum CellType {
  label, value, annotation
}

export interface IPage {
  id: string;
  title: string;
  language?: string;

  isLanguageSupported(): boolean;
  isTimePeriodSupported(): boolean;
  getAreas(language?: string): Observable<IArea[]>;
}

export interface IArea {
  id: string;
  pageId: string;
  title: string;
  language?: string;
  isStandalone?: boolean;

  getCharts(language?: string, zoom?: string): Observable<IChart[]>;
}

export interface IChart {
  /** The chart ID */
  id: string;

  /** The area ID */
  areaId: string;

  /** The page ID */
  pageId: string;

  /** The optional group ID */
  groupId?: string;

  /** The optional impersonated user ID, requires group ID */
  impersonatedUserId?: string;

  /** Whether this chart is the default chart */
  isDefault: boolean;

  /** The language of the data. */
  language?: string;

  /** The limit of the data shown */
  limit: number;

  /** The title of the chart group */
  groupedChartTitle?: string;

  /** The title of the chart */
  title: string;

  /** Whether the chart is extended */
  extended: boolean;

  /** The start date */
  startDate: Date;

  /** The end date */
  endDate: Date;

  /** The type of the chart */
  chartType: string;

  /** Whether to include a pie chart */
  includePieChart: boolean;

  /** The zoom level */
  zoom: string;

  link?: IChartLink;

  getData(language?: string, zoom?: string, startDate?: Date, endDate?: Date): Observable<DataTable>;
  getDataOrigin(): Observable<IChartOrigin>;
  getTitleLink(): string|undefined;
}

export interface IChartLink {
  text: string;
  href: string;
}

export interface IChartOrigin {
  title: string;
  info: string;
}

export interface IDataColumn {
  label?: string,
  href?: string,
  type: string
}

export interface IDataCell {
  value: any,
  href?: string,
  hrefQuery?: any,
  type: CellType
}

export interface IDataGroup {
  rows: IDataRow[];
}

export interface IData {
  cols: IDataColumn[];
  rows: IDataRow[];
  groups: IDataGroup[];
  title?: string;
  dataOriginTitle?: string;
  dataOriginInfo?: string;
  language?: string;
  links?: IDataLink[];
  numberOfColumns?: number;
}

export interface IDataOptions {
  id: string,
  zoom?: string,
  language?: string,
  from?: Date,
  to?: Date,
  limit?: number,
  extended?: string,
  relativeLink?: (string|undefined)[]
}

export interface IDataLink {
  text: string,
  href: string|string[]
}

export interface IDataRow {
  cells: IDataCell[];
  isHeader?: boolean;
}

export class DataTable {
  public title?: string;
  public cols: IDataColumn[];
  public rows: IDataRow[];
  public groups: IDataGroup[];
  public dataOriginTitle?: string;
  public dataOriginInfo?: string;
  public language?: string;
  public links?: IDataLink[];
  public numberOfColumns?: number;

  constructor(
    data?: IData
  ) {
    if (data) {
      this.validateData(data);

      this.cols = data.cols;
      this.rows = data.rows;
      this.groups = data.groups;
      this.title = data.title;
      this.dataOriginInfo = data.dataOriginInfo;
      this.dataOriginTitle = data.dataOriginTitle;
      this.language = data.language;
      this.links = data.links;
      this.numberOfColumns = data.numberOfColumns;
    } else {
      this.cols = [];
      this.rows = [];
      this.groups = [];
    }
  }

  private validateData(data: IData) {
    let columnsSize = data.cols.length;

    for (const row of data.rows) {
      let len = 0;
      for (const cell of row.cells) {
        if (cell.type !== CellType.annotation) {
          len++;
        }
      }

      /*if (len !== columnsSize)
        throw new Error("Row at index " + i + " has size " + len + " where column size is " + columnsSize + ".");*/
    }

    for (const row of data.rows) {
      let valueEnded: boolean = false;
      for (let i = 0; i < row.cells.length; i++) {
        if (row.cells[i].type === CellType.label && i > 0)
          throw new Error("Only the first cell in a row can be a label.");
        if (row.cells[i].type !== CellType.label && i === 0)
          throw new Error("The first cell in a row can only be a label.");
        if (valueEnded && row.cells[i].type === CellType.value)
          throw new Error("After a non value only the annotation type is allowed.");
        if (row.cells[i].type !== CellType.label && row.cells[i].type !== CellType.value)
          valueEnded = true;
        if (row.cells[i].type === CellType.annotation) continue;
      }
    }
  }
}
