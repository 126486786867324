import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-account-snackbar',
  templateUrl: './glossaries-search-snackbar.component.html',
  styleUrls: ['./glossaries-search-snackbar.component.scss']
})
export class GlossariesSearchSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public type: GlossariesSearchSnackbarType
  ) {}

  isSubscribe() {
    return this.type === GlossariesSearchSnackbarType.Subscribe;
  }

  isUnsubscribe() {
    return this.type === GlossariesSearchSnackbarType.Unsubscribe;
  }

  isGroupSubscribe() {
    return this.type === GlossariesSearchSnackbarType.GroupSubscribe;
  }

  isGroupUnsubscribe() {
    return this.type === GlossariesSearchSnackbarType.GroupUnsubscribe;
  }
}

export enum GlossariesSearchSnackbarType {
  Subscribe,
  Unsubscribe,
  GroupSubscribe,
  GroupUnsubscribe
}
