<ng-container [ngSwitch]="_">
  <ng-container *ngSwitchCase="keys['root']" i18n="@@PageTitle_FrontPage">Front page</ng-container>
  <ng-container *ngSwitchCase="keys['account']" i18n="@@PageTitle_YourAccount">Your account</ng-container>
  <ng-container *ngSwitchCase="keys['profile']" i18n="@@PageTitle_YourProfile">Your profile</ng-container>
  <ng-container *ngSwitchCase="keys['groups']" i18n="@@PageTitle_YourGroups">Your groups</ng-container>
  <ng-container *ngSwitchCase="keys['templates']" i18n="@@PageTitle_Templates">Templates</ng-container>
  <ng-container *ngSwitchCase="keys['aw']" i18n="@@PageTitle_AppWriter">AppWriter</ng-container>
  <ng-container *ngSwitchCase="keys['stats']" i18n="@@PageTitle_Statistics">Statistic</ng-container>
  <ng-container *ngSwitchCase="keys['glossaries']" i18n="@@PageTitle_Glossaries">Glossaries</ng-container>
  <ng-container *ngSwitchCase="keys['spelling']" i18n="@@PageTitle_Spelling">Spelling</ng-container>
  <ng-container *ngSwitchCase="keys['activity']" i18n="@@PageTitle_Activity">Activity</ng-container>
  <ng-container *ngSwitchCase="keys['errors']" i18n="@@PageTitle_ErrorTypes">Error types</ng-container>
  <ng-container *ngSwitchCase="keys['predictions']" i18n="@@PageTitle_SuggestedWords">Used predictions</ng-container>
  <ng-container *ngSwitchCase="keys['all_predictions']" i18n="@@PageTitle_AllPredictions">All predictions</ng-container>
  <ng-container *ngSwitchCase="keys['all_predictions_chronological']" i18n="@@PageTitle_AllPredictions">All predictions</ng-container>
  <ng-container *ngSwitchCase="keys['year_week']" i18n="@@PageTitle_Week">Week {{ data[1] }} - {{ data[0] }}</ng-container>
  <ng-container *ngSwitchCase="keys['search']" i18n="@@PageTitle_Search">Subscribe to glossaries</ng-container>
  <ng-container *ngSwitchCase="keys['categories']" i18n="@@PageTitle_Categories">Categories</ng-container>
  <ng-container *ngSwitchCase="keys['errorreporting']" i18n="@@PageTitle_ErrorReporting">Error reporting</ng-container>
  <ng-container *ngSwitchCase="keys['ignores']" i18n="@@PageTitle_DocumentIgnores">Ignores</ng-container>
  <ng-container *ngSwitchCase="keys['sentences']" i18n="@@PageTitle_StatsSentences">Sentence statistics</ng-container>
  <ng-container *ngSwitchCase="keys['errors_details_ta']" i18n="@@PageTitle_ErrorsDetailsTa">Details - TxtAnalyser</ng-container>
  <ng-container *ngSwitchCase="keys['errors_details_aw']" i18n="@@PageTitle_ErrorsDetailsAw">Details - AppWriter</ng-container>
  <ng-container *ngSwitchCase="'ta'" i18n="@@PageTitle_TxtAnalyser">TxtAnalyser</ng-container>
  <ng-container *ngSwitchCase="'ms'" i18n="@@PageTitle_MathSolver">MathSolver</ng-container>
  <ng-container *ngSwitchCase="'ta/upload'" i18n="@@PageTitle_DocumentUpload">Upload and reports</ng-container>
  <ng-container *ngSwitchCase="'ta/upload/all'" i18n="@@PageTitle_DocumentAll">Documents</ng-container>
  <ng-container *ngSwitchCase="'ta/upload/result'" i18n="@@PageTitle_AnalysisResults">Analysis results</ng-container>
  <ng-container *ngSwitchCase="'ta/upload/result/document'" i18n="@@PageTitle_DocumentAll">Document</ng-container>
  <ng-container *ngSwitchDefault>{{ label }}</ng-container>
</ng-container>
