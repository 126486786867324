import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserDetail } from '../models/account';
import { IAccountService } from '../interfaces/iaccount.service';
import { IAccountServiceToken } from '../interfaces/iaccount.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class AccountUserDetailResolver implements Resolve<UserDetail> {
  constructor(
    @Inject(IAccountServiceToken) private accountService: IAccountService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserDetail> {
    return this.accountService.getUserDetail();
  }
}
