import { Injectable } from '@angular/core';
import { Collection } from './collection.service';
import { BreadcrumbService } from './breadcrumb.service';

@Injectable()
export class BreadcrumbMiddlewareService {
  constructor(
    private breadcrumbService: BreadcrumbService
  ) {}

  middleware(collection: Collection) {
    const labels = [];

    if (collection) {
      const group = collection.getLink("group");
      if (group) {
        labels.push(group.prompt);
      }

      const user = collection.getLink("user");
      if (user) {
        labels.push(user.prompt);
      }
    }

    if (labels.length > 0) {
      this.breadcrumbService.setLabels(labels);
    }

    return collection;
  }
}