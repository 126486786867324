<h1 mat-dialog-title i18n="@@EditConfirmGlossary_Header">Update glossary</h1>
<mat-dialog-content>
  <span i18n="@@EditConfirmGlossary_Description">
    You're about to make the glossary private. All subscribed users will no
    longer be able to access or use this glossary. Are you sure you want to
    update the glossary?
  </span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    <span i18n="@@EditConfirmGlossary_btnCancel">Cancel</span>
  </button>
  <button (click)="onUpdate()" mat-raised-button color="primary">
    <span i18n="@@EditConfirmGlossary_btnUpdate">Update</span>
  </button>
</mat-dialog-actions>