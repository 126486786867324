import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplatesService } from '../../services/templates.service';
import { ITemplate } from '../../models/template';
import { Language } from '../../models/language';
import { UserDetail } from '../../models/account';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-templates',
  templateUrl: 'templates.component.html',
  styleUrls: ['templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  public templates: Record<string, Record<string, ITemplate[]| boolean>> = {};
  public categories: string[] = [];
  public language = '';
  public languages: Language[] = [];
  public userDetail?: UserDetail;

  constructor(private route: ActivatedRoute, private service: TemplatesService) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.userDetail = data.userDetail;
    })

    this.service.getTemplatesLanguages().pipe(
      switchMap(languages => {
        this.languages = languages;

        if (languages.length > 0) {
          const userLanguage = this.userDetail?.language;
          this.language =
            userLanguage && languages.some(l => l.code === userLanguage)
              ? userLanguage
              : this.languages[0].code;
        }

        return this.service.getTemplates(this.language)
      })
    ).subscribe(templates => {
      this.templates = templates
      this.categories = Object.keys(templates)
    });
  }

  toggleExpandCategory(category: string) {
    this.templates[category].expanded = !this.templates[category].expanded
  }

  changeLanguage() {
    this.service.getTemplates(this.language).subscribe(data => {
      this.templates = data
      this.categories = Object.keys(data)
    })
  }
}
