<app-header></app-header>

<div class="options">
  <div *ngIf="page?.isLanguageSupported()">
    <h2 i18n="@@Stats_Language">Language</h2>
    <mat-form-field>
      <mat-select [(ngModel)]="language" (selectionChange)="onLanguageChange()">
        <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="page?.isTimePeriodSupported()">
    <h2 i18n="@@Stats_TimePeriod">Time Period</h2>
    <mat-form-field *ngIf="zoom === 'week' || !zoom">
      <mat-select [(ngModel)]="monthValue" (selectionChange)="changeDateRange()">
        <mat-option [value]="12" i18n="@@Stats_LastYear">Last year</mat-option>
        <mat-option [value]="6" i18n="@@Stats_LastSixMonths">Last 6 months</mat-option>
        <mat-option [value]="3" i18n="@@Stats_LastThreeMonths">Last 3 months</mat-option>
        <mat-option [value]="1" i18n="@@Stats_LastMonth">Last month</mat-option>
        <mat-option [value]="0" i18n="@@Stats_OtherPeriod">Other period</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="zoom === 'day'">
      <mat-select [(ngModel)]="week" (selectionChange)="changeDateRange()">
        <mat-option *ngFor="let week of weeks" [value]="week" i18n="@@Stats_Week">Week {{ week }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<app-stats-area *ngFor="let area of areas" [area]="area" [language]="language"></app-stats-area>