import { IPage, IArea } from './../../models/stats';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { Language } from '../../models/language';
import { flatMap } from 'rxjs/operators';
import { DatePeriodDialogComponent, IDatePeriod } from '../date-period-dialog/date-period-dialog.component';
import * as moment  from 'moment';
import { MatDialog } from '@angular/material/dialog';

interface IRouteData {
  languages: Language[],
  page: IPage
}

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit, OnDestroy {
  private _routeSubscription?: Subscription;
  private _languageSubscription?: Subscription;
  private _queryParamMapSubscription?: Subscription;

  public loading: boolean = false;

  public page?: IPage;
  public areas: IArea[] = [];

  public language?: string;
  public languages?: Language[];

  public zoom?: string;

  public year: number = 2018;
  public week: number = 1;

  public monthValue: number = 3;
  public oldMonthValue: number = 3;

  public startDate?: Date;
  public endDate?: Date;

  public customStartDate?: Date;
  public customEndDate?: Date;

  public get weeks(): number[] {
    const weeksInYear = moment().year(this.year).weeksInYear();

    const weeks: number[] = [];
    for (let i = 0; i < weeksInYear; i++) {
      weeks.push(i + 1);
    }

    return weeks;
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _zone: NgZone,
    private _dialog: MatDialog
  ) {
  }

  public onLanguageChange() {
    if (this.language) {
      window.localStorage.setItem('language.statistics', this.language);
    }

    const segments = this._route.snapshot.url;
    const commands = [];
    for (let i = 0; i < segments.length; i++) {
      commands.push(segments[i].path);
      if (segments[i].path === 'stats' && i < segments.length - 1) {
        i++;
        commands.push(this.language);
      }
    }
    this._router.navigateByUrl(this._router.createUrlTree(commands, {
      queryParams: this._getQueryParams()
    }));
  }

  public ngOnInit() {
    this.loading = true;
    const snapshot = this._route.snapshot;
    this.language = snapshot.params['language'];
    this.onQueryParamMapChange(snapshot.queryParamMap);

    this._routeSubscription = this._route.data
    .pipe(flatMap(value => {
      const data = value as IRouteData;
      this._zone.run(() => {
        this.page = data.page;
        this.languages = data.languages;
      });

      return data.page.getAreas();
    }))
    .subscribe((areas: IArea[]) => {
      this._zone.run(() => {
        this.areas = areas;

        this.loading = false;
      });
    });
  }

  public ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
      this._routeSubscription = undefined;
    }
    if (this._languageSubscription) {
      this._languageSubscription.unsubscribe();
      this._languageSubscription = undefined;
    }
    if (this._queryParamMapSubscription) {
      this._queryParamMapSubscription.unsubscribe();
      this._queryParamMapSubscription = undefined;
    }
  }

  public onQueryParamMapChange(params: ParamMap) {
    if (params.has('year') && params.has('week')) {
      this.year = parseInt(params.get("year")!, 10);
      this.week = parseInt(params.get("week")!, 10);

      this.startDate = moment().year(this.year).week(this.week).startOf('week').toDate();
      this.endDate = moment().year(this.year).week(this.week).endOf('week').toDate();

      this.zoom = 'day';
    } else if (params.has('startDate') && params.has('endDate')) {
      this.startDate = moment(params.get('startDate')!, 'DD-MM-YYYY').toDate();
      this.endDate = moment(params.get('endDate')!, 'DD-MM-YYYY').toDate();
      this.monthValue = 0;
      this.zoom = undefined;
    } else if (params.has('month')) {
      this.monthValue = parseInt(params.get('month')!, 10);

      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - this.monthValue);
      this.startDate = startDate;
      this.endDate = new Date();

      this.zoom = undefined;
    } else {
      this.zoom = undefined;

      if (this.monthValue === 0) {
        this.startDate = this.customStartDate;
        this.endDate = this.customEndDate;
      } else {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - this.monthValue);
        this.startDate = startDate;
        this.endDate = new Date();
      }
    }
  }

  private _getQueryParams(): Params {
    if (this.monthValue === 0) {
      return {
        startDate: moment(this.startDate).format('DD-MM-YYYY'),
        endDate: moment(this.endDate).format('DD-MM-YYYY')
      };
    } else if (this.zoom === 'week' || !this.zoom) {
      return {
        month: this.monthValue,
      };
    } else if (this.zoom === 'day') {
      return {
        week: this.week,
        year: this.year
      };
    } else {
      return {};
    }
  }

  public changeDateRange() {
    if (this.monthValue === 0) {
      const ref = this._dialog.open(DatePeriodDialogComponent, {
        data: {
          end: new Date()
        },
        width: '750px'
      });
      if (ref.componentInstance.onCancel) {
        ref.componentInstance.onCancel.subscribe(() => {
          this._zone.run(() => {
            this.monthValue = this.oldMonthValue;
          });
        });
      }
      if (ref.componentInstance.onDate) {
        ref.componentInstance.onDate.subscribe((datePeriod: IDatePeriod) => {
          this._zone.run(() => {
            this.startDate = datePeriod.start;
            this.endDate = datePeriod.end;
            this.customStartDate = datePeriod.start;
            this.customEndDate = datePeriod.end;

            this._router.navigate([], {
              relativeTo: this._route,
              queryParams: this._getQueryParams()
            });

            this.oldMonthValue = this.monthValue;
          });
        });
      }
    } else {
      if (this.zoom === 'week' || !this.zoom) {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - this.monthValue);
        this.startDate = startDate;
        this.endDate = new Date();

        this._router.navigate([], {
          relativeTo: this._route,
          queryParams: this._getQueryParams()
        });
      } else if (this.zoom === 'day') {
        const startDate: Date = moment().year(this.year).week(this.week).startOf('week').toDate();
        const endDate: Date = moment().year(this.year).week(this.week).endOf('week').toDate();

        this._router.navigate([], {
          relativeTo: this._route,
          queryParams: this._getQueryParams()
        });

        this.startDate = startDate;
        this.endDate = endDate;
      }
      this.oldMonthValue = this.monthValue;
    }
  }
}
