import { DataOriginActivityDialogComponent } from './../data-origin-activity-dialog/data-origin-activity-dialog.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePeriodDialogComponent, IDatePeriod } from './../date-period-dialog/date-period-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { IArea, IChart } from './../../models/stats';
import { Component, OnInit, OnDestroy, Input, NgZone } from '@angular/core';
import * as moment from 'moment';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-stats-area',
  templateUrl: './stats-area.component.html',
  styleUrls: ['./stats-area.component.scss']
})
export class StatsAreaComponent implements OnInit, OnDestroy {
  private _chartsSubscription?: Subscription;
  private _queryParamMapSubscription?: Subscription;

  private _changesDetected = false;

  public charts: IChart[] = [];
  public groupedCharts: IChart[][] = [];

  public zoom?: string;

  public year = 2018;
  public week = 1;

  public monthValue = 3;
  public oldMonthValue = 3;

  public startDate?: Date;
  public endDate?: Date;

  public customStartDate?: Date;
  public customEndDate?: Date;

  private _init = false;

  private _language = 'da-DK';

  @Input()
  get language(): string { return this._language; }
  set language(language: string) {
    if (this._language !== language) {
      this._language = language;

      this._changesDetected = true;
    }
  }

  @Input()
  public selectedChartIndex = 0;

  @Input()
  get area(): IArea {
    return this._area;
  }
  set area(area: IArea) {
    if (area !== this._area) {
      this.selectedChartIndex = 0;
      this._area = area;

      this._changesDetected = true;
    }
  }
  private _area: IArea = {
    id: '',
    pageId: '',
    title: '',

    getCharts: () => of([])
  };

  get summaryDatePeriod(): string {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    const endDate = new Date();

    if (this._direction.value === 'rtl') {
      return moment(endDate).format('MM/DD') + ' - ' + moment(startDate).format('MM/DD');
    }
    return moment(startDate).format('DD/MM') + ' - ' + moment(endDate).format('DD/MM');
  }

  get pageId(): string {
    return this._area.pageId;
  }

  get title(): string {
    return this._area.title;
  }

  get href(): string|undefined {
    if (this.chart) {
      return this.chart.getTitleLink();
    } else {
      return undefined;
    }
  }

  get chart(): IChart|undefined {
    if (this.selectedChartIndex < 0 || this.selectedChartIndex - 1 > this.charts.length) { return undefined; }

    return this.charts[this.selectedChartIndex];
  }

  get chartType(): string|undefined {
    const chart = this.chart;
    if (!chart) { return undefined; }

    return chart.chartType;
  }

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _direction: Directionality,
  ) {}

  private _getGroupedCharts(charts: IChart[]): IChart[][] {
    const rv: {[key: string]: IChart[]} = {};
    const nonGrouped = [];

    for (const chart of charts) {
      if (chart.groupedChartTitle === undefined) {
        nonGrouped.push([chart]);
      } else {
        if (!rv.hasOwnProperty(chart.groupedChartTitle)) {
          rv[chart.groupedChartTitle] = [];
        }
        rv[chart.groupedChartTitle].push(chart);
      }
    }

    return [...nonGrouped, ...Object.keys(rv).map(key => rv[key])];
  }

  private _loadCharts() {
    if (this._chartsSubscription) {
      this._chartsSubscription.unsubscribe();
      this._chartsSubscription = undefined;
    }
    if (!this._init) { return; }

    if (this._area) {
      this._chartsSubscription = this._area.getCharts(this.language, this.zoom)
        .subscribe(charts => {
          this.charts = charts;
          this.groupedCharts = this._getGroupedCharts(charts);
        });
    } else {
      this.charts = [];
      this.groupedCharts = [];
    }
  }

  public getHrefByChart(chart: IChart) {
    if (chart) {
      return chart.getTitleLink();
    } else {
      return undefined;
    }
  }

  public getTitleByChart(chart: IChart) {
    if (!chart || !chart.title) { return this._area.title; }
    return chart.title;
  }

  public onQueryParamMapChange(params: ParamMap) {
    if (params.has('year') && params.has('week')) {
      this.year = parseInt(params.get('year')!, 10);
      this.week = parseInt(params.get('week')!, 10);

      this.startDate = moment().year(this.year).week(this.week).startOf('week').toDate();
      this.endDate = moment().year(this.year).week(this.week).endOf('week').toDate();

      this.zoom = 'day';
    } else if (params.has('startDate') && params.has('endDate')) {
      this.startDate = moment(params.get('startDate')!, 'DD-MM-YYYY').toDate();
      this.endDate = moment(params.get('endDate')!, 'DD-MM-YYYY').toDate();
      this.monthValue = 0;
      this.zoom = undefined;
    } else if (params.has('month')) {
      this.monthValue = parseInt(params.get('month')!, 10);

      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - this.monthValue);
      this.startDate = startDate;
      this.endDate = new Date();

      this.zoom = undefined;
    } else {
      this.zoom = undefined;

      if (this.monthValue === 0) {
        this.startDate = this.customStartDate;
        this.endDate = this.customEndDate;
      } else {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - this.monthValue);
        this.startDate = startDate;
        this.endDate = new Date();
      }
    }
  }

  public ngOnInit() {
    this._init = true;

    this._queryParamMapSubscription = this._route.queryParamMap.subscribe((params) => this.onQueryParamMapChange(params));

    const snapshot = this._route.snapshot;
    this.onQueryParamMapChange(snapshot.queryParamMap);

    this._loadCharts();
  }

  public ngOnDestroy() {
    this._init = false;

    if (this._chartsSubscription) {
      this._chartsSubscription.unsubscribe();
      this._chartsSubscription = undefined;
    }
    if (this._queryParamMapSubscription) {
      this._queryParamMapSubscription.unsubscribe();
      this._queryParamMapSubscription = undefined;
    }
  }

  public ngOnChanges(): void {
    if (this._changesDetected) {
      this._changesDetected = false;
      this._loadCharts();
    }
  }

  public viewDataOrigin() {
    if (!this.chart) { return; }
    this.chart.getDataOrigin()
      .subscribe((dataOrigin) => {
        this._dialog.open(DataOriginActivityDialogComponent, {
          data: dataOrigin,
          width: '750px'
        });
      });
  }
}
