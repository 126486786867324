import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IGlossary } from '../models/glossary';
import { IGlossaryService } from '../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../interfaces/iglossary.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class GlossariesResolver implements Resolve<IGlossary[]> {
  constructor(
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IGlossary[]> {
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.glossaryService.getGlossaries(groupId, userId);
  }
}
