import { Component, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-navigation-progress',
  templateUrl: './page-navigation-progress.component.html',
  styleUrls: ['./page-navigation-progress.component.scss']
})
export class PageNavigationProgressComponent {
  @Input()
  private value: number = 0;
  
  @Input()
  private visible: boolean = false;
  
  public transitionend: EventEmitter<any> = new EventEmitter();
  
  constructor() {}

  setValue(value: number): void {
    if (value < 0 || value > 1)  throw new Error("Value out of bounds [0, 1].");
    
    this.value = value;
  }
  
  getValue(): number {
    return this.value;
  }
  
  setVisible(visible: boolean): void {
    this.visible = visible;
  }
  
  isVisible(): boolean {
    return this.visible;
  }
}
