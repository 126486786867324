<div class="card-outer">
  <mat-card>
    <mat-card-title i18n="@@ForgotPassword_Header">
      Forgot your password?
    </mat-card-title>
    <mat-card-content>
      <span i18n="@@ForgotPassword_Info">
        If you've forgotten your username or password enter your email here and an
        email will be sent with instruction on how to recover your account.
      </span>
      <form (ngSubmit)="send()" [formGroup]="form" novalidate>
        <div class="form">
          <mat-form-field class="input-full-width" hideRequiredMarker="true">
            <mat-placeholder i18n="@@ForgotPassword_YourEmail">Your email</mat-placeholder>
            <input matInput name="email" formControlName="email" focus>
            <mat-error *ngIf="errors['USER_EMAIL_NOT_SET']" i18n="@@ForgotPassword_EnterEmail">Please enter an email.</mat-error>
            <mat-error *ngIf="errors['USER_EMAIL_INVALID']" i18n="@@ForgotPassword_EnterValidEmail">Please enter a valid email.</mat-error>
            <mat-error *ngIf="errors['USER_EMAIL_UNKNOWN']" i18n="@@ForgotPassword_UnknownEmail">Email is unknown.</mat-error>
            <mat-error *ngIf="errors['UNEXPECTED']" i18n="@@ForgotPassword_Unexpected">An unexpected error occurred.</mat-error>
          </mat-form-field>
        </div>

        <div class="success" *ngIf="success" i18n="@@ForgotPassword_ForgotSuccessInfoText">
          An email has been sent to you with the instruction on how to recover your account.
        </div>
        
        <div class="actions">
          <a mat-button routerLink="/login" i18n="@@ForgotPassword_btnCancel">Cancel</a>
          <button mat-raised-button color="primary" [disabled]="form.disabled">Send</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>