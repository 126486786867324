import { PhoneticSpellingsSnackbarComponent, PhoneticSpellingsSnackbarType } from '../phonetic-spellings-snackbar/phonetic-spellings-snackbar.component';
import { Component, Inject, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Category } from '../../models/txtanalyser/category';
import { Rule } from '../../models/txtanalyser/rule';
import { Language } from '../../models/language';

import { MediaQueryService } from '../../services/media-query.service';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';

interface IRouteData {
  categories: Category[];
  languages: Language[];
}

interface ICategoryMetadata {
  rules?: Rule[];
  rulesMetadata?: IRuleMetadata[];
  category: Category,
  expanded: boolean;
  loading: boolean;
  enableChanging: boolean;
}

interface IRuleMetadata {
  enableChanging: boolean;
}

@Component({
  selector: 'app-txtanalyser-categories',
  templateUrl: './txtanalyser-categories.component.html',
  styleUrls: ['./txtanalyser-categories.component.scss']
})
export class TxtAnalyserCategoriesComponent implements OnInit, OnDestroy {
  public categoryMetadata: ICategoryMetadata[] = [];
  public categories: Category[] = [];
  public languages: Language[] = [];
  public language: string|undefined;

  public userId?: string;
  public groupId?: string;

  private routeSubscription?: Subscription;
  private opSubscriptions?: Subscription[] = [];

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    @Inject(ITxtAnalyserServiceToken) private txtanalyserService: ITxtAnalyserService,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone
  ) {}
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this.groupId = this.route.snapshot.paramMap.has('groupId') ? this.route.snapshot.paramMap.get('groupId')! : undefined;
    this.userId = this.route.snapshot.paramMap.has('userId') ? this.route.snapshot.paramMap.get('userId')! : undefined;

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.categories = data.categories;
      this.categories = this.categories.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.languages = data.languages;
      this.language = this.getLanguage();

      this.categoryMetadata = [];
      for (let i = 0; i < this.categories.length; i++) {
        this.categoryMetadata.push(
          {
            rules: undefined,
            category: this.categories[i],
            expanded: false,
            loading: false,
            enableChanging: false
          }
        );
      }
    });
  }

  isCategoryRulesLoading(metadata: ICategoryMetadata): boolean {
    if (!metadata.rulesMetadata) return false;
    for (let i = 0; i < metadata.rulesMetadata.length; i++) {
      if (metadata.rulesMetadata[i].enableChanging) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }

    if (this.opSubscriptions) {
      for (let i = 0; i < this.opSubscriptions.length; i++) {
        this.opSubscriptions[i].unsubscribe();
      }
      this.opSubscriptions = undefined;
    }
  }

  toggleExpandCategory(category: Category) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    metadata.expanded = !metadata.expanded;

    if (metadata.rules === undefined) {
      metadata.loading = true;

      let subscription = this.txtanalyserService.getRulesByCategory(category)
        .subscribe((rules: Rule[]) => {
          rules = rules.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          metadata.rules = rules;
          metadata.rulesMetadata = rules.map(x => {
            return {
              enableChanging: false
            };
          });
        }, (err) => {
          console.error(err);
          if (this.opSubscriptions) {
            let index = this.opSubscriptions.indexOf(subscription);
            if (index !== -1) {
              this.opSubscriptions.splice(index, 1);
              subscription.unsubscribe();
            }
          }
          metadata.loading = false;
        }, () => {
          if (this.opSubscriptions) {
            let index = this.opSubscriptions.indexOf(subscription);
            if (index !== -1) {
              this.opSubscriptions.splice(index, 1);
              subscription.unsubscribe();
            }
          }
          metadata.loading = false;
        });
      if (this.opSubscriptions) {
        this.opSubscriptions.push(subscription);
      }
    }
  }

  getLanguage(): string|undefined {
    if (!this.languages) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        return this.languages[i].code;
      }
    }
    return undefined;
  }

  getFullLanguagePath(language?: string): string|undefined {
    if (!this.languages || !language) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        tokens[tokens.length - 1] = language;
        break;
      }
    }
    return tokens.join("/");
  }
  
  onLanguageChange(): void {
    this.router.navigate([this.getFullLanguagePath(this.language)]);
  }

  updateCategory(category: Category, checked: boolean) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    metadata.enableChanging = true;

    category.enabled = checked;
    category.indeterminate = false;

    if (metadata.rules) {
      for (let i = 0; i < metadata.rules.length; i++) {
        metadata.rules[i].enabled = checked;
        metadata.rules[i].indeterminate = false;
      }
    }

    let subscription = this.txtanalyserService.updateCategoryEnabled(category)
    .subscribe(undefined, (err) => {
      console.error(err);
      metadata.enableChanging = false;
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    }, () => {
      metadata.enableChanging = false;
      this.snackbar.openFromComponent(PhoneticSpellingsSnackbarComponent, {
        data: category.groupId === undefined
          ? checked ? PhoneticSpellingsSnackbarType.CategoryEnabled : PhoneticSpellingsSnackbarType.CategoryDisabled
          : checked ? PhoneticSpellingsSnackbarType.GroupCategoryEnabled : PhoneticSpellingsSnackbarType.GroupCategoryDisabled,
        duration: 4000
      });

      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    });
    if (this.opSubscriptions) {
      this.opSubscriptions.push(subscription);
    }
  }

  updateRule(category: Category, rule: Rule, checked: boolean) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    rule.enabled = checked;

    if (metadata.rules) {
      let indeterminate: boolean = false;
      for (let i = 0; i < metadata.rules.length; i++) {
        if (metadata.rules[i].enabled !== checked) {
          indeterminate = true;
          break;
        }
      }

      if (!indeterminate) {
        category.enabled = checked;
      }
      category.indeterminate = indeterminate;
    }

    let ruleMetadata: IRuleMetadata|undefined;
    if (metadata.rules && metadata.rulesMetadata) {
      for (let i = 0; i < metadata.rulesMetadata.length; i++) {
        if (metadata.rules[i] === rule) {
          ruleMetadata = metadata.rulesMetadata[i];
          break;
        }
      }
    }

    if (ruleMetadata) {
      ruleMetadata.enableChanging = true;
    }

    let subscription = this.txtanalyserService.updateRuleEnabled(rule)
    .subscribe(undefined, (err) => {
      console.error(err);
      if (ruleMetadata) {
        ruleMetadata.enableChanging = false;
      }
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    }, () => {
      if (ruleMetadata) {
        ruleMetadata.enableChanging = false;
      }

      this.snackbar.openFromComponent(PhoneticSpellingsSnackbarComponent, {
        data: rule.groupId === undefined
          ? checked ? PhoneticSpellingsSnackbarType.RuleEnabled : PhoneticSpellingsSnackbarType.RuleDisabled
          : checked ? PhoneticSpellingsSnackbarType.GroupRuleEnabled : PhoneticSpellingsSnackbarType.GroupRuleDisabled,
        duration: 4000
      });

      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    });

    if (this.opSubscriptions) {
      this.opSubscriptions.push(subscription);
    }
  }
}