import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '../../models/language';
import { ILanguageService } from '../../interfaces/ilanguage.service';
import { CollectionService, Collection, Item } from '../collection.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class LanguageCollectionService implements ILanguageService {
  constructor(
    private collectionService: CollectionService,
    private httpClient: HttpClient
  ) {}

  /** @override */
  getProfileLanguages(): Observable<Language[]> {
    return this.collectionService.getCollection("/profile/aw/supportedlanguages")
    .pipe(map((collection: Collection) => {
      let languages: Language[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item: Item = collection.items[i];

        let name = item.getDataAsString("name");
        let code = item.getDataAsString("code");

        languages.push(new Language(name ,code));
      }

      return languages;
    }));
  }

  getDisplayLanguages(): Observable<Language[]> {
    return this.httpClient.get<any[]>(environment.collectionUrl + "/languages")
    .pipe(map((jsonArray: any[]) => {
      let languages: Language[] = [];
      for (let i = 0; i < jsonArray.length; i++) {
        let item: any = jsonArray[i];

        let name = item['name'];
        let code = item['code'];

        languages.push(new Language(name ,code));
      }

      return languages;
    }));
  }

  getTextAnalyzerLanguages(groupId?: number, userId?: number): Observable<Language[]> {
    let uri: string = "/ta/supportedlanguages";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = "/profile" + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map((collection: Collection) => {
      let languages: Language[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item: Item = collection.items[i];

        let name = item.getDataAsString("name");
        let code = item.getDataAsString("code");

        languages.push(new Language(name ,code));
      }

      return languages;
    }));
  }
}
