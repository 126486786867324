import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    public authService: AuthService,
  ) {}

  public ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.authService.redirectBack();
    }
  }

  public async unicLogin() {
    const url = this.authService.getUnicLogin();
    window.location.href = url;
  }

  public async googleLogin() {
    const url = this.authService.getGoogleLogin();
    window.location.href = url;
  }

  public async feideLogin() {
    const url = this.authService.getFeideLogin();
    window.location.href = url;
  }

  public async microsoftLogin() {
    const url = this.authService.getMicrosoftLogin();
    window.location.href = url;
  }

  public async kmdNexusLogin() {
    const url = this.authService.getKmdNexusLogin();
    window.location.href = url;
  }

  public async microsoftAdfsLogin() {
    const url = this.authService.getMicrosoftAdfsLogin();
    window.location.href = url;
  }
}
