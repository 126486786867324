import { Injectable, Inject } from '@angular/core';
import { Resolve } from '@angular/router';

import { Group } from '../models/groups';
import { IGroupsService } from '../interfaces/igroups.service';
import { IGroupsServiceToken } from '../interfaces/igroups.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class GroupsResolver implements Resolve<Group[]> {
  constructor(
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) {}

  resolve(): Observable<Group[]> {
    return this.groupsService.getGroups();
  }
}
