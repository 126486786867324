import { Component, OnInit, Inject, NgZone, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { DocumentIgnoresDataSource } from '../../models/txtanalyser/DocumentIgnoresDataSource';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { IgnoreDocument } from '../../models/txtanalyser/IgnoreDocument';
import { Ignore } from '../../models/txtanalyser/ignore';
import { TxtAnalyserErrorReportingSnackbarComponent, TxtAnalyserErrorreportingSnackbarType } from '../txtanalyser-errorreporting-snackbar/txtanalyser-errorreporting-snackbar.component';
import { TxtAnalyserDocumentIgnoresDeleteDialogComponent } from '../txtanalyser-document-ignores-delete-dialog/txtanalyser-document-ignores-delete-dialog.component';

interface IRouteData {
  document: IgnoreDocument;
}

@Component({
  selector: 'app-txtanalyser-document-ignores',
  templateUrl: './txtanalyser-document-ignores.component.html',
  styleUrls: ['./txtanalyser-document-ignores.component.scss']
})
export class TxtAnalyserDocumentIgnoresComponent implements OnInit, AfterViewInit, OnDestroy {
  public language: string|undefined;
  public document?: IgnoreDocument;

  public userId?: number;
  public groupId?: number;

  public loadingIgnores: {[key: string]: boolean} = {};

  @ViewChild(MatSort, { static: true }) private _sort?: MatSort;
  public dataSource?: DocumentIgnoresDataSource;

  private routeSubscription?: Subscription;

  public get displayedColumns(): string[] {
    return ["category", "subcategory", "text", "actions"];
  };

  constructor(
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private _dialog: MatDialog,
    @Inject(ITxtAnalyserServiceToken) private txtanalyserService: ITxtAnalyserService
  ) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this.route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this.route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this.route.snapshot.paramMap.get('userId')!, 10);
    }

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.document = data.document;
      this.language = data.document.language;

      this.dataSource = new DocumentIgnoresDataSource(this.txtanalyserService, this.language || "da-DK", this.document.id, this.groupId, this.userId);
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this._sort;
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
  }

  removeIgnore(ignore: Ignore) {
    const ref = this._dialog.open(TxtAnalyserDocumentIgnoresDeleteDialogComponent);
    ref.componentInstance.onDelete.subscribe(x => {
      if (this.dataSource) {
        this.dataSource.removeItem(ignore);
      }
      this.loadingIgnores[ignore.id] = true;
  
      this.txtanalyserService
        .deleteIgnoreInIgnoreDocument(ignore.id, ignore.documentId, ignore.language, ignore.groupId, ignore.impersonatedUserId)
        .subscribe(
          undefined,
          () => {
            this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
              duration: 4000,
              data: TxtAnalyserErrorreportingSnackbarType.Error
            });
            this.loadingIgnores[ignore.id] = false;
          },
          () => {
            this.snackbar.openFromComponent(TxtAnalyserErrorReportingSnackbarComponent, {
              duration: 4000,
              data: TxtAnalyserErrorreportingSnackbarType.IgnoreSuccess
            });
            this.loadingIgnores[ignore.id] = false;
  
            if (this.dataSource) {
              this.dataSource.update();
            }
          }
        );
    });
  }
}
