import { Component, Input } from '@angular/core';
import { User, AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input()
  public user?: User;

  constructor(private authService: AuthService) {
    this.authService.userUpdated.subscribe((user: User) => this.user = user);
  }
  
  OnInit(){
    this.authService.getUser().subscribe(user =>{
      this.user = user;  
    });
  }

  public get name(): string|undefined {
    if (!this.user)
      return "";
    if (!this.user.name)
      return this.user.email;
    
    return this.user.name;
  }
}