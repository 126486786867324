export enum GroupType {
  USER_CREATED
}

export enum GroupRole {
  /** The user is not a part of the group */
  NONE,

  /** The user is a member of the group */
  MEMBER,

  /** The group administrator */
  ADMINISTRATOR,

  /** The group editor */
  EDITOR,

  /** The user is the group owner */
  OWNER
}

export function getGroupRoleByKey(key?: string): GroupRole {
  switch (key) {
    case "ADMINISTRATOR": {
      return GroupRole.ADMINISTRATOR;
    }
    case "EDITOR": {
      return GroupRole.EDITOR;
    }
    case "OWNER": {
      return GroupRole.OWNER;
    }
    case "NONE": {
      return GroupRole.NONE;
    }
    case "MEMBER":
    default: {
      return GroupRole.MEMBER;
    }
  }
}

export class GroupRoleName {
  constructor(
    public role: GroupRole,
    public name: string
  ) {}
}

export class GroupPermissions {
  constructor(
    public edit: boolean = false,
    public viewStatistics: boolean = false,
    public editApplicationSettings: boolean = false
  ) {}
}

export class GroupMemberPermissions {
  constructor(
    public edit: boolean = false,
    public remove: boolean = false,
    public impersonate: boolean = false,
    public includeInStatistics: boolean = false
  ) {}

  clone(): GroupMemberPermissions {
    return new GroupMemberPermissions(
      this.edit,
      this.remove,
      this.impersonate,
      this.includeInStatistics
    );
  }
}

export class Group {
  constructor(
    public id: number|undefined,

    /** The name of the group */
    public name: string,

    /** The amount of memebers there are in the group */
    public members: number,

    /** The join token used for non-members to join the group */
    public joinToken: string|undefined,

    /** The state of the user in the group */
    public role: GroupRole = GroupRole.NONE,
    public type: GroupType = GroupType.USER_CREATED,
    public permissions: GroupPermissions = new GroupPermissions()
  ) {}
}

export class GroupMember{
  constructor(
      public groupId: number,
      public userId: string,
      public firstName: string,
      public lastName: string,
      public email: string,
      public roleName: string,
      public role: GroupRole = GroupRole.NONE,
      public permissions: GroupMemberPermissions = new GroupMemberPermissions(),
      public name?: string
  ) {
    if (this.firstName || this.lastName) {
      this.name = this.firstName + ' ' + this.lastName;
    } else {
      this.name = this.email;
    }
  }

  clone(): GroupMember {
    return new GroupMember(
      this.groupId,
      this.userId,
      this.firstName,
      this.lastName,
      this.email,
      this.roleName,
      this.role,
      this.permissions
    );
  }
}