import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService, IBreadcrumb } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private queryParamMapSubscription?: Subscription;

  public breadcrumbs: IBreadcrumb[] = [];
  public currentPage?: IBreadcrumb;
  public unknownBreadcrumbs: IBreadcrumb[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.queryParamMapSubscription = this.route.queryParamMap.subscribe(() => this.onQueryParamMapChange());
    this.onQueryParamMapChange();
  }

  ngOnDestroy() {
    if (this.queryParamMapSubscription) {
      this.queryParamMapSubscription.unsubscribe();
      this.queryParamMapSubscription = undefined;
    }
  }

  onQueryParamMapChange() {
    const b = this.breadcrumbService.getBreadcrumbs();
    this.breadcrumbs = b.breadcrumbs;
    this.currentPage = b.currentPage;

    const breadcrumbs: IBreadcrumb[] = [];
    for (let i = 0; i < this.breadcrumbs.length; i++) {
      if (this.breadcrumbs[i].unknown) {
        breadcrumbs.push(this.breadcrumbs[i]);
      }
    }
    if (this.currentPage && this.currentPage.unknown) {
      breadcrumbs.push(this.currentPage);
    }

    this.unknownBreadcrumbs = breadcrumbs;
  }
  
  public get title(): string {
    if (!this.currentPage) return "";
    return this.currentPage.label;
  }
  
  public get data(): string[]|undefined {
    if (!this.currentPage) return undefined;
    return this.currentPage.data;
  }

  public getUnknownKeyIndex(breadcrumb?: IBreadcrumb): number {
    if (!breadcrumb) return -1;
    return this.unknownBreadcrumbs.indexOf(breadcrumb);
  }
}
