import { LingappsError } from './../../services/auth.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { IAccountService } from '../../interfaces/iaccount.service';
import { IAccountServiceToken } from '../../interfaces/iaccount.service.token';

interface IError {
  [key: string]: boolean|undefined;
  PROFILE_USERNAME_NOT_SET?: boolean;
  PROFILE_PASSWORD_NOT_SET?: boolean;
  USER_LOGIN_FAILED?: boolean;
}

function errorValidator(error: IError): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}

@Component({
  selector: 'app-account-transfer-profile-settings-dialog',
  templateUrl: './account-transfer-profile-settings-dialog.component.html',
  styleUrls: ['./account-transfer-profile-settings-dialog.component.scss']
})
export class AccountTransferProfileSettingsDialogComponent implements OnInit, OnDestroy {
  public errorUsername: IError = {};
  public errorPassword: IError = {};
  public unexpectedError: boolean = false;
  
  public form?: FormGroup;
  
  public subscription?: Subscription = undefined;

  constructor(
    public dialogRef: MatDialogRef<AccountTransferProfileSettingsDialogComponent>,
    @Inject(IAccountServiceToken) private accountService: IAccountService
  ) {}
  
  resetErrors() {
    this.unexpectedError = false;
    for (let key in this.errorUsername) {
      this.errorUsername[key] = false;
    }
    for (let key in this.errorPassword) {
      this.errorPassword[key] = false;
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      'username': new FormControl('', [Validators.required, errorValidator(this.errorUsername)]),
      'password': new FormControl('', [Validators.required, errorValidator(this.errorPassword)])
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }
  
  onTransferProfileSettings() {
    if (!this.form || this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    let username = this.form.get('username')!.value;
    let password = this.form.get('password')!.value;
    
    if (!username) {
      this.errorUsername["PROFILE_USERNAME_NOT_SET"] = true;
      this.form.updateValueAndValidity();
      this.form.enable();
      this.form.patchValue({ 'username': "" });
      return;
    }
    if (!password) {
      this.errorPassword["PROFILE_PASSWORD_NOT_SET"] = true;
      this.form.updateValueAndValidity();
      this.form.enable();
      this.form.patchValue({ 'password': "" });
      return;
    }
    
    this.subscription = this.accountService.transferProfileSettings(username, password)
    .subscribe(
      undefined,
      (error: LingappsError) => {
        this.subscription = undefined;

        let code: string|undefined = undefined;
        if (error.code) {
          code = error.code;
        }

        if (!this.form) return;

        switch (code) {
          case "PROFILE_USERNAME_NOT_SET":
            this.errorUsername["PROFILE_USERNAME_NOT_SET"] = true;
            this.form.patchValue({ 'username': "" });
            break;
          case "PROFILE_PASSWORD_NOT_SET":
            this.errorPassword["PROFILE_PASSWORD_NOT_SET"] = true;
            this.form.patchValue({ 'password': "" });
            break;
          case "USER_LOGIN_FAILED":
            this.errorPassword["USER_LOGIN_FAILED"] = true;
            this.form.patchValue({ 'password': "" });
            break;
          default:
            this.unexpectedError = true;
            break;
        }
        this.form.updateValueAndValidity();
        this.form.enable();
      },
      () => {
        if (!this.form) return;
        this.subscription = undefined;
        this.form.enable();
        this.dialogRef.close();
      }
    );
  }
}
