import { ILocalization } from './interfaces/ILocalization';
import { GlossaryListComponent } from './components/glossary-list/glossary-list.component';
import { ErrorInterceptor } from './services/error-interceptor.service';
import { LocaleInterceptor } from './services/locale-interceptor.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { LoginErrorService } from './services/login-error.service';
import { MediaQueryService } from './services/media-query.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollectionService } from './services/collection.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth-guard';
import { BreadcrumbService } from './services/breadcrumb.service';
import { PageNavigationProgressService } from './services/page-navigation-progress.service';
import { LocaleService } from './services/locale.service';
import { CookieService } from './services/cookie.service';
import { BreadcrumbMiddlewareService } from './services/breadcrumb.middleware.service';
import { PaginatorIntl } from './services/paginator.service';

import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AccountComponent } from './components/account/account.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { NavigationCardComponent } from './components/navigation-card/navigation-card.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoginWizkidsComponent } from './components/login-wizkids/login-wizkids.component';
import { LoginUnicComponent } from './components/login-unic/login-unic.component';
import { LoginGoogleComponent } from './components/login-google/login-google.component';
import { LoginFeideComponent } from './components/login-feide/login-feide.component';
import { LoginKmdNexusComponent } from './components/login-kmd-nexus/login-kmd-nexus.component';
import { LoginMicrosoftAdfsComponent } from './components/login-microsoft-adfs/login-microsoft-adfs.component';
import { AccountPasswordChangeDialogComponent } from './components/account-password-change-dialog/account-password-change-dialog.component';
import { AccountTransferProfileSettingsDialogComponent } from './components/account-transfer-profile-settings-dialog/account-transfer-profile-settings-dialog.component';
import { FocusDirective } from './directives/focus.directive';
import { AutoGrowDirective } from './directives/autoGrow.directive';
import { PageNavigationProgressComponent } from './components/page-navigation-progress/page-navigation-progress.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PhoneticSpellingsComponent } from './components/phonetic-spellings/phonetic-spellings.component';

import { GlossariesComponent } from './components/glossaries/glossaries.component';
import { GlossaryCreateDialogComponent } from './components/glossary-create-dialog/glossary-create-dialog.component';
import { GlossaryDeleteDialogComponent } from './components/glossary-delete-dialog/glossary-delete-dialog.component';
import { GlossaryDeleteConfirmDialogComponent } from './components/glossary-delete-confirm-dialog/glossary-delete-confirm-dialog.component';
import { GlossaryUnsubscribeDialogComponent } from './components/glossary-unsubscribe-dialog/glossary-unsubscribe-dialog.component';
import { GlossaryViewDialogComponent } from './components/glossary-view-dialog/glossary-view-dialog.component';
import { GlossaryEditDialogComponent } from './components/glossary-edit-dialog/glossary-edit-dialog.component';

import { PhoneticSpellingCreateDialogComponent } from './components/phonetic-spelling-create-dialog/phonetic-spelling-create-dialog.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PhoneticSpellingRecommendDialogComponent } from './components/phonetic-spelling-recommend-dialog/phonetic-spelling-recommend-dialog.component';
import { PhoneticSpellingDeleteDialogComponent } from './components/phonetic-spelling-delete-dialog/phonetic-spelling-delete-dialog.component';
import { PhoneticSpellingEditDialogComponent } from './components/phonetic-spelling-edit-dialog/phonetic-spelling-edit-dialog.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { StatsComponent } from './components/stats/stats.component';
import { StatsSpacedTableComponent } from './components/stats-spaced-table/stats-spaced-table.component';

import { GroupsComponent } from './components/groups/groups.component';
import { GroupsCreateGroupDialogComponent } from './components/groups-create-group-dialog/groups-create-group-dialog.component';
import { GroupsJoinGroupDialogComponent } from './components/groups-join-group-dialog/groups-join-group-dialog.component';
import { DataOriginActivityDialogComponent } from './components/data-origin-activity-dialog/data-origin-activity-dialog.component';
import { DatePeriodDialogComponent } from './components/date-period-dialog/date-period-dialog.component';
import { GroupComponent } from './components/group/group.component';
import { GroupsEditGroupDialogComponent } from './components/groups-edit-group-dialog/groups-edit-group-dialog.component';
import { GroupsDeleteGroupDialogComponent } from './components/groups-delete-group-dialog/groups-delete-group-dialog.component';
import { GroupsQuitGroupDialogComponent } from './components/groups-quit-group-dialog/groups-quit-group-dialog.component';
import { GroupEditMemberDialogComponent } from './components/group-edit-member-dialog/group-edit-member-dialog.component';
import { GroupRemoveMemberDialogComponent } from './components/group-remove-member-dialog/group-remove-member-dialog.component';
import { LogoutComponent } from './components/logout/logout.component';

import { StatsTableComponent } from './components/stats-table/stats-table.component';
import { StatsColumnComponent } from './components/stats-column/stats-column.component';

import { IGroupsServiceToken } from './interfaces/igroups.service.token';
import { GroupsCollectionService } from './services/collection/groups-collection.service';

import { INavigationServiceToken } from './interfaces/inavigation.service.token';
import { NavigationCollectionService } from './services/collection/navigation-collection.service';

import { IAccountServiceToken } from './interfaces/iaccount.service.token';
import { AccountCollectionService } from './services/collection/account-collection.service';

import { ISpellingServiceToken } from './interfaces/ispelling.service.token';
import { SpellingCollectionService } from './services/collection/spelling-collection.service';

import { IGlossaryServiceToken } from './interfaces/iglossary.service.token';
import { GlossaryCollectionService } from './services/collection/glossary-collection.service';

import { ILanguageServiceToken } from './interfaces/ilanguage.service.token';
import { LanguageCollectionService } from './services/collection/language-collection.service';

import { ICountryServiceToken } from './interfaces/icountry.service.token';
import { CountryCollectionService } from './services/collection/country-collection.service';

import { IStatsServiceToken } from './interfaces/istats.service.token';
import { StatsCollectionService } from './services/collection/stats-collection.service';

import { IProfileServiceToken } from './interfaces/iprofile.service.token';
import { ProfileCollectionService } from './services/collection/profile-collection.service';

import { GroupsResolver } from './resolvers/groups.resolver';
import { GroupResolver } from './resolvers/group.resolver';
import { GroupMembersResolver } from './resolvers/group-members.resolver';
import { NavigationResolver } from './resolvers/navigation.resolver';
import { AccountUserDetailResolver } from './resolvers/account-userdetail.resolver';
import { AccountApplicationsResolver } from './resolvers/account-applications.resolver';
import { LanguagesProfileResolver } from './resolvers/languages-profile.resolver';
import { LanguagesDisplayResolver } from './resolvers/languages-display.resolver';
import { CountriesResolver } from './resolvers/countries.resolver';
import { SpellingCategoriesResolver } from './resolvers/spelling-categories.resolver';
import { SpellingCustomRulesResolver } from './resolvers/spelling-customrules.resolver';
import { GlossariesResolver } from './resolvers/glossaries.resolver';
import { StatsPageResolver } from './resolvers/stats-page.resolver';
import { GroupTokenResolver } from './resolvers/group-token.resolver';
import { GroupAreasResolver } from './resolvers/group-areas.resolver';
import { GroupMemberNavigationResolver } from './resolvers/group-member-navigation.resolver';

import { GlossaryAddToGroupDialogComponent } from './components/glossary-add-to-group-dialog/glossary-add-to-group-dialog.component';
import { VirtualScrollComponent } from './components/virtual-scroll/virtual-scroll.component';
import { VirtualScrollListComponent } from './components/virtual-scroll-list/virtual-scroll-list.component';
import { CreateUserSuccessDialogComponent } from './components/create-user-success-dialog/create-user-success-dialog.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { StatsAreaComponent } from './components/stats-area/stats-area.component';
import { StatsChartComponent } from './components/stats-chart/stats-chart.component';
import { VisibleTextComponent } from './components/visible-text/visible-text.component';
import { RatingComponent } from './components/rating/rating.component';
import { GlossariesSearchComponent } from './components/glossaries-search/glossaries-search.component';
import { PhoneticSpellingsSnackbarComponent } from './components/phonetic-spellings-snackbar/phonetic-spellings-snackbar.component';
import { GlossarySnackbarComponent } from './components/glossary-snackbar/glossary-snackbar.component';
import { AccountSnackbarComponent } from './components/account-snackbar/account-snackbar.component';
import { GlossaryAddToGroupPublicDialogComponent } from './components/glossary-add-to-group-public-dialog/glossary-add-to-group-public-dialog.component';
import { GlossaryEditConfirmDialogComponent } from './components/glossary-edit-confirm-dialog/glossary-edit-confirm-dialog.component';
import { GlossariesSearchSnackbarComponent } from './components/glossaries-search-snackbar/glossaries-search-snackbar.component';
import { TxtAnalyserCategoriesComponent } from './components/txtanalyser-categories/txtanalyser-categories.component';
import { TxtAnalyserCategoriesResolver } from './resolvers/txtanalyser-categories.resolver';
import { TxtAnalyserCollectionService } from './services/collection/txtanalyser-collection.service';
import { ITxtAnalyserServiceToken } from './interfaces/itxtanalyser.service.token';
import { TxtAnalyserErrorReportingComponent } from './components/txtanalyser-errorreporting/txtanalyser-errorreporting.component';
import { TxtAnalyserErrorReportingResolver } from './resolvers/txtanalyser-errorreporting.resolver';
import { TxtAnalyserIgnoreDocumentsResolver } from './resolvers/txtanalyser-ignore-documents.resolver';
import { TxtAnalyserErrorReportingSnackbarComponent } from './components/txtanalyser-errorreporting-snackbar/txtanalyser-errorreporting-snackbar.component';
import { TxtAnalyserDocumentIgnoresComponent } from './components/txtanalyser-document-ignores/txtanalyser-document-ignores.component';
import { TxtAnalyserIgnoreDocumentResolver } from './resolvers/txtanalyser-ignore-document.resolver';
import { TxtAnalyserUploadComponent } from './components/txtanalyser-upload/txtanalyser-upload.component';
import { TxtAnalyserUploadDialogComponent } from './components/txtanalyser-upload-dialog/txtanalyser-upload-dialog.component';
import { TxtAnalyserReportJobsResolver } from './resolvers/txtanalyser-report-jobs.resolver';
import { TxtAnalyserReportDocumentResultResolver } from './resolvers/txtanalyser-report-job-result.resolver';
import { TxtAnalyserUploadDocumentsComponent } from './components/txtanalyser-upload-documents/txtanalyser-upload-documents.component';
import { TxtAnalyserUploadDocumentComponent } from './components/txtanalyser-upload-document/txtanalyser-upload-document.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { TxtAnalyserReportJobResolver } from './resolvers/txtanalyser-report-job.resolver';
import { TxtAnalyserReportDocumentTextResolver } from './resolvers/txtanalyser-report-document-text.resolver';
import { TxtAnalyserUploadViewDocumentComponent } from './components/txtanalyser-upload-view-document/txtanalyser-upload-view-document.component';
import { ReadonlyDocumentComponent } from './components/readonly-document/readonly-document.component';
import { TxtAnalyserReportDocumentErrorTypesResolver } from './resolvers/txtanalyser-report-document-error-types.resolver';
import { GoogleDrivePickerComponent } from './components/google-drive-picker/google-drive-picker.component';

import { appRoutes } from './app.routes';
import { GoogleDrivePickerLoaderService } from './services/google-drive-picker-loader.service';
import { IGoogleDrivePickerConfigurationService, IGoogleDrivePickerConfiguration } from './models/IGoogleDrivePickerConfiguration';

import { environment } from '../environments/environment';
import { OneDriveFilePickerLoaderService } from './services/onedrive-file-picker-loader.service';
import { IOneDriveFilePickerConfigurationService, IOneDriveFilePickerConfiguration } from './models/IOneDriveFilePickerConfiguration';
import { OneDriveFilePickerComponent } from './components/onedrive-file-picker/onedrive-file-picker.component';
import { OneDriveFilePickerRedirectComponent } from './components/onedrive-file-picker-redirect/onedrive-file-picker-redirect.component';
import { LanguagesTextAnalyzerResolver } from './resolvers/languages-text-analyzer.resolver';
import { TxtAnalyserUploadFileComponent } from './components/txtanalyser-upload-file/txtanalyser-upload-file.component';
import { LocalFilePickerComponent } from './components/local-file-picker/local-file-picker.component';
import { TxtAnalyserDocumentIgnoresDeleteDialogComponent } from './components/txtanalyser-document-ignores-delete-dialog/txtanalyser-document-ignores-delete-dialog.component';
import { TxtAnalyserDocumentIgnores2DeleteDialogComponent } from './components/txtanalyser-document-ignores2-delete-dialog/txtanalyser-document-ignores2-delete-dialog.component';
import { StatsPieChartComponent } from './components/stats-pie-chart/stats-pie-chart.component';
import { StatsGroupedTableComponent } from './components/stats-grouped-table/stats-grouped-table.component';
import { LanguagesStatsResolver } from './resolvers/languages-stats.resolver';
import { MarkedDirective } from './directives/marked.directive';
import { Localization_arEG } from './services/Localization.ar-EG';
import { Localization_daDK } from './services/Localization.da-DK';
import { Localization_deDE } from './services/Localization.de-DE';
import { Localization_enGB } from './services/Localization.en-GB';
import { Localization_nbNO } from './services/Localization.nb-NO';
import { Localization_nlNL } from './services/Localization.nl-NL';
import { Localization_nnNO } from './services/Localization.nn-NO';
import { Localization_svSE } from './services/Localization.sv-SE';
import { TxtAnalyserReportDocumentsResolver } from './resolvers/txtanalyser-report-documents.resolver';
import { LoginSessionComponent } from './components/login-session/login-session.component';
import { LoginMicrosoftComponent } from './components/login-microsoft/login-microsoft.component';
import { MathSolverComponent } from './components/mathsolver/mathsolver.component';
import { MathSolverSettingsService } from './services/collection/mathsolver-settings.service';
import { MathSolverSettingsServiceToken } from './interfaces/imathsolver-settings.service.token';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplatesService } from './services/templates.service';
import { TemplatesServiceToken } from './services/templates.service.token';
import { TemplatesResolver } from './resolvers/templates.resolver';
import { BidiModule } from '@angular/cdk/bidi';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GoogleAccountsLoaderService } from './services/google-accounts-loader.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AccountComponent,
    UserInfoComponent,
    NavigationCardComponent,
    LoginWizkidsComponent,
    LoginUnicComponent,
    LoginGoogleComponent,
    LoginFeideComponent,
    LoginKmdNexusComponent,
    LoginMicrosoftComponent,
    LoginMicrosoftAdfsComponent,
    BreadcrumbComponent,
    AccountPasswordChangeDialogComponent,
    AccountTransferProfileSettingsDialogComponent,
    FocusDirective,
    AutoGrowDirective,
    MarkedDirective,
    GlossaryCreateDialogComponent,
    GlossaryViewDialogComponent,
    GlossaryEditDialogComponent,
    PageNavigationProgressComponent,
    GlossariesComponent,
    GlossaryListComponent,
    NavigationComponent,
    PhoneticSpellingsComponent,
    PhoneticSpellingCreateDialogComponent,
    PageTitleComponent,
    GlossaryDeleteDialogComponent,
    GlossaryDeleteConfirmDialogComponent,
    GlossaryUnsubscribeDialogComponent,
    PhoneticSpellingRecommendDialogComponent,
    PhoneticSpellingDeleteDialogComponent,
    PhoneticSpellingEditDialogComponent,
    CreateUserComponent,
    GroupsComponent,
    GroupsCreateGroupDialogComponent,
    GroupsJoinGroupDialogComponent,
    DataOriginActivityDialogComponent,
    HeaderComponent,
    StatsComponent,
    StatsTableComponent,
    StatsColumnComponent,
    DatePeriodDialogComponent,
    GroupComponent,
    GroupsEditGroupDialogComponent,
    GroupsDeleteGroupDialogComponent,
    GroupsQuitGroupDialogComponent,
    GroupEditMemberDialogComponent,
    GroupRemoveMemberDialogComponent,
    LogoutComponent,
    StatsSpacedTableComponent,
    GlossaryAddToGroupDialogComponent,
    VirtualScrollComponent,
    VirtualScrollListComponent,
    CreateUserSuccessDialogComponent,
    ErrorSnackbarComponent,
    StatsAreaComponent,
    StatsChartComponent,
    VisibleTextComponent,
    RatingComponent,
    GlossariesSearchComponent,
    PhoneticSpellingsSnackbarComponent,
    GlossarySnackbarComponent,
    AccountSnackbarComponent,
    GlossaryAddToGroupPublicDialogComponent,
    GlossaryEditConfirmDialogComponent,
    GlossariesSearchSnackbarComponent,
    TxtAnalyserCategoriesComponent,
    TxtAnalyserErrorReportingComponent,
    TxtAnalyserErrorReportingSnackbarComponent,
    TxtAnalyserDocumentIgnoresComponent,
    TxtAnalyserUploadComponent,
    TxtAnalyserUploadDialogComponent,
    TxtAnalyserUploadDocumentsComponent,
    TxtAnalyserUploadDocumentComponent,
    PieChartComponent,
    TxtAnalyserUploadViewDocumentComponent,
    ReadonlyDocumentComponent,
    GoogleDrivePickerComponent,
    OneDriveFilePickerComponent,
    OneDriveFilePickerRedirectComponent,
    TxtAnalyserUploadFileComponent,
    LocalFilePickerComponent,
    TxtAnalyserDocumentIgnoresDeleteDialogComponent,
    TxtAnalyserDocumentIgnores2DeleteDialogComponent,
    StatsPieChartComponent,
    StatsGroupedTableComponent,
    LoginSessionComponent,
    MathSolverComponent,
    TemplatesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule,
    Ng2GoogleChartsModule,
    BidiModule,
    ScrollingModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    CollectionService,
    MatIconRegistry,
    BreadcrumbMiddlewareService,
    BreadcrumbService,
    PageNavigationProgressService,
    LocaleService,
    // { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    { provide: IGroupsServiceToken, useClass: GroupsCollectionService },
    { provide: INavigationServiceToken, useClass: NavigationCollectionService },
    { provide: IAccountServiceToken, useClass: AccountCollectionService },
    { provide: ISpellingServiceToken, useClass: SpellingCollectionService },
    { provide: ITxtAnalyserServiceToken, useClass: TxtAnalyserCollectionService },
    { provide: IGlossaryServiceToken, useClass: GlossaryCollectionService },
    { provide: ICountryServiceToken, useClass: CountryCollectionService },
    { provide: ILanguageServiceToken, useClass: LanguageCollectionService },
    { provide: IStatsServiceToken, useClass: StatsCollectionService },
    { provide: IProfileServiceToken, useClass: ProfileCollectionService },
    { provide: MathSolverSettingsServiceToken, useClass: MathSolverSettingsService },
    { provide: TemplatesServiceToken, useClass: TemplatesService },
    {
      provide: ILocalization,
      deps: [LOCALE_ID],
      useFactory: (locale: string) => {
        switch (locale) {
          case "ar":
          case "ar-EG":
            return new Localization_arEG();
          case "da":
          case "da-DK":
            return new Localization_daDK();
          case "de":
          case "de-DE":
            return new Localization_deDE();
          case "sv":
          case "sv-SE":
            return new Localization_svSE();
          case "nb-NO":
            return new Localization_nbNO();
          case "nl-NL":
            return new Localization_nlNL();
          case "nn-NO":
            return new Localization_nnNO();
          case "en":
          case "en-GB":
          default:
            return new Localization_enGB();
        }
      }
    },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
    {
      provide: IGoogleDrivePickerConfigurationService,
      useValue: {
        appId: environment.google.appId,
        developerKey: environment.google.developerKey,
        clientId: environment.google.clientId
      } as IGoogleDrivePickerConfiguration
    },
    {
      provide: IOneDriveFilePickerConfigurationService,
      useValue: {
        clientId: environment.onedrive.clientId,
      } as IOneDriveFilePickerConfiguration
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    GroupsResolver,
    GroupResolver,
    GroupMembersResolver,
    GroupAreasResolver,
    NavigationResolver,
    AccountUserDetailResolver,
    AccountApplicationsResolver,
    GlossariesResolver,
    TxtAnalyserCategoriesResolver,
    TxtAnalyserErrorReportingResolver,
    TxtAnalyserIgnoreDocumentsResolver,
    TxtAnalyserIgnoreDocumentResolver,
    TxtAnalyserReportJobsResolver,
    TxtAnalyserReportDocumentResultResolver,
    TxtAnalyserReportJobResolver,
    TxtAnalyserReportDocumentsResolver,
    TxtAnalyserReportDocumentTextResolver,
    TxtAnalyserReportDocumentErrorTypesResolver,
    SpellingCategoriesResolver,
    SpellingCustomRulesResolver,
    LanguagesProfileResolver,
    LanguagesTextAnalyzerResolver,
    LanguagesStatsResolver,
    CountriesResolver,
    StatsPageResolver,
    GroupTokenResolver,
    CookieService,
    LanguagesDisplayResolver,
    GroupMemberNavigationResolver,
    TemplatesResolver,
    MediaQueryService,
    LoginErrorService,
    AuthInterceptor,
    LocaleInterceptor,
    ErrorInterceptor,
    GoogleDrivePickerLoaderService,
    GoogleAccountsLoaderService,
    OneDriveFilePickerLoaderService
  ],
  entryComponents: [
    AccountPasswordChangeDialogComponent,
    AccountTransferProfileSettingsDialogComponent,
    GlossaryCreateDialogComponent,
    GlossaryDeleteDialogComponent,
    GlossaryDeleteConfirmDialogComponent,
    GlossaryUnsubscribeDialogComponent,
    GlossaryViewDialogComponent,
    GlossaryEditDialogComponent,
    PhoneticSpellingCreateDialogComponent,
    PhoneticSpellingRecommendDialogComponent,
    PhoneticSpellingDeleteDialogComponent,
    PhoneticSpellingEditDialogComponent,
    GroupsCreateGroupDialogComponent,
    GroupsJoinGroupDialogComponent,
    DataOriginActivityDialogComponent,
    DatePeriodDialogComponent,
    GroupsEditGroupDialogComponent,
    GroupsDeleteGroupDialogComponent,
    GroupsQuitGroupDialogComponent,
    GroupEditMemberDialogComponent,
    GroupRemoveMemberDialogComponent,
    GlossaryAddToGroupDialogComponent,
    CreateUserSuccessDialogComponent,
    ErrorSnackbarComponent,
    PhoneticSpellingsSnackbarComponent,
    GlossarySnackbarComponent,
    AccountSnackbarComponent,
    GlossaryAddToGroupPublicDialogComponent,
    GlossaryEditConfirmDialogComponent,
    GlossariesSearchSnackbarComponent,
    TxtAnalyserErrorReportingSnackbarComponent,
    TxtAnalyserUploadDialogComponent,
    TxtAnalyserDocumentIgnoresDeleteDialogComponent,
    TxtAnalyserDocumentIgnores2DeleteDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
