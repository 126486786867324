<app-header></app-header>

<div class="mat-elevation-z2">
  <div class="table__header">
    <div class="header__start">
      <mat-form-field class="header-start__filter">
        <input matInput [(ngModel)]="dataSource!.filter" placeholder="Search for documents" i18n-placeholder="@@SearchUploadDocuments_searchForGlossaries">
        <button
          mat-button
          *ngIf="dataSource!.filter"
          matSuffix
          mat-icon-button
          i18n-aria-label="@@SearchUploadDocuments_clear"
          aria-label="Clear"
          (click)="dataSource.filter=''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="header__end">
      <div>
        <span i18n="@@SearchUploadDocuments_itemsPerPage">Items per page</span>
        <mat-form-field class="header-end__page-size">
          <mat-select [(ngModel)]="dataSource!.pageSize">
            <mat-option [value]="5">5</mat-option>
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="25">25</mat-option>
            <mat-option [value]="100">100</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchUploadDocuments_document">Document</mat-header-cell>
      <mat-cell *matCellDef="let element"><span><a class="glossary-view" [routerLink]="'../' + element.id">{{element.name}}</a></span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="language">
      <mat-header-cell *matHeaderCellDef i18n="@@SearchUploadDocuments_language">Language</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{getLanguageByCode(element.language)}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="mostCommonErrorType">
      <mat-header-cell *matHeaderCellDef i18n="@@SearchUploadDocuments_mostCommonErrorType">Most common error type</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{element.mostCommonErrorType}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef i18n="@@SearchUploadDocuments_status">Status</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.completedAnalysis" i18n="@@SearchUploadDocuments_completed">Completed</span>
        <ng-container *ngIf="!element.completedAnalysis">
          <span *ngIf="element.errorOnAnalysis !== undefined" i18n="@@SearchUploadDocuments_error">Error ({{ element.errorOnAnalysis }})</span>
          <span *ngIf="element.errorOnAnalysis === undefined" i18n="@@SearchUploadDocuments_running">Running</span>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@SearchUploadDocuments_uploaded">Uploaded</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{element.created}}</span></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    [length]="dataSource!.totalItems"
    [pageIndex]="dataSource!.pageIndex"
    [pageSize]="dataSource!.pageSize"
    (page)="dataSource!.pageIndex = $event.pageIndex"
    [hidePageSize]="true">
  </mat-paginator>
</div>