import { ErrorReporting } from './../../models/txtanalyser/errorreporting';
import { Injectable } from '@angular/core';
import { Category } from '../../models/txtanalyser/category';
import { Rule } from '../../models/txtanalyser/rule';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { CollectionService, Collection, Template, Data } from '../collection.service';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ignore } from '../../models/txtanalyser/ignore';
import { IgnoreDocument } from '../../models/txtanalyser/IgnoreDocument';
import { IPage } from '../../models/txtanalyser/IPage';
import { IReportDocumentOptions } from '../../models/txtanalyser/IReportDocumentOptions';
import { ITxtAnalyserReportJobResult, IResultCategory } from '../../models/txtanalyser/ReportJobResult';
import { ITxtAnalyserReportDocumentText } from '../../models/txtanalyser/ReportDocumentText';
import { ITxtAnalyserReportDocumentErrorType } from '../../models/txtanalyser/ReportDocumentErrorType';
import { ITxtAnalyserReportJob } from '../../models/txtanalyser/ReportJob';
import { IErrorType } from '../../models/txtanalyser/IErrorType';
import { IUploadDocumentType } from '../../models/txtanalyser/IUploadDocument';
import { base64ArrayBuffer } from 'app/utils/string';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ITxtAnalyserReportDocument } from 'app/models/txtanalyser/ITxtAnalyserReportDocument';

const URI: string = "/ta";
const PROFILE_URI: string = "/profile";

@Injectable()
export class TxtAnalyserCollectionService implements ITxtAnalyserService {
  constructor(
    private collectionService: CollectionService,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService,
    private httpClient: HttpClient
  ) {}

  private toCategoryUri(category: Category): string {
    let uri: string;
    if (category.groupId !== undefined) {
      uri = "/groups/" + encodeURIComponent(category.groupId.toString());
      if (category.impersonatedUserId !== undefined) {
        uri += "/members/" + encodeURIComponent(category.impersonatedUserId.toString());
      }
    } else {
      uri = PROFILE_URI;
    }

    return uri + URI + "/categories";
  }

  private toRuleUri(rule: Rule): string {
    let uri: string;
    if (rule.groupId !== undefined) {
      uri = "/groups/" + encodeURIComponent(rule.groupId.toString());
      if (rule.impersonatedUserId !== undefined) {
        uri += "/members/" + encodeURIComponent(rule.impersonatedUserId.toString());
      }
    } else {
      uri = PROFILE_URI;
    }

    return uri + URI + "/categories";
  }

  getErrorTypes(language: string, groupId?: number, userId?: number): Observable<IErrorType[]> {
    let uri: string = URI + "/errortypes/" + encodeURIComponent(language);
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      let errorTypes: IErrorType[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];
        errorTypes.push(
          {
            id: item.getDataAsString("id"),
            category: item.getDataAsString("category"),
            subCategory: item.getDataAsString("subCategory"),
            description: item.getDataAsString("description"),
            correctionText: item.getDataAsString("correctionText"),
            noCorrectionText: item.getDataAsStringOrUndefined("noCorrectionText") || "",
            video: item.getDataAsStringOrUndefined("video")
          }
        );
      }
      return errorTypes;
    }));
  }

  getErrorType(id: string, language: string, groupId?: number, userId?: number): Observable<IErrorType> {
    let uri: string = URI + "/errortypes/" + encodeURIComponent(language) + "/" + encodeURIComponent(id);
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];
        return {
          id: item.getDataAsString("id"),
          category: item.getDataAsString("category"),
          subCategory: item.getDataAsString("subCategory"),
          description: item.getDataAsString("description"),
          correctionText: item.getDataAsString("correctionText"),
          noCorrectionText: item.getDataAsStringOrUndefined("noCorrectionText") || "",
          video: item.getDataAsStringOrUndefined("video")
        };
      }
      throw new Error("Error type not found");
    }));
  }

  getCategories(language: string, groupId?: number, userId?: number): Observable<Category[]> {
    let uri: string = URI + "/categories/" + encodeURIComponent(language);
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      let categories: Category[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        let id = item.getDataAsString("id");
        let name = item.getDataAsString("name");
        let description = item.getDataAsString("description");
        let language = item.getDataAsString("language");
        let enabled = item.getDataAsBoolean("enabled");
        let indeterminate = item.getDataAsBoolean("indeterminate");

        categories.push(
          new Category(
            id, language, name, description, enabled, indeterminate, item.getDataAsIntegerOrUndefined("groupId"), item.getDataAsIntegerOrUndefined("userId")
          )
        );
      }
      return categories;
    }));
  }

  getRulesByCategory(category: Category): Observable<Rule[]> {
    const uri = this.toCategoryUri(category)
      + "/" + encodeURIComponent(category.language)
      + "/" + encodeURIComponent(category.id) + "/rules";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map((collection: Collection) => {
      const rules: Rule[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        let id: string = item.getDataAsString("id");
        let name: string = item.getDataAsString("name");
        let description: string = item.getDataAsString("description");
        description = description.replace(/\[([^\]]+)\]\[([^\]]+)\]/g, (m, $1) => {
          return $1;
        });
        let enabled: boolean = item.getDataAsBoolean("enabled");
        let category: string = item.getDataAsString("category");
        let language: string = item.getDataAsString("language");
        let indeterminate = item.getDataAsBoolean("indeterminate");

        rules.push(
          new Rule(
            id, category, language, name, description, enabled, indeterminate,
            item.getDataAsIntegerOrUndefined("groupId"),
            item.getDataAsIntegerOrUndefined("userId")
          )
        );
      }

      return rules;
    }));
  }

  updateCategoryEnabled(category: Category): Observable<any> {
    const uri = this.toCategoryUri(category)
      + "/" + encodeURIComponent(category.language)
      + "/" + encodeURIComponent(category.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("enabled", category.enabled ? "True" : "False")
    ]);

    return collection.put(template);
  }

  updateRuleEnabled(rule: Rule): Observable<any> {
    const uri = this.toRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/"
      + encodeURIComponent(rule.categoryId) + "/rules/"
      + encodeURIComponent(rule.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("enabled", rule.enabled ? "True" : "False")
    ]);

    return collection.put(template);
  }

  getErrorReporting(language: string, groupId?: number, userId?: number): Observable<ErrorReporting> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language);
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        let language = item.getDataAsString("language");
        let value = item.getDataAsString("value");

        return new ErrorReporting(
          language, value, item.getDataAsIntegerOrUndefined("groupId"), item.getDataAsIntegerOrUndefined("userId")
        );
      }

      throw new Error("Error reporting not found");
    }));
  }

  updateErrorReporting(errorReporting: ErrorReporting): Observable<any> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(errorReporting.language);
    if (errorReporting.groupId !== undefined) {
      if (errorReporting.impersonatedUserId !== undefined) {
        uri = "/members/" + encodeURIComponent(errorReporting.impersonatedUserId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(errorReporting.groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("value", errorReporting.value)
    ]);

    return collection.put(template);
  }

  getIgnoreDocuments(language: string, groupId?: number, userId?: number): Observable<IgnoreDocument[]> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      const documents: IgnoreDocument[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const language = item.getDataAsString("language");
        const name = item.getDataAsString("name");
        const ignores = item.getDataAsInteger("ignores");

        documents.push(new IgnoreDocument(
          id, language, name, ignores,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        ));
      }

      return documents;
    }));
  }

  getPagedIgnoreDocuments(language: string, pageIndex: number, pageSize: number, orderActive: string, orderDirection: string, groupId?: number, userId?: number): Observable<IPage<IgnoreDocument>> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "?pageIndex=" + encodeURIComponent(pageIndex.toString())
      + "&pageSize=" + encodeURIComponent(pageSize.toString());
    if (orderActive) {
      uri += "&orderActive=" + encodeURIComponent(orderActive);
      if (orderDirection) {
        uri += "&orderDirection=" + encodeURIComponent(orderDirection);
      }
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      const page: IPage<IgnoreDocument> = {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0
      };

      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const language = item.getDataAsString("language");
        const name = item.getDataAsString("name");
        const ignores = item.getDataAsInteger("ignores");

        page.totalItems = item.getDataAsInteger("totalItems");
        page.pageIndex = item.getDataAsInteger("pageIndex");
        page.pageSize = item.getDataAsInteger("pageSize");
        page.totalPages = item.getDataAsInteger("totalPages");

        page.items.push(new IgnoreDocument(
          id, language, name, ignores,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        ));
      }

      return page;
    }));
  }

  getIgnoreDocument(id: string, language: string, groupId?: number, userId?: number): Observable<IgnoreDocument> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + id;
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const language = item.getDataAsString("language");
        const name = item.getDataAsString("name");
        const ignores = item.getDataAsInteger("ignores");

        return new IgnoreDocument(
          id, language, name, ignores,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        );
      }

      throw new Error("Unable to get document");
    }));
  }

  deleteIgnoreDocument(id: string, language: string, groupId?: number, userId?: number): Observable<any> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + id;
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }
    const collection = this.collectionService.createCollection(uri);

    return collection.delete();
  }

  getIgnoresInIgnoreDocument(documentId: string, language: string, groupId?: number, userId?: number): Observable<Ignore[]> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + documentId + "/ignores";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      const ignores: Ignore[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const documentId = item.getDataAsString("documentId");
        const language = item.getDataAsString("language");
        const category = item.getDataAsString("category");
        const subCategory = item.getDataAsString("subCategory");
        const text = item.getDataAsString("text");

        ignores.push(new Ignore(
          id, documentId, language, category, subCategory, text,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        ));
      }

      return ignores;
    }));
  }

  getPagedIgnoresInIgnoreDocument(documentId: string, language: string, pageIndex: number, pageSize: number, orderActive: string, orderDirection: string, groupId?: number | undefined, userId?: number | undefined): Observable<IPage<Ignore>> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + documentId + "/ignores";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "?pageIndex=" + encodeURIComponent(pageIndex.toString())
      + "&pageSize=" + encodeURIComponent(pageSize.toString());

    if (orderActive) {
      uri += "&orderActive=" + encodeURIComponent(orderActive);
      if (orderDirection) {
        uri += "&orderDirection=" + encodeURIComponent(orderDirection);
      }
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      const page: IPage<Ignore> = {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0
      };

      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const documentId = item.getDataAsString("documentId");
        const language = item.getDataAsString("language");
        const category = item.getDataAsString("category");
        const subCategory = item.getDataAsString("subCategory");
        const text = item.getDataAsString("text");

        page.totalItems = item.getDataAsInteger("totalItems");
        page.pageIndex = item.getDataAsInteger("pageIndex");
        page.pageSize = item.getDataAsInteger("pageSize");
        page.totalPages = item.getDataAsInteger("totalPages");

        page.items.push(new Ignore(
          id, documentId, language, category, subCategory, text,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        ));
      }

      return page;
    }));
  }

  getIgnoreInDocument(id: string, documentId: string, language: string, groupId?: number, userId?: number): Observable<Ignore> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + documentId + "/ignores/" + id;
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        const id = item.getDataAsString("id");
        const documentId = item.getDataAsString("documentId");
        const language = item.getDataAsString("language");
        const category = item.getDataAsString("category");
        const subCategory = item.getDataAsString("subCategory");
        const text = item.getDataAsString("text");

        return new Ignore(
          id, documentId, language, category, subCategory, text,
          item.getDataAsIntegerOrUndefined("groupId"),
          item.getDataAsIntegerOrUndefined("userId")
        );
      }

      throw new Error("Unable to get ignore");
    }));
  }

  deleteIgnoreInIgnoreDocument(id: string, documentId: string, language: string, groupId?: number, userId?: number): Observable<any> {
    let uri: string = URI + "/errorreporting/" + encodeURIComponent(language) + "/documents/" + documentId + "/ignores/" + id;
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }
    const collection = this.collectionService.createCollection(uri);

    return collection.delete();
  }

  getReportJob(jobId: string, groupId?: number, userId?: number): Observable<ITxtAnalyserReportJob> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri + "/" + encodeURIComponent(jobId))
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      if (collection.items.length === 0) throw new Error("Unable to fetch details of document");

      const item = collection.items[0];
      return {
        id: item.getDataAsString("id"),
        name: item.getDataAsString("name"),
        language: item.getDataAsString("language"),
        mostCommonErrorType: item.getDataAsString("mostCommonErrorType"),
        created: item.getDataAsString("created"),
        completedAnalysis: item.getDataAsBoolean("completedAnalysis"),
        errorOnAnalysis: item.getDataAsStringOrUndefined("errorOnAnalysis")
      };
    }));
  }

  getReportDocuments(jobId: string, groupId?: number, userId?: number): Observable<ITxtAnalyserReportDocument[]> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "/" + encodeURIComponent(jobId) + "/documents";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      if (collection.items.length === 0) throw new Error("Unable to fetch documents of job");

      return collection.items.map(x => ({
        jobId,
        id: x.getDataAsString("id"),
        name: x.getDataAsString("name")
      }));
    }));
  }

  getReportJobResult(jobId: string, groupId?: number, userId?: number): Observable<ITxtAnalyserReportJobResult> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "/" + encodeURIComponent(jobId) + "/result";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      let name = "";
      let language = "";

      const categories: {[key: string]: IResultCategory} = {};
      for (const item of collection.items) {
        if (item.getDataAsString("type") !== "Category") continue;

        // Just to get name and language
        name = item.getDataAsString("name");
        language = item.getDataAsString("language");

        const category = item.getDataAsString("category");
        const percentage = item.getDataAsString("percentage");
        const totalErrors = item.getDataAsInteger("errors");

        categories[category] = {
          category,
          errors: [],
          totalErrors,
          percentage
        };
      }

      for (const item of collection.items) {
        if (item.getDataAsString("type") !== "SubCategory") continue;

        const category = item.getDataAsString("category");
        const subCategory = item.getDataAsString("subCategory");
        const percentage = item.getDataAsString("percentage");
        const totalErrors = item.getDataAsInteger("errors");

        categories[category].errors.push({
          subCategory,
          totalErrors,
          percentage
        });
      }

      return {
        id: jobId,
        name,
        language,
        categories: Object.keys(categories).map(x => categories[x])
      };
    }));
  }

  getReportDocumentText(jobId: string, documentId: string, groupId?: number, userId?: number): Observable<ITxtAnalyserReportDocumentText> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "/" + encodeURIComponent(jobId) + "/documents/" + documentId + "/text";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      if (collection.items.length === 0) throw new Error("Unable to fetch details of document");

      const item = collection.items[0];
      return {
        jobId: item.getDataAsString("id"),
        documentId: documentId,
        text: item.getDataAsString("text")
      };
    }));
  }

  getReportDocumentErrorTypes(jobId: string, documentId: string, groupId?: number, userId?: number): Observable<ITxtAnalyserReportDocumentErrorType[]> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    uri += "/" + encodeURIComponent(jobId) + "/documents/" + documentId + "/errors";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      const items = [];
      for (const item of collection.items) {
        items.push({
          jobId: item.getDataAsString("jobId"),
          id: item.getDataAsString("id"),

          startIndex: item.getDataAsInteger("startIndex"),
          endIndex: item.getDataAsInteger("endIndex"),

          contextStartIndex: item.getDataAsInteger("contextStartIndex"),
          contextEndIndex: item.getDataAsInteger("contextEndIndex"),

          corrections: item.getDataAsArray<string>("corrections"),

          category: item.getDataAsString("category"),
          subCategory: item.getDataAsString("subCategory"),
          correctionText: item.getDataAsString("correctionText"),
          description: item.getDataAsString("description"),
          noCorrectionText: item.getDataAsStringOrUndefined("noCorrectionText") || "",
          video: item.getDataAsStringOrUndefined("video"),
        });
      }

      return items;
    }));
  }

  getReportPagedJobs(options?: IReportDocumentOptions, groupId?: number, userId?: number): Observable<IPage<ITxtAnalyserReportJob>> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    let queryStringBuilder: string[] = [];

    if (options) {
      if (options.pageIndex !== undefined) {
        queryStringBuilder.push("pageIndex=" + encodeURIComponent(options.pageIndex.toString()));
      }
      if (options.pageSize !== undefined) {
        queryStringBuilder.push("pageSize=" + encodeURIComponent(options.pageSize.toString()));
      }
      if (options.orderByActive !== undefined) {
        queryStringBuilder.push("orderByActive=" + encodeURIComponent(options.orderByActive));
      }
      if (options.orderByDirection !== undefined) {
        queryStringBuilder.push("orderByDirection=" + encodeURIComponent(options.orderByDirection));
      }
      if (options.filterByName !== undefined) {
        queryStringBuilder.push("filterByName=" + encodeURIComponent(options.filterByName));
      }
    }

    if (queryStringBuilder.length > 0) {
      uri += "?" + queryStringBuilder.join("&");
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => {
      collection.links = collection.links.filter(x => x.rel !== "currentPage" && x.rel !== "currentPageLabel");

      return collection;
    }))
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map(collection => {
      let totalItems = 0;
      let totalPages = 0;
      let pageIndex = 0;
      let pageSize = 0;

      const items: ITxtAnalyserReportJob[] = [];
      for (const item of collection.items) {
        totalItems = item.getDataAsInteger("totalItems");
        totalPages = item.getDataAsInteger("totalPages");
        pageIndex = item.getDataAsInteger("pageIndex");
        pageSize = item.getDataAsInteger("pageSize");

        items.push({
          id: item.getDataAsString("id"),
          name: item.getDataAsString("name"),
          language: item.getDataAsString("language"),
          mostCommonErrorType: item.getDataAsString("mostCommonErrorType"),
          created: item.getDataAsString("created"),
          completedAnalysis: item.getDataAsBoolean("completedAnalysis"),
          errorOnAnalysis: item.getDataAsStringOrUndefined("errorOnAnalysis")
        });
      }
      return {
        totalItems,
        totalPages,
        pageIndex,
        pageSize,
        items
      };
    }));
  }

  public uploadReportDocuments(language: string, disabledErrorTypes: string[], documents: IUploadDocumentType[], groupId?: number, userId?: number): Observable<ITxtAnalyserReportJob> {
    let uri: string = URI + "/report";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.httpClient.post<UploadReportResponse>(environment.collectionUrl + uri, JSON.stringify({
      "language": language,
      "disabledErrorTypes": disabledErrorTypes,
      "documents": documents.map(x => ({
        name: x.name,
        mimeType: x.mimeType,
        content: base64ArrayBuffer(x.content)
      }))
    }), {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .pipe<ITxtAnalyserReportJob>(map<UploadReportResponse, ITxtAnalyserReportJob>(x => ({
        id: x.id,
        name: x.name,
        language: x.language,
        mostCommonErrorType: x.mostCommonErrorType === null ? '' : x.mostCommonErrorType,
        created: x.created,

        completedAnalysis: x.completedAnalysis,
        errorOnAnalysis: x.errorOnAnalysis === null ? undefined : x.errorOnAnalysis
      })));
  }
}

interface UploadReportResponse {
  id: string;
  name: string;
  language: string;
  mostCommonErrorType: string;
  created: string;

  disabledErrorTypes: string;
  completedAnalysis: boolean;

  error?: string;
  errorOnAnalysis?: string;
}
