<app-header></app-header>
<div class="subtitle">
  <div i18n="@@Group_Token">Group token: {{ group!.joinToken }}</div>
  <div i18n="@@Group_Link">Group link: <a [href]="transformJoinTokenToUrl(group!.joinToken)">{{ transformJoinTokenToUrl(group!.joinToken) }}</a></div>
</div>

<div class="list">
  <app-navigation-card [link]="link" *ngFor="let link of links"></app-navigation-card>
</div>

<h1 class="page" i18n="@@Group_MembersHeader">Members</h1>

<table>
  <thead *ngIf="!isPhone">
    <tr>
      <th i18n="@@Group_Name">Name</th>
      <th i18n="@@Group_Email">Email</th>
      <th i18n="@@Group_Role">Role</th>
      <th></th>
    </tr>
  </thead>
  <ng-container *ngFor="let member of members; let i = index">
    <tbody class="card">
      <tr>
        <td class="card-cell cell-overflow card-cell__name">
          <a
            [routerLink]="'/groups/' + group!.id! + '/members/' + member.userId"
            class="cell-overflow-text"
            *ngIf="member.permissions.impersonate">
            {{ member.name }}
          </a>
          <span *ngIf="!member.permissions.impersonate" class="cell-overflow-text">
            {{ member.name }}
          </span>
        </td>
        <td class="card-cell cell-overflow" *ngIf="!isPhone">
          <span class="cell-overflow-text">{{ member.email }}</span>
        </td>
        <td class="card-cell" *ngIf="!isPhone">
          <span>{{ member.roleName }}</span>
        </td>
        <td class="card-actions" *ngIf="isPhone">
          <button mat-icon-button color="primary" (click)="editMember(member)" *ngIf="member.permissions.edit && !isRoleOwner(member.role)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="removeMember(member)" *ngIf="member.permissions.remove && !isRoleOwner(member.role)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
        <td class="card-actions" *ngIf="!isPhone">
          <button mat-button color="primary" (click)="editMember(member)" *ngIf="member.permissions.edit && !isRoleOwner(member.role)">
            <mat-icon>edit</mat-icon>
            <span i18n="@@Group_ntmEdit">Edit</span>
          </button>
          <button mat-button color="primary" (click)="removeMember(member)" *ngIf="member.permissions.remove && !isRoleOwner(member.role)">
            <mat-icon>delete</mat-icon>
            <span i18n="@@Group_btnRemove">Remove</span>
          </button>
        </td>
      </tr>
      <tr *ngIf="isPhone">
        <td colspan="2" class="cell-overflow">
          <div class="flex-between">
            <span class="cell-overflow-text">{{ member.email }}</span>
            <span class="nowrap">{{ member.roleName }}</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr class="card-spacing" *ngIf="i < members.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>