<mat-dialog-content>
  <h1>{{ glossary.name }}</h1>

  <div class="grid">
    <div class="grid-tile">
      <h3 i18n="@@ViewGlossary_Description">Description</h3>
      <div>{{ glossary.description }}</div>

      <h3 i18n="@@ViewGlossary_Language">Language</h3>
      <div>{{ getLanguageByCode(glossary.language) }}</div>

      <h3 i18n="@@ViewGlossary_Subscribers">Subscribers</h3>
      <div>{{ glossary.subscribers }}</div>

      <h3 i18n="@@ViewGlossary_Owner">Made by</h3>
      <span *ngIf="unknownOwner" class="unknown-owner" i18n="@@ViewGlossary_UnknownOwner">- Unknown -</span>
      <div *ngIf="!unknownOwner">{{ glossary.ownerDisplayName }}</div>

      <!--<h3 i18n="@@ViewGlossary_Rating">Rating</h3>
      <app-rating [rating]="glossary.rating" [readonly]="true"></app-rating>-->
    </div>
    <div class="grid-tile">
      <h3 i18n="@@ViewGlossary_WordInGlossary">Words in the glossary</h3>
      <div *ngIf="wordList.words.length === 0 && !wordsLoading"
            i18n="@@ViewGlossary_NoWords">
        No words in the glossary.
      </div>
      <app-virtual-scroll
          *ngIf="wordList.words.length > 0 || wordsLoading"
          [update]="wordList.onWordChanges"
          [items]="wordList.words"
          [loading]="wordsLoading">
        <ng-template #loading>
          <mat-progress-spinner
            class="loading"
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </ng-template>
        <ng-template #item let-item let-row="row">
          <div class="word" [class.odd]="row % 2 === 0">
            <span>{{ item.text }}</span>
          </div>
        </ng-template>
      </app-virtual-scroll>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!--<span>
    <h2 i18n="@@ViewGlossary_RateGlossary">Rate the glossary</h2>
    <app-rating [rating]="glossary.userRating" size="40px" (change)="rate($event)" class="rating"></app-rating>
  </span>-->
  <button class="close-btn" mat-raised-button color="primary" mat-dialog-close i18n="@@ViewGlossary_Close">Close</button>
</mat-dialog-actions>