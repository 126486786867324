import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject ,  Subscription } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { ITxtAnalyserReportJob } from "./ReportJob";

export class ReportDocumentSearchDataSource extends DataSource<ITxtAnalyserReportJob> {
  private _service: ITxtAnalyserService;

  private readonly _renderData = new BehaviorSubject<ITxtAnalyserReportJob[]>([]);

  private _sortChangeSubscription?: Subscription;
  private _renderChangesSubscription?: Subscription;
  
  private _pageSize: number = 25;
  private _pageIndex: number = 0;
  private _filter: string = "";
  private _sort: MatSort|undefined;

  private _totalItems: number = 0;

  private _groupId?: number;
  private _userId?: number;

  get totalItems(): number { return this._totalItems; }

  get pageSize(): number { return this._pageSize; }
  set pageSize(size: number) {
    if (this._pageSize === size) return;
    this._pageSize = size;

    this._updateRenderDataSubscription();
  }

  get pageIndex(): number { return this._pageIndex; }
  set pageIndex(index: number) {
    if (this._pageIndex === index) return;
    this._pageIndex = index;

    this._updateRenderDataSubscription();
  }

  get filter(): string { return this._filter; }
  set filter(filter: string) {
    if (this._filter === filter) return;
    this._filter = filter;

    this._updateRenderDataSubscription();
  }

  get sort(): MatSort | undefined { return this._sort; }
  set sort(sort: MatSort|undefined) {
    this._sort = sort;

    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._sort) {
      this._sortChangeSubscription = this._sort.sortChange.subscribe(() => this._updateRenderDataSubscription());
    }

    this._updateRenderDataSubscription();
  }

  constructor(service: ITxtAnalyserService, groupId?: number, userId?: number) {
    super();
    this._service = service;
    this._groupId = groupId;
    this._userId = userId;

    this._updateRenderDataSubscription();
  }

  private _updateRenderDataSubscription(): void {
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }

    let sortDirection: ""|"asc"|"desc"|undefined = undefined;
    let sortActive: string|undefined = undefined;

    if (this._sort) {
      sortDirection = this._sort.direction;
      sortActive = this._sort.active;
    }

    this._renderChangesSubscription = this._service.getReportPagedJobs({
      filterByName: this._filter,
      orderByActive: sortActive,
      orderByDirection: sortDirection,
      pageSize: this._pageSize,
      pageIndex: this._pageIndex
    }, this._groupId, this._userId)
    .subscribe(data => {
      this._totalItems = data.totalItems;
      this._renderData.next(data.items);
    });
  }

  connect() {
    return this._renderData;
  }

  disconnect() {
    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }
  }
}