<h1 mat-dialog-title i18n="@@EditPhoneticSpelling_Header">Edit spelling rule</h1>
<form (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div>
      <mat-form-field class="full-width">
        <mat-placeholder i18n="@@EditPhoneticSpelling_Error">Error</mat-placeholder>
        <input name="spellingError" matInput [(ngModel)]="from" [disabled]="loading">
      </mat-form-field>
      <mat-icon>{{ isRtL ? 'arrow_back' : 'arrow_forward'}}</mat-icon>
      <mat-form-field class="full-width">
        <mat-placeholder i18n="@@EditPhoneticSpelling_Correction">Correction</mat-placeholder>
        <input name="spellingCorrection" matInput [(ngModel)]="to" [disabled]="loading">
      </mat-form-field>
    </div>
    <div class="error" *ngIf="hasError">{{ errorMessage }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close type="button" i18n="@@EditPhoneticSpelling_btnCancel">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="loading || !from || !to" i18n="@@EditPhoneticSpelling_btnEdit">Edit</button>
  </mat-dialog-actions>
</form>
