<h1 mat-dialog-title i18n="@@DeleteConfirmGlossary_DeleteHeader">Delete glossary</h1>
<mat-dialog-content>
  <div i18n="@@DeleteConfirmGlossary_WarnSubscribers">
    Please notice that you're about to delete a glossary that has subscribers.
    Every subscribed user will no longer be able to access the glossary. Are you
    sure you want to delete the glossary?
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button" i18n="@@DeleteConfirmGlossary_btnCancel">Cancel</button>
  <button mat-raised-button color="primary" (click)="delete()" i18n="@@DeleteConfirmGlossary_btnDelete">Delete</button> 
</mat-dialog-actions>