import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-glossary-snackbar',
  templateUrl: './glossary-snackbar.component.html',
  styleUrls: ['./glossary-snackbar.component.scss']
})
export class GlossarySnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public type: GlossarySnackbarType
  ) { }

  isEnabled() {
    return this.type === GlossarySnackbarType.Enabled;
  }

  isDisabled() {
    return this.type === GlossarySnackbarType.Disabled;
  }

  isUnsubscribed() {
    return this.type === GlossarySnackbarType.Unsubscribed;
  }
}

export enum GlossarySnackbarType {
  Enabled,
  Disabled,
  Unsubscribed
}
