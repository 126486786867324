<h1 mat-dialog-title i18n="@@RecommednPhoneticSpelling_Header">Recommend phonetic spelling</h1>
<mat-dialog-content>
  <div class="paragraph" i18n="@@RecommednPhoneticSpelling_Info">AppWriter can change your phonetic spelling settings to only show the errors your normally make.</div>
  <div class="paragraph" i18n="@@RecommednPhoneticSpelling_RecommendsInfo">AppWriter recommends the following phonetic spellings</div>
  <div class="view">
    <div class="shadow"></div>
    <div class="content">
      <mat-progress-bar color="primary" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <table *ngIf="!loading">
        <ng-container *ngFor="let category of categories; let i = index">
          <tbody class="card">
            <tr>
              <td class="card-title">
                <mat-checkbox [(ngModel)]="category.enabled"  [indeterminate]="category.indeterminate" (change)="updateCategory(category)">
                  {{ category.name }}
                </mat-checkbox>
              </td>
              <td class="card-actions">
                <button mat-icon-button color="primary" (click)="expanded[i] = !expanded[i]">
                  <mat-icon *ngIf="expanded[i]" class="expand-icon">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="!expanded[i]" class="expand-icon">keyboard_arrow_down</mat-icon>
                </button>
              </td>
            </tr>
            <ng-container *ngIf="expanded[i]">
              <ng-container *ngFor="let rule of rules[i]">
                <tr class="card-rule">
                  <td class="card-title">
                    <mat-checkbox [(ngModel)]="rule.enabled" (change)="updateRule(category, rule)">
                      {{ rule.name }}
                    </mat-checkbox>
                  </td>
                  <td class="card-actions"></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button" i18n="@@RecommednPhoneticSpelling_btnCancel">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="disabled" (click)="update()" i18n="@@RecommednPhoneticSpelling_btnUpdate">Update</button>
</mat-dialog-actions>