import { LingappsError } from './../../services/auth.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { CustomRule } from '../../models/spelling';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../../interfaces/ispelling.service.token';
import { Directionality } from '@angular/cdk/bidi';

interface IData {
  language: string,
  groupId: number,
  userId: number
}
@Component({
  selector: 'app-phonetic-spelling-create-dialog',
  templateUrl: './phonetic-spelling-create-dialog.component.html',
  styleUrls: ['./phonetic-spelling-create-dialog.component.scss']
})
export class PhoneticSpellingCreateDialogComponent {
  public language: string;
  public from: string = "";
  public to: string = "";
  public example: string = "";

  public loading: boolean = false;
  public hasError: boolean = false;
  public errorMessage = "";
  public isRtL = false;

  public onCreate: Subject<CustomRule> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService,
    public dialogRef: MatDialogRef<PhoneticSpellingCreateDialogComponent>,
    private direction: Directionality
  ) {
    this.language = data.language;
    this.isRtL = direction.value === 'rtl';
  }

  onSubmit() {
    if (this.loading || !this.from || !this.to) return;

    this.loading = true;

    this.hasError = false;
    this.errorMessage = "";

    this.spellingService.createSimpleCustomRule(this.from, this.to, this.example, this.language, this.data.groupId, this.data.userId)
    .subscribe((rule: CustomRule) => {
      this.onCreate.next(rule);
    }, (error: LingappsError) => {
      this.hasError = true;

      if (error && error.message) {
        this.errorMessage = error.message;
      }

      this.loading = false;
    }, () => {
      this.onCreate.complete();
      this.dialogRef.close();
    });
  }
}
