export class AuthToken {
  constructor(private sessionId: string, private machineId: string,
    private phpSessionId: string) { }
  
  public static fromToken(token: string): AuthToken {
    const obj: {[key: string]: string} = JSON.parse(atob(token));
    
    return new AuthToken(obj['LingAppsUserSessionID'], obj['MID'],
      obj['PHPSESSID']);
  }
  
  /**
   * Returns the session ID.
   */
  public getSessionId(): string {
    return this.sessionId;
  }
  
  /**
   * Returns the machine ID.
   */
  public getMachineId(): string {
    return this.machineId;
  }
  
  /**
   * Returns the PHP session ID.
   */
  public getPHPSessionId(): string {
    return this.phpSessionId;
  }
  
  /** @override */
  public toString(): string {
    return btoa(JSON.stringify({
      "PHPSESSID": this.phpSessionId,
      "MID": this.machineId,
      "LingAppsUserSessionID": this.sessionId
    }));
  }
}
