import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group, GroupRole, GroupRoleName, GroupType, GroupPermissions, GroupMemberPermissions, GroupMember, getGroupRoleByKey } from '../../models/groups';
import { IGroupsService } from '../../interfaces/igroups.service';
import { CollectionService, Collection, Item, Template, Data } from '../collection.service';
import { Observable } from 'rxjs';
import { NavigationLink, NavigationState, getNavigationStateByKey } from '../../models/navigation';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

const URI = "/groups";



@Injectable()
export class GroupsCollectionService implements IGroupsService {
  constructor(
    private collectionService: CollectionService,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService,
    private httpClient: HttpClient
  ) {}

  private getGroupByItem(item: Item): Group {
    let id = item.getDataAsInteger("id");
    let name = item.getDataAsString("name");
    let type: GroupType = GroupType.USER_CREATED;

    if (item.hasData("groupType")) {
      switch (item.getDataAsString("groupType")) {
        case "USER_CREATED":
        default: {
          type = GroupType.USER_CREATED;
          break;
        }
      }
    }

    let totalMembersCount = item.getDataAsInteger("totalMembersCount") || 0;
    let joinToken = item.getDataAsString("joinKey");

    let isOwner = item.getDataAsBoolean("isOwner");
    let canEdit = item.getDataAsBoolean("canEdit");
    let canViewStatistics = item.getDataAsBoolean("canViewStatistics");
    let canEditApplicationSettings = item.getDataAsBoolean("canEditApplicationSettings");

    let state: GroupRole = GroupRole.MEMBER;
    if (isOwner) {
      state = GroupRole.OWNER;
    }

    let permissions = new GroupPermissions(canEdit, canViewStatistics, canEditApplicationSettings);

    return new Group(
      id,
      name,
      totalMembersCount,
      joinToken,
      state,
      type,
      permissions
    );
  }

  getGroupById(id: number): Observable<Group> {
    const uri =  "/groups/" + id;

    return this.collectionService.getCollection(uri)
      .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
      .pipe(map((collection: Collection) => {
        if (!collection || !collection.items || collection.items.length !== 1)
          throw new Error("No Group by ID");
        return this.getGroupByItem(collection.items[0]);
      }));
  }

  getMembersById(id: number): Observable<GroupMember[]> {
    const uri =  "/groups/" + id + "/members";

    return this.collectionService.getCollection(uri)
      .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
      .pipe(map((collection: Collection) => {
        let users: GroupMember[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          let item = collection.items[i];

          let userId = item.getDataAsString("userId");
          let firstName = item.getDataAsString("firstName");
          let lastName = item.getDataAsString("lastName");
          let email = item.getDataAsString("email");
          let role: GroupRole = getGroupRoleByKey(item.getDataAsString("role"));

          let canEdit = item.getDataAsBoolean("canEdit");
          let canRemove = item.getDataAsBoolean("canRemove");
          let canImpersonate = item.getDataAsBoolean("canImpersonate");
          let includeInGroupStatistics = item.getDataAsBoolean("includeInGroupStatistics");

          let permissions: GroupMemberPermissions = new GroupMemberPermissions(canEdit, canRemove, canImpersonate, includeInGroupStatistics);

          users.push(new GroupMember(
            id, userId, firstName, lastName, email,item.getDataAsString("roleName"), role, permissions
          ));
        }

        return users;
      }));
  }

  /** @override */
  getGroups(): Observable<Group[]> {
    return this.collectionService.getCollection(URI)
    .pipe(map((collection: Collection) => {
      let groups: Group[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item: Item = collection.items[i];

        groups.push(this.getGroupByItem(item));
      }

      return groups;
    }));
  }

  deleteGroup(group: Group): Observable<Group> {
    const uri =  "/groups/" + (group.id === undefined ? "" : group.id);
    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => {
        return group;
      }));
  }

  updateGroupNameById(id: number, name: string): Observable<Group> {
    const uri =  "/groups/" + id;
    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("name", name)
    ]);

    return collection.put(template)
      .pipe(map((response) => JSON.parse(response)))
      .pipe(map((response) => Collection.parse(this.httpClient, response['collection'])))
      .pipe(map((collection) => {
        if (!collection || !collection.items || collection.items.length !== 1)
          throw new Error("No group in collection response.");
        
        return this.getGroupByItem(collection.items[0]);
      }));
  }

  createGroup(name: string): Observable<Group> {
    const uri = "/groups";
    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("name", name)
    ]);

    return collection.post(template)
      .pipe(map((response) => JSON.parse(response)))
      .pipe(map((response) => Collection.parse(this.httpClient, response['collection'])))
      .pipe(map((collection) => {
        if (!collection || !collection.items || collection.items.length !== 1)
          throw new Error("No group in collection response.");
        
        return this.getGroupByItem(collection.items[0]);
      }));
  }

  getGroupAreasById(id: number): Observable<NavigationLink[]> {
    const uri =  "/groups/" + id + "/areas";

    return this.collectionService.getCollection(uri)
      .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
      .pipe(map((collection: Collection) => {
        const links: NavigationLink[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          const item = collection.items[i];
          
          const name = item.getDataAsString("name");
          const description = item.getDataAsString("description");
          let state: NavigationState = NavigationState.ACTIVE;
          if (item.hasData("state")) {
            state = getNavigationStateByKey(item.getDataAsString("state"));
          }
          const stateMessage = item.getDataAsStringOrUndefined("stateMessage");
          
          const href = item.links[0].href;
          const rel = item.links[0].rel;

          links.push(new NavigationLink(name, description, href, state, stateMessage, rel));
        }

        return links;
      }));
  }

  getGroupByToken(token: string): Observable<Group> {
    const uri = "/validateJoinKey";
    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("Key", token)
    ]);

    return collection.post(template)
      .pipe(map((response) => JSON.parse(response)))
      .pipe(map((response) => Collection.parse(this.httpClient, response['collection'])))
      .pipe(map((collection) => {
        if (!collection || !collection.items || collection.items.length !== 1)
          throw new Error("No group in collection response.");
        let item = collection.items[0];
        if (!item.getDataAsBoolean("valid"))
          throw new Error("Invalid group token.");
        let name = item.getDataAsString("groupName");
        let token = item.getDataAsString("key");

        return new Group(undefined, name, 0, token);
      }));
  }

  joinGroup(group: Group): Observable<Group> {
    const uri = "/joinGroup";

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("JoinKey", group.joinToken || "")
    ]);

    return collection.post(template)
      .pipe(map((response) => JSON.parse(response)))
      .pipe(map((response) => Collection.parse(this.httpClient, response['collection'])))
      .pipe(map((collection) => {
        if (!collection || !collection.items || collection.items.length !== 1)
          throw new Error("No group in collection response.");
        return this.getGroupByItem(collection.items[0]);
      }));
  }

  quitGroup(group: Group): Observable<Group> {
    const uri =  "/groups/" + (group.id === undefined ? "" : group.id) + "/members";
    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => group));
  }

  getRoles(): Observable<GroupRoleName[]> {
    return this.httpClient
      .get(environment.collectionUrl + "/groups/roles")
      .pipe(map((rolesJson: any) => {
        let roles: GroupRoleName[] = [];
        for (let key in rolesJson) {
          if (rolesJson.hasOwnProperty(key)) {
            roles.push(new GroupRoleName(getGroupRoleByKey(key), rolesJson[key]));
          }
        }

        return roles;
      }));
  }

  removeMember(member: GroupMember): Observable<GroupMember> {
    const uri =  "/groups/" + member.groupId + "/members/" + encodeURIComponent(member.userId);
    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => {
        return member;
      }));
  }

  
  updateMember(member: GroupMember): Observable<GroupMember> {
    const uri =  "/groups/" + member.groupId + "/members/" + encodeURIComponent(member.userId);
    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("role", GroupRole[member.role]),
      new Data("includeInGroupStatistics", member.permissions.includeInStatistics ? "True" : "False")
    ]);

    return collection.put(template)
      .pipe(map((response) => JSON.parse(response)))
      .pipe(map((response) => Collection.parse(this.httpClient, response['collection'])))
      .pipe(map((collection) => {
        const item = collection.items[0];
        let userId = item.getDataAsString("userId");
        let groupId = item.getDataAsInteger("groupId");
        
        let firstName = item.getDataAsString("firstName");
        let lastName = item.getDataAsString("lastName");
        let email = item.getDataAsString("email");
        let role = getGroupRoleByKey(item.getDataAsString("role"));

        let canEdit = item.getDataAsBoolean("canEdit");
        let canRemove = item.getDataAsBoolean("canRemove");
        let canImpersonate = item.getDataAsBoolean("canImpersonate");
        let includeInGroupStatistics = item.getDataAsBoolean("includeInGroupStatistics");

        let permissions: GroupMemberPermissions = new GroupMemberPermissions(canEdit, canRemove, canImpersonate, includeInGroupStatistics);

        return new GroupMember(
           groupId, userId, firstName, lastName, email,item.getDataAsString("roleName"), role, permissions
        );
      }));
  }
}