import { InjectionToken } from "@angular/core";

export interface ILocalization {
  getPageXOfY(x: number, y: number): string;
  getItemsPerPage(): string;
  getNextPage(): string;
  getPreviousPage(): string;

  getErrorType(): string;
  getPercentage(): string;
  getAmount(): string;
}
export let ILocalization = new InjectionToken<ILocalization>('ILocalization');