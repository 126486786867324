<app-header></app-header>

<mat-form-field>
  <mat-label i18n="@@language">Language</mat-label>
  <mat-select [(value)]="language" (selectionChange)="changeLanguage()">
    <mat-option *ngFor="let lang of languages" [value]="lang.code">{{lang.name}}</mat-option>
  </mat-select>
</mat-form-field>

<table class="template-table">
  <ng-container *ngFor="let category of categories">
    <tbody class="card">
    <tr>
      <td class="card-title">
        <span (click)="toggleExpandCategory(category)">{{ category }}</span>
      </td>
      <td class="card-description"></td>
      <td class="card-actions">
        <button mat-icon-button color="primary" (click)="toggleExpandCategory(category)">
          <mat-icon *ngIf="templates[category].expanded" class="expand-icon">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="!templates[category].expanded" class="expand-icon">keyboard_arrow_down</mat-icon>
        </button>
      </td>
    </tr>
    <ng-container *ngIf="templates[category].expanded">
      <ng-container *ngFor="let template of templates[category].templates;">
        <tr>
          <td class="card-title">
            {{ template.name}}
          </td>
          <td class="card-description">
            <span>{{ template.description }}</span>
          </td>
          <td class="card-actions">
            <a [href]="template.wordUrl" target="_blank"  matTooltip="Word online">
              <img src="../../../assets/microsoft-office-word.png" alt="" width="25" >
            </a>
            <a [href]="template.googleDocUrl" target="_blank" matTooltip="Google Docs">
              <img src="../../../assets/google-docs.png" alt="" width="25" >
            </a>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
    <br class="card-spacing"/>
  </ng-container>
</table>
