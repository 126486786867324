import { VirtualScrollListComponent } from './../virtual-scroll-list/virtual-scroll-list.component';
import { Observable } from 'rxjs';
import {
  Component, Input, OnChanges, ViewChild, ViewContainerRef, ContentChild,
  TemplateRef, AfterViewChecked, AfterContentChecked, ChangeDetectionStrategy,
  ViewRef, NgZone
} from '@angular/core';

@Component({
  selector: 'app-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollComponent implements OnChanges, AfterViewChecked, AfterContentChecked {
  @ViewChild(VirtualScrollListComponent)
  private listComponent?: VirtualScrollListComponent;

  @ViewChild('loadingContainerRef', { read: ViewContainerRef, static: true })
  private loadingContainerRef?: ViewContainerRef;

  @ViewChild('errorContainerRef', { read: ViewContainerRef, static: true })
  private errorContainerRef?: ViewContainerRef;

  @ViewChild('loadingTemplate', { read: TemplateRef, static: true })
  private loadingDefaultTemplateRef?: TemplateRef<any>;

  @ViewChild('errorTemplate', { read: TemplateRef, static: true })
  private errorDefaultTemplateRef?: TemplateRef<any>;

  @ContentChild('loading', { read: TemplateRef, static: true })
  private loadingTemplateRef?: TemplateRef<any>;

  @ContentChild('error', { read: TemplateRef, static: true })
  private errorTemplateRef?: TemplateRef<any>;

  @ContentChild('item', { read: TemplateRef, static: true })
  public itemTemplateRef?: TemplateRef<any>;

  private loadingViewRef?: ViewRef;
  private errorViewRef?: ViewRef;

  /**
   * @todo Implement ngDoCheck() instead of using update.
   */
  @Input('update')
  public update?: Observable<any>;

  @Input('items')
  public items: any[] = [];

  @Input('loading')
  public loading: boolean = false;
  private _lastLoading: boolean = false;

  @Input('error')
  public error: boolean = false;
  private _lastError: boolean = false;

  private isViewInitialized: boolean = false;
  private isContentInitialized: boolean = false;

  constructor(
    private _zone: NgZone
  ) {}

  private _updateInternal() {
    if (!this.isViewInitialized || !this.isContentInitialized) return;

    this._zone.run(() => {
      if (this.loading !== this._lastLoading && this.loadingContainerRef) {
        this._lastLoading = this.loading;
        this.loadingContainerRef.clear();
        if (this.loading) {
          if (this.loadingViewRef) {
            this.loadingContainerRef.insert(this.loadingViewRef);
          } else if (this.loadingTemplateRef) {
            this.loadingViewRef = this.loadingContainerRef.createEmbeddedView(this.loadingTemplateRef);
          } else if (this.loadingDefaultTemplateRef) {
            this.loadingViewRef = this.loadingContainerRef.createEmbeddedView(this.loadingDefaultTemplateRef);
          }
        }
      }

      if (this.error !== this._lastError && this.errorContainerRef) {
        this._lastError = this.error;
        this.errorContainerRef.clear();
        if (this.error) {
          if (this.errorViewRef) {
            this.errorContainerRef.insert(this.errorViewRef);
          } else if (this.errorTemplateRef) {
            this.errorViewRef = this.errorContainerRef.createEmbeddedView(this.errorTemplateRef);
          } else if (this.errorDefaultTemplateRef) {
            this.errorViewRef = this.errorContainerRef.createEmbeddedView(this.errorDefaultTemplateRef);
          }
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.isViewInitialized = true;
    this._updateInternal();
  }

  ngAfterContentChecked() {
    this.isContentInitialized = true;
    this._updateInternal();
  }

  ngOnChanges() {
    this._updateInternal();
  }

  /**
   * Force redraw.
   */
  redraw() {
    if (this.listComponent) {
      this.listComponent.redraw();
    }
  }

  resize() {
    if (this.listComponent) {
      this.listComponent.resize();
    }
  }
}
