import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OneDriveFilePickerLoaderService {
  private _scriptNotifier = new BehaviorSubject<boolean>(false);

  private _scriptLoading = false;
  private _scriptComplete = false;

  constructor() {
    this._loadScript();
  }

  public async open(options: OneDrive.IOneDriveOptions): Promise<void> {
    // ensure that internal has loaded
    await this._loadScript();

    return OneDrive.open(options);
  }

  private async _loadScript(): Promise<void> {
    if (this._scriptComplete) return;

    if (!this._scriptLoading) {
      this._scriptLoading = true;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://js.live.net/v7.2/OneDrive.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this._scriptLoading = false;
        this._scriptComplete = true;
        this._scriptNotifier.complete();
      };
      script.onerror = (e) => {
        const evt = e as any as ErrorEvent;

        this._scriptLoading = false;
        this._scriptNotifier.error(evt.error);
      };
      (document.head || document.body || document.documentElement)
        .appendChild(script);
    }

    return new Promise<void>((resolve, reject) => {
      this._scriptNotifier
        .subscribe(
          undefined,
          (err) => reject(err),
          () => resolve()
        );
    });
  }
}