import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { IAccountServiceToken } from '../interfaces/iaccount.service.token';
import { IAccountService } from '../interfaces/iaccount.service';

@Injectable()
export class TemplatesResolver implements Resolve<any> {
  constructor(@Inject(IAccountServiceToken) private accountService: IAccountService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.accountService.getUserDetail()
  }
}
