import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '../../models/country';
import { ICountryService } from '../../interfaces/icountry.service';
import { CollectionService } from '../collection.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryCollectionService implements ICountryService {
  constructor(
    private collectionService: CollectionService,
    private httpClient: HttpClient
  ) {}

  /** @override */
  getCountries(): Observable<Country[]> {
    return this.httpClient.get<any[]>(environment.collectionUrl + "/countries")
      .pipe(map((countriesJson: any[]) => {
        let countries: Country[] = [];
        for (let i = 0; i < countriesJson.length; i++) {
          let name = countriesJson[i]["name"];
          let code = countriesJson[i]["code"];

          countries.push(new Country(name ,code));
        }

        return countries;
      }));
  }
}
