import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-glossary-edit-confirm-dialog',
  templateUrl: './glossary-edit-confirm-dialog.component.html',
  styleUrls: ['./glossary-edit-confirm-dialog.component.scss']
})
export class GlossaryEditConfirmDialogComponent {
  public update: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GlossaryEditConfirmDialogComponent>
  ) {}
  
  onUpdate() {
    this.update = true;
    this.dialogRef.close();
  }
}
