<ng-template #step1>
  <form [formGroup]="languageForm">
    <div i18n="@@TxtAnalyserUploadDialog_description1">
      Select the language that will be used to analyse the documents
    </div>
    <mat-form-field>
      <mat-label i18n="@@TxtAnalyserUploadDialog_language">Language</mat-label>
      <mat-select formControlName="language">
        <mat-option [value]="language.code" *ngFor="let language of languages">
          {{ language.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>

<ng-template #step2>
  <div class="view">
    <div class="shadow"></div>
    <mat-progress-bar color="primary" mode="indeterminate" *ngIf="categoriesLoading"></mat-progress-bar>
    <div class="content">
      <table *ngIf="!categoriesLoading">
        <ng-container *ngFor="let category of categories; let i = index">
          <tbody class="card">
            <tr>
              <td class="card-title">
                <mat-checkbox [(ngModel)]="category.enabled" [indeterminate]="category.indeterminate" [disabled]="isLoading">
                  {{ category.name }}
                </mat-checkbox>
              </td>
              <td class="card-actions">
                <button mat-icon-button color="primary" (click)="category.expanded = !category.expanded">
                  <mat-icon *ngIf="category.expanded" class="expand-icon">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="!category.expanded" class="expand-icon">keyboard_arrow_down</mat-icon>
                </button>
              </td>
            </tr>
            <ng-container *ngIf="category.expanded">
              <ng-container *ngFor="let subCategory of category.subCategories">
                <tr class="card-sub-category">
                  <td class="card-title">
                    <mat-checkbox [(ngModel)]="subCategory.enabled" [disabled]="isLoading">
                      {{ subCategory.name }}
                    </mat-checkbox>
                  </td>
                  <td class="card-actions"></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #step3>
  <div i18n="@@TxtAnalyserUploadDialog_description2" class="subheader">
    Select one or more documents
  </div>
  <div class="file-list" *ngIf="files.length > 0">
    <ng-container *ngFor="let file of files">
      <app-txtanalyser-upload-file [file]="file" [disabled]="isLoading" (remove)="onRemoveUploadFile($event)"></app-txtanalyser-upload-file>
    </ng-container>
  </div>
  <div *ngIf="files.length > 0" class="separator">
    <span i18n="@@TxtAnalyserUploadDialog_more_documents_subtitle">Add more documents:</span>
  </div>
  <div class="file-pickers">
    <app-google-drive-picker [disabled]="isLoading" (onFile)="onGoogleDriveFile($event)"></app-google-drive-picker>
    <app-onedrive-file-picker [disabled]="isLoading" (onFile)="onOneDriveFile($event)"></app-onedrive-file-picker>
    <app-local-file-picker [disabled]="isLoading" (onFile)="onLocalFile($event)"></app-local-file-picker>
  </div>
  <div class="error" *ngIf="uploadError" i18n="@@TxtAnalyserUploadDialog_uploadError">An error occurred while uploading files</div>
</ng-template>

<h1 mat-dialog-title i18n="@@TxtAnalyserUploadDialog_Header">Upload documents for analysis</h1>
<mat-dialog-content>
  <mat-stepper #horizontalStepper *ngIf="!isPhone" [linear]="true" (selectionChange)="onStepperChange(horizontalStepper, $event.selectedIndex)">
    <mat-step [stepControl]="languageStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_StepperTitleName">Select language</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button
          mat-button
          matDialogClose
          i18n="@@TxtAnalyserUploadDialog_btnCancel"
          [disabled]="disabled || isLoading">Cancel</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@TxtAnalyserUploadDialog_btnNext"
          [disabled]="disabled || isLoading || !this.languageForm.valid">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="errorTypeStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_StepperTitle">Select categories</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@TxtAnalyserUploadDialog_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@TxtAnalyserUploadDialog_btnNext"
          [disabled]="disabled || isLoading || categoriesLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="uploadStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_UploadTitle">Select documents</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@TxtAnalyserUploadDialog_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          (click)="uploadFiles()"
          i18n="@@TxtAnalyserUploadDialog_btnSave"
          [disabled]="disabled || isLoading || files.length === 0">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
  <mat-stepper orientation="vertical"  #verticalStepper *ngIf="isPhone" [linear]="true" (selectionChange)="onStepperChange(verticalStepper, $event.selectedIndex)">
    <mat-step [stepControl]="languageStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_StepperTitleName">Select language</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button
          mat-button
          matDialogClose
          i18n="@@TxtAnalyserUploadDialog_btnCancel"
          [disabled]="disabled || isLoading">Cancel</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@TxtAnalyserUploadDialog_btnNext"
          [disabled]="disabled || isLoading || !this.languageForm.valid">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="errorTypeStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_StepperTitle">Select categories</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@TxtAnalyserUploadDialog_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@TxtAnalyserUploadDialog_btnNext"
          [disabled]="disabled || isLoading || categoriesLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="uploadStepControl">
      <ng-template matStepLabel i18n="@@TxtAnalyserUploadDialog_UploadTitle">Select documents</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@TxtAnalyserUploadDialog_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          (click)="uploadFiles()"
          i18n="@@TxtAnalyserUploadDialog_btnSave"
          [disabled]="disabled || isLoading || files.length === 0">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
