export enum NavigationState {
  ACTIVE, INACTIVE, UPCOMING
}

export function getNavigationStateByKey(key?: string): NavigationState {
  switch (key) {
    case "INACTIVE": {
      return NavigationState.INACTIVE;
    }
    case "UPCOMING": {
      return NavigationState.UPCOMING;
    }
    case "ACTIVE":
    default: {
      return NavigationState.ACTIVE;
    }
  }
}

export class NavigationLink {
  constructor(
    public name: string|undefined,
    public description: string|undefined,
    public href: string|undefined,
    public state: NavigationState,
    public stateMessage: string|undefined,
    public iconId: string
  ) {}
}