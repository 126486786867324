import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { IGlossary } from '../../models/glossary';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';
import { GlossaryDeleteConfirmDialogComponent } from 'app/components/glossary-delete-confirm-dialog/glossary-delete-confirm-dialog.component';

@Component({
  selector: 'app-glossary-delete-dialog',
  templateUrl: './glossary-delete-dialog.component.html',
  styleUrls: ['./glossary-delete-dialog.component.scss']
})
export class GlossaryDeleteDialogComponent implements OnDestroy {
  public onDeleted?: Subject<IGlossary> = new Subject();
  public disabled?: boolean;
  public error?: boolean;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<GlossaryDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public glossary: IGlossary,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService
  ) {}

  ngOnDestroy() {
    if (this.onDeleted) {
      this.onDeleted.unsubscribe();
      this.onDeleted = undefined;
    }
  }
  
  delete() {
    if (this.disabled) return;
    this.disabled = true;
    this.error = false;

    const _delete = () => {
      this.glossaryService.deleteGlossary(this.glossary)
        .subscribe(() => {
          if (this.onDeleted) {
            this.onDeleted.next(this.glossary);
          }
        }, (err) => {
          this.disabled = false;
          
          this.error = true;
        }, () => {
          if (this.onDeleted) {
            this.onDeleted.complete();
          }
          this.dialogRef.close();
        });
    };

    if (this.glossary.subscribers > 0) {
      const ref = this.dialog.open(GlossaryDeleteConfirmDialogComponent, {
        width: '750px'
      });
      ref.beforeClosed().subscribe(undefined, undefined, () => {
        if (ref.componentInstance.deleted) {
          _delete();
        } else {
          this.disabled = false;
        }
      });
    } else {
      _delete();
    }
  }
}
