import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from './cookie.service';

import * as moment from 'moment';

@Injectable()
export class LocaleService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {
    // TODO: Locales should be set only for displaying date
    if (locale !== 'ar-EG') { moment.locale(locale); }
  }

  public getLocale(): string {
    return this.locale;
  }

  public setLocale(locale: string): void {
    let cookieEnabled = navigator.cookieEnabled;
    let queryParam = false;

    let currentLocale: string|undefined;
    if (this.route.snapshot.queryParamMap.has('locale')) {
      currentLocale = this.route.snapshot.queryParamMap.get('locale')!;
      queryParam = true;
    }
    if (!currentLocale && cookieEnabled) {
      currentLocale = this.cookieService.get('locale');
    }
    if (!currentLocale) {
      currentLocale = this.getLocale();
    }

    if (cookieEnabled) {
      this.cookieService.set('locale', locale, 60*60*24*365, "/");
      cookieEnabled = this.cookieService.containsKey('locale');
    }

    if (currentLocale !== locale) {
      if (cookieEnabled && !queryParam) {
        window.location.reload();
      } else {
        let link = window.location.href;
        const queryStart = link.indexOf("?");
        if (queryStart === -1) {
          link += "?locale=" + encodeURIComponent(locale);
        } else {
          const rawParams = window.location.href.substring(queryStart + 1).split("&");
          let noLocale = true;
          for (let i = 0; i < rawParams.length; i++) {
            let [name, value] = rawParams[i].split("=");
            if (name === "locale") {
              value = encodeURIComponent(locale);
              rawParams[i] = name + "=" + value;
              noLocale = false;
            }
          }
          if (noLocale) {
            rawParams.push("locale=" + encodeURIComponent(locale));
          }

          link = link.substring(0, queryStart) + "?" + rawParams.join("&");
        }

        window.location.href = link;
      }
    }
  }
}
