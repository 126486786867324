import {of as observableOf,  Observable ,  Subscription ,  Subject } from 'rxjs';
import { CollectionService } from './../../services/collection.service';
import { MediaQueryService } from './../../services/media-query.service';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { VirtualScrollComponent } from './../virtual-scroll/virtual-scroll.component';
import { GlossaryWordList } from './../../models/glossary-word-list';
import { Component, ChangeDetectorRef, Inject, OnDestroy, ViewChildren, QueryList, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { IGlossary, Word } from '../../models/glossary';
import { Language } from '../../models/language';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';


import { LingappsError } from '../../services/auth.service';
import { map, flatMap } from 'rxjs/operators';

export class StepControl extends AbstractControl {
  private _formGroup?: AbstractControl;

  private _invalid: boolean = false;

  constructor(formGroup?: AbstractControl, validator?: ValidatorFn, asyncValidator?: AsyncValidatorFn) {
    super(validator || null, asyncValidator || null);
    this._formGroup = formGroup;
  }

  setInvalid(invalid: boolean) {
    this._invalid = invalid;
  }

  // TODO: should not override status(readonly property in AbstractControl), here in the subclass
  // @ts-ignore
  get status(): string {
    if (this._invalid)
      return "INVALID";
    if (this._formGroup)
      return this._formGroup.status;
    return "VALID";
  }

  get invalid(): boolean {
    return this.status === "INVALID";
  }

  setValue(value: any, options?: Object): void {
    throw new Error("Method not implemented.");
  }

  patchValue(value: any, options?: Object): void {
    throw new Error("Method not implemented.");
  }

  reset(value?: any, options?: Object): void {
    throw new Error("Method not implemented.");
  }
}

@Component({
  selector: 'app-glossary-create-dialog',
  templateUrl: './glossary-create-dialog.component.html',
  styleUrls: ['./glossary-create-dialog.component.scss']
})
export class GlossaryCreateDialogComponent implements OnDestroy {
  @ViewChildren(VirtualScrollComponent) private virtualScrolls?: QueryList<VirtualScrollComponent>;
  private _horizontalStepper?: MatStepper;
  private _verticalStepper?: MatStepper;

  @ViewChild('horizontalStepper') set __content(content: MatStepper) {
    this._horizontalStepper = content;
    this.onStepperChange(content, this.selectedIndex);
  }
  @ViewChild('verticalStepper') set __content2(content: MatStepper) {
    this._verticalStepper = content;
    this.onStepperChange(content, this.selectedIndex);
  }

  start: boolean = true;
  end: boolean = true;

  public metadataForm: FormGroup = new FormGroup({
    'name': new FormControl('', Validators.required),
    'language': new FormControl('', Validators.required),
    'description': new FormControl(''),
    'public': new FormControl(false)
  });
  public metadataStepControl: StepControl = new StepControl(this.metadataForm);
  public textStepControl: StepControl = new StepControl();
  public saveStepControl: StepControl = new StepControl();

  public text?: string;

  public wordList: GlossaryWordList = new GlossaryWordList();

  wordsCount: number = 0;
  public selectedIndex = 0;
  public userId?: number;
  public groupId?: number;

  public onCreated?: Subject<IGlossary> = new Subject();
  public createSubscription?: Subscription;

  public isLoading: boolean = false;
  public unexpectedParseError: boolean = false;
  public disabled: boolean = false;
  public errorMessage?: string;

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _textUnvisited: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<GlossaryCreateDialogComponent>,
    private _zone: NgZone,
    private _mediaQueryService: MediaQueryService,
    private changeDetectionRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public languages: Language[],
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService,
    private _collectionService: CollectionService
  ) {
    this._updateControls();
  }

  get name(): string {
    return this.metadataForm.get('name')!.value;
  }

  get language(): string {
    return this.metadataForm.get('language')!.value;
  }

  get description(): string {
    return this.metadataForm.get('description')!.value;
  }

  isPublic(): boolean {
    return this.metadataForm.get('public')!.value;
  }

  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  private _updateControls() {
    this.metadataStepControl.setInvalid(this.disabled || this.isLoading);
    this.textStepControl.setInvalid(this.disabled || this.isLoading || this._textUnvisited);
    this.saveStepControl.setInvalid(this.disabled || this.isLoading);
  }

  private _setLoading(loading: boolean): void {
    this.isLoading = loading;

    this._updateControls();
  }

  private _setDisabled(disabled: boolean): void {
    this.disabled = disabled;

    this._updateControls();
  }

  ngOnInit() {
    this.text = undefined;
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');
    this._updateControls();
  }

  ngOnDestroy() {
    if (this.onCreated) {
      this.onCreated.unsubscribe();
      this.onCreated = undefined;
    }

    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
      this.createSubscription = undefined;
    }
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
  }

  removeWord(word: Word): void {
    this.wordList.removeWord(word);
  }

  removeOneStarWords(): void {
    if (this.disabled) return;
    this.wordList.removeCaseInsensitiveDuplicates();
  };

  removeTwoStarWords(): void {
    if (this.disabled) return;
    this.wordList.removeCaseInsensitiveDupliactesInDefault();
  }

  removeThreeStarWords(): void {
    if (this.disabled) return;
    this.wordList.removeExactMatches();
  }

  keepExactMatches(): void{
    this.wordList.keepBufferedExactMatchWords();
  }

  addWords(text?: string): Observable<Word[]> {
    text = text || this.text;
    this.text = "";
    this._setLoading(true);
    this.unexpectedParseError = false;
    return this.glossaryService
      .parseTextToWordsForTempGlossary(text || this.text, this.language)
      .pipe(map((words: Word[]) => {
        this.wordList.addWords(words);

        this._setLoading(false);

        this.changeDetectionRef.detectChanges();

        return this.wordList.words;
      }));
  }

  onStepperChange(stepper: MatStepper, selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
    if (this.selectedIndex === 1) {
      this._textUnvisited = false;
      this._updateControls();
    }

    this._zone.run(() => {
      if (stepper === this._horizontalStepper) {
        if (this._verticalStepper) {
          this._verticalStepper.selectedIndex = this.selectedIndex;
        }
      }
      if (stepper === this._verticalStepper) {
        if (this._horizontalStepper) {
          this._horizontalStepper.selectedIndex = this.selectedIndex;
        }
      }

      this.changeDetectionRef.detectChanges();
    });

    if (stepper) {
      const length = stepper._steps.length;
      if (selectedIndex === length - 1 && this.text) {
        this.addWords().subscribe(()  =>{
          this._setLoading(false);
        }, (err) => {
          console.error(err);
          this._setLoading(false);
          this.unexpectedParseError = true;
        }, () =>{
          this._setLoading(false);
        });
      }
    }

    setTimeout(() => {
      this.resize();
    }, 7);
  }

  resize() {
    if (!this.virtualScrolls) return;
    let list = this.virtualScrolls.toArray();
    for (let i = 0; i < list.length; i++) {
      list[i].resize();
    }
  }

  submit() {
    if (this.disabled) return;
    this._setDisabled(true);
    this.errorMessage = undefined;

    this.createSubscription = this.glossaryService.createGlossary(this.name, this.description, this.language, this.isPublic(), this.groupId, this.userId)
    .pipe(flatMap((glossary: IGlossary) => {
      if (this.wordList.words.length == 0){
        return observableOf(glossary);
      }

      return this.glossaryService.addWordsToGlossary(glossary, this.wordList.words);
    }))
    .subscribe((glossary: IGlossary) => {
      if (this.onCreated) {
        this.onCreated.next(glossary);
      }
    }, (err: LingappsError) => {
      this.errorMessage = err.message;
      this._setDisabled(false);
    }, () => {
      this.errorMessage = undefined;
      if (this.onCreated) {
        this.onCreated.complete();
      }
      this.dialogRef.close();
    });
  }
}
