import { Component, Input } from '@angular/core';
import { NavigationLink, NavigationState } from '../../models/navigation';

@Component({
  selector: 'app-navigation-card',
  templateUrl: './navigation-card.component.html',
  styleUrls: ['./navigation-card.component.scss']
})
export class NavigationCardComponent {
  @Input()
  public link?: NavigationLink;

  public get name(): string|undefined {
    if (!this.link) return undefined;

    return this.link.name;
  }

  public get description(): string|undefined {
    if (!this.link) return undefined;

    return this.link.description;
  }

  public get href(): string|undefined {
    if (!this.link) return undefined;

    return this.link.href;
  }

  public get active(): boolean {
    if (!this.link) return false;

    return this.link.state === NavigationState.ACTIVE;
  }

  public get stateMessage(): string | undefined {
    if (!this.link) return undefined;

    return this.link.stateMessage;
  }

  public get upcoming(): boolean {
    if (!this.link) return false;

    return this.link.state === NavigationState.UPCOMING;
  }

  public get icon(): string {
    if (!this.link) return "navigation-card:unknown";

    return "navigation-card:" + this.link.iconId;
  }
}
