<app-header></app-header>

<h1 i18n="@@Groups_Header">Your groups</h1>
<div *ngIf="ownedGroups.length === 0" i18n="@@Groups_InfoText">
  You can create groups and share glossary lists, change settings or view
  statistics for the group.
</div>
<table class="table-card" *ngIf="ownedGroups.length > 0">
  <thead *ngIf="!isPhone">
    <tr>
      <th class="column__fill" i18n="@@Groups_Name">Group name</th>
      <th class="column__small" i18n="@@Groups_Members">Members</th>
      <th class="column__small" i18n="@@Groups_Type">Group type</th>
      <th class="column__actions"></th>
    </tr>
  </thead>
  <ng-container *ngFor="let group of ownedGroups; let i = index">
    <tbody class="table-card__group">
      <tr>
        <td class="cell-overflow">
          <a
            [routerLink]="'/groups/' + group.id!"
            class="cell-overflow-text">{{ group.name }}</a>
        </td>
        <td *ngIf="!isPhone" class="cell-overflow">
          <span class="cell-overflow-text">{{ group.members }}</span>
        </td>
        <td *ngIf="!isPhone" class="cell-overflow" [ngSwitch]="group.type">
          <span class="cell-overflow-text" *ngSwitchCase="GroupType.USER_CREATED" i18n="@@Groups_UserCreated">User created</span>
        </td>
        <td class="table-card__actions" *ngIf="isPhone">
          <button mat-icon-button color="primary" (click)="editGroup(group)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="deleteGroup(group)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
        <td class="table-card__actions" *ngIf="!isPhone">
          <button mat-button color="primary" (click)="editGroup(group)">
            <mat-icon>edit</mat-icon>
            <span i18n="@@Groups_btnRename">Rename</span>
          </button>
          <button mat-button color="primary" (click)="deleteGroup(group)">
            <mat-icon>delete</mat-icon>
            <span i18n="@@Groups_btnDelete">Delete</span>
          </button>
        </td>
      </tr>
      <tr *ngIf="isPhone">
        <td colspan="2" class="cell-overflow">
          <div class="flex-between">
            <ng-container [ngSwitch]="group.type">
              <span
                *ngSwitchCase="GroupType.USER_CREATED"
                class="cell-overflow-text"
                i18n="@@Groups_UserCreated">User created</span>
            </ng-container>
            <span i18n="@@Groups_MemberCount" class="nowrap">{{ group.members }} { group.members, plural, =1 {member} other {members}}</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr class="table-card__spacing" *ngIf="i < ownedGroups.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>

<button mat-button color="primary" (click)="createGroup()">
  <mat-icon>create</mat-icon>
  <span i18n="@@Groups_btnCreateGroup">Create group</span>
</button>

<h1 i18n="@@Groups_MemberOfHeader">Groups you're a member of</h1>
<table class="table-card">
  <thead *ngIf="!isPhone">
    <tr>
      <th class="column__fill" i18n="@@Groups_Name">Group name</th>
      <th class="column__small" i18n="@@Groups_Members">Members</th>
      <th class="column__small" i18n="@@Groups_Type">Group type</th>
      <th class="column__actions"></th>
    </tr>
  </thead>
  <ng-container *ngFor="let group of groups; let i = index">
    <tbody class="table-card__group">
      <tr>
        <td class="cell-overflow">
          <a class="cell-overflow-text" [routerLink]="'/groups/' + group.id!">{{ group.name }}</a>
        </td>
        <td class="cell-overflow" *ngIf="!isPhone">
          <span class="cell-overflow-text">{{ group.members }}</span>
        </td>
        <td class="cell-overflow" [ngSwitch]="group.type" *ngIf="!isPhone">
          <span
            class="cell-overflow-text"
            *ngSwitchCase="GroupType.USER_CREATED"
            i18n="@@Groups_UserCreated">User created</span>
        </td>
        <td class="table-card__actions" *ngIf="isPhone">
          <button mat-icon-button color="primary" (click)="quitGroup(group)">
            <mat-icon>close</mat-icon>
          </button>
        </td>
        <td class="table-card__actions" *ngIf="!isPhone">
          <button mat-button color="primary" (click)="quitGroup(group)">
            <mat-icon>close</mat-icon>
            <span i18n="@@Groups_Leave">Leave</span>
          </button>
        </td>
      </tr>
      <tr *ngIf="isPhone">
        <td colspan="2" class="cell-overflow">
          <div class="flex-between">
            <ng-container [ngSwitch]="group.type">
              <span
                *ngSwitchCase="GroupType.USER_CREATED"
                class="cell-overflow-text"
                i18n="@@Groups_UserCreated">User created</span>
            </ng-container>
            <span i18n="@@Groups_MemberCount" class="nowrap">{{ group.members }} { group.members, plural, =1 {member} other {members}}</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr class="table-card__spacing" *ngIf="i < groups.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>
<button mat-button color="primary" (click)="joinGroup()">
  <mat-icon>link</mat-icon>
  <span i18n="@@Groups_btnJoin">Join group</span>
</button>