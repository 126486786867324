import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable, Subject, forkJoin } from 'rxjs';
import { Group } from '../../models/groups';

import { IGlossary, EnabledState } from '../../models/glossary';
import { Language } from '../../models/language';

import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';
import { GlossaryAddToGroupPublicDialogComponent } from '../glossary-add-to-group-public-dialog/glossary-add-to-group-public-dialog.component';

import { map } from 'rxjs/operators';

interface IAddData {
  selected: boolean
}

export interface IAddGlossariesToGroupData {
  group: Group;
  languages: Language[];
}

@Component({
  selector: 'app-glossary-add-to-group-dialog',
  templateUrl: './glossary-add-to-group-dialog.component.html',
  styleUrls: ['./glossary-add-to-group-dialog.component.scss']
})
export class GlossaryAddToGroupDialogComponent implements OnInit {
  public onCreated: Subject<IGlossary[]> = new Subject();

  public glossariesLoading: boolean = true;

  public addSubmitting: boolean = false;
  
  private glossariesSubscription?: Subscription;
  private addSubscription?: Subscription;
  
  public glossaries: IGlossary[] = [];
  public glossariesData: IAddData[] = [];

  public unexpectedUsersError: boolean = false;
  public unexpectedGroupsError: boolean = false;

  public group: Group;
  public languages: Language[];

  constructor(
    private dialog: MatDialogRef<GlossaryAddToGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IAddGlossariesToGroupData,
    @Inject(IGlossaryServiceToken) public glossaryService: IGlossaryService,
    private _dialog: MatDialog,
  ) {
    this.group = data.group;
    this.languages = data.languages;
  }

  ngOnInit() {
    const groupId = this.group.id;

    this.glossariesSubscription = this.glossaryService.getGlossaries(groupId, undefined, true)
      .subscribe((glossaries) => {
       this.glossaries = [];
        this.glossariesData = [];
        for (let i = 0; i < glossaries.length; i++) {
          if (glossaries[i].ownedByMe) {
            this.glossaries.push(glossaries[i]);
            this.glossariesData.push({
              selected: false
            });
          }
        }
        this.glossariesLoading = false;
      }, (err) => {
        console.error(err);
        this.glossariesLoading = false;
        this.unexpectedGroupsError = true;
      });
  }

  ngDestroy(){
    if (this.glossariesSubscription) {
      this.glossariesSubscription.unsubscribe();
      this.glossariesSubscription = undefined;
    }
    if (this.addSubscription) {
      this.addSubscription.unsubscribe();
      this.addSubscription = undefined;
    }
  }

  get searchLink(): string {
    return "/groups/" + this.group.id + "/aw/glossaries/search";
  }

  get disabled(): boolean {
    if (this.addSubmitting) return true;

    if (this.glossariesData) {
      for (let i = 0; i < this.glossariesData.length; i++) {
        if (this.glossariesData[i].selected) return false;
      }
    }
    
    return true;
  }

  close(): void {
    this.dialog.close();
  }

  add() {
    if (this.disabled) return;
    this.addSubmitting = true;

    const tasks: Observable<IGlossary>[] = [];

    const selectedGlossaries: IGlossary[] = [];

    let verifyPublic = false;

    for (let i = 0; i < this.glossariesData.length; i++) {
      if (this.glossariesData[i].selected) {
        selectedGlossaries.push(this.glossaries[i]);
        /*if (!this.glossaries[i].isPublic) {
          verifyPublic = true;
        }*/
      }
    }

    const addGlossaries = () => {
      for (let i = 0; i < selectedGlossaries.length; i++) {
        tasks.push(
          this.glossaryService.shareGlossaryWithGroup(selectedGlossaries[i], this.group)
            .pipe(map(() => selectedGlossaries[i]))
        );
      }
  
      this.addSubscription = forkJoin(tasks)
      .subscribe((glossaries: IGlossary[]) => {
        for (let i = 0; i < glossaries.length; i++) {
          glossaries[i].subscribed = true;
          glossaries[i].enabled = EnabledState.Enabled;
        }
        this.onCreated.next(glossaries);
      }, (err) => {
        console.error(err);
        this.addSubmitting = false;
      }, () => {
        this.onCreated.complete();
        this.dialog.close();
      });
    };

    if (verifyPublic) {
      // Open dialog
      const ref = this._dialog.open(GlossaryAddToGroupPublicDialogComponent, {
        width: '750px'
      });
      const sub = ref.beforeClosed().subscribe(() => {
        sub.unsubscribe();
        if (ref.componentInstance.isAccepted()) {
          addGlossaries();
        } else {
          this.addSubmitting = false;
        }
      });
    } else {
      addGlossaries();
    }
  }
  
  getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }
}
