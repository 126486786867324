import { IDataCell, IDataLink, IDataColumn, IDataRow } from './../../models/stats';
import { Component, Input, ViewChild } from '@angular/core';
import { DataTable } from '../../models/stats';
import { MatPaginator } from "@angular/material/paginator";
import { IPieSlice } from '../stats-pie-chart/stats-pie-chart.component';
import { COLORS } from 'app/utils/color';

@Component({
  selector: 'app-stats-table',
  templateUrl: './stats-table.component.html',
  styleUrls: ['./stats-table.component.scss']
})
export class StatsTableComponent {
  @Input('data')
  public get data(): DataTable | undefined {
    return this._data;
  }
  public set data(data: DataTable | undefined) {
    this._data = data;

    if (this.includePieChart) {
      this.chartPieSlices = this._getPieSlices();
    } else {
      this.chartPieSlices = [];
    }
  }
  private _data?: DataTable;

  @Input('includePieChart')
  public get includePieChart() {
    return this._includePieChart;
  }
  public set includePieChart(includePieChart: boolean) {
    const different = this._includePieChart !== includePieChart;
    this._includePieChart = includePieChart;

    if (different) {
      if (includePieChart) {
        this.chartPieSlices = this._getPieSlices();
      } else {
        this.chartPieSlices = [];
      }
    }
  }
  private _includePieChart = false;
  
  @ViewChild(MatPaginator)
  private _paginator?: MatPaginator;

  constructor() { }

  public chartPieSlices: IPieSlice[] = [];

  get rows(): IDataRow[] {
    if (!this.data || !this.data.rows) return [];
    let rows = this.data.rows.slice();
    if (this._paginator && this.length > 25) {
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      rows = rows.slice(startIndex, startIndex + this._paginator.pageSize);
    }

    return rows;
  }
  
  get cols(): IDataColumn[] {
    if (!this.data || !this.data.cols) return [];
    return this.data.cols;
  }
  
  get links(): IDataLink[] {
    if (!this.data || !this.data.links) return [];
    return this.data.links;
  }

  get length(): number {
    if (!this.data || !this.data.rows) return 0;
    return this.data.rows.length;
  }

  private _getColorByIndex(index: number): string {
    if (COLORS.length <= index || index < 0) {
      return "#cccccc";
    }

    return COLORS[index];
  }

  private _getPieSlices(): IPieSlice[] {
    if (!this.data) return [];
    
    return this.data.rows
      .map(x => x.cells[2])
      .map((x, i) => ({
        value: parseFloat(x.value),
        color: this._getColorByIndex(i)
      }));
  }
}