import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-glossary-add-to-group-public-dialog',
  templateUrl: './glossary-add-to-group-public-dialog.component.html',
  styleUrls: ['./glossary-add-to-group-public-dialog.component.scss']
})
export class GlossaryAddToGroupPublicDialogComponent implements OnInit {
  private _accept = false;

  constructor(
    private dialogRef: MatDialogRef<GlossaryAddToGroupPublicDialogComponent>,
  ) { }

  ngOnInit() {
  }

  isAccepted(): boolean {
    return this._accept;
  }

  accept(): void {
    this._accept = true;
    this.dialogRef.close();
  }
}
