import { Component, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject ,  Subscription } from 'rxjs';

import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

import { Group } from '../../models/groups';

interface IError {
  [key: string]: boolean|undefined;
  empty?: boolean;
}

function errorValidator(error: IError): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}
@Component({
  selector: 'app-groups-create-group-dialog',
  templateUrl: './groups-create-group-dialog.component.html',
  styleUrls: ['./groups-create-group-dialog.component.scss']
})
export class GroupsCreateGroupDialogComponent implements OnDestroy {
  public onCreated?: Subject<Group> = new Subject();
  private subscription?: Subscription;

  public error: IError = {};
  public unexpectedError: boolean = false;
  
  public form: FormGroup = new FormGroup({
    'name': new FormControl('', errorValidator(this.error))
  });

  constructor(
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService,
    public dialogRef: MatDialogRef<GroupsCreateGroupDialogComponent>
  ) {}

  resetErrors() {
    this.unexpectedError = false;
    for (let key in this.error) {
      this.error[key] = false;
    }
  }

  createGroup() {
    if (this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    let name: string = this.form.get('name')!.value;

    if (!name) {
      this.error.empty = true;
      this.form.updateValueAndValidity();
      this.form.enable();
      return;
    }

    this.subscription = this.groupsService.createGroup(name)
      .subscribe((group: Group) => {
        if (this.onCreated) {
          this.onCreated.next(group);
        }
      }, (err) => {
        console.error(err);
        this.unexpectedError = true;
        this.form.enable();
      }, () => {
        if (this.onCreated) {
          this.onCreated.complete();
        }
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.onCreated) {
      this.onCreated.unsubscribe();
      this.onCreated = undefined;
    }
  }
}
