<div class="no-data" *ngIf="length === 0" i18n="@@StatsTable_noDataAvailable">No data is available for the given period.</div>
<div *ngIf="length > 0" class="container">
  <table>
    <tbody>
      <tr *ngFor="let row of rows; let even = even; let i = index">
        <td *ngFor="let cell of row.cells" [class.even]="even">
          <a [routerLink]="cell.href" [queryParams]="cell.hrefQuery" *ngIf="cell.href">{{ cell.value }}</a>
          <span *ngIf="!cell.href">{{ cell.value }}</span>
        </td>
        <td *ngIf="includePieChart">
          <div class="color-indicator" [style.background]="chartPieSlices[i].color"></div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th *ngFor="let column of cols">
          <a [routerLink]="column.href" *ngIf="column.href">{{ column.label }}</a>
          <span *ngIf="!column.href">{{ column.label }}</span>
        </th>
        <th *ngIf="includePieChart"></th>
      </tr>
    </tfoot>
  </table>
  <div *ngIf="includePieChart" class="pie-chart">
    <app-stats-pie-chart [slices]="chartPieSlices"></app-stats-pie-chart>
  </div>
</div>
<a *ngFor="let link of links" [routerLink]="link.href" queryParamsHandling="merge">{{ link.text }}</a>
<ng-container *ngIf="length > 25">
  <mat-paginator
    [length]="length"
    [pageSize]="25"
    [pageSizeOptions]="[5,10,25,100]">
  </mat-paginator>
</ng-container>