import { IGlossaryService } from '../interfaces/iglossary.service';
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject ,  Subscription } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { IGlossary } from "../models/glossary";

const targetAudiencesWithSubject = ['primary-school-0-3', 'primary-school-4-6', 'primary-school-7-10'];

export class GlossarySearchDataSource extends DataSource<IGlossary> {
  private _service: IGlossaryService;

  private readonly _renderData = new BehaviorSubject<IGlossary[]>([]);

  private _sortChangeSubscription?: Subscription;
  private _renderChangesSubscription?: Subscription;

  private _pageSize: number = 25;
  private _pageIndex: number = 0;
  private _filter: string = "";

  private _targetAudience = '';
  private _subject = '';
  private _publisher = '';
  private _language = '';

  private _includeUserGlossaries: boolean = true;
  private _includePublisherGlossaries: boolean = true;
  private _sort: MatSort|undefined;

  private _totalItems: number = 0;

  private _groupId?: number;
  private _userId?: number;

  get totalItems(): number { return this._totalItems; }

  get pageSize(): number { return this._pageSize; }
  set pageSize(size: number) {
    if (this._pageSize === size) return;
    this._pageSize = size;

    this._updateRenderDataSubscription();
  }

  get pageIndex(): number { return this._pageIndex; }
  set pageIndex(index: number) {
    if (this._pageIndex === index) return;
    this._pageIndex = index;

    this._updateRenderDataSubscription();
  }

  get filter(): string { return this._filter; }
  set filter(filter: string) {
    if (this._filter === filter) return;
    this._filter = filter;

    this.resetPageIndex();
    this._updateRenderDataSubscription();
  }

  get targetAudience(): string { return this._targetAudience; }
  set targetAudience(targetAudience: string) {
    if (this._targetAudience === targetAudience) { return; }
    this._targetAudience = targetAudience;
    if (!this.showSubject()) { this._subject = '' }

    this.resetPageIndex();
    this._updateRenderDataSubscription();
  }

  get subject(): string { return this._subject; }
  set subject(subject: string) {
    if (this._subject === subject) { return; }
    this._subject = subject;

    this.resetPageIndex();
    this._updateRenderDataSubscription();
  }

  get language(): string { return this._language; }
  set language(language: string) {
    if (this._language === language) { return; }
    this._language = language;

    this.resetPageIndex();
    this._updateRenderDataSubscription();
  }

  get publisher(): string { return this._publisher; }
  set publisher(publisher: string) {
    if (this._publisher === publisher) { return; }
    this._publisher = publisher;

    this.resetPageIndex();
    this._updateRenderDataSubscription();
  }

  get includeUserGlossaries(): boolean { return this._includeUserGlossaries; }
  set includeUserGlossaries(include: boolean) {
    if (this._includeUserGlossaries === include) return;
    this._includeUserGlossaries = include;

    this._updateRenderDataSubscription();
  }

  get includePublisherGlossaries(): boolean { return this._includePublisherGlossaries; }
  set includePublisherGlossaries(include: boolean) {
    if (this._includePublisherGlossaries === include) return;
    this._includePublisherGlossaries = include;

    this._updateRenderDataSubscription();
  }

  get sort(): MatSort | undefined { return this._sort; }
  set sort(sort: MatSort|undefined) {
    this._sort = sort;

    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._sort) {
      this._sortChangeSubscription = this._sort.sortChange.subscribe(() => this._updateRenderDataSubscription());
    }

    this._updateRenderDataSubscription();
  }

  constructor(service: IGlossaryService, groupId?: number, userId?: number) {
    super();
    this._service = service;
    this._groupId = groupId;
    this._userId = userId;

    this._updateRenderDataSubscription();
  }

  private resetPageIndex() {
    this._pageIndex = 0
  }

  showSubject(): boolean {
    return targetAudiencesWithSubject.includes( this._targetAudience )
  }

  private _updateRenderDataSubscription(): void {
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }

    let sortDirection: ""|"asc"|"desc"|undefined = undefined;
    let sortActive: string|undefined = undefined;

    if (this._sort) {
      sortDirection = this._sort.direction;
      sortActive = this._sort.active;
    }

    this._renderChangesSubscription = this._service.searchGlossaries({
      query: this._filter,
      language: this._language,
      targetAudience: this._targetAudience,
      subject: this._subject,
      publisher: this._publisher,
      includeOtherUserGlossaries: this._includeUserGlossaries,
      includePublisherGlossaries: this._includePublisherGlossaries,
      sort: {
        direction: sortDirection,
        active: sortActive
      },
      pageSize: this._pageSize,
      pageIndex: this._pageIndex
    }, this._groupId, this._userId)
    .subscribe(data => {
      this._totalItems = data.totalGlossaries;
      this._renderData.next(data.glossaries);
    });
  }

  connect() {
    return this._renderData;
  }

  disconnect() {
    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }
  }
}
