import { AccountSnackbarComponent } from '../account-snackbar/account-snackbar.component';
import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountPasswordChangeDialogComponent } from '../account-password-change-dialog/account-password-change-dialog.component';
import { AccountTransferProfileSettingsDialogComponent } from '../account-transfer-profile-settings-dialog/account-transfer-profile-settings-dialog.component';

import { User, AuthService } from '../../services/auth.service';
import { UserDetail, Application } from '../../models/account';
import { Country } from '../../models/country';
import { Language } from '../../models/language';
import { IAccountService } from '../../interfaces/iaccount.service';
import { IAccountServiceToken } from '../../interfaces/iaccount.service.token';
import { LocaleService } from '../../services/locale.service';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';

interface IRouteData {
  userDetail: UserDetail;
  languages: Language[];
  countries: Country[];
  applications: Application[];
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
  public userDetail?: UserDetail;
  public countries: Country[] = [];
  public languages: Language[] = [];
  public applications?: Application[] = [];

  public languageForm = new FormGroup({
    language: new FormControl(''),
  });

  private routeSubscription?: Subscription;
  private subscription?: Subscription;
  private updateTimer?: number;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private localeService: LocaleService,
    @Inject(IAccountServiceToken) private accountService: IAccountService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.userDetail = data.userDetail;

      this.languageForm.setValue({
        language: this.userDetail.language,
      });

      this.countries = data.countries;
      this.languages = data.languages;
      this.applications = data.applications;
    });
  }

  public get user(): User {
    if (!this.authService.user) {
      return User.createEmpty();
    }

    return this.authService.user;
  }

  public getUserAuthTokenParameters(): string {
    return JSON.stringify({
      'application': 'account',
      'machineId': this.getUserMachineId(),
      'sessionId': this.getUserSessionId()
    });
  }

  public getUserSessionId(): string {
    const token = this.authService.getToken();
    if (!token) {
      return '';
    }
    return token.getSessionId();
  }

  public getUserMachineId(): string {
    const token = this.authService.getToken();
    if (!token) {
      return '';
    }
    return token.getMachineId();
  }

  public getStukMergeURL(): string {
    return environment.services.claimStukLicense;
  }

  public openPasswordChangeDialog(): void {
    const dialogRef = this.dialog.open(AccountPasswordChangeDialogComponent, {
      width: '750px'
    });
  }

  public openTransferProfileSettingsDialog(): void {
    const dialogRef = this.dialog.open(AccountTransferProfileSettingsDialogComponent, {
      width: '750px'
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
    if (this.updateTimer) {
      window.clearTimeout(this.updateTimer);
      this.updateTimer = undefined;
    }
  }

  onUpdateDelay() {
    if (this.updateTimer) {
      window.clearTimeout(this.updateTimer);
      this.updateTimer = undefined;
    }
    this.updateTimer = window.setTimeout(() => this.onUpdate(), 1000);
  }

  onUpdate() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.updateTimer) {
      window.clearTimeout(this.updateTimer);
      this.updateTimer = undefined;
    }
    if (!this.userDetail) { return; }

    const changes = this.userDetail.language !== this.languageForm.get('language')!.value;

    if (!changes) { return; }

    this.userDetail.language = this.languageForm.get('language')!.value;

    this.subscription = this.accountService.updateUserDetail(this.userDetail)
    .subscribe(
      undefined,
      (err) => { console.error(err); },
      () => {
        this.snackbar.openFromComponent(AccountSnackbarComponent, {
          duration: 4000
        });
        // if (languageChanged) {
        //   this.localeService.setLocale(language);
        // }
      }
    );
  }
}
