<ng-template #card let-controller="controller">
  <tbody class="card">
    <tr>
      <td class="card__toggle">
        <mat-checkbox
          *ngIf="!controller.enabling"
          [checked]="controller.isEnabled()"
          [indeterminate]="controller.isEnabledIndeterminate()"
          (change)="controller.setEnabled($event.checked)"></mat-checkbox>
        <mat-spinner *ngIf="controller.enabling" diameter="20"></mat-spinner>
      </td>
      <td class="card__title">
        <a (click)="onlyEdit ? controller.edit() : controller.view()">
          {{ controller.glossary.name }}
        </a>
      </td>
      <td class="card__description" *ngIf="!isPhone">
        {{ controller.glossary.description }}
      </td>
      <td *ngIf="!isPhone">
        {{ getLanguageByCode(controller.glossary.language) }}
      </td>
      <td *ngIf="showPublicColumn && !isPhone">
        <span *ngIf="controller.glossary.isPublic" i18n="@@GlossaryList_PublicYes">Yes ({{ controller.glossary.subscribers }} { controller.glossary.subscribers, plural, =1 {subscriber} other {subscribers} })</span>
        <span *ngIf="!controller.glossary.isPublic" i18n="@@GlossaryList_PublicNo">No</span>
      </td>
      <td *ngIf="showOwnerColumn && !isPhone">
        <span *ngIf="controller.glossary.ownerDisplayName === undefined" class="unknown-owner" i18n="@@GlossaryList_UnknownOwner">- Unknown -</span>
        <ng-container *ngIf="controller.glossary.ownerDisplayName !== undefined">{{ controller.glossary.ownerDisplayName }}</ng-container>
      </td>
      <td *ngIf="showSubscribersColumn && !isPhone" i18n="@@GlossaryList_PublicYesShort">{{ controller.glossary.subscribers }} { controller.glossary.subscribers, plural, =1 {subscriber} other {subscribers} }</td>
      <td *ngIf="showGroupColumn && !isPhone">{{ controller.glossary.sharedByGroupDisplayName }}</td>
      <td *ngIf="showEdit || showDelete || showUnsubscribe || showUnsubscribeFromGroup" class="card__actions">
        <ng-container *ngIf="isPhone">
          <button mat-icon-button color="primary" *ngIf="showEdit" (click)="controller.edit()">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="showDelete" (click)="controller.delete()">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="showUnsubscribe" (click)="controller.unsubscribe()">
            <mat-icon>clear</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="showUnsubscribeFromGroup" (click)="controller.unsubscribe(true)">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!isPhone">
          <button mat-button color="primary" *ngIf="showEdit" (click)="controller.edit()">
            <mat-icon>mode_edit</mat-icon>
            <span i18n="@@GlossaryList_Edit">Edit</span>
          </button>
          <button mat-button color="primary" *ngIf="showDelete" (click)="controller.delete()">
            <mat-icon>delete</mat-icon>
            <span i18n="@@GlossaryList_Delete">Delete</span>
          </button>
          <button mat-button color="primary" *ngIf="showUnsubscribe" (click)="controller.unsubscribe()">
            <mat-icon>clear</mat-icon>
            <span i18n="@@GlossaryList_Unsubscribe">Unsubscribe</span>
          </button>
          <button mat-button color="primary" *ngIf="showUnsubscribeFromGroup" (click)="controller.unsubscribe(true)">
            <mat-icon>clear</mat-icon>
            <span i18n="@@GlossaryList_UnsubscribeFromGroup">Unsubscribe from group</span>
          </button>
        </ng-container>
      </td>
    </tr>
  </tbody>
</ng-template>

<table>
  <thead class="header" *ngIf="!isPhone">
    <tr>
      <th></th>
      <th i18n="@@GlossaryList_GlossaryName" class="header__title">Name of the glossary</th>
      <th i18n="@@GlossaryList_GlossaryDescription">Description</th>
      <th i18n="@@GlossaryList_GlossaryLanguage">Language</th>
      <th *ngIf="showPublicColumn" i18n="@@GlossaryList_GlossaryPublic">Public</th>
      <th *ngIf="showOwnerColumn" i18n="@@GlossaryList_GlossaryOwner">Made by</th>
      <th *ngIf="showSubscribersColumn" i18n="@@GlossaryList_GlossarySubscribers">Subscribers</th>
      <th *ngIf="showGroupColumn" i18n="@@GlossaryList_GlossaryGroup">From group</th>
      <th *ngIf="showEdit || showDelete || showUnsubscribe || showUnsubscribeFromGroup"></th>
    </tr>
  </thead>
  <ng-container *ngFor="let controller of controllers; let i = index">
    <ng-container *ngTemplateOutlet="card;context: { controller: controller }"></ng-container>
    <tbody>
      <tr class="card__spacing" *ngIf="i < controllers.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>