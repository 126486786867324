<h1 mat-dialog-title i18n="@@AccountPasswordChange_Header">Change password</h1>
<form (ngSubmit)="onPasswordUpdate()">
  <mat-dialog-content>
    <mat-form-field class="full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@AccountPasswordChange_YourCurrentPassword">Your current password</mat-placeholder>
      <input name="currentPassword" matInput [(ngModel)]="currentPassword" [disabled]="!enabled" type="password" required>
      <mat-error *ngIf="error?.USER_CURRENT_PASSWORD_NOT_SET" i18n="@@AccountPasswordChange_PasswordIsEmpty">Current password is empty.</mat-error>
      <mat-error *ngIf="error?.USER_INCORRECT_CURRENT_PASSWORD" i18n="@@AccountPasswordChange_IncorrectPassword">Incorrect current password.</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@AccountPasswordChange_NewPassword">New password</mat-placeholder>
      <input name="password" matInput [(ngModel)]="password" [disabled]="!enabled" type="password" required>
      <mat-error *ngIf="error?.USER_NEW_PASSWORD_NOT_SET" i18n="@@AccountPasswordChange_NewPasswordIsEmpty">New password is empty.</mat-error>
      <mat-error *ngIf="error?.USER_INVALID_NEW_PASSWORD" i18n="@@AccountPasswordChange_InvalidNewPassword">Invalid new password.</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@AccountPasswordChange_RepeatNewPassword">Repeat new password</mat-placeholder>
      <input name="repeatPassword" matInput [(ngModel)]="repeatPassword" [disabled]="!enabled" type="password" required>
      <mat-error *ngIf="errorRepeatPassword" i18n="@@AccountPasswordChange_PasswordDoNotMatch">Password doesn't match.</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close type="button" i18n="@@AccountPasswordChange_btnClose">Close</button>
    <button mat-raised-button color="primary" [disabled]="!enabled" i18n="@@AccountPasswordChange_btnSave">Save</button>
  </mat-dialog-actions>
</form>