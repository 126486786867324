import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { LoginErrorService } from 'app/services/login-error.service';
import { AuthToken } from 'app/models/auth-token';

@Component({
  selector: 'app-login-session',
  templateUrl: './login-session.component.html',
  styleUrls: ['./login-session.component.scss']
})
export class LoginSessionComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private loginErrorService: LoginErrorService
  ) {}

  public ngOnInit() {
    const search = window.location.search;
    if (search.substring(0, 1) !== "?") {
      this.router.navigateByUrl("/login");
      return;
    }
    
    const parameters: {[key: string]: string} = {};
    
    const tokens = search.substring(1).split("&");
    for (let i = 0; i < tokens.length; i++) {
      const [ name, value ] = tokens[i].split("=");
      
      parameters[decodeURIComponent(name)] = decodeURIComponent(value);
    }

    if (
      !parameters.hasOwnProperty("sessionId")
      || !parameters.hasOwnProperty("machineId")
      || !parameters.hasOwnProperty("PHPSessionId")
      || !parameters["sessionId"]
      || !parameters["machineId"]
      || !parameters["PHPSessionId"]
    ) {
      this.router.navigateByUrl("/login");
      return;
    }
    
    this.authService.setToken(new AuthToken(parameters["sessionId"], parameters["machineId"], parameters["PHPSessionId"]));
    
    return this.authService.getUser()
      .subscribe(
        async () => {
          if (parameters.hasOwnProperty("redirectPath")) {
            try {
              await this.router.navigateByUrl(parameters["redirectPath"]);
            } catch (e) {
              this.router.navigateByUrl("/");
            }
          } else {
            this.authService.redirectBack();
          }
        },
        err => {
          console.error(err);
          this.loginErrorService.setUnknownError(true);

          this.router.navigateByUrl("/login");
        }
      )
    ;
  }
}
