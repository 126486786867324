import { Subject } from 'rxjs';
import { Word } from './glossary';

export class GlossaryWordList {
  public onWordChanges: Subject<Word[]> = new Subject();

  /**
   * The words used to diff some variables
   */
  private _diffWords: Word[] = [];

  /**
   * All the words
   */
  public words: Word[] = [];

  /**
   * The exact matched words that hasn't been added yet.
   * keepBufferedExactMatchWords() needs to be called before they're added to
   * the list.
   */
  private _exactWords: Word[] = [];

  /**
   * The amount of words in _exactWords.
   */
  private _bufferedExactWordsCount: number = 0;

  /**
   * The amount of words that are insensitive duplicates of already existing
   * words in the glossary list.
   */
  private _duplicatesInGlossaryCount: number = 0;

  /**
   * The amount of words that are insensitive duplicates of words in the default
   * AppWriter glossary list.
   */
  private _duplicatesInDefaultCount: number = 0;

  /**
   * The amount of words that are exact matches of already existing words in the
   * glossary list.
   */
  private _exactMatchesCount: number = 0;

  /**
   * Words that has been removed. They're diffed from _diffWords.
   */
  private _removedWords: Word[] = [];

  /**
   * The length of _removedWords.
   */
  private _removedWordsCount: number = 0;

  /**
   * Words that has been added. They're diffed from _diffWords.
   */
  private _addedWords: Word[] = [];
  
  /**
   * The length of _addedWords.
   */
  private _addedWordsCount: number = 0;

  get bufferedExactWordsCount(): number {
    return this._bufferedExactWordsCount;
  }

  get duplicatesInGlossaryCount(): number {
    return this._duplicatesInGlossaryCount;
  }

  get duplicatesInDefaultCount(): number {
    return this._duplicatesInDefaultCount;
  }
  
  get exactMatchesCount(): number {
    return this._exactMatchesCount;
  }
  
  get addedWordsCount(): number {
    return this._addedWordsCount;
  }
  
  get removedWordsCount(): number {
    return this._removedWordsCount;
  }
  
  get addedWords(): Word[] {
    return this._addedWords;
  }
  
  get removedWords(): Word[] {
    return this._removedWords;
  }

  protected updateInternal() {
    this.words.sort((a, b) => {
      let nameA = a.text.toLowerCase(),
          nameB = b.text.toLowerCase();
      if (nameA < nameB) // sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    });

    this._duplicatesInGlossaryCount = this.words.filter(
      w => w.isCaseInsensitiveMatchInGlossary()).length;
    this._duplicatesInDefaultCount = this.words.filter(
      w => w.isCaseInsensitiveMatchInDefault()).length;
    this._exactMatchesCount = this.words.filter(w => w.isExactMatch()).length;

    this._bufferedExactWordsCount = this._exactWords.length;

    this._addedWords = this.words.filter(w => this._diffWords.indexOf(w) === -1);
    this._removedWords = this._diffWords.filter(w => this.words.indexOf(w) === -1);

    this._addedWordsCount = this._addedWords.length;
    this._removedWordsCount = this._removedWords.length;

    this.onWordChanges.next(this.words);
  }

  /**
   * Set the words of the glossary.
   */
  setSavedWords(words: Word[]) {
    this._diffWords = words;
    for (let i = 0; i < words.length; i++) {
      this.words.push(words[i]);
    }

    this.updateInternal();
  }

  /**
   * Add words.
   * @param words the words being added to the list.
   * @param force whether to force add the words.
   */
  addWords(words: Word[], force: boolean = false): number {
    let count = 0;
    for (let i = 0; i < words.length; i++) {
      if (this.addWordInternal(words[i], force))
        count++;
    }
    this.updateInternal();
    return count;
  }

  /**
   * Add word to the list.
   * @param word the word to add to the list
   * @param force whether to force add it.
   */
  protected addWordInternal(word: Word, force: boolean = false): boolean {
    const index: number = this.words.indexOf(word);
    if (index !== -1) return false;

    for (let i = 0; i < this.words.length; i++) {
      if (word.text === this.words[i].text)
        return false;
    }

    for (let i = 0; i < this._exactWords.length; i++) {
      if (word.text === this._exactWords[i].text)
        return false;
    }

    for (let i = 0; i < this._removedWords.length; i++) {
      if (word.text === this._removedWords[i].text
          && word !== this.removedWords[i])
        return this.addWordInternal(this._removedWords[i], force);
    }

    if (force) {
      this.words.push(word);
      return true;
    } else {
      // Do a local check for whether pending words (words not yet saved to the
      // glossary) matches the word being added as pending.
      let exactMatch: boolean = word.exactMatch;
      let caseInsensitiveMatchInGlossary: boolean
        = word.caseInsensitiveMatchInGlossary;

      for (let i = 0; i < this.words.length; i++) {
        exactMatch = exactMatch || this.words[i].text === word.text;
        caseInsensitiveMatchInGlossary
          = caseInsensitiveMatchInGlossary
          || this.words[i].text.toLowerCase() === word.text.toLowerCase();
      }
      word.exactMatch = exactMatch;
      word.caseInsensitiveMatchInGlossary = caseInsensitiveMatchInGlossary;

      if (word.exactMatch) {
        this._exactWords.push(word);
        return false;
      } else {
        this.words.push(word);
        return true;
      }
    }
  }

  /**
   * Add word to the list.
   * @param word the word to add to the list
   * @param force whether to force add it.
   */
  addWord(word: Word, force: boolean = false): boolean {
    let count = this.addWordInternal(word, force);

    this.updateInternal();
    return count;
  }

  /**
   * Remove a word from the list.
   * @param word the word to remove
   */
  removeWord(word: Word): boolean {
    let index: number = this.words.indexOf(word);
    if (index === -1) return false;

    this.words.splice(index, 1);

    this.updateInternal();

    return true;
  }

  removeWords(words: Word[]): number {
    const count = this.words.length;
    for (let i = count - 1; i >= 0; i--) {
      if (words.indexOf(this.words[i]) !== -1) {
        this.words.splice(i, 1);
      }
    }

    this.updateInternal();

    return count - this.words.length;
  }

  /**
   * Removes all case insensitive duplicates of words already in the glossary
   * list.
   */
  removeCaseInsensitiveDuplicates(): number {
    const count = this.words.length;
    for (let i = count - 1; i >= 0; i--) {
      if (this.words[i].isCaseInsensitiveMatchInGlossary()) {
        this.words.splice(i, 1);
      }
    }

    this.updateInternal();

    return count - this.words.length;
  }
  
  /**
   * Removes all case insenitive dupliaces of words in the default AppWriter
   * glossary list.
   */
  removeCaseInsensitiveDupliactesInDefault(): number {
    const count = this.words.length;
    for (let i = count - 1; i >= 0; i--) {
      if (this.words[i].isCaseInsensitiveMatchInDefault()) {
        this.words.splice(i, 1);
      }
    }

    this.updateInternal();

    return count - this.words.length;
  }
  
  /**
   * Removes all exact matches.
   */
  removeExactMatches(): number {
    const count = this.words.length;
    for (let i = count - 1; i >= 0; i--) {
      if (this.words[i].isExactMatch()) {
        this.words.splice(i, 1);
      }
    }

    this.updateInternal();

    return count - this.words.length;
  }

  /**
   * Add the exact matches to the word list.
   */
  keepBufferedExactMatchWords(): number {
    const count = this._exactWords.length;
    
    for (let i = 0; i < count; i++) {
      this.words.push(this._exactWords[i]);
    }
    this._exactWords = [];

    this.updateInternal();

    return count;
  }

  /**
   * Clear the exact matches that's hasn't been confirmed.
   */
  clearBufferedExactMatchWords() {
    this._exactWords = [];

    this.updateInternal();
  }

  clearRemovedWords(): number {
    const count: number = this._removedWordsCount;
    for (let i = 0; i < count; i++) {
      this.words.push(this._removedWords[i]);
    }
    this._removedWords = [];

    this.updateInternal();

    return count;
  }

  clearAddedWords(): number {
    return this.removeWords(this._addedWords);
  }
}