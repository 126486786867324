<div class="card-outer">
  <mat-card>
    <mat-card-title i18n="@@CreateUser_Header">
      Get an account
    </mat-card-title>
    <mat-card-content>
      <span i18n="@@CreateUser_Info">
        If you don't already have a Wizkids account you can create one by
        entering your email.
      </span>
      <form (ngSubmit)="createUser()" [formGroup]="form" novalidate>
        <div class="form">
          <mat-form-field class="input-full-width" hideRequiredMarker="true">
            <mat-placeholder i18n="@@CreateUser_YourEmail">Your email</mat-placeholder>
            <input matInput name="email" formControlName="email" focus>
            <mat-error *ngIf="errors['USER_APPLICATION_NOT_SET']" i18n="@@CreateUser_AppNotSet">Application is not set.</mat-error>
            <mat-error *ngIf="errors['USER_APPLICATION_INVALID']" i18n="@@CreateUser_InvalidApp">Application is invalid.</mat-error>
            <mat-error *ngIf="errors['USER_USERNAME_EXISTS']" i18n="@@CreateUser_UsernameExits">Username already exists.</mat-error>
            <mat-error *ngIf="errors['USER_EMAIL_NOT_SET']" i18n="@@CreateUser_EnterEmail">Please enter an email.</mat-error>
            <mat-error *ngIf="errors['USER_EMAIL_INVALID']" i18n="@@CreateUser_EnterValid">Please enter a valid email.</mat-error>
            <mat-error *ngIf="errors['USER_EMAIL_EXISTS']" i18n="@@CreateUser_EmailExits">Email already exists.</mat-error>
            <mat-error *ngIf="errors['LICENSE_MAX_USERS_REACHED']" i18n="@@CreateUser_MaxUsersReached">All user slots have been used.</mat-error>
            <mat-error *ngIf="errors['LICENSE_NO_LICENSE_FOR_DOMAIN']" i18n="@@CreateUser_NoLicense">No license for domain.</mat-error>
          </mat-form-field>
        </div>
        
        <div class="actions">
          <a mat-button routerLink="/login" i18n="@@CreateUser_btnCancel">Cancel</a>
          <button mat-raised-button color="primary" [disabled]="!form || form!.disabled" i18n="@@CreateUser_btnCreate">Create</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>