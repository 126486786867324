import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  constructor() {}

  private getCookie(): string {
    return document.cookie;
  }

  private setCookie(cookie: string): void {
    document.cookie = cookie;
  }

  private getParts(): string[] {
    return (this.getCookie() || '').split(";");
  }

  private getKeyValues(): { keys: string[], values: string[] } {
    const keys: string[] = [];
    const values: string[] = [];

    const parts: string[] = this.getParts();
    for (let i = 0; i < parts.length; i++) {
      let part: string = parts[i].replace(/^\s+/g, "");

      let index = part.indexOf("=");
      if (index === -1) {
        keys.push('');
        values.push(part);
      } else {
        keys.push(part.substring(0, index));
        values.push(part.substring(index + 1));
      }
    }

    return { keys: keys, values: values };
  }

  isValidName(name: string): boolean {
    return !(/[;=\s]/.test(name));
  }

  isValidValue(value: string): boolean {
    return !(/[;\r\n]/.test(value));
  }

  set(name: string, value: string, maxAge: number = -1, path?: string, domain?: string, secure?: boolean) {
    if (!this.isValidName(name))
      throw new Error("Invalid cookie name '" + name + "'");
    if (!this.isValidValue(value))
      throw new Error("Invalid cookie value '" + value + "'");

    const domainStr = domain ? ';domain=' + domain : '';
    const pathStr = path ? ';path=' + path : '';
    const secureStr = secure ? ';secure' : '';

    let expiresStr = '';
    if (maxAge === 0) {
      const pastDate = new Date(1970, 1, 1);
      expiresStr = ';expires=' + pastDate.toUTCString();
    } else if (maxAge > 0) {
      const futureDate = new Date(Date.now() + maxAge*1000);
      expiresStr = ';expires=' + futureDate.toUTCString();
    }

    this.setCookie(
      name + "=" + value + domainStr + pathStr + expiresStr + secureStr);
  }

  get(name: string): string|undefined {
    const nameEq = name + "=";
    const parts = this.getParts();
    for (let i = 0; i < parts.length; i++) {
      let part: string = parts[i].replace(/^\s+/g, "");
      if (part.lastIndexOf(nameEq, 0) === 0) {
        return part.substr(nameEq.length);
      } else if (part === name) {
        return '';
      }
    }
    return undefined;
  }

  remove(name: string, path?: string, domain?: string): boolean {
    const rv = this.containsKey(name);
    this.set(name, '', 0, path, domain);
    return rv;
  }

  getKeys(): string[] {
    return this.getKeyValues().keys;
  }

  getValues(): string[] {
    return this.getKeyValues().values;
  }

  containsKey(key: string): boolean {
    return this.getKeys().indexOf(key) !== -1;
  }

  containsValue(value: string): boolean {
    return this.getValues().indexOf(value) !== -1;
  }

  isEmpty(): boolean {
    return !this.getCookie();
  }

  getCount(): number {
    if (this.isEmpty()) {
      return 0;
    }
    return this.getParts().length;
  }
}
