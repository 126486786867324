import { IBreadcrumb } from './../../services/breadcrumb.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthService, User } from '../../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { PageNavigationProgressComponent } from '../page-navigation-progress/page-navigation-progress.component';
import { PageNavigationProgressService } from '../../services/page-navigation-progress.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(PageNavigationProgressComponent, { static: true })
  private pageNavigationProgress?: PageNavigationProgressComponent;

  constructor(
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public pageNavigationProgressService: PageNavigationProgressService,
    private breadcrumbService: BreadcrumbService,
    private locale: LocaleService,
  ) {
      document.dir = this.locale.getLocale() === 'ar-EG' ? 'rtl' : 'ltr';
  }

  ngOnInit() {
    this.matIconRegistry
      .addSvgIconInNamespace("app", "back", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/back.svg"))
      .addSvgIconInNamespace("app", "logout", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/logout.svg"))
      .addSvgIconInNamespace("app", "settings", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/settings.svg"))
      .addSvgIconInNamespace("mobile", "more", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile.svg"))
      .addSvgIconInNamespace("login", "google", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/googlelogin.svg"))
      .addSvgIconInNamespace("navigation-card", "profile", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "template", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/template.svg"))
      .addSvgIconInNamespace("navigation-card", "appWriter", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "mathSolver", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "txtAnalyzer", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "stats", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/profile-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "groups", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/groups-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "appWriter-group", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/groups-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "stats-group", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/groups-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "txtAnalyzer-group", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/groups-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "mathSolver-group", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/groups-icon.svg"))
      .addSvgIconInNamespace("navigation-card", "dictionaries", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/glossaries.svg"))
      .addSvgIconInNamespace("navigation-card", "spelling", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/phoneticspelling.svg"))
      .addSvgIconInNamespace("navigation-card", "categories", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/categories.svg"))
      .addSvgIconInNamespace("navigation-card", "errorreporting", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/errorreporting.svg"))
      .addSvgIconInNamespace("navigation-card", "upload", this.sanitizer.bypassSecurityTrustResourceUrl("/assets/upload.svg"));

    this.router.events
      .subscribe(evt => {
        if (evt instanceof NavigationStart) {
          this.pageNavigationProgressService.start();
        } else if (evt instanceof NavigationEnd) {
          this.pageNavigationProgressService.complete();
        } else if (evt instanceof NavigationCancel) {
          this.pageNavigationProgressService.complete();
        } else if (evt instanceof NavigationError) {
          this.pageNavigationProgressService.complete();

          if (evt.error instanceof Response) {
            const httpResponse: Response = evt.error;
            console.error("HTTP error - " + httpResponse.status);
          }
        }
      });
  }

  ngAfterViewInit() {
    if (this.pageNavigationProgress) {
      this.pageNavigationProgressService.setPageNavigationProgress(this.pageNavigationProgress);
    }
  }

  get routerParent(): IBreadcrumb|undefined {
    if (!this.authService.isAuthenticated() || this.router.url === "/")
      return undefined;

    const breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    if (breadcrumbs.breadcrumbs.length === 0) return undefined;

    return breadcrumbs.breadcrumbs[breadcrumbs.breadcrumbs.length - 1];
  }

  isDanishLocale() {
    return this.locale.getLocale() === "da-DK";
  }

  isEnglishLocale() {
    return this.locale.getLocale() === "en-GB" || this.locale.getLocale() === "en-US";
  }

  isSwedishLocale() {
    return this.locale.getLocale() === "sv-SE";
  }
}
