import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { detectIE } from '../../utils/utils';

import { IGlossary } from '../../models/glossary';
import { Language } from '../../models/language';
import { Group } from '../../models/groups';

import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';

import { GlossaryCreateDialogComponent } from '../glossary-create-dialog/glossary-create-dialog.component';
import { GlossaryEditDialogComponent, IDialogData as EditDialogData } from '../glossary-edit-dialog/glossary-edit-dialog.component';
import { GlossaryDeleteDialogComponent } from '../glossary-delete-dialog/glossary-delete-dialog.component';
import { GlossaryUnsubscribeDialogComponent, IUnsubscribeData } from '../glossary-unsubscribe-dialog/glossary-unsubscribe-dialog.component';
import { GlossaryAddToGroupDialogComponent, IAddGlossariesToGroupData } from '../glossary-add-to-group-dialog/glossary-add-to-group-dialog.component';

interface IRouteData {
  glossaries: IGlossary[];
  languages: Language[];
  group: Group;
}

@Component({
  selector: 'app-glossaries',
  templateUrl: './glossaries.component.html',
  styleUrls: ['./glossaries.component.scss']
})
export class GlossariesComponent implements OnInit, OnDestroy {
  private routeSubscription?: Subscription;
  private opSubscriptions?: Subscription[] = [];
  public updatingGroupGlossaries: boolean = false;
  private updateGroupGlossariesSubscription?: Subscription;

  public languages: Language[] = [];
  public glossaries: IGlossary[] = [];

  public myGlossaries: IGlossary[] = [];
  public subscribedGlossaries: IGlossary[] = [];
  public groupGlossaries: IGlossary[] = [];

  // List of glossaries for groups
  public groupViewGlossaries: IGlossary[] = [];

  public userId?: number;
  public groupId?: number;
  public group?: Group;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService
  ) {}

  public isGroupView(): boolean {
    return this.groupId !== undefined && this.userId === undefined
  }

  private _updateGlossaries() {
    this.groupViewGlossaries = this.glossaries.filter(x => x.subscribed);
    this.myGlossaries = this.glossaries.filter(x => x.ownedByMe);
    this.subscribedGlossaries = this.glossaries.filter(x => !x.ownedByMe && x.subscribed && x.sharedByGroupId === undefined);
    this.groupGlossaries = this.glossaries.filter(x => !x.ownedByMe && x.sharedByGroupId !== undefined);
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this.route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this.route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this.route.snapshot.paramMap.get('userId')!, 10);
    }
    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.glossaries = data.glossaries;
      this.languages = data.languages;
      this.group = data.group;

      this._updateGlossaries();
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }

    if (this.opSubscriptions) {
      for (let i = 0; i < this.opSubscriptions.length; i++) {
        this.opSubscriptions[i].unsubscribe();
      }
      this.opSubscriptions = undefined;
    }
  }

  /**
   * Remove glossary from list.
   */
  onDeleteGlossary(glossary: IGlossary): void {
    const index = this.glossaries.indexOf(glossary);
    if (index !== -1) {
      this.glossaries.splice(index, 1);

      this._updateGlossaries();
    }
  }
  
  openCreateGlossaryDialog(): void {
    const ref = this.dialog.open(GlossaryCreateDialogComponent, {
      data: this.languages,
      disableClose: true,
      width: '750px'
    });
    
    ref.componentInstance.groupId = this.groupId;
    ref.componentInstance.userId = this.userId;
    
    if (ref.componentInstance.onCreated) {
      ref.componentInstance.onCreated
        .subscribe((glossary: IGlossary) => {
          this.glossaries.push(glossary);
        }, undefined, () => this._updateGlossaries());
    }
  }
  
  editGlossary(glossary: IGlossary): void {
    const data: EditDialogData = {
      glossary: glossary,
      languages: this.languages
    };

    const ref = this.dialog.open(GlossaryEditDialogComponent, {
      data: data,
      disableClose: true,
      width: '750px'
    });
    
    if (ref.componentInstance.onUpdated) {
      ref.componentInstance.onUpdated
        .subscribe((glossary: IGlossary) => {
          glossary.name = ref.componentInstance.name;
          glossary.language = ref.componentInstance.language;
          glossary.description = ref.componentInstance.description;
        });
    }
  }
  
  addGlossariesToGroup(): void {
    const ref = this.dialog.open(GlossaryAddToGroupDialogComponent, {
      data: {
        group: this.group,
        languages: this.languages
      } as IAddGlossariesToGroupData,
      width: '750px'
    });

    ref.componentInstance.onCreated
      .subscribe((glossary: IGlossary[]) => {
        this.glossaries = this.glossaries.concat(glossary);
        this._updateGlossaries();
      });
  }

  deleteGlossary(glossary: IGlossary): void {
    const ref = this.dialog.open(GlossaryDeleteDialogComponent, {
      data: glossary,
      width: '750px'
    });
    if (ref.componentInstance.onDeleted) {
      ref.componentInstance.onDeleted.subscribe(
        (glossary: IGlossary) => {
          if (detectIE()) {
            // The splice method does not seem to work for IE. Reload the whole page if IE, until we find a better solution
            window.location.reload(); 
          } else {
            this.glossaries.splice(this.glossaries.indexOf(glossary), 1);
            this._updateGlossaries();
          }
        }
      );
    }
  }
  
  getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }
}
