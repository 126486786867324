import { Component, Output, EventEmitter, Inject, NgZone, Input } from '@angular/core';
import { IOneDriveFilePickerConfigurationService, IOneDriveFilePickerConfiguration } from '../../models/IOneDriveFilePickerConfiguration';
import { OneDriveFilePickerLoaderService } from '../../services/onedrive-file-picker-loader.service';
import { DriveItem } from '@microsoft/microsoft-graph-types';

@Component({
  selector: 'app-onedrive-file-picker',
  templateUrl: './onedrive-file-picker.component.html',
  styleUrls: ['./onedrive-file-picker.component.scss']
})
export class OneDriveFilePickerComponent {
  @Input()
  public disabled = false;

  @Output()
  public onFile = new EventEmitter<IOneDriveFile>();

  constructor(
    @Inject(IOneDriveFilePickerConfigurationService) private _config: IOneDriveFilePickerConfiguration,
    public picker: OneDriveFilePickerLoaderService,
    private _zone: NgZone
  ) { }

  public async onOpen() {
    await this.picker.open({
      clientId: this._config.clientId,
      action: "download",
      multiSelect: true,
      openInNewWindow: true,
      advanced: {
        redirectUri: location.origin + "/onedrive-file-picker-redirect.html",
        filter: ".docx,.txt"
      },
      success: (res) => this._onPickerCallback(res)
    });
  }

  private _onPickerCallback(res: OneDrive.IOneDriveResponse) {
    this._zone.run(() => {
      const items = res.value as IDownloadableDriveItem[];
      for (const item of items) {
        this.onFile.emit({
          name: item.name || "",
          downloadUrl: item["@microsoft.graph.downloadUrl"]
        });
      }
    });
  }
}

export interface IOneDriveFile {
  name: string;
  downloadUrl: string;
}

export interface IDownloadableDriveItem extends DriveItem {
  ["@microsoft.graph.downloadUrl"]: string;
}