import { Injectable } from '@angular/core';
import { PageNavigationProgressComponent } from '../components/page-navigation-progress/page-navigation-progress.component';
import { Subscription } from 'rxjs';

@Injectable()
export class PageNavigationProgressService {
  private pageNavigationProgress?: PageNavigationProgressComponent;
  private transitionEndSubscription?: Subscription;
  
  private completed: boolean = true;
  private progressValue: number = 0;
  private maxProgressValue: number = 1;
  private loadStart: number = 0;
  private pseudoStart: number = 0;
  
  private updateInterval?: number;

  constructor() { }

  public isCompleted() {
    return this.completed;
  }

  public setPageNavigationProgress(pageNavigationProgress: PageNavigationProgressComponent) {
    if (this.transitionEndSubscription) {
      this.transitionEndSubscription.unsubscribe();
      this.transitionEndSubscription = undefined;
    }
    
    this.pageNavigationProgress = pageNavigationProgress;
    this.transitionEndSubscription = this.pageNavigationProgress.transitionend.subscribe(
      () => this.onTransitionEnd()
    );
    
    this.update();
  }
  
  private onTransitionEnd() {
    if (this.completed && this.pageNavigationProgress) {
      this.pageNavigationProgress.setVisible(false);
    }
  }
  
  public complete() {
    if (this.completed) return;
    if (this.updateInterval) {
      window.clearInterval(this.updateInterval);
      this.updateInterval = undefined;
    }
    this.completed = true;
    this.update();
  }
  
  public start(maxProgressValue?: number) {
    if (maxProgressValue) {
      this.maxProgressValue = maxProgressValue;
      if (!this.completed) this.update();
    }
    if (!this.completed) return;
    this.completed = false;
    this.loadStart = this.pseudoStart = Date.now();
    this.progressValue = 0;
    this.maxProgressValue = 1;
    this.update();
    this.updateInterval = window.setInterval(() => this.update(), 200);
  }
  
  public progress() {
    this.progressValue++;
    this.pseudoStart = Date.now();
    if (this.progressValue >= this.maxProgressValue) {
      this.complete();
    } else {
      this.update();
    }
  }
  
  public setMaxProgressValue(maxValue: number) {
    this.maxProgressValue = maxValue;
    this.update();
  }
  
  private getPseudoValue() {
    const dt = Date.now() - this.pseudoStart;
    return 1 - 1/Math.pow(1 + dt/10000, 1/Math.E);
  }
  
  private update() {
    if (!this.pageNavigationProgress) return;
    
    if (this.completed) {
      this.pageNavigationProgress.setValue(1);
    } else {
      if (this.progressValue === this.maxProgressValue) {
        this.complete();
      } else {
        if (this.progressValue === 0) {
          this.pageNavigationProgress.setValue(Math.min(0.1 + this.getPseudoValue(), 0.9));
        } else {
          const value = Math.min(this.progressValue, this.maxProgressValue)/this.maxProgressValue;
          this.pageNavigationProgress.setValue(Math.min(value + this.getPseudoValue(), 0.9));
        }
        if (Math.abs(this.loadStart - Date.now()) > 500) {
          this.pageNavigationProgress.setVisible(true);
        }
      }
    }
  }
}
