import { GlossarySnackbarType } from './../glossary-snackbar/glossary-snackbar.component';
import { GlossaryDeleteDialogComponent } from './../glossary-delete-dialog/glossary-delete-dialog.component';
import { GlossaryEditDialogComponent } from './../glossary-edit-dialog/glossary-edit-dialog.component';
import {
  Component, Inject, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, Output,
  EventEmitter, NgZone
} from '@angular/core';
import { IGlossary, EnabledState } from './../../models/glossary';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlossaryViewDialogComponent } from 'app/components/glossary-view-dialog/glossary-view-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Language } from 'app/models/language';
import { IGlossaryService } from 'app/interfaces/iglossary.service';
import { IGlossaryServiceToken } from 'app/interfaces/iglossary.service.token';
import { GlossarySnackbarComponent } from 'app/components/glossary-snackbar/glossary-snackbar.component';
import { GlossaryUnsubscribeDialogComponent, IUnsubscribeData } from 'app/components/glossary-unsubscribe-dialog/glossary-unsubscribe-dialog.component';
import { MediaQueryService } from 'app/services/media-query.service';

export class GlossaryController {
  public glossary: IGlossary;
  public languages: Language[] = [];

  public enabling: boolean = false;

  private parentComponent: GlossaryListComponent;

  constructor(
    private dialog: MatDialog,
    private glossaryService: IGlossaryService,
    private snackbar: MatSnackBar,
    glossary: IGlossary,
    component: GlossaryListComponent
  ) {
    this.glossary = glossary;
    this.parentComponent = component;
  }

  setEnabled(enabled: boolean): void {
    if (this.enabling) return;
    this.glossary.enabled = enabled ? EnabledState.Enabled : EnabledState.Disabled;
    this.glossary.subscribed = true;

    this.enabling = true;
    this.glossaryService.updateGlossarySubscription(this.glossary)
      .subscribe(undefined, () => {
        this.enabling = false;
      }, () => {
        this.snackbar.openFromComponent(GlossarySnackbarComponent, {
          data: enabled ? GlossarySnackbarType.Enabled : GlossarySnackbarType.Disabled,
          duration: 4000
        });
        this.enabling = false;
      });
  }

  isEnabled(): boolean {
    return this.glossary.enabled === EnabledState.Enabled;
  }

  isEnabledIndeterminate(): boolean {
    return this.glossary.enabled === EnabledState.Indeterminate;
  }

  view(): void {
    const ref = this.dialog.open(GlossaryViewDialogComponent, {
      width: '750px',
      data: {
        glossary: this.glossary,
        languages: this.parentComponent.languages
      }
    });
  }

  edit(): void {
    const ref = this.dialog.open(GlossaryEditDialogComponent, {
      width: '750px',
      data: {
        glossary: this.glossary,
        languages: this.parentComponent.languages
      }
    });
  }

  delete(): void {
    const ref = this.dialog.open(GlossaryDeleteDialogComponent, {
      width: '750px',
      data: this.glossary
    });

    if (ref.componentInstance.onDeleted) {
      ref.componentInstance.onDeleted.subscribe(glossary => {
        this.parentComponent.onDelete.emit(glossary);
      });
    }
  }

  unsubscribe(group = false): void {
    const ref = this.dialog.open(GlossaryUnsubscribeDialogComponent, {
      width: '750px',
      data: {
        glossary: this.glossary,
        group: group
      } as IUnsubscribeData
    });

    if (ref.componentInstance.onUnsubscribed) {
      ref.componentInstance.onUnsubscribed.subscribe(glossary => {
        this.snackbar.openFromComponent(GlossarySnackbarComponent, {
          data: GlossarySnackbarType.Unsubscribed,
          duration: 4000
        });

        this.parentComponent.onDelete.emit(glossary);
      });
    }
  }
}

interface IRouteData {
  languages: Language[];
}

@Component({
  selector: 'app-glossary-list',
  templateUrl: './glossary-list.component.html',
  styleUrls: ['./glossary-list.component.scss']
})
export class GlossaryListComponent implements OnInit, OnDestroy, OnChanges {
  private routeSubscription: Subscription|undefined = undefined;

  @Input('glossaries')
  public glossaries: IGlossary[] = [];

  @Input('showPublicColumn')
  public showPublicColumn: boolean = false;

  @Input('showOwnerColumn')
  public showOwnerColumn: boolean = false;

  @Input('showSubscribersColumn')
  public showSubscribersColumn: boolean = false;

  @Input('showGroupColumn')
  public showGroupColumn: boolean = false;

  @Input('showEdit')
  public showEdit: boolean = false;

  @Input('showDelete')
  public showDelete: boolean = false;

  @Input('showUnsubscribe')
  public showUnsubscribe: boolean = false;

  @Input('onlyEdit')
  public onlyEdit: boolean = false;

  @Input('showUnsubscribeFromGroup')
  public showUnsubscribeFromGroup: boolean = false;

  @Output('onDelete')
  public onDelete = new EventEmitter<IGlossary>();

  public controllers: GlossaryController[] = [];

  public languages: Language[] = [];

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService,
    private snackbar: MatSnackBar,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const glossaries = changes.glossaries.currentValue as IGlossary[]|undefined;
    if (glossaries) {
      this.controllers = glossaries.map(v => new GlossaryController(this.dialog, this.glossaryService, this.snackbar, v, this));
    } else {
      this.controllers = [];
    }
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.languages = data.languages;
    });
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }
  
  getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }
}
