import { Injectable } from '@angular/core';
import { UserDetail, Application } from '../../models/account';
import { IAccountService } from '../../interfaces/iaccount.service';
import { CollectionService, Collection, Template, Data } from '../collection.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountCollectionService implements IAccountService {
  
  constructor(
    private collectionService: CollectionService,
    private authService: AuthService    
  ) {}

  /** @override */
  getUserDetail(): Observable<UserDetail> {
    return this.collectionService.getCollection("/account")
    .pipe(map((collection: Collection) => {
      let item = collection.items[0];

      let firstName: string = item.getDataAsString("firstName");
      let lastName: string = item.getDataAsString("lastName");
      let countryCode: string = item.getDataAsString("country");
      let phone: string = item.getDataAsString("phone");
      let languageCode: string = item.getDataAsString("language");
      let email: string = item.getDataAsString("email");

      return new UserDetail(
        firstName,
        lastName,
        countryCode,
        languageCode,
        phone,
        email
      );
    }));
  }

  updateUserDetail(userDetail: UserDetail): Observable<UserDetail> {
    const collection = this.collectionService.createCollection("/account");

    const template = new Template([
      new Data("firstName", userDetail.firstName),
      new Data("lastName", userDetail.lastName),
      new Data("country", userDetail.country),
      new Data("language", userDetail.language),
      new Data("phone", userDetail.phone)
    ]);

    return collection.put(template)
      .pipe(map(() => { 
        this.authService.updateUser();
        return userDetail;      
      }));
  }

  changePassword(currentPassword: string, password: string): Observable<any> {
    const collection = this.collectionService.createCollection("/account/changepassword");

    const template = new Template([
      new Data("currentPassword", currentPassword),
      new Data("password", password)
    ]);

    return collection.post(template);
  }

  transferProfileSettings(username: string, password: string) {
    const collection = this.collectionService.createCollection("/account/transfer");

    const template = new Template([
      new Data("userName", username),
      new Data("password", password)
    ]);

    return collection.post(template);
  }

  getApplications(): Observable<Application[]> {
    return this.collectionService.getCollection("/account/applications")
      .pipe(map((collection: Collection) => {
        let applications: Application[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          let item = collection.items[i];

          applications.push(new Application(
            item.getDataAsString("name"),
            item.getDataAsString("cleanName"),
            item.getDataAsString("imageUrl"),
            item.getDataAsString("downloadUrl"),
          ));
        }

        return applications;
      }));
  }
}
