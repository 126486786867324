import { LingappsError } from './../../services/auth.service';
import { Component, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { IAccountService } from '../../interfaces/iaccount.service';
import { IAccountServiceToken } from '../../interfaces/iaccount.service.token';

interface IErrorObject {
  [key: string]: boolean|undefined;
  USER_CURRENT_PASSWORD_NOT_SET?: boolean;
  USER_NEW_PASSWORD_NOT_SET?: boolean;
  USER_INCORRECT_CURRENT_PASSWORD?: boolean;
  USER_INVALID_NEW_PASSWORD?: boolean;
}

@Component({
  selector: 'app-account-password-change-dialog',
  templateUrl: './account-password-change-dialog.component.html',
  styleUrls: ['./account-password-change-dialog.component.scss']
})
export class AccountPasswordChangeDialogComponent implements OnDestroy {
  public currentPassword: string = "";
  public password: string = "";
  public repeatPassword: string = "";
  
  public enabled: boolean = true;
  
  public error: IErrorObject = {};
  public errorRepeatPassword: boolean = false;
  
  private subscription?: Subscription = undefined;

  constructor(
    public dialogRef: MatDialogRef<AccountPasswordChangeDialogComponent>,
    @Inject(IAccountServiceToken) private accountService: IAccountService
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }
  
  onPasswordUpdate() {
    if (!this.enabled) return;
    this.error = {};
    this.errorRepeatPassword = false;
    
    if (!this.currentPassword) {
      this.error["USER_CURRENT_PASSWORD_NOT_SET"] = true;
      return;
    }
    
    if (this.password !== this.repeatPassword) {
      this.errorRepeatPassword = true;
      this.repeatPassword = "";
      return;
    }
    
    if (!this.password) {
      this.error["USER_NEW_PASSWORD_NOT_SET"] = true;
      this.repeatPassword = "";
      return;
    }
    
    this.enabled = false;

    this.subscription = this.accountService.changePassword(this.currentPassword, this.password)
    .subscribe(undefined, (error: LingappsError) => {
      this.subscription = undefined;
      this.enabled = true;

      this.currentPassword = "";
      this.password = "";
      this.repeatPassword = "";

      if (error.code) {
        this.error[error.code] = true;
      }
    }, () => {
      this.subscription = undefined;
      this.enabled = true;
      this.dialogRef.close();
    });
  }
}
