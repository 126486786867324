<div mat-dialog-title i18n="@@EditMember_Header">
  Edit member
</div>

<form (ngSubmit)="editMember()" [formGroup]="form" novalidate>
  <mat-dialog-content>
    <div class="input-container">
      <mat-form-field class="input-full-width">
        <mat-select formControlName="role" placeholder="Member role" i18n-placeholder="@@EditMember_Role">
          <mat-option [value]="role.role" *ngFor="let role of roles">{{ role.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-container input-container--checkbox">
      <mat-checkbox class="input-full-width" formControlName="includeInStats" i18n="@@EditMember_IncludeInStats">Include in the group statistics</mat-checkbox>
    </div>
    
    <div class="warning" *ngIf="form.get('role')!.value === GroupRole.OWNER" i18n="@@EditMember_TransferInfo">
      There can only be one owner per group. Are you sure you want to transfer the ownership to this member?
    </div>
    <div *ngIf="unexpectedError" class="error" i18n="@@EditMember_UnexpectedError">An unexpected error occurred.</div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="@@EditMember_btnCancel">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.disabled" i18n="@@EditMember_btnSave">Save</button>
  </mat-dialog-actions>
</form>