import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationLink } from '../../models/navigation';

interface IRouteData {
  links: NavigationLink[]
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  public links: NavigationLink[] = [];

  private routeSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.links = data.links.map(link => {
        const language = window.localStorage.getItem('language.statistics');

        if (language) {
          const regex = /\/stats\/([a-zA-Z\-\_]+)/;
          if (link.href && regex.test(link.href)) {
            link.href = link.href.replace(regex, '\/stats\/' + encodeURIComponent(language));
          }
        }

        return link;
      });
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
  }
}
