import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-pie-chart',
  templateUrl: './stats-pie-chart.component.html',
  styleUrls: ['./stats-pie-chart.component.scss']
})
export class StatsPieChartComponent {
  @Input()
  public get slices(): IPieSlice[] {
    return this._slices;
  }
  public set slices(slices: IPieSlice[]) {
    this._slices = slices;

    this._total = this.slices.reduce((x, y) => x + y.value, 0);
    
    this.paths = this._getPaths(slices);
  }
  private _slices: IPieSlice[] = [];

  public paths: ISvgPath[] = [];

  public get total() {
    return this._total;
  }
  private _total = 0;

  private _getPaths(slices: IPieSlice[]): ISvgPath[] {
    let cumulativePercent = 0;

    return slices.map(slice => {
      const percent = slice.value / this.total;

      const [startX, startY] = this._getCoordinatesForPercent(cumulativePercent);

      cumulativePercent += percent;

      const [endX, endY] = this._getCoordinatesForPercent(cumulativePercent);

      const largeArcFlag = percent > .5 ? 1 : 0;

      return {
        data: [
          `M ${startX} ${startY}`,
          `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
          `L 0 0`
        ].join(' '),
        fill: slice.color
      };
    });
  }

  private _getCoordinatesForPercent(percent: number) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);

    return [x, y];
  }
}

interface ISvgPath {
  data: string;
  fill: string;
}

export interface IPieSlice {
  value: number;
  color: string;
}