import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { NavigationLink } from '../models/navigation';
import { INavigationService } from '../interfaces/inavigation.service';
import { INavigationServiceToken } from '../interfaces/inavigation.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class NavigationResolver implements Resolve<NavigationLink[]> {
  constructor(
    @Inject(INavigationServiceToken) private navigationService: INavigationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NavigationLink[]> {
    return this.navigationService.getLinks(state.url);
  }
}
