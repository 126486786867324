import { Component, OnDestroy, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

import { Group } from '../../models/groups';

interface IError {
  [key: string]: boolean|undefined;
  empty?: boolean;
}

function errorValidator(error: IError): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}

@Component({
  selector: 'app-groups-join-group-dialog',
  templateUrl: './groups-join-group-dialog.component.html',
  styleUrls: ['./groups-join-group-dialog.component.scss']
})
export class GroupsJoinGroupDialogComponent implements OnDestroy {
  private tokenSubscription?: Subscription;

  public tokenError: IError = {};

  public form: FormGroup = new FormGroup({
    'token': new FormControl('', errorValidator(this.tokenError))
  });
  public unexpectedError: boolean = false;

  constructor(
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public group: Group,
    public dialogRef: MatDialogRef<GroupsJoinGroupDialogComponent>,
    private router: Router
  ) { }

  ngOnDestroy() {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
      this.tokenSubscription = undefined;
    }
  }

  resetErrors() {
    this.unexpectedError = false;
    for (let key in this.tokenError) {
      this.tokenError[key] = false;
    }
  }

  onSubmit() {
    if (this.group) {
      this.joinGroup();
    } else {
      this.submitToken();
    }
  }
  
  submitToken() {
    if (this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    let token: string = this.form.get('token')!.value;
    
    if (!token) {
      this.tokenError.empty = true;
      this.form.updateValueAndValidity();
      this.form.enable();
      return;
    }

    this.tokenSubscription = this.groupsService.getGroupByToken(token)
      .subscribe((group: Group) => {
        this.group = group;
      }, (err) => {
        console.error(err);
        this.form.enable();
        this.unexpectedError = true;
      }, () => {
        this.form.enable();
      });
  }

  joinGroup() {
    if (this.form.disabled) return;
    this.form.disable();
    this.resetErrors();

    this.groupsService.joinGroup(this.group)
      .subscribe((group) => {
        this.onClose(group.id);
      }, (err) => {
        console.error(err);
        this.form.enable();
        this.unexpectedError = true;
      }, () => {
        this.form.enable();
      });
  }

  onClose(groupId?: number) {
    if (groupId) {
      this.router.navigate(["/groups", groupId]);
    } else {
      this.router.navigate(["/groups"]);
    }
    this.dialogRef.close();
  }

}
