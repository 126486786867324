
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthToken } from '../models/auth-token';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';
import { LingappsResponse } from "app/models/lingapps";

export class LingappsError extends Error {
  public code: string;

  constructor(code?: string, message?: string) {
    super(message);

    this.code = code || "UNKNOWN";
    this.message = message || "An unknown error occurred.";
  }
}

@Injectable()
export class AuthService {
  private token?: AuthToken;

  public user?: User;
  public redirectUrl?: string;

  public authServiceApiKey: EventEmitter<AuthToken> = new EventEmitter();
  public userUpdated: EventEmitter<User> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private localeService: LocaleService
  ) {
    const redirectUrl = localStorage.getItem("redirectUrl");
    this.redirectUrl = redirectUrl === null ? undefined : redirectUrl;
    this.token = this.getToken();
    if (this.token) {
      setTimeout(async () => {
        const authenticated = await this.validateToken().toPromise();
        if (authenticated) {
          this.user = await this.getUser().toPromise();
        } else {
          this.user = undefined;
        }
      }, 7);
    }
  }

  public redirectBack(): void {
    let redirectUrl: string = this.redirectUrl || "/";
    if (this._isRedirectUrlLoginPage(redirectUrl))
      redirectUrl = "/";

    this.router.navigateByUrl(redirectUrl);
  }

  public redirectToLogin(redirectUrl: string): void {
    if (this._isRedirectUrlLoginPage(redirectUrl))
      redirectUrl = "/";
    this.redirectUrl = redirectUrl;
    localStorage.setItem("redirectUrl", this.redirectUrl || "/");

    this.router.navigate(["/login"]);
  }

  public getUser(): Observable<User> {
    if (!this.token)
      return observableThrowError(new Error("Invalid authentication token."));

    const body = "application=" + encodeURIComponent(environment.application) + "&sessionId=" + encodeURIComponent(this.token.getSessionId());

    return this.http.post<LingappsResponse<ILingappsUser>>(environment.services.getUserInfo, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded',
          'LingApps-Machine-ID': this.token.getMachineId()
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          if (body.data) {
            if (body.data.locale) {
              this.localeService.setLocale(body.data.locale);
            }

            this.user = User.fromObject(body.data);
            return this.user;
          } else {
            throw new LingappsError("EMPTY_DATA", "No data found in response");
          }
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }),
      catchError(err => {
        this.logout();

        throw err;
      }),);
  }

  public updateUser() {
    this.getUser().subscribe((user) => {
      this.userUpdated.emit(user);
    });
  }

  public setToken(token?: AuthToken) {
    this.token = token;
    this.authServiceApiKey.emit(token);

    if (token) {
      localStorage.setItem("token", token.toString());
    } else {
      localStorage.removeItem("token");
    }
  }

private validateToken(): Observable<boolean> {
   if (!this.token)
      return observableOf(false);

    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'userSessionId': this.token.getSessionId()
    })) + "&sessionId=" + encodeURIComponent(this.token.getSessionId())
    + "&application=" + encodeURIComponent(environment.application);

    return this.http.post<LingappsResponse<any>>(environment.services.validate, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded',
          'LingApps-Machine-ID': this.token.getMachineId()
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          return body.data;
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }),
      catchError(err => {
        this.logout();

        throw err;
      }));
  }

  private _isRedirectUrlLoginPage(url: string): boolean {
    const ignoreUrls = [
      '/login',
      '/sessionLogin',
      '/logout'
    ];

    return ignoreUrls.some(ignoreUrl => url.startsWith(ignoreUrl));
  }

  public isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public isUsernameAvailable(username: string): Observable<boolean> {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'username': username
    })) + "&application=" + encodeURIComponent(environment.application);

    return this.http.post<LingappsResponse<any>>(environment.services.validateUsername, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded'
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          return !!body.data;
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }));
  }

  /**
   * Authenticate the user with username and password.
   */
  public authenticate(username: string, password: string, remember?: boolean): Observable<boolean> {
    this.clearToken();

    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'username': username,
      'password': password,
      'remember': !!remember
    })) + "&application=" + encodeURIComponent(environment.application);

    return this.http.post<LingappsResponse<ISessionResponse>>(
      environment.services.login,
      body,
      {
        observe: 'response',
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded'
        }
      }
    ).pipe(
      map(res => {
        const body = res.body;
        if (body && body.status === "success" && body.data) {
          const mid = res.headers.get("LingApps-Machine-ID");
          const phpSessionId = res.headers.get("LingApps-PHP-Session-ID");

          const token = new AuthToken(body.data.sessionId, mid || "", phpSessionId || "");
          this.setToken(token);
          localStorage.setItem("token", token.toString());

          this.localeService.setLocale(body.data.user.locale);

          const { username, email, name, firstName, lastName } = body.data.user;

          this.user = new User(username, email, name, firstName, lastName);
          return true;
        } else {
          let errorCode: string|undefined;
          let errorMessage: string|undefined;
          if (body) {
            errorCode = body.errorCode;
            errorMessage = body.errorMessage;
          }

          throw new LingappsError(errorCode, errorMessage);
        }
      }));
  }

  public getUnicLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_unic",
      'provider': 'TextHelpUniLogin',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  public getGoogleLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_google",
      'provider': 'Google',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  public getFeideLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_feide",
      'provider': 'WizkidsFeide',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  public getMicrosoftLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_microsoft",
      'provider': 'WizkidsMicrosoft',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  public getKmdNexusLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_kmd_nexus",
      'provider': 'KmdNexus',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  public getMicrosoftAdfsLogin(): string {
    const body = "parameters=" + encodeURIComponent(JSON.stringify({
      'redirect': window.location.protocol + "//" + window.location.host + window.location.pathname + "_microsoft_adfs",
      'provider': 'WizkidsMicrosoftAdfs',
      'direct': '1'
    })) + "&application=" + encodeURIComponent(environment.application);

    return environment.services.getLoginUrl + "?" + body;
  }

  private clearToken() {
    this.user = undefined;
    this.setToken(undefined);
    localStorage.removeItem("token");
  }

  /**
   * Log out the user by first contacting the LingApps servers.
   */
  public logout(): Observable<boolean> {
    if (!this.token) {
      return observableOf(true);
    }

    const sessionId = this.token.getSessionId();
    const machineId = this.token.getMachineId();
    this.clearToken();

    const body = "application=" + encodeURIComponent(environment.application) + "&sessionId=" + encodeURIComponent(sessionId);
    return this.http.post<LingappsResponse<any>>(environment.services.logout, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded',
          'LingApps-Machine-ID': machineId
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          return true;
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }));
  }

  public createUserByEmail(email: string): Observable<any> {
    const locales = ["en-GB", "da-DK", "nb-NO", "sv-SE"];
    let locale = this.localeService.getLocale();
    if (locales.indexOf(locale) === -1) {
      locale = locales[0];
    }

    const parameters = {
      "email": email,
      "locale": locale
    };

    const body = "application=" + encodeURIComponent(environment.application)
             + "&parameters=" + encodeURIComponent(JSON.stringify(parameters));

    return this.http.post<LingappsResponse<any>>(environment.services.createUserByEmail, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded'
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          return true;
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }));
  }

  public forgotUsernameOrPassword(email: string): Observable<any> {
    const locales = ["en-GB", "da-DK", "nb-NO", "sv-SE"];
    let locale = this.localeService.getLocale();
    if (locales.indexOf(locale) === -1) {
      locale = locales[0];
    }

    const parameters = {
      "email": email,
      "locale": locale
    };

    const body = "application=" + encodeURIComponent(environment.application)
             + "&parameters=" + encodeURIComponent(JSON.stringify(parameters));

    return this.http.post<LingappsResponse<any>>(environment.services.forgotUsernameOrPassword, body,
      {
        headers: {
          'Content-Type': 'application/X-www-form-urlencoded'
        }
      }).pipe(
      map(body => {
        if (body.status === "success") {
          return true;
        } else {
          throw new LingappsError(body.errorCode, body.errorMessage);
        }
      }));
  }

  public getToken(): AuthToken|undefined {
    const token = localStorage.getItem("token") || undefined;
    if (token) {
      return AuthToken.fromToken(token);
    } else {
      return undefined;
    }
  }
}

export class User {
  public constructor(
    public username?: string,
    public email?: string,
    public name?: string,
    public firstName?: string,
    public lastName?: string
  ) {

  }

  public isStukUser(): boolean {
    const email = this.email || "";
    return !!email.match(/@stuk\.dk$/);
  }

  public static fromObject(json: ILingappsUser): User {
    return new User(
      json.username,
      json.email,
      json.name,
      json.firstName,
      json.lastName
    );
  }

  public static createEmpty(): User {
    return new User(undefined, undefined, undefined, undefined, undefined);
  }
}

export declare interface ILingappsUser {
  username: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  locale: string;
}

export declare interface ISessionResponse {
  sessionId: string;
  user: ILingappsUser;
}
