import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject ,  Subscription } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { IgnoreDocument } from "./IgnoreDocument";

export class IgnoreDocumentsDataSource extends DataSource<IgnoreDocument> {
  private _service: ITxtAnalyserService;

  private readonly _renderData = new BehaviorSubject<IgnoreDocument[]>([]);

  private _sortChangeSubscription?: Subscription;
  private _renderChangesSubscription?: Subscription;
  
  private _pageSize: number = 25;
  private _pageIndex: number = 0;
  private _filter: string = "";
  private _sort: MatSort|undefined;

  private _totalItems: number = 0;

  private _language: string;
  private _groupId?: number;
  private _userId?: number;

  get totalItems(): number { return this._totalItems; }

  get pageSize(): number { return this._pageSize; }
  set pageSize(size: number) {
    if (this._pageSize === size) return;
    this._pageSize = size;

    this._updateRenderDataSubscription();
  }

  get pageIndex(): number { return this._pageIndex; }
  set pageIndex(index: number) {
    if (this._pageIndex === index) return;
    this._pageIndex = index;

    this._updateRenderDataSubscription();
  }

  get filter(): string { return this._filter; }
  set filter(filter: string) {
    if (this._filter === filter) return;
    this._filter = filter;

    this._updateRenderDataSubscription();
  }

  get sort(): MatSort | undefined { return this._sort; }
  set sort(sort: MatSort|undefined) {
    this._sort = sort;

    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._sort) {
      this._sortChangeSubscription = this._sort.sortChange.subscribe(() => this._updateRenderDataSubscription());
    }

    this._updateRenderDataSubscription();
  }

  constructor(service: ITxtAnalyserService, language: string, groupId?: number, userId?: number) {
    super();
    this._service = service;
    this._language = language;
    this._groupId = groupId;
    this._userId = userId;

    this._updateRenderDataSubscription();
  }

  private _updateRenderDataSubscription(): void {
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }

    let sortDirection: "asc"|"desc" = "asc";
    let sortActive: string = "name";

    if (this._sort) {
      sortDirection = this._sort.direction || "asc";
      sortActive = this._sort.active;
    }

    this._renderChangesSubscription = this._service.getPagedIgnoreDocuments(this._language, this._pageIndex, this._pageSize, sortActive, sortDirection, this._groupId, this._userId)
    .subscribe(data => {
      this._totalItems = data.totalItems;
      this._renderData.next(data.items);
    });
  }

  removeItem(item: IgnoreDocument) {
    this._totalItems--;
    this._renderData.next(this._renderData.value.filter(x => x !== item));
  }

  update() {
    this._updateRenderDataSubscription();
  }

  connect() {
    return this._renderData;
  }

  disconnect() {
    if (this._sortChangeSubscription) {
      this._sortChangeSubscription.unsubscribe();
    }
    if (this._renderChangesSubscription) {
      this._renderChangesSubscription.unsubscribe();
    }
  }
}