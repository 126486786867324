import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleAccountsLoaderService {
  private _notifier = new BehaviorSubject<boolean>(false);

  private _complete = false;
  private _loading = false;

  constructor() {
    this.getAccounts();
  }

  public async getAccounts(): Promise<typeof google.accounts> {
    // ensure that internal has loaded
    await this._loadScript();

    return google.accounts;
  }

  private async _loadScript(): Promise<void> {
    if (this._complete) {
      return;
    }

    if (!this._loading) {
      this._loading = true;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this._loading = false;
        this._complete = true;
        this._notifier.complete();
      };
      script.onerror = (e) => {
        const evt = e as any as ErrorEvent;

        this._loading = false;
        this._notifier.error(evt.error);
      };
      (document.head || document.body || document.documentElement)
        .appendChild(script);
    }

    return new Promise<void>((resolve, reject) => {
      this._notifier
        .subscribe(
          undefined,
          (err) => reject(err),
          () => resolve()
        );
    });
  }
}
