<h1 mat-dialog-title i18n="@@UnsubsribeGlossary_Header">Unsubscribe glossary</h1>
<mat-dialog-content>
  <div class="description" *ngIf="!group" i18n="@@UnsubsribeGlossary_Info">You're about to unsubscribe from the glossary "{{ glossary.name }}"</div>
  <div class="description" *ngIf="group" i18n="@@UnsubsribeGlossary_InfoGroup">You're about to unsubscribe the group from the glossary "{{ glossary.name }}"</div>
  <div *ngIf="error" class="error">
    <span i18n="@@UnsubsribeGlossary_UnexpectedError">An unexpected error occurred.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button" i18n="@@UnsubsribeGlossary_btnCancel">Cancel</button>
  <button mat-raised-button color="primary" (click)="unsubscribe()" [disabled]="disabled" i18n="@@UnsubsribeGlossary_btnUnsubscribe">Unsubscribe</button>
</mat-dialog-actions>