<div class="desktop">
  <div class="user-info">
    <span i18n="@@UserInfo_YourAccountAndProducts">Your account and products:</span>
    <span>
      <a routerLink="/account">
        <span class="user__label">{{ name }}</span>
        <mat-icon mat-list-icon svgIcon="app:settings" class="user__icon"></mat-icon>
      </a>
    </span>
  </div>
  <a class="logout" routerLink="/logout">
    <div class="logout__wrapper">
      <mat-icon mat-list-icon class="logout__icon" svgIcon="app:logout"></mat-icon>
      <span class="logout__label" i18n="@@UserInfo_Logout">Logout</span>
    </div>
  </a>
</div>
<div class="mobile">
  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon class="mobile-more" mat-list-icon svgIcon="mobile:more"></mat-icon>
  </button>
  <mat-menu #appMenu="matMenu">
    <div class="user-info">
      <span i18n="@@UserInfo_YourAccountAndProducts">Your account and products:</span>
      <span class="name">{{ name }}</span>
    </div>
    <mat-nav-list>
      <a mat-list-item routerLink="/account">
        <mat-icon mat-list-icon svgIcon="app:settings"></mat-icon>
        <span mat-line i18n="@@UserInfo_YourAccount">Your account</span>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="/logout" class="logout-menu-item">
        <mat-icon mat-list-icon svgIcon="app:logout"></mat-icon>
        <span mat-line i18n="@@UserInfo_Logout">Logout</span>
      </a>
    </mat-nav-list>
  </mat-menu>
</div>