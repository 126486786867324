import { MediaQueryService } from './../../services/media-query.service';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { detectIE } from '../../utils/utils';
import { Group, GroupRole, GroupMember } from '../../models/groups';
import { NavigationLink } from '../../models/navigation';
import { GroupEditMemberDialogComponent } from '../group-edit-member-dialog/group-edit-member-dialog.component';
import { GroupRemoveMemberDialogComponent } from '../group-remove-member-dialog/group-remove-member-dialog.component';

interface IRouteData {
  group: Group;
  members: GroupMember[];
  areas: NavigationLink[];
}

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {
  public group?: Group;
  public members: GroupMember[] = [];
  public links: NavigationLink[] = [];
  public isPhone: boolean = false;

  private routeSubscription?: Subscription;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private _zone: NgZone,
    private _mediaQueryService: MediaQueryService
  ) {}
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');
    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.group = data.group;
      this.members = data.members;

      this.links = data.areas.map(link => {
        const language = window.localStorage.getItem('language.statistics');

        if (language && link.href) {
          const regex = /\/stats\/([a-zA-Z\-\_]+)/;
          if (regex.test(link.href)) {
            link.href = link.href.replace(regex, '\/stats\/' + encodeURIComponent(language));
          }
        }

        return link;
      });
    });
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
  }

  editMember(member: GroupMember) {
    const ref = this.dialog.open(GroupEditMemberDialogComponent, {
      data: member,
      width: '750px'
    });

    ref.componentInstance.onUpdated.subscribe(
      (updatedMember: GroupMember) => {
          if(detectIE()){
            // The splice method does not seem to work for IE. Reload the whole page if IE, until we find a better solution
            window.location.reload(); 
          }
          else{  
            const index = this.members.indexOf(member);
            if (index === -1) return;
            this.members.splice(index, 1, updatedMember);
          }
      }
    );
  }

  removeMember(member: GroupMember) {
    const ref = this.dialog.open(GroupRemoveMemberDialogComponent, {
      data: member,
      width: '750px'
    });

    ref.componentInstance.onDeleted.subscribe(
      (member: GroupMember) => {
          if(detectIE()){
            // The splice method does not seem to work for IE. Reload the whole page if IE, until we find a better solution
            window.location.reload(); 
          }
          else{  
            const index = this.members.indexOf(member);
            if (index === -1) return;

            this.members.splice(index, 1);
          }
      }
    );
  }

  transformJoinTokenToUrl(joinToken?: string): string {
    if (!joinToken)
      return window.location.href;
    const absoluteUrl: string = window.location.href;
    const relativeUrl: string = this.router.url;

    const index = absoluteUrl.indexOf(relativeUrl);
    if (index === -1) {
       console.warn("Relative and absolute urls doesn't match");
      return window.location.href;
    }

    const baseUrl = absoluteUrl.substring(0, index);

    return baseUrl + "/groups/join/" + encodeURIComponent(joinToken);
  }

  isRoleMember(role: GroupRole): boolean {
    return GroupRole.MEMBER === role;
  }

  isRoleAdministrator(role: GroupRole): boolean {
    return GroupRole.ADMINISTRATOR === role;
  }

  isRoleEditor(role: GroupRole): boolean {
    return GroupRole.EDITOR === role;
  }

  isRoleOwner(role: GroupRole): boolean {
    return GroupRole.OWNER === role;
  }
}
