import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject ,  Subscription } from 'rxjs';

import { GroupMember, GroupRoleName, GroupRole } from '../../models/groups';
import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

@Component({
  selector: 'app-group-edit-member-dialog',
  templateUrl: './group-edit-member-dialog.component.html',
  styleUrls: ['./group-edit-member-dialog.component.scss']
})
export class GroupEditMemberDialogComponent implements OnInit, OnDestroy {
  public onUpdated: Subject<GroupMember> = new Subject();
  private rolesSubscription?: Subscription;
  private subscription?: Subscription;

  public form: FormGroup;
  public roles: GroupRoleName[] = [];

  public GroupRole = GroupRole;

  public unexpectedError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GroupEditMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public member: GroupMember,
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) {
    this.form = new FormGroup({
      'role': new FormControl(this.member.role),
      'includeInStats': new FormControl(this.member.permissions.includeInStatistics)
    });
  }

  ngOnInit() {
    this.rolesSubscription = this.groupsService.getRoles()
      .subscribe((roles) => {
        this.roles = roles;
      });
  }

  ngOnDestroy() {
    if (this.rolesSubscription) {
      this.rolesSubscription.unsubscribe();
      this.rolesSubscription = undefined;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  editMember() {
    if (this.form.disabled) return;
    this.form.disable();
    this.unexpectedError = false;

    this.member.role = this.form.get('role')!.value;
    this.member.permissions.includeInStatistics = this.form.get('includeInStats')!.value

    this.subscription = this.groupsService.updateMember(this.member)
     .subscribe((groupMember: GroupMember) => {
        this.onUpdated.next(groupMember);
      }, (err) => {
        console.error(err);
        this.unexpectedError = true;
        this.form.enable();
      }, () => {
        this.onUpdated.complete();
        this.dialogRef.close();
      });
  }
}
