export class ErrorReporting {
  public constructor(
    public language: string,

    public value: string,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}

  clone(): ErrorReporting {
    return new ErrorReporting(this.language, this.value, this.groupId, this.impersonatedUserId);
  }
}