import { IChartOrigin } from './../../models/stats';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-data-origin-activity-dialog',
  templateUrl: './data-origin-activity-dialog.component.html',
  styleUrls: ['./data-origin-activity-dialog.component.scss']
})
export class DataOriginActivityDialogComponent {
  public title: string;
  public info: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IChartOrigin
  ) {
    this.title = data.title;
    this.info = data.info;
  }
}
