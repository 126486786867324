import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collection, CollectionService } from './collection.service';
import { ITemplate } from '../models/template';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  constructor(private http: CollectionService) {}

  getTemplates(language: string): Observable<Record<string, Record<string, ITemplate[]>>> {
    return this.http.getCollection(`/profile/templates/${language}`)
      .pipe<ITemplate[], Record<string, Record<string, ITemplate[]>>>(
        map(collection => {
          return collection.items.map(i => i.data.reduce((acc, value) => {
            return {...acc, ...{[value.name]: value.value}}
          }, {} as ITemplate))
        }),
        map((templateList: ITemplate[])  => {
          const categories = Array.from(new Set(templateList.map(item => item.category))) ;
          const templates: Record<string, Record<string, ITemplate[]>> = {};
          categories.map(category => {
            return templates[category] = {templates: templateList.filter(item => item.category === category)}
          })
          return templates
        })
      )
  }

  getTemplatesLanguages(): Observable<Language[]> {
    return  this.http.getCollection('/profile/templates/supportedlanguages')
      .pipe<Language[]>(
        map<Collection, Language[]>(collection => {
          return collection.items.map(item => item.data.reduce((acc, data) => {
            return {...acc, ...{[data.name]: data.value}}
          }, {} as Language))
        })
      )
  }
}
