
import {concat as observableConcat,  Observable ,  Subscription ,  Subject } from 'rxjs';

import {map} from 'rxjs/operators';
import { MediaQueryService } from './../../services/media-query.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VirtualScrollComponent } from './../virtual-scroll/virtual-scroll.component';
import { GlossaryWordList } from './../../models/glossary-word-list';
import {
  Component, ChangeDetectorRef, Inject, OnInit, OnDestroy, ElementRef,
  ViewChildren, QueryList, OnChanges, ViewChild, NgZone
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router, ActivatedRoute } from '@angular/router';

import { IGlossary, Word } from '../../models/glossary';
import { Language } from '../../models/language';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';





import { GlossaryEditConfirmDialogComponent } from '../glossary-edit-confirm-dialog/glossary-edit-confirm-dialog.component';
import { CollectionService } from '../../services/collection.service';
import { LingappsError } from '../../services/auth.service';

export interface IDialogData {
  glossary: IGlossary,
  languages: Language[]
}

@Component({
  selector: 'app-glossary-edit-dialog',
  templateUrl: './glossary-edit-dialog.component.html',
  styleUrls: ['./glossary-edit-dialog.component.scss']
})
export class GlossaryEditDialogComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(VirtualScrollComponent) private virtualScrolls?: QueryList<VirtualScrollComponent>;

  @ViewChild('horizontalStepper') private _horizontalStepper?: MatStepper;
  @ViewChild('verticalStepper') private _verticalStepper?: MatStepper;
  private _oldHorizontalStepper?: MatStepper;
  private _oldVerticalStepper?: MatStepper;

  public languages: Language[];
  public glossary: IGlossary;

  public metadataForm: FormGroup = new FormGroup({
    'name': new FormControl('', Validators.required),
    'language': new FormControl('', Validators.required),
    'description': new FormControl(''),
    'public': new FormControl(false)
  });

  public text?: string;

  public disabled: boolean = false;

  public wordList: GlossaryWordList = new GlossaryWordList();

  public wordsLoading: boolean = false;
  public isLoading: boolean = false;
  public unexpectedParseError: boolean = false;
  public unexpectedError: boolean = false;
  public errorMessage?: string;

  public onUpdated?: Subject<IGlossary> = new Subject();
  private wordsSubscription?: Subscription;

  public tmpAddedText: number = -1;

  public isPhone: boolean = false;
  private _stepperSelectedIndex: number = 0;

  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    public dialogRef: MatDialogRef<GlossaryEditDialogComponent>,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private _zone: NgZone,
    private _mediaQueryService: MediaQueryService,
    @Inject(MAT_DIALOG_DATA) data: IDialogData,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService,
    private _dialog: MatDialog,
    private _collectionService: CollectionService
  ) {
    this.languages = data.languages;
    this.glossary = data.glossary;

    this.metadataForm.setValue({
      'name': this.glossary.name,
      'language': this.glossary.language,
      'description': this.glossary.description,
      'public': this.glossary.isPublic
    });
  }

  get name(): string {
    return this.metadataForm.get('name')!.value;
  }

  get language(): string {
    return this.metadataForm.get('language')!.value;
  }

  get description(): string {
    return this.metadataForm.get('description')!.value;
  }

  isPublic(): boolean {
    return this.metadataForm.get('public')!.value;
  }

  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this.unexpectedError = false;
    this.isLoading = true;
    this.wordsLoading = true;
    this.wordsSubscription = this.glossaryService.getWordsByGlossary(this.glossary)
      .subscribe((words: Word[]) => {
        this.wordList.setSavedWords(words);

        this.isLoading = false;
        this.wordsLoading = false;
        this.changeDetectionRef.detectChanges();
      }, (err) =>{
        console.log(err);
        this.unexpectedError = true;
        this.isLoading = false;
        this.wordsLoading = false;
        this.changeDetectionRef.detectChanges();
      }, () =>{
        this.isLoading = false;
        this.wordsLoading = false;
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);

    if (this.wordsSubscription) {
      this.wordsSubscription.unsubscribe();
      this.wordsSubscription = undefined;
    }
    if (this.onUpdated) {
      this.onUpdated.unsubscribe();
      this.onUpdated = undefined;
    }
  }

  ngOnChanges() {
    this.resize();

    if (this._horizontalStepper !== this._oldHorizontalStepper) {
      this._oldHorizontalStepper = this._horizontalStepper;
      if (this._horizontalStepper) {
        this.onStepperChange(this._stepperSelectedIndex);
      }
    }
    if (this._verticalStepper !== this._oldVerticalStepper) {
      this._oldVerticalStepper = this._verticalStepper;
      if (this._verticalStepper) {
        this.onStepperChange(this._stepperSelectedIndex);
      }
    }
  }

  onStepperChange(selectedIndex: number, updateSteppers: boolean = true) {
    this._stepperSelectedIndex = selectedIndex;

    /*if (updateSteppers) {
      if (this._horizontalStepper) {
        if (this._horizontalStepper.selectedIndex !== this._stepperSelectedIndex) {
          this._horizontalStepper.selectedIndex = this._stepperSelectedIndex;
        }
      }

      if (this._verticalStepper) {
        if (this._verticalStepper.selectedIndex !== this._stepperSelectedIndex) {
          this._verticalStepper.selectedIndex = this._stepperSelectedIndex;
        }
      }
    }*/

    setTimeout(() => {
      this.resize();
    }, 7);
  }

  onAddNext() {
    if (this.text) {
      this.isLoading = true;
      this.addWords().subscribe(()  =>{
        this.isLoading = false;
        this.changeDetectionRef.detectChanges();
      }, (err) => {
        console.error(err);
        this.isLoading = false;
        this.unexpectedParseError = true;
        this.changeDetectionRef.detectChanges();
      }, () =>{
        this.isLoading = false;
        this.changeDetectionRef.detectChanges();
      });
    } else {
      if (this._horizontalStepper) {
        this._horizontalStepper.next();
      }
      if (this._verticalStepper) {
        this._verticalStepper.next();
      }
    }
  }

  addText(): void {
    this.addWords().subscribe(undefined, (err: any) => {
      console.error(err);
      this.isLoading = false;
      this.unexpectedParseError = true;
      this.changeDetectionRef.detectChanges();
    }, () => {
      this.isLoading = false;
      this.changeDetectionRef.detectChanges();
    });
  }


  addWords(text?: string): Observable<Word[]> {
    this.isLoading = true;
    this.unexpectedParseError = false;
    return this.glossaryService
      .parseTextToWordsForGlossary(this.glossary, text || this.text || "").pipe(
      map((words: Word[]) => {
        this.tmpAddedText = this.wordList.addWords(words);
        if (this.tmpAddedText === 0 && this.wordList.bufferedExactWordsCount > 0) {
          this.tmpAddedText = -1;
        }

        this.isLoading = false;
        this.text = "";

        this.changeDetectionRef.detectChanges();

        return this.wordList.words;
      }));
  }

  removeWord(word: Word): void {
    this.wordList.removeWord(word);
  }

  clearRemovedWords(): void {
    if (this.disabled) return;

    this.wordList.clearRemovedWords();
  }

  clearAddedWords(): void {
    if (this.disabled) return;

    this.wordList.clearAddedWords();
  }

  removeThreeStarWords(): void{
    if (this.disabled) return;
    this.wordList.removeExactMatches();
  };

  removeTwoStarWords(): void{
    if (this.disabled) return;
    this.wordList.removeCaseInsensitiveDupliactesInDefault();
  };

  removeOneStarWords(): void{
    if (this.disabled) return;
    this.wordList.removeCaseInsensitiveDuplicates();
  };

  resize() {
    if (!this.virtualScrolls) return;
    const list = this.virtualScrolls.toArray();
    for (let i = 0; i < list.length; i++) {
      list[i].resize();
    }
  }

  keepExactMatches(): void {
    this.wordList.keepBufferedExactMatchWords();
  };

  submit(): void {
    if (this.disabled) return;
    this.disabled = true;
    this.errorMessage = undefined;

    const next = () => {
      // Remove text
      let removeText = this.glossaryService.removeWordsFromGlossary(
        this.glossary, this.wordList.removedWords);

      // Add text
      let addText = this.glossaryService.addWordsToGlossary(
        this.glossary, this.wordList.addedWords);
      const newGlossaryState = {
        name: this.name,
        language: this.language,
        description: this.description,
        isPublic: this.isPublic()
      };

      let tasks = [];
      tasks.push(this.glossaryService.updateGlossary(this.glossary, newGlossaryState));
      if (this.wordList.removedWordsCount > 0) {
        tasks.push(removeText);
      }
      if (this.wordList.addedWordsCount > 0) {
        tasks.push(addText);
      }

      observableConcat(...tasks)
        .subscribe(undefined,
          (err: LingappsError) => {
            this.errorMessage = err.message;

            this.disabled = false;
            this.unexpectedError = !this.errorMessage;
          }, () => {
            // Update glossary state
            this.glossary.name = newGlossaryState.name;
            this.glossary.language = newGlossaryState.language;
            this.glossary.description = newGlossaryState.description;
            this.glossary.isPublic = newGlossaryState.isPublic;

            if (this.onUpdated) {
              this.onUpdated.next(this.glossary);
              this.onUpdated.complete();
            }
            this.dialogRef.close();
            this.changeDetectionRef.detectChanges();
          }
        );
    };

    if (this.glossary.isPublic && !this.isPublic()) {
      const ref = this._dialog.open(GlossaryEditConfirmDialogComponent, {
        width: '750px'
      });
      const sub = ref.beforeClosed().subscribe(() => {
        sub.unsubscribe();

        if (ref.componentInstance.update) {
          next();
        } else {
          this.disabled = false;
        }
      });
    } else {
      next();
    }
  }
}
