
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { IBreadcrumb, IBreadcrumbObject, BreadcrumbService } from "../../services/breadcrumb.service";


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadcrumb[];
  public currentPage?: IBreadcrumb;
  public unknownBreadcrumbs: IBreadcrumb[] = [];
  
  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbs = [];
    this.currentPage = {
      label: "",
      url: "",
      query: {},
      unknown: true
    };
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => this.updateBreadcrumbs());
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs(): void {
    let breadcrumbObject: IBreadcrumbObject = this.breadcrumbService.getBreadcrumbs();
    this.currentPage = breadcrumbObject.currentPage;
    this.breadcrumbs = breadcrumbObject.breadcrumbs;

    const breadcrumbs: IBreadcrumb[] = [];
    for (let i = 0; i < this.breadcrumbs.length; i++) {
      if (this.breadcrumbs[i].unknown) {
        breadcrumbs.push(this.breadcrumbs[i]);
      }
    }
    if (this.currentPage && this.currentPage.unknown) {
      breadcrumbs.push(this.currentPage);
    }

    this.unknownBreadcrumbs = breadcrumbs;
  }

  public getUnknownKeyIndex(breadcrumb?: IBreadcrumb): number {
    if (!breadcrumb) return -1;
    return this.unknownBreadcrumbs.indexOf(breadcrumb);
  }
}