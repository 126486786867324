import { Language } from './../../models/language';
import { Component, OnInit, ViewChild, NgZone, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MediaQueryService } from '../../services/media-query.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { ReportDocumentSearchDataSource } from '../../models/txtanalyser/ReportDocumentSearchDataSource';

interface IRouteData {
  languages: Language[];
}

@Component({
  selector: 'app-txtanalyser-upload-documents',
  templateUrl: './txtanalyser-upload-documents.component.html',
  styleUrls: ['./txtanalyser-upload-documents.component.scss']
})
export class TxtAnalyserUploadDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  public displayedColumns: string[] = ["name", "language", "mostCommonErrorType", "status", "created"];
  public languages: Language[] = [];
  public isPhone: boolean = false;

  public groupId?: number;
  public userId?: number;

  public dataSource?: ReportDocumentSearchDataSource;

  @ViewChild(MatSort, { static: true }) private _sort?: MatSort;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _routeSubscription: Subscription|undefined = undefined;
  
  constructor(
    private _route: ActivatedRoute,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    @Inject(ITxtAnalyserServiceToken) private service: ITxtAnalyserService,
  ) { }

  public ngOnInit() {
    if (this._route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this._route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this._route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this._route.snapshot.paramMap.get('userId')!, 10);
    }
    this.dataSource = new ReportDocumentSearchDataSource(this.service, this.groupId, this.userId);

    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this._routeSubscription = this._route.data.subscribe(value => {
      const data = value as IRouteData;
      this.languages = data.languages;
    });
  }

  public ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this._sort;
    }
  }

  public ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
  }

  public getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }
}
