import { ILocalization } from "app/interfaces/ILocalization";

export class Localization_enGB implements ILocalization {
  public getPageXOfY(x: number, y: number) {
    return `Page ${x} of ${y}`;
  }

  public getItemsPerPage() {
    return "Items per page:";
  }

  public getNextPage() {
    return "Next page";
  }

  public getPreviousPage() {
    return "Previous page";
  }

  public getErrorType() {
    return "Category";
  }

  public getPercentage() {
    return "Percentage";
  }

  public getAmount() {
    return "Amount";
  }
}