import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[autoGrow]' })

export class AutoGrowDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.expand();
  }
  
  @Input('maxRows') maxRows: number = 99999;
  @Input('minRows') minRows: number = 1;

  @HostListener('keypress') onKeypress() {
    // We probably don't need keypress, but why not :)
    this.expand();
  }

  @HostListener('keyup') onKeyup() {
    this.expand();
  }

  @HostListener('keydown') onKeydown() {
    this.expand();
  }

  @HostListener('paste') onPaste() {
    this.expand();
  }

  expand(): void {
    const text: string = this.el.nativeElement.value;
    let lines: number = 1 + (text.match(/\n/g) || []).length;

    if (lines < this.minRows)
      lines = 1;
    if (lines > this.maxRows)
      lines = this.maxRows;

    this.el.nativeElement.rows = lines.toString();
  }
}