
import {catchError} from 'rxjs/operators';
import { ErrorSnackbarComponent } from './../components/error-snackbar/error-snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, LingappsError } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
    private _snackbar: MatSnackBar
  ) {}

  private _errorHandler(auth: AuthService, err: HttpErrorResponse): HttpErrorResponse|LingappsError {
    let errorCode = '';
    switch (err.status) {
      case 0: {
        errorCode = '0';
        /**auth.logout()
        .subscribe(() => {}, (err) => {
          console.error(err);
        });
        auth.redirectToLogin(location.pathname); */
        break;
      }
      case 401: {
        if (auth.isAuthenticated()) {
          errorCode = '401_authenticated';
        } else {
          errorCode = '401';
        }
        auth.logout()
        .subscribe(() => {}, (err) => {
          console.error(err);
        });
        auth.redirectToLogin(location.pathname);
        break;
      }
      case 400:
      case 403:
      case 409:
        break;
      default:
        errorCode = err.status.toString();
        break;
    }
    if (errorCode) {
      this._snackbar.openFromComponent(ErrorSnackbarComponent, {
        data: errorCode,
      });
    }

    let json = undefined;
    try {
      json = JSON.parse(err.error);
    } catch (err) {
      console.error(err);
    }
    if (json && json["error"] && json["error"]["code"]) {
      return new LingappsError(json["error"]["code"], json["error"]["message"]);
    } else {
      return err;
    }
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this._injector.get(AuthService);
    if (!req.url.startsWith(environment.collectionUrl)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
    catchError((err: any, caught: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> => {
      if (err instanceof HttpErrorResponse) {
        if (req.url.startsWith(environment.collectionUrl)) {
          throw this._errorHandler(auth, err);
        }
      }
      throw err;
    }));
  }
}