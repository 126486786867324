import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription ,  Subject } from 'rxjs';

import { CustomRule } from '../../models/spelling';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../../interfaces/ispelling.service.token';

@Component({
  selector: 'app-phonetic-spelling-delete-dialog',
  templateUrl: './phonetic-spelling-delete-dialog.component.html',
  styleUrls: ['./phonetic-spelling-delete-dialog.component.scss']
})
export class PhoneticSpellingDeleteDialogComponent implements OnDestroy {
  private subscription?: Subscription;
  public loading: boolean = false;
  public error: any;

  public onDelete?: Subject<CustomRule> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public rule: CustomRule,
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService,
    private dialogRef: MatDialogRef<PhoneticSpellingDeleteDialogComponent>
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.onDelete) {
      this.onDelete.unsubscribe();
      this.onDelete = undefined;
    }
  }
  
  delete() {
    if (this.loading) return;
    this.error = undefined;
    this.loading = true;

    this.subscription = this.spellingService.deleteCustomRule(this.rule)
      .subscribe(undefined, (err) => {
        console.error(err);

        this.error = err;
        this.loading = false;
      }, () => {
        if (this.onDelete) {
          this.onDelete.next(this.rule);
          this.onDelete.complete();
        }
        this.dialogRef.close();
      });
  }
}
