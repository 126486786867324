<div class="card" [class.error]="isError">
  <div class="card__content">
    <div>
      <i [class]="getSourceIconClassName()" *ngIf="!isError"></i>
      <mat-icon *ngIf="isError" color="warn">error</mat-icon>
      <span>{{ name }}</span>
    </div>
    <div class="remove-item">
      <button mat-icon-button color="primary" (click)="remove.emit(file)" [disabled]="disabled">
        <mat-icon aria-label="Remove file" i18n-aria-label="@@TxtAnalyser_Upload_File_Close_Aria_Label">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="card__loading" *ngIf="isLoading || isPending">
    <mat-progress-bar [mode]="isPending ? 'indeterminate' : 'determinate'" [value]="progress"></mat-progress-bar>
  </div>
</div>