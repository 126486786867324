import { ITxtAnalyserService } from './../../interfaces/itxtanalyser.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, NgZone, OnDestroy, Inject } from '@angular/core';
import { TxtAnalyserUploadDialogComponent, IData } from '../txtanalyser-upload-dialog/txtanalyser-upload-dialog.component';
import { Language } from '../../models/language';
import { Subscription, of } from 'rxjs';
import { MediaQueryService } from '../../services/media-query.service';
import { ActivatedRoute } from '@angular/router';
import { map, flatMap, delay } from 'rxjs/operators';
import { ITxtAnalyserReportJob } from 'app/models/txtanalyser/ReportJob';
import { ITxtAnalyserServiceToken } from 'app/interfaces/itxtanalyser.service.token';

interface IRouteData {
  languages: Language[];
  latestReports: ITxtAnalyserReportJob[];
}

@Component({
  selector: 'app-txtanalyser-upload',
  templateUrl: './txtanalyser-upload.component.html',
  styleUrls: ['./txtanalyser-upload.component.scss']
})
export class TxtAnalyserUploadComponent implements OnInit, OnDestroy {
  public latestReports: ITxtAnalyserReportJob[] = [];
  public languages: Language[] = [];

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  private _routeSubscription: Subscription|undefined = undefined;
  private _onReportSubscription: Subscription|undefined = undefined;
  private _onAfterCloseSubscription: Subscription|undefined = undefined;
  private _latestReportSubscription: Subscription|undefined = undefined;

  private _groupId: number | undefined;
  private _userId: number | undefined;

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    @Inject(ITxtAnalyserServiceToken) private _txtAnalyserService: ITxtAnalyserService,
  ) {}

  public ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    if (this._route.snapshot.paramMap.has('groupId')) {
      this._groupId = parseInt(this._route.snapshot.paramMap.get('groupId')!, 10);
    }

    if (this._route.snapshot.paramMap.has('userId')) {
      this._userId = parseInt(this._route.snapshot.paramMap.get('userId')!, 10);
    }

    this._routeSubscription = this._route.data.subscribe(value => {
      const data = value as IRouteData;
      this.languages = data.languages;
      this.latestReports = data.latestReports;

      this._updateLatestReport();
    });
  }

  public ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);

    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }

    if (this._onAfterCloseSubscription) {
      this._onAfterCloseSubscription.unsubscribe();
    }
    if (this._onReportSubscription) {
      this._onReportSubscription.unsubscribe();
    }
    if (this._latestReportSubscription) {
      this._latestReportSubscription.unsubscribe();
    }
  }

  public openUploadDialog() {
    const ref = this._dialog.open(TxtAnalyserUploadDialogComponent, {
      data: {
        languages: this.languages,
        groupId: this._groupId,
        userId: this._userId
      } as IData
    });
    this._onReportSubscription = ref.componentInstance.onReport.subscribe(x => {
      if (!x) return;

      this.latestReports.unshift(x);
      if (this.latestReports.length > 5) {
        this.latestReports.pop();
      }

      this._updateLatestReport();
    });

    if (this._onAfterCloseSubscription) {
      this._onAfterCloseSubscription.unsubscribe();
    }

    this._onAfterCloseSubscription = ref.afterClosed()
      .subscribe(undefined, undefined, () => {
        if (this._onReportSubscription) {
          this._onReportSubscription.unsubscribe();
        }
      });
  }

  public getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }

  private _isAllComplete(): boolean {
    for (const report of this.latestReports) {
      if (!report.completedAnalysis && !report.errorOnAnalysis) {
        return false;
      }
    }
    return true;
  }

  private _updateLatestReport() {
    if (this._isAllComplete()) return;
    if (this._latestReportSubscription) {
      this._latestReportSubscription.unsubscribe();
    }

    this._latestReportSubscription = of(this.latestReports)
      .pipe(delay(2000))
      .pipe(flatMap(x => {
        return this._txtAnalyserService.getReportPagedJobs({
          pageIndex: 0,
          pageSize: 5,
          orderByActive: "Created",
          orderByDirection: "desc"
        }, this._groupId, this._userId)
          .pipe(map(x => x.items));
      }))
      .subscribe(x => {
        this.latestReports = x;
      }, undefined, () => this._updateLatestReport());
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }
}
