<ng-template #errorTemplate>
  <div class="error" *ngIf="error" i18n="@@VirtualScroll_UnexpectedError">An unexpected error occurred.</div>
</ng-template>
<ng-template #loadingTemplate>
  Loading...
</ng-template>

<div class="view">
  <div class="shadow"></div>

  <ng-container #loadingContainerRef></ng-container>
  <ng-container #errorContainerRef></ng-container>

  <app-virtual-scroll-list
      *ngIf="!loading"
      class="content"
      [items]="items"
      [update]="update"
      [template]="itemTemplateRef">
  </app-virtual-scroll-list>
</div>
