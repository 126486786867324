import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject ,  Subscription } from 'rxjs';

import { Group } from '../../models/groups';
import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

@Component({
  selector: 'app-groups-quit-group-dialog',
  templateUrl: './groups-quit-group-dialog.component.html',
  styleUrls: ['./groups-quit-group-dialog.component.scss']
})
export class GroupsQuitGroupDialogComponent implements OnDestroy {
  private quitSubscription?: Subscription;

  public onQuit?: Subject<Group> = new Subject();

  public disabled: boolean = false;
  public unexpectedError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GroupsQuitGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public group: Group,
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) { }

  ngOnDestroy() {
    if (this.onQuit) {
      this.onQuit.unsubscribe();
      this.onQuit = undefined;
    }
    if (this.quitSubscription) {
      this.quitSubscription.unsubscribe();
      this.quitSubscription = undefined;
    }
  }

  quit() {
    if (this.disabled) return;
    this.disabled = true;
    this.unexpectedError = false;

    this.quitSubscription = this.groupsService.quitGroup(this.group)
      .subscribe(() => {
        if (this.onQuit) {
          this.onQuit.next(this.group);
        }
      }, (err) => {
        this.disabled = false;
        this.unexpectedError = true;
        console.error(err);
      }, () => {
        if (this.onQuit) {
          this.onQuit.complete();
        }
        this.dialogRef.close();
      });
  }
}
