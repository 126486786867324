import { Injectable } from '@angular/core';

@Injectable()
export class LoginErrorService {
  private _error: boolean = false;

  constructor() {}

  isUnknownError(): boolean {
    return this._error;
  }

  setUnknownError(error: boolean): void {
    this._error = error;
  }
}
