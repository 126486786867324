<ng-template #tablebody let-type="type">
  <tbody class="card">
    <tr>
      <td class="card__toggle">
        <mat-radio-button *ngIf="!loadingErrorReporting" name="errorreporting" [value]="type" [checked]="errorReporting?.value === type" (change)="onErrorReportingChange(type)"></mat-radio-button>
        <mat-spinner diameter="20" *ngIf="loadingErrorReporting"></mat-spinner>
      </td>
      <td class="card__title">
        <span (click)="config[type].expand = !config[type].expand">
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'none'" i18n="@@TxtAnalyserErrorReporting_none">No view</ng-container>
            <ng-container *ngSwitchCase="'errorOnly'" i18n="@@TxtAnalyserErrorReporting_errorOnly">Show errors without any information</ng-container>
            <ng-container *ngSwitchCase="'errorAndType'" i18n="@@TxtAnalyserErrorReporting_errorAndType">Show errors</ng-container>
            <ng-container *ngSwitchCase="'errorTypeAndCorrectionsNoReplace'" i18n="@@TxtAnalyserErrorReporting_errorTypeAndCorrectionsNoReplace">Show errrors and corrections without corrrection button</ng-container>
            <ng-container *ngSwitchCase="'errorTypeAndCorrections'" i18n="@@TxtAnalyserErrorReporting_errorTypeAndCorrections">Show errors and corrections</ng-container>
          </ng-container>
        </span>
      </td>
      <td class="card-description" *ngIf="!isPhone">
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'none'" i18n="@@TxtAnalyserErrorReporting_description_none">Shows nothing but collects statistics</ng-container>
          <ng-container *ngSwitchCase="'errorOnly'" i18n="@@TxtAnalyserErrorReporting_description_errorOnly">Shows only that there's an error but no information about the error</ng-container>
          <ng-container *ngSwitchCase="'errorAndType'" i18n="@@TxtAnalyserErrorReporting_description_errorAndType">Shows errors with information but there's not given any corrections</ng-container>
          <ng-container *ngSwitchCase="'errorTypeAndCorrectionsNoReplace'" i18n="@@TxtAnalyserErrorReporting_description_errorTypeAndCorrectionsNoReplace">Shows errors with information and suggested corrections but without correction button</ng-container>
          <ng-container *ngSwitchCase="'errorTypeAndCorrections'" i18n="@@TxtAnalyserErrorReporting_description_errorTypeAndCorrections">Shows errors with information and suggested corrections</ng-container>
        </ng-container>
      </td>
      <td class="card-actions">
        <button mat-icon-button color="primary" (click)="config[type].expand = !config[type].expand">
          <mat-icon *ngIf="config[type].expand" class="expand-icon">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="!config[type].expand" class="expand-icon">keyboard_arrow_down</mat-icon>
        </button>
      </td>
    </tr>
    <ng-container *ngIf="config[type].expand">
      <tr>
        <td class="card__second-first"></td>
        <td class="card__second-second" colspan="3">
          <div class="error-reporting-container">
            <ng-container [ngSwitch]="type">
              <img *ngSwitchCase="'none'" [src]="'/assets/ta-errorreporting-show-nothing-' + locale + '.png'">
              <img *ngSwitchCase="'errorOnly'" [src]="'/assets/ta-errorreporting-show-errors-' + locale + '.png'">
              <img *ngSwitchCase="'errorAndType'" [src]="'/assets/ta-errorreporting-show-errors-info-' + locale + '.png'">
              <img *ngSwitchCase="'errorTypeAndCorrectionsNoReplace'" [src]="'/assets/ta-errorreporting-show-errors-info-corrections-without-button-' + locale + '.png'">
              <img *ngSwitchCase="'errorTypeAndCorrections'" [src]="'/assets/ta-errorreporting-show-errors-info-corrections-' + locale + '.png'">
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody>
    <tr class="card-spacing" *ngIf="config[type].space">
      <td></td>
    </tr>
  </tbody>
</ng-template>

<app-header></app-header>

<h2 class="no-margin" i18n="@@TxtAnalyserErrorReporting_Language">Language</h2>
<mat-form-field class="language">
  <mat-select (selectionChange)="onLanguageChange()" [(ngModel)]="language">
    <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
  </mat-select>
</mat-form-field>

<h1 i18n="@@TxtAnalyserErrorReporting_View" class="subpage">View</h1>
<table>
  <thead *ngIf="!isPhone">
    <tr>
      <th></th>
      <th i18n="@@TxtAnalyserErrorReporting_View">View</th>
      <th class="header__description" i18n="@@TxtAnalyserErrorReporting_Description">Description</th>
      <th></th>
    </tr>
  </thead>
  <ng-container [ngTemplateOutlet]="tablebody" [ngTemplateOutletContext]="{type: 'errorTypeAndCorrections'}"></ng-container>
  <ng-container [ngTemplateOutlet]="tablebody" [ngTemplateOutletContext]="{type: 'errorTypeAndCorrectionsNoReplace'}"></ng-container>
  <ng-container [ngTemplateOutlet]="tablebody" [ngTemplateOutletContext]="{type: 'errorAndType'}"></ng-container>
  <ng-container [ngTemplateOutlet]="tablebody" [ngTemplateOutletContext]="{type: 'errorOnly'}"></ng-container>
  <ng-container [ngTemplateOutlet]="tablebody" [ngTemplateOutletContext]="{type: 'none'}"></ng-container>
</table>

<ng-container *ngIf="!groupId || userId">
  <h1 class="subpage" i18n="@@TxtAnalyserErrorReporting_Ignores">Ignores</h1>

  <div class="mat-elevation-z2">
    <div class="table__header">
      <div class="header__start"></div>
      <div class="header__end">
        <div>
          <span i18n="@@TxtAnalyserErrorReporting_itemsPerPage">Items per page</span>
          <mat-form-field class="header-end__page-size">
            <mat-select [(ngModel)]="dataSource!.pageSize">
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="100">100</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@TxtAnalyserErrorReporting_documents">Documents</mat-header-cell>
        <mat-cell *matCellDef="let element"><span><a [routerLink]="getDocumentLinkById(element.id)" class="document-view">{{element.name}}</a></span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="ignores">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@TxtAnalyserErrorReporting_ignores">Ignores</mat-header-cell>
        <mat-cell *matCellDef="let element"><span>{{element.ignores}}</span></mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="header-actions"></mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-actions">
            <button mat-button color="primary" [disabled]="loadingDocuments[element.id]" (click)="removeDocument(element)">
              <mat-icon>clear</mat-icon>
              <span i18n="@@TxtAnalyserErrorReporting_remove">Remove</span>
            </button>
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator
      [length]="dataSource!.totalItems"
      [pageIndex]="dataSource!.pageIndex"
      [pageSize]="dataSource!.pageSize"
      (page)="dataSource!.pageIndex = $event.pageIndex"
      [hidePageSize]="true">
    </mat-paginator>
  </div>
</ng-container>