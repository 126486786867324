import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject ,  Subscription } from 'rxjs';

import { Group } from '../../models/groups';
import { IGroupsService } from '../../interfaces/igroups.service';
import { IGroupsServiceToken } from '../../interfaces/igroups.service.token';

@Component({
  selector: 'app-groups-delete-group-dialog',
  templateUrl: './groups-delete-group-dialog.component.html',
  styleUrls: ['./groups-delete-group-dialog.component.scss']
})
export class GroupsDeleteGroupDialogComponent implements OnDestroy {
  private deleteSubscription?: Subscription;

  public onDeleted?: Subject<Group> = new Subject();

  public disabled: boolean = false;
  public unexpectedError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GroupsDeleteGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public group: Group,
    @Inject(IGroupsServiceToken) private groupsService: IGroupsService
  ) { }

  ngOnDestroy() {
    if (this.onDeleted) {
      this.onDeleted.unsubscribe();
      this.onDeleted = undefined;
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
      this.deleteSubscription = undefined;
    }
  }

  delete() {
    if (this.disabled) return;
    this.disabled = true;
    this.unexpectedError = false;

    this.deleteSubscription = this.groupsService.deleteGroup(this.group)
      .subscribe(() => {
        if (this.onDeleted) {
          this.onDeleted.next(this.group);
        }
      }, (err) => {
        this.disabled = false;
        this.unexpectedError = true;
        console.error(err);
      }, () => {
        if (this.onDeleted) {
          this.onDeleted.complete();
        }
        this.dialogRef.close();
      });
  }
}
