export class Ignore {
  public constructor(
    public id: string,
    public documentId: string,
    public language: string,

    public category: string,
    public subCategory: string,
    public text: string,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}
}