<h3 class="login-header" i18n="@@login_AltLogin">Choose login method</h3>
<mat-card>
  <mat-tab-group color="none">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>school</mat-icon>
        <span>Education</span>
      </ng-template>
      <div class="login-items-wrapper">
        <ng-container *ngTemplateOutlet="wizkidsLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="googleLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="microsoftLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="unicLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="feideLoginTemp"></ng-container>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>business_center</mat-icon>
        <span>Business</span>
      </ng-template>
      <div class="login-items-wrapper">
        <ng-container *ngTemplateOutlet="wizkidsLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="googleLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="microsoftLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="kmdNexusLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="microsoftAdfsLoginTemp"></ng-container>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>person</mat-icon>
        <span>Private</span>
      </ng-template>
      <div class="login-items-wrapper">
        <ng-container *ngTemplateOutlet="wizkidsLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="googleLoginTemp"></ng-container>
        <ng-container *ngTemplateOutlet="microsoftLoginTemp"></ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<ng-template #wizkidsLoginTemp>
  <a mat-stroked-button class="login-btn" routerLink="/login_wizkids">
    <div class="login-btn-content">
      <img src="assets/wizkids-small.png" alt="">
    </div>
  </a>
</ng-template>
<ng-template #googleLoginTemp>
  <button mat-stroked-button class="login-btn" (click)="googleLogin()">
    <div class="login-btn-content">
      <mat-icon svgIcon="login:google" class="google-login-logo-img"></mat-icon>
      <span class="google-login-label">Google</span>
    </div>
  </button>
</ng-template>
<ng-template #microsoftLoginTemp>
  <button mat-stroked-button class="login-btn login-btn-microsoft" (click)="microsoftLogin()">
    <div class="login-btn-content">
      <img src="/assets/microsoft-login.png">
    </div>
  </button>
</ng-template>
<ng-template #unicLoginTemp>
  <button mat-stroked-button class="login-btn" (click)="unicLogin()">
    <div class="login-btn-content">
      <img src="/assets/uniclogin.png">
    </div>
  </button>
</ng-template>
<ng-template #feideLoginTemp>
  <button mat-stroked-button class="login-btn login-btn-feide" (click)="feideLogin()">
    <div class="login-btn-content">
      <img src="/assets/feidelogin.png">
    </div>
  </button>
</ng-template>
<ng-template #kmdNexusLoginTemp>
  <button mat-stroked-button class="login-btn login-btn-kmd" (click)="kmdNexusLogin()">
    <div class="login-btn-content">
      <div class="login-btn-content">
        <img src="/assets/kmd-nexus-login.svg">
      </div>
    </div>
  </button>
</ng-template>
<ng-template #microsoftAdfsLoginTemp>
  <button mat-stroked-button class="login-btn login-btn-microsoft" (click)="microsoftAdfsLogin()">
    <div class="login-btn-content">
      <img src="/assets/microsoft-login.svg">
    </div>
  </button>
</ng-template>
