import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FileDownloaderService } from './../../services/file-downloader.service';
import { Subscription, Observable } from 'rxjs';
import { ITxtAnalyserServiceToken } from './../../interfaces/itxtanalyser.service.token';
import { Component, OnInit, Inject, OnDestroy, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StepControl } from '../glossary-create-dialog/glossary-create-dialog.component';
import { Language } from '../../models/language';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MediaQueryService } from '../../services/media-query.service';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { HttpClient, HttpRequest, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map, last, catchError } from 'rxjs/operators';
import { IGoogleDriveFile } from '../google-drive-picker/google-drive-picker.component';
import { IOneDriveFile } from '../onedrive-file-picker/onedrive-file-picker.component';
import { IFile, IFileState } from '../../models/txtanalyser/IFIle';
import { ITxtAnalyserReportJob } from 'app/models/txtanalyser/ReportJob';

@Component({
  selector: 'app-txtanalyser-upload-dialog',
  templateUrl: './txtanalyser-upload-dialog.component.html',
  styleUrls: ['./txtanalyser-upload-dialog.component.scss'],
  providers: [ FileDownloaderService ]
})
export class TxtAnalyserUploadDialogComponent implements OnInit, OnDestroy {
  private _horizontalStepper?: MatStepper;
  private _verticalStepper?: MatStepper;

  @ViewChild('horizontalStepper') set __content(content: MatStepper) {
    this._horizontalStepper = content;
    this.onStepperChange(content, this.selectedIndex);
  }
  @ViewChild('verticalStepper') set __content2(content: MatStepper) {
    this._verticalStepper = content;
    this.onStepperChange(content, this.selectedIndex);
  }

  public languageForm = new FormGroup({
    'language': new FormControl('', Validators.required)
  });
  private _errorTypeForm = new FormGroup({
    'loaded': new FormControl('', Validators.required)
  });
  public languageStepControl = new StepControl(this.languageForm);

  public errorTypeStepControl = new StepControl(this._errorTypeForm);
  public uploadStepControl = new StepControl();

  public languages: Language[];

  public get categoriesLoading() {
    const languageControl = this.languageForm.get('language');
    if (!languageControl) return true;
    const language = languageControl.value;
    if (!language) return true;

    if (!this._categoriesGroupedByLanguage[language]) return true;

    return this._categoriesGroupedByLanguage[language].loading;
  }

  public get categories() {
    const languageControl = this.languageForm.get('language');
    if (!languageControl) return [];
    const language = languageControl.value;
    if (!language) return [];

    if (!this._categoriesGroupedByLanguage[language]) return [];

    return this._categoriesGroupedByLanguage[language].categories;
  }

  private _categoriesGroupedByLanguage: {[key: string]: { categories: Category[], loading: boolean }} = {};

  public selectedIndex = 0;
  public disabled = false;
  public isLoading = false;
  public uploading = false;

  public get files(): IFile[] {
    return this._downloadService.getFiles();
  }

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _onLanguageChangeSubscription: Subscription | undefined;
  private _onFileCompleteSubscription: Subscription | undefined;
  private _uploadSubscription: Subscription | undefined;

  private _groupId: number | undefined;
  private _userId: number | undefined;

  public uploadError = false;
  public onReport = new BehaviorSubject<ITxtAnalyserReportJob | undefined>(undefined);

  constructor(
    private _dialogRef: MatDialogRef<TxtAnalyserUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IData,
    private _zone: NgZone,
    private _mediaQueryService: MediaQueryService,
    @Inject(ITxtAnalyserServiceToken) private _textAnalyzerService: ITxtAnalyserService,
    private _http: HttpClient,
    private _downloadService: FileDownloaderService
  ) {
    this.languages = data.languages;
    this._groupId = data.groupId;
    this._userId = data.userId;
  }

  public onRemoveUploadFile(file: IFile | undefined) {
    if (!file) return;

    this._downloadService.removeFile(file);
  }

  public uploadFiles() {
    this.languageForm.disable();

    this.uploadError = false;
    this.isLoading = true;
    this.uploading = true;

    this._downloadService.acknowledgeErrors();

    if (this._isReadyToUpload()) {
      this._uploadToService();
    }
  }

  public ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');
    this._onLanguageChangeSubscription = this.languageForm.valueChanges.subscribe(x => {
      this._updateErrorTypes();
    });

    this._onFileCompleteSubscription = this._downloadService.onFileComplete.subscribe(x => {
      if (this.uploading && this._isReadyToUpload()) {
        this._uploadToService();
      }
    });
  }

  public ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
    if (this._onLanguageChangeSubscription) {
      this._onLanguageChangeSubscription.unsubscribe();
    }
    if (this._onFileCompleteSubscription) {
      this._onFileCompleteSubscription.unsubscribe();
    }
    if (this._uploadSubscription) {
      this._uploadSubscription.unsubscribe();
    }

    this._downloadService.removeFiles();
  }

  private _isReadyToUpload(): boolean {
    return this._downloadService.isComplete();
  }

  private _uploadToService() {
    const language = this.languageForm.get('language')!.value;
    const disabledErrorTypes = this._categoriesGroupedByLanguage[language]
      .categories
      .map(x => x.subCategories)
      .reduce((x, y) => [...x, ...y])
      .filter(x => !x.enabled)
      .map(x => x.id);

    const results = this._downloadService.getCompletedFiles();

    this._uploadSubscription = this._textAnalyzerService.uploadReportDocuments(
      language,
      disabledErrorTypes,
      results.map(x => ({
        name: x.file.name,
        mimeType: x.file.mimeType,
        content: x.result
      })),
      this._groupId,
      this._userId
    )
    .subscribe(
      x => {
        this.onReport.next(x);
      },
      err => {
        this.languageForm.enable();
        this.isLoading = false;
        this.uploading = false;
        console.error(err);
        this.uploadError = true;
      },
      () => {
        this.languageForm.enable();
        this.isLoading = false;
        this.uploading = false;

        this._dialogRef.close();
      }
    );
  }

  private _updateErrorTypes(): void {
    if (this.categoriesLoading) {
      this._errorTypeForm.setValue({
        "loaded": ""
      });
    } else {
      this._errorTypeForm.setValue({
        "loaded": "true"
      });
    }

    const languageControl = this.languageForm.get('language');
    if (!languageControl) return;
    const language = languageControl.value;
    if (!language) return;

    if (this._categoriesGroupedByLanguage[language]) return;
    this._categoriesGroupedByLanguage[language] = {
      loading: true,
      categories: []
    };

    this._textAnalyzerService.getErrorTypes(language)
      .subscribe(errorTypes => {
        const categories: Category[] = [];
        const categoryLabels = errorTypes
          .map(x => x.category)
          .filter((value, index, self) => self.indexOf(value) === index);
        for (const label of categoryLabels) {
          const filteredErrorTypes = errorTypes.filter(x => x.category === label);

          categories.push(new Category(label, filteredErrorTypes.map(x => ({
            id: x.id,
            name: x.subCategory,
            enabled: true
          }))));
        }

        this._categoriesGroupedByLanguage[language].categories = categories;
        this._categoriesGroupedByLanguage[language].loading = false;

        if (!this.categoriesLoading) {
          this._errorTypeForm.setValue({
            "loaded": "true"
          });
        }
      });
  }

  public onStepperChange(stepper: MatStepper, selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
    if (this.selectedIndex === 1) {
      this._updateErrorTypes();
    }

    this._zone.run(() => {
      if (stepper === this._horizontalStepper) {
        if (this._verticalStepper) {
          this._verticalStepper.selectedIndex = this.selectedIndex;
        }
      }
      if (stepper === this._verticalStepper) {
        if (this._horizontalStepper) {
          this._horizontalStepper.selectedIndex = this.selectedIndex;
        }
      }
    });
  }

  public onLocalFile(data: File) {
    if (this.uploading) return;

    this._zone.run(() => {
      this._downloadService.addFile(new LocalFile(data));
    });
  }

  public onGoogleDriveFile(data: IGoogleDriveFile) {
    if (this.uploading) return;

    this._zone.run(() => {
      this._downloadService.addFile(new GoogleFile(this._http, data.name, data.mimeType, data.fileId, data.accessToken, data.isDocument));
    });
  }

  public onOneDriveFile(data: IOneDriveFile) {
    if (this.uploading) return;

    this._zone.run(() => {
      this._downloadService.addFile(new RemoteFile(this._http, data.name, data.downloadUrl, "OneDrive"));
    });
  }

  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }
}

export interface IData {
  languages: Language[];
  groupId?: number;
  userId?: number;
}

class LocalFile implements IFile {
  public readonly source = "LocalFile";
  public name: string;
  public mimeType: string;

  public progress: number | undefined;
  public state = IFileState.Pending;
  public error: Error | undefined = undefined;

  private _file: File;

  constructor(
    file: File
  ) {
    this._file = file;

    this.name = file.name;
    this.mimeType = file.type;
  }

  public readAsArrayBuffer(): Observable<ArrayBuffer> {
    if (this.state !== IFileState.Pending)
      throw new Error("Already called once");
    this.state = IFileState.Loading;

    const reader = new FileReader();
    reader.onprogress = (ev) => {
      this.progress = ev.loaded / ev.total;
    };

    return new Observable((obs) => {
      reader.onerror = (e) => {
        e = e || window.event;
        this.state = IFileState.Error;
        const target = e.target as any as (IFileReaderErrorTarget | undefined);
        if (target) {
          this.error = new FileReaderError(target.error.code);
        } else {
          this.error = new Error("Unknown error");
        }
        obs.error(this.error);
      };
      reader.onload = () => {
        this.state = IFileState.Complete;
        obs.next(reader.result as ArrayBuffer);
        obs.complete();
      };
      reader.readAsArrayBuffer(this._file);
    });
  }
}

class FileReaderError extends Error {
  constructor(code: number) {
    super("Unknown error");

    switch (code) {
      case 1:
        this.message = "File not found"
        break;
      case 2:
        this.message = "File not accessible due to security reasons"
        break;
      case 3:
        this.message = "File read was aborted"
        break;
      case 4:
        this.message = "The file couldn't be read due to changes to permissions since the file was acquired"
        break;
      case 5:
        this.message = "The readAsDataURL() method failed because the file was too long to encode as a \"data://\" URL."
        break;
    }
  }
}

class RemoteFile implements IFile {
  public readonly source: string;
  public mimeType: string;
  public progress: number | undefined;
  public state = IFileState.Pending;
  public error = undefined;

  private _url: string;
  private _req: HttpRequest<ArrayBuffer>;

  constructor(
    private _http: HttpClient,
    public name: string,
    url: string,
    source: string
  ) {
    this._url = url;
    this.source = source;

    const ext = RemoteFile._getExtension(name);
    const type = RemoteFile._getMimeType(ext);
    this.mimeType = type || "";

    this._req = new HttpRequest("GET", this._url, {
      reportProgress: true,
      responseType: "arraybuffer"
    });
  }

  public readAsArrayBuffer(): Observable<ArrayBuffer> {
    if (this.state !== IFileState.Pending)
      throw new Error("Already called once");

    this.state = IFileState.Loading;
    return this._http.request(this._req)
      .pipe(
        map(evt => {
          if (evt.type === HttpEventType.DownloadProgress) {
            if (evt.total !== undefined) {
              this.progress = evt.loaded / evt.total;
            } else {
              this.progress = undefined;
            }
          } else if (evt.type === HttpEventType.Response) {
            return evt.body as ArrayBuffer;
          }
        }),
        last(),
        catchError(err => {
          this.state = IFileState.Error;
          this.error = err;
          throw err;
        }),
        map(x => {
          if (x === undefined) {
            throw new Error("Didn't return a response");
          }
          this.state = IFileState.Complete;
          return x;
        })
      );
  }

  private static _getExtension(fileName: string): string {
    const index = fileName.lastIndexOf(".");
    if (index === -1) return "";

    return fileName.substring(index);
  }

  private static _getMimeType(ext: string): string | undefined {
    switch (ext) {
      case ".doc":
        return "application/msword";
      case ".docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case ".txt":
        return "text/plain";
      case ".odt":
        return "application/vnd.oasis.opendocument.text";
    }
    return undefined;
  }
}

class GoogleFile implements IFile {
  public readonly source = "GoogleDrive";

  public progress: number | undefined;
  public state = IFileState.Pending;
  public error = undefined;

  private _req: HttpRequest<ArrayBuffer>;

  constructor(
    private _http: HttpClient,
    public name: string,
    public mimeType: string,
    fileId: string,
    accessToken: string,
    exportAs: boolean = false
  ) {
    let url = "https://www.googleapis.com/drive/v3/files/" + encodeURIComponent(fileId);
    if (exportAs) {
      url += "/export?mimeType=text/plain";
      this.mimeType = "text/plain";
    } else {
      url += "?alt=media";
    }

    this._req = new HttpRequest("GET", url, {
      reportProgress: true,
      responseType: "arraybuffer",
      headers: new HttpHeaders({
        "Authorization": "Bearer " + accessToken
      })
    });
  }

  public readAsArrayBuffer(): Observable<ArrayBuffer> {
    if (this.state !== IFileState.Pending)
      throw new Error("Already called once");

    this.state = IFileState.Loading;
    return this._http.request(this._req)
      .pipe(
        map(evt => {
          if (evt.type === HttpEventType.DownloadProgress) {
            if (evt.total !== undefined) {
              this.progress = evt.loaded / evt.total;
            } else {
              this.progress = undefined;
            }
          } else if (evt.type === HttpEventType.Response) {
            return evt.body as ArrayBuffer;
          }
        }),
        last(),
        catchError(err => {
          this.state = IFileState.Error;
          this.error = err;
          throw err;
        }),
        map(x => {
          if (x === undefined) {
            throw new Error("Didn't return a response");
          }
          this.state = IFileState.Complete;
          return x;
        })
      );
  }
}

interface IFileReaderError {
  code: number;

  /**
   * The file does not exist. A strange occurence considering that the user
   * presumably selected or dragged the file from the file system. Nonetheless,
   * it's there if you need it, in the rare event that someone deletes the file
   * between the time it is acquired and the time that the FileReader attempts
   * to read it.
   */
  NOT_FOUND_ERR: 1;

  /**
   * Security restrictions prevented the script from reading the file.
   */
  SECURITY_ERR: 2;

  /**
   * The file read was aborted - typically when the user cancels.
   */
  ABORT_ERR: 3;

  /**
   * The file could not be read because of a change to permissions since the
   * file was acquired - likely because the file was locked by another program.
   */
  NOT_READABLE_ERR: 4;

  /**
   * The readAsDataURL() method failed because the file was too long to encode
   * as a "data://" URL.
   */
  ENCODING_ERR: 5;
}

interface IFileReaderErrorTarget {
  error: IFileReaderError;
}

class Category {
  public get enabled() {
    let itemsState: boolean | undefined = undefined;
    for (const item of this.subCategories) {
      if (itemsState === undefined) {
        itemsState = item.enabled;
      } else if (itemsState !== item.enabled) {
        itemsState = undefined;
        break;
      }
    }
    if (itemsState !== undefined)
      return itemsState;

    return this._enabled;
  }
  public set enabled(enabled: boolean) {
    this._enabled = enabled;
    for (const item of this.subCategories) {
      item.enabled = enabled;
    }
  }
  private _enabled = true;

  public get indeterminate() {
    let lastValue: boolean | undefined = undefined;
    for (const item of this.subCategories) {
      if (lastValue === undefined) {
        lastValue = item.enabled;
      } else if (lastValue !== item.enabled) {
        return true;
      }
    }
    return false;
  }

  public expanded = false;

  constructor(
    public name: string,
    public subCategories: ISubCategory[]
  ) {}
}

interface ISubCategory {
  id: string;
  name: string;
  enabled: boolean;
}
