import { GlossaryWordList } from './../../models/glossary-word-list';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IGlossary } from '../../models/glossary';
import { Language } from '../../models/language';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';

export interface DialogData {
  glossary: IGlossary;
  languages: Language[];
}

@Component({
  selector: 'app-glossary-view-dialog',
  templateUrl: './glossary-view-dialog.component.html',
  styleUrls: ['./glossary-view-dialog.component.scss']
})
export class GlossaryViewDialogComponent implements OnInit, OnDestroy {
  public glossary: IGlossary;
  public languages: Language[];
  public wordList: GlossaryWordList = new GlossaryWordList();
  public wordsLoading: boolean = true;

  private ratingUpdateSubscription?: Subscription;
  private wordsSubscription?: Subscription;

  public ratedRating: number = 0;
  public ratingLoading: boolean = false;

  public get unknownOwner(): boolean {
    return this.glossary.ownerDisplayName === undefined;
  }

  constructor(
    public dialogRef: MatDialogRef<GlossaryViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService
  ) {
    this.glossary = data.glossary;
    this.languages = data.languages;
  }
  
  ngOnInit() {
    this.wordsSubscription = this.glossaryService.getWordsByGlossary(this.glossary)
      .subscribe(words => {
        this.wordList.words = words;
        this.wordsLoading = false;
      });
    ;
  }

  ngOnDestroy() {
    if (this.wordsSubscription) {
      this.wordsSubscription.unsubscribe();
      this.wordsSubscription = undefined;
    }
    if (this.ratingUpdateSubscription) {
      this.ratingUpdateSubscription.unsubscribe();
      this.ratingUpdateSubscription = undefined;
    }
  }

  rate(rating: number): void {
    this.ratingLoading = true;
    this.glossary.userRating = rating;

    this.glossaryService.rateGlossary(this.glossary, rating)
      .subscribe(undefined, () => {
          this.ratingLoading = false;
        }, () => {
        this.ratingLoading = false;
      });
  }
  
  getLanguageByCode(code: string): string|undefined {
    if (!this.languages) return undefined;
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }
}
