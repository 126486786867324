import { IGoogleDrivePickerConfigurationService, IGoogleDrivePickerConfiguration } from './../../models/IGoogleDrivePickerConfiguration';
import { Component, OnDestroy, EventEmitter, Output, Inject, NgZone, Input } from '@angular/core';
import { GoogleDrivePickerLoaderService } from '../../services/google-drive-picker-loader.service';
import { GoogleAccountsLoaderService } from 'app/services/google-accounts-loader.service';

const SCOPE = [ 'https://www.googleapis.com/auth/drive' ];

@Component({
  selector: 'app-google-drive-picker',
  templateUrl: './google-drive-picker.component.html',
  styleUrls: ['./google-drive-picker.component.scss']
})
export class GoogleDrivePickerComponent implements OnDestroy {
  private _accessToken: string | null = null;
  private _picker?: google.picker.Picker;

  @Input()
  public disabled = false;

  @Output()
  public onFile = new EventEmitter<IGoogleDriveFile>();

  constructor(
    @Inject(IGoogleDrivePickerConfigurationService) private _config: IGoogleDrivePickerConfiguration,
    public picker: GoogleDrivePickerLoaderService,
    public accounts: GoogleAccountsLoaderService,
    private _zone: NgZone
  ) { }

  public ngOnDestroy() {
    if (this._picker) {
      this._picker.setVisible(false);
    }
  }

  public async onOpen() {
    if (this._picker) {
      this._picker.setVisible(true);
    }

    const gp = await this.picker.getGooglePicker();

    if (this._accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share
      // their data when establishing a new session.
      this._accessToken = await this._requestAccessToken({ "prompt": "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing
      // session.
      this._accessToken = await this._requestAccessToken({ "prompt": "" });
    }

    this._picker = new gp.PickerBuilder()
      .enableFeature(gp.Feature.MULTISELECT_ENABLED)
      .enableFeature(gp.Feature.SUPPORT_TEAM_DRIVES)
      .setAppId(this._config.appId)
      .setOAuthToken(this._accessToken)
      .addView(gp.ViewId.DOCUMENTS)
      .setDeveloperKey(this._config.developerKey)
      .setCallback((data: any) => this._onPickerCallback(data))
      .build();
    this._picker.setVisible(true);
  }

  private _onPickerCallback(data: any) {
    if (data[google.picker.Response.ACTION] !== google.picker.Action.PICKED) return;

    this._zone.run(() => {
      const documents = data[google.picker.Response.DOCUMENTS];
      for (const document of documents) {
        const fileId = document[google.picker.Document.ID];
        const name = document[google.picker.Document.NAME];
        const isDocument = document[google.picker.Document.TYPE] === "document";
        const mimeType = document[google.picker.Document.MIME_TYPE];
        const url = document[google.picker.Document.URL];
        const accessToken = this._accessToken!;

        this.onFile.emit({
          fileId,
          name,
          mimeType,
          isDocument,
          url,
          accessToken
        });
      }
    });

    if (this._picker) {
      this._picker.setVisible(false);
    }
  }

  private async _requestAccessToken(config?: google.accounts.oauth2.OverridableTokenClientConfig): Promise<string> {
    const accounts = await this.accounts.getAccounts();

    const token = await new Promise<string>((resolve, reject) => {
      const client = accounts.oauth2.initTokenClient({
        "client_id": this._config.clientId,
        "scope": SCOPE.join(" "),
        "callback": (response) => {
          if (response.error) {
            reject(new Error(response.error));
          } else {
            resolve(response.access_token);
          }
        }
      });
      client.requestAccessToken(config);
    });

    if (!token) {
      throw new Error("Token was not returned");
    }

    return token;
  }
}

export interface IGoogleDriveFile {
  fileId: string;
  name: string;
  mimeType: string;
  isDocument: boolean;
  url: string;
  accessToken: string;
}