import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ITxtAnalyserService } from '../interfaces/itxtanalyser.service';
import { ITxtAnalyserServiceToken } from '../interfaces/itxtanalyser.service.token';

import { Observable } from 'rxjs';
import { ITxtAnalyserReportJob } from 'app/models/txtanalyser/ReportJob';

@Injectable()
export class TxtAnalyserReportJobResolver implements Resolve<ITxtAnalyserReportJob> {
  constructor(
    @Inject(ITxtAnalyserServiceToken) private txtAnalyserService: ITxtAnalyserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITxtAnalyserReportJob> {
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    if (!route.paramMap.has('jobId')) {
      throw new Error("No job ID in URL");
    }

    let jobId = route.paramMap.get('jobId')!;

    return this.txtAnalyserService.getReportJob(jobId, groupId, userId);
  }
}