import { Component, Input } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  @Input()
  public key: string = "";

  @Input()
  public data: string[] = [];

  @Input()
  public keyIndex: number = -1;
  
  constructor(
    public breadcrumbService: BreadcrumbService
  ) {}
  
  public get _(): string {
    return this.key;
  }

  public get keys(): {[key: string]: string} {
    return this.breadcrumbService.staticKeys;
  }

  public get label(): string {
    const labels = this.breadcrumbService.getLabels();
    if (this.keyIndex >= 0 && labels && this.keyIndex < labels.length) {
      return labels[this.keyIndex];
    }

    return this.key;
  }
}
