import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleDrivePickerLoaderService {
  private _scriptNotifier = new BehaviorSubject<boolean>(false);
  private _pickerNotifier = new BehaviorSubject<boolean>(false);

  private _scriptLoading = false;
  private _scriptComplete = false;

  private _pickerLoading = false;
  private _pickerComplete = false;

  constructor() {
    this.getGooglePicker();
  }

  public async getGooglePicker(): Promise<typeof google.picker> {
    // ensure that internal has loaded
    await this._loadScript();
    await this._loadPicker();

    return google.picker;
  }

  private async _loadPicker(): Promise<void> {
    if (this._pickerComplete) {
      return;
    }

    if (!this._pickerLoading) {
      gapi.load('picker', {
        'callback': () => {
          this._pickerLoading = false;
          this._pickerComplete = true;
          this._pickerNotifier.complete();
        }
      });
    }

    return new Promise<void>((resolve, reject) => {
      this._pickerNotifier
        .subscribe(
          undefined,
          (err) => reject(err),
          () => resolve()
        );
    });
  }

  private async _loadScript(): Promise<void> {
    if (this._scriptComplete) {
      return;
    }

    if (!this._scriptLoading) {
      this._scriptLoading = true;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://apis.google.com/js/api.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this._scriptLoading = false;
        this._scriptComplete = true;
        this._scriptNotifier.complete();
      };
      script.onerror = (e) => {
        const evt = e as any as ErrorEvent;

        this._scriptLoading = false;
        this._scriptNotifier.error(evt.error);
      };
      (document.head || document.body || document.documentElement)
        .appendChild(script);
    }

    return new Promise<void>((resolve, reject) => {
      this._scriptNotifier
        .subscribe(
          undefined,
          (err) => reject(err),
          () => resolve()
        );
    });
  }
}
