import { Directive, Input, ElementRef, Inject } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective {
  @Input()
  public focus: boolean = false;
  
  constructor(
    @Inject(ElementRef) private element: ElementRef
  ) {}
  
  protected ngOnChanges() {
    this.element.nativeElement.focus();
  }
}
