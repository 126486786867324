import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ITxtAnalyserService } from '../interfaces/itxtanalyser.service';
import { ITxtAnalyserServiceToken } from '../interfaces/itxtanalyser.service.token';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITxtAnalyserReportJob } from '../models/txtanalyser/ReportJob';

@Injectable()
export class TxtAnalyserReportJobsResolver implements Resolve<ITxtAnalyserReportJob[]> {
  constructor(
    @Inject(ITxtAnalyserServiceToken) private txtAnalyserService: ITxtAnalyserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITxtAnalyserReportJob[]> {
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.txtAnalyserService.getReportPagedJobs({
      pageIndex: 0,
      pageSize: 5,
      orderByActive: "Created",
      orderByDirection: "desc"
    }, groupId, userId)
      .pipe(map(x => x.items));
  }
}