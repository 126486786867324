import { OneDriveFilePickerRedirectComponent } from './components/onedrive-file-picker-redirect/onedrive-file-picker-redirect.component';
import { Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { LoginUnicComponent } from "./components/login-unic/login-unic.component";
import { LoginGoogleComponent } from "./components/login-google/login-google.component";
import { LoginFeideComponent } from "./components/login-feide/login-feide.component";
import { LoginMicrosoftComponent } from "./components/login-microsoft/login-microsoft.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { CreateUserComponent } from "./components/create-user/create-user.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { AuthGuard } from "./auth-guard";
import { AccountComponent } from "./components/account/account.component";
import { AccountUserDetailResolver } from "./resolvers/account-userdetail.resolver";
import { LanguagesDisplayResolver } from "./resolvers/languages-display.resolver";
import { CountriesResolver } from "./resolvers/countries.resolver";
import { AccountApplicationsResolver } from "./resolvers/account-applications.resolver";
import { GroupsComponent } from "./components/groups/groups.component";
import { GroupsResolver } from "./resolvers/groups.resolver";
import { GroupTokenResolver } from "./resolvers/group-token.resolver";
import { GroupComponent } from "./components/group/group.component";
import { GroupResolver } from "./resolvers/group.resolver";
import { GroupMembersResolver } from "./resolvers/group-members.resolver";
import { GroupAreasResolver } from "./resolvers/group-areas.resolver";
import { PhoneticSpellingsComponent } from "./components/phonetic-spellings/phonetic-spellings.component";
import { SpellingCategoriesResolver } from "./resolvers/spelling-categories.resolver";
import { LanguagesProfileResolver } from "./resolvers/languages-profile.resolver";
import { TxtAnalyserCategoriesComponent } from "./components/txtanalyser-categories/txtanalyser-categories.component";
import { TxtAnalyserCategoriesResolver } from "./resolvers/txtanalyser-categories.resolver";
import { TxtAnalyserErrorReportingComponent } from "./components/txtanalyser-errorreporting/txtanalyser-errorreporting.component";
import { TxtAnalyserErrorReportingResolver } from "./resolvers/txtanalyser-errorreporting.resolver";
import { TxtAnalyserIgnoreDocumentsResolver } from "./resolvers/txtanalyser-ignore-documents.resolver";
import { GlossariesComponent } from "./components/glossaries/glossaries.component";
import { GlossariesResolver } from "./resolvers/glossaries.resolver";
import { GlossariesSearchComponent } from "./components/glossaries-search/glossaries-search.component";
import { SpellingCustomRulesResolver } from "./resolvers/spelling-customrules.resolver";
import { TxtAnalyserUploadComponent } from "./components/txtanalyser-upload/txtanalyser-upload.component";
import { TxtAnalyserReportJobsResolver } from "./resolvers/txtanalyser-report-jobs.resolver";
import { TxtAnalyserUploadDocumentsComponent } from "./components/txtanalyser-upload-documents/txtanalyser-upload-documents.component";
import { TxtAnalyserUploadDocumentComponent } from "./components/txtanalyser-upload-document/txtanalyser-upload-document.component";
import { TxtAnalyserReportJobResolver } from "./resolvers/txtanalyser-report-job.resolver";
import { TxtAnalyserReportDocumentResultResolver } from "./resolvers/txtanalyser-report-job-result.resolver";
import { TxtAnalyserUploadViewDocumentComponent } from "./components/txtanalyser-upload-view-document/txtanalyser-upload-view-document.component";
import { TxtAnalyserReportDocumentErrorTypesResolver } from "./resolvers/txtanalyser-report-document-error-types.resolver";
import { TxtAnalyserReportDocumentTextResolver } from "./resolvers/txtanalyser-report-document-text.resolver";
import { TxtAnalyserDocumentIgnoresComponent } from "./components/txtanalyser-document-ignores/txtanalyser-document-ignores.component";
import { TxtAnalyserIgnoreDocumentResolver } from "./resolvers/txtanalyser-ignore-document.resolver";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { GroupMemberNavigationResolver } from "./resolvers/group-member-navigation.resolver";
import { NavigationResolver } from "./resolvers/navigation.resolver";
import { StatsComponent } from "./components/stats/stats.component";
import { StatsPageResolver } from "./resolvers/stats-page.resolver";
import { LanguagesTextAnalyzerResolver } from './resolvers/languages-text-analyzer.resolver';
import { LanguagesStatsResolver } from './resolvers/languages-stats.resolver';
import { TxtAnalyserReportDocumentsResolver } from './resolvers/txtanalyser-report-documents.resolver';
import { LoginSessionComponent } from './components/login-session/login-session.component';
import { MathSolverComponent } from './components/mathsolver/mathsolver.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplatesResolver } from './resolvers/templates.resolver';
import {LoginKmdNexusComponent} from './components/login-kmd-nexus/login-kmd-nexus.component';
import {LoginMicrosoftAdfsComponent} from './components/login-microsoft-adfs/login-microsoft-adfs.component';
import {LoginWizkidsComponent} from './components/login-wizkids/login-wizkids.component';

export const appRoutes: Routes = [
  { path: 'onedrive-file-picker-redirect.html', component: OneDriveFilePickerRedirectComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login_wizkids', component: LoginWizkidsComponent },
  { path: 'login_unic', component: LoginUnicComponent },
  { path: 'login_google', component: LoginGoogleComponent },
  { path: 'login_feide', component: LoginFeideComponent },
  { path: 'login_microsoft', component: LoginMicrosoftComponent },
  { path: 'login_kmd_nexus', component: LoginKmdNexusComponent },
  { path: 'login_microsoft_adfs', component: LoginMicrosoftAdfsComponent },
  { path: 'sessionLogin', component: LoginSessionComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'createUser', component: CreateUserComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [ AuthGuard ] },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      userDetail: AccountUserDetailResolver,
      languages: LanguagesDisplayResolver,
      countries: CountriesResolver,
      applications: AccountApplicationsResolver
    }
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [ AuthGuard ],
    resolve: { groups: GroupsResolver }
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      userDetail: TemplatesResolver
    }
  },
  {
    path: 'groups/join/:token',
    component: GroupsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      groups: GroupsResolver,
      joinGroup: GroupTokenResolver
    }
  },
  {
    path: 'groups/:groupId',
    component: GroupComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      group: GroupResolver,
      members: GroupMembersResolver,
      areas: GroupAreasResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ms',
    component: MathSolverComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'groups/:groupId/ms',
    component: MathSolverComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'groups/:groupId/aw/spelling/:language',
    component: PhoneticSpellingsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: SpellingCategoriesResolver,
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'groups/:groupId/ta/categories/:language',
    component: TxtAnalyserCategoriesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: TxtAnalyserCategoriesResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/ta/errorreporting/:language',
    component: TxtAnalyserErrorReportingComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      errorReporting: TxtAnalyserErrorReportingResolver,
      documents: TxtAnalyserIgnoreDocumentsResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/aw/spelling',
    redirectTo: 'groups/:groupId/aw/spelling/da-DK'
  },
  {
    path: 'groups/:groupId/aw/glossaries',
    component: GlossariesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      glossaries: GlossariesResolver,
      languages: LanguagesProfileResolver,
      group: GroupResolver
    }
  },
  {
    path: 'groups/:groupId/aw/glossaries/search',
    component: GlossariesSearchComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      languages: LanguagesProfileResolver,
      group: GroupResolver
    }
  },
  {
    path: 'profile/aw/spelling/:language',
    component: PhoneticSpellingsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: SpellingCategoriesResolver,
      customRules: SpellingCustomRulesResolver,
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'profile/ta/categories/:language',
    component: TxtAnalyserCategoriesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: TxtAnalyserCategoriesResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'profile/ta/errorreporting/:language',
    component: TxtAnalyserErrorReportingComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      errorReporting: TxtAnalyserErrorReportingResolver,
      documents: TxtAnalyserIgnoreDocumentsResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'profile/ta/upload',
    component: TxtAnalyserUploadComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      latestReports: TxtAnalyserReportJobsResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'profile/ta/upload/all',
    component: TxtAnalyserUploadDocumentsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'profile/ta/upload/:jobId',
    component: TxtAnalyserUploadDocumentComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      job: TxtAnalyserReportJobResolver,
      documents: TxtAnalyserReportDocumentsResolver,
      result: TxtAnalyserReportDocumentResultResolver
    }
  },
  {
    path: 'profile/ta/upload/:jobId/document/:documentId',
    component: TxtAnalyserUploadViewDocumentComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      job: TxtAnalyserReportJobResolver,
      documents: TxtAnalyserReportDocumentsResolver,
      errorTypes: TxtAnalyserReportDocumentErrorTypesResolver,
      text: TxtAnalyserReportDocumentTextResolver
    }
  },
  {
    path: 'profile/ta/errorreporting/:language/ignores/:documentId',
    component: TxtAnalyserDocumentIgnoresComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      document: TxtAnalyserIgnoreDocumentResolver
    }
  },
  {
    path: 'profile/aw/spelling',
    redirectTo: 'profile/aw/spelling/da-DK'
  },
  {
    path: 'profile/aw/glossaries',
    component: GlossariesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      glossaries: GlossariesResolver,
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'profile/aw/glossaries/search',
    component: GlossariesSearchComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId',
    component: NavigationComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      links: GroupMemberNavigationResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/**',
    component: NavigationComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      links: NavigationResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/aw/spelling/:language',
    component: PhoneticSpellingsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: SpellingCategoriesResolver,
      customRules: SpellingCustomRulesResolver,
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/categories/:language',
    component: TxtAnalyserCategoriesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      categories: TxtAnalyserCategoriesResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/errorreporting/:language',
    component: TxtAnalyserErrorReportingComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      errorReporting: TxtAnalyserErrorReportingResolver,
      documents: TxtAnalyserIgnoreDocumentsResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/upload',
    component: TxtAnalyserUploadComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      latestReports: TxtAnalyserReportJobsResolver,
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/upload/all',
    component: TxtAnalyserUploadDocumentsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      languages: LanguagesTextAnalyzerResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/upload/:jobId',
    component: TxtAnalyserUploadDocumentComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      job: TxtAnalyserReportJobResolver,
      documents: TxtAnalyserReportDocumentsResolver,
      result: TxtAnalyserReportDocumentResultResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/upload/:jobId/document/:documentId',
    component: TxtAnalyserUploadViewDocumentComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      job: TxtAnalyserReportJobResolver,
      documents: TxtAnalyserReportDocumentsResolver,
      errorTypes: TxtAnalyserReportDocumentErrorTypesResolver,
      text: TxtAnalyserReportDocumentTextResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/ta/errorreporting/:language/ignores/:documentId',
    component: TxtAnalyserDocumentIgnoresComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      document: TxtAnalyserIgnoreDocumentResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/aw/spelling',
    redirectTo: 'groups/:groupId/members/:userId/aw/spelling/da-DK'
  },
  {
    path: 'groups/:groupId/members/:userId/aw/glossaries',
    component: GlossariesComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      glossaries: GlossariesResolver,
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/aw/glossaries/search',
    component: GlossariesSearchComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      languages: LanguagesProfileResolver
    }
  },
  {
    path: 'profile/ms',
    component: MathSolverComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'profile/stats/:language',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'profile/stats/:language/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'profile/stats/:language/:parentId/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/stats/:language',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/stats/:language/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/stats/:language/:parentId/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/stats/:language',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/stats/:language/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: 'groups/:groupId/members/:userId/stats/:language/:parentId/:pageId',
    component: StatsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      page: StatsPageResolver,
      languages: LanguagesStatsResolver
    }
  },
  {
    path: '**',
    component: NavigationComponent,
    canActivate: [ AuthGuard ],
    resolve: { links: NavigationResolver }
  },
];
