import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { Group, GroupRole, GroupType } from '../../models/groups';
import { IProfileService } from '../../interfaces/iprofile.service';
import { CollectionService, Collection } from '../collection.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const URI_USERS = "/aw/users";
const URI_GROUPS = "/aw/groups";

@Injectable()
export class ProfileCollectionService implements IProfileService {
  constructor(
    private collectionService: CollectionService
  ) {}

  toUri(groupId?: number, userId?: number): string {
    if (groupId !== undefined) {
      let uri = "/groups/" + encodeURIComponent(groupId.toString());
      if (userId !== undefined) {
        uri += "/members/" + encodeURIComponent(userId.toString());
      }

      return uri;
    } else {
      return "/profile";
    }
  }

  getUsers(groupId?: number, userId?: number): Observable<User[]> {
    const uri: string = this.toUri(groupId, userId) + URI_USERS;
    return this.collectionService.getCollection(uri)
      .pipe(map((collection: Collection) => {
        let users: User[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          let item = collection.items[i];
          users.push(new User(
            item.getDataAsString("id"),
            item.getDataAsString("email")
          ));
        }

        return users;
      }));
  }

  getGroups(groupId?: number, userId?: number): Observable<Group[]> {
    const uri: string = this.toUri(groupId, userId) + URI_GROUPS;
    return this.collectionService.getCollection(uri)
      .pipe(map((collection: Collection) => {
        let groups: Group[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          let item = collection.items[i];
          groups.push(new Group(
            item.getDataAsInteger("id"),
            item.getDataAsString("name"),
            0,
            undefined,
            GroupRole.MEMBER,
            GroupType.USER_CREATED
          ));
        }

        return groups;
      }));
  }
}
