import { LingappsError } from './../../services/auth.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject ,  Subscription } from 'rxjs';

import { IGlossary } from '../../models/glossary';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { IGlossaryServiceToken } from '../../interfaces/iglossary.service.token';

export interface IUnsubscribeData {
  glossary: IGlossary;
  group: boolean;
}

@Component({
  selector: 'app-glossary-unsubscribe-dialog',
  templateUrl: './glossary-unsubscribe-dialog.component.html',
  styleUrls: ['./glossary-unsubscribe-dialog.component.scss']
})
export class GlossaryUnsubscribeDialogComponent implements OnDestroy {
  private unsubscribeSubscription?: Subscription;
  public onUnsubscribed?: Subject<IGlossary> = new Subject();
  public disabled: boolean = false;
  public error: boolean = false;
  public errorCode?: string;

  public glossary: IGlossary;
  public group: boolean;

  constructor(
    public dialogRef: MatDialogRef<GlossaryUnsubscribeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IUnsubscribeData,
    @Inject(IGlossaryServiceToken) private glossaryService: IGlossaryService
  ) {
    this.glossary = data.glossary;
    this.group = data.group;
  }

  ngOnDestroy() {
    if (this.onUnsubscribed) {
      this.onUnsubscribed.unsubscribe();
      this.onUnsubscribed = undefined;
    }
    if (this.unsubscribeSubscription) {
      this.unsubscribeSubscription.unsubscribe();
      this.unsubscribeSubscription = undefined;
    }
  }
  
  unsubscribe() {
    if (this.disabled) return;
    this.disabled = true;
    this.error = false;
    this.errorCode = undefined;

    this.glossary.subscribed = false;
    this.unsubscribeSubscription = this.glossaryService.updateGlossarySubscription(this.glossary)
      .subscribe(() => {
        if (this.onUnsubscribed) {
          this.onUnsubscribed.next(this.glossary);
        }
      }, (error: LingappsError) => {
        this.disabled = false;
        if (error && error.code) {
          this.errorCode = error.code;
        }
        this.error = true;
      }, () => {
        if (this.onUnsubscribed) {
          this.onUnsubscribed.complete();
        }
        this.dialogRef.close();
      });
  }
}
