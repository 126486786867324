<ng-template #wordListTemplate>
  <h4 i18n="@@EditGlossary_WordInGlossary">Words in the glossary</h4>
  <div *ngIf="wordList.words.length === 0 && !wordsLoading"
        i18n="@@EditGlossary_NoWordsThatCanBeRemoved">
    No words in the glossary that can be removed.
  </div>
  <app-virtual-scroll
      *ngIf="wordList.words.length > 0 || wordsLoading"
      [update]="wordList.onWordChanges"
      [items]="wordList.words"
      [loading]="wordsLoading">
    <ng-template #loading>
      <mat-progress-spinner
        class="loading"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </ng-template>
    <ng-template #item let-item let-row="row">
      <div class="word" [class.odd]="row % 2 === 0">
        <span>{{ item.text }}</span>
        <span *ngIf="item.isExactMatch()"
              class="three-star-word"
              title="Words that are exact duplicates of words that already exist in the AppWriter's default glossary."
              i18n-title="@@GlossaryItemsList_ThreeStarInfo">***</span>
        <span *ngIf="item.isCaseInsensitiveMatchInDefault()"
              class="two-star-word"
              title="Words that already exists in AppWriter's standard glossary, but in a different case combination."
              i18n-title="@@GlossaryItemsList_TwoStarInfo">**</span>
        <span *ngIf="item.isCaseInsensitiveMatchInGlossary()"
              class="one-star-word"
              title="Words that already exists in this glossary, but in a different case combination."
              i18n-title="@@GlossaryItemsList_OneStarInfo">*</span>
        <button mat-icon-button color="primary" (click)="removeWord(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-template>
  </app-virtual-scroll>
</ng-template>

<ng-template #step1>
  <form [formGroup]="metadataForm">
    <div class="grid">
      <div class="grid-tile">
        <div>
          <mat-form-field class="full-width" hideRequiredMarker="true">
            <input
              matInput
              formControlName="name"
              placeholder="Name of the glossary"
              i18n-placeholder="@@EditGlossary_Name"
              required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width" hideRequiredMarker="true">
            <mat-select
              formControlName="language"
              placeholder="Language"
              i18n-placeholder="@@EditGlossary_Language"
              required>
              <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width">
            <input
              matInput
              formControlName="description"
              placeholder="Description"
              i18n-placeholder="@@EditGlossary_Descritpion">
          </mat-form-field>
        </div>
        <!--<div>
          <mat-checkbox
            formControlName="public"
            i18n="@@EditGlossary_Public">Public</mat-checkbox>
          <div class="public-message public-warning" *ngIf="glossary.isPublic && !isPublic()" i18n="@@EditGlossary_PublicNoticeDescription">
            Notice that if you make the glossary private all subscribed users
            will no longer be able to access or use this glossary.
          </div>
          <div class="public-message" i18n="@@EditGlossary_PublicDescription">
            If a glossary is public it can be shared with groups and subscribed
            to by all other AppWriter users.
          </div>
        </div>-->
      </div>
      <div class="grid-tile">
        <ng-container *ngTemplateOutlet="wordListTemplate"></ng-container>
      </div>
    </div>
    <div class="error" *ngIf="unexpectedError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
  </form>
</ng-template>

<ng-template #step2>
  <div class="grid">
    <div class="grid-tile" *ngIf="isLoading">
      <mat-progress-spinner class="loading"
                            color="primary"
                            mode="indeterminate">
      </mat-progress-spinner>
      <span class="loading__text" i18n="@@ParseTextLoadingText">
        Please wait while we're analysing the text. This can take up to a minute.
      </span>
    </div>
    <div class="error" *ngIf="unexpectedParseError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
    <div class="grid-tile" *ngIf="!isLoading && !unexpectedParseError">
      <span *ngIf="tmpAddedText === -1" i18n="@@EditGlossary_AddWordsInfo">You can just enter single words or whole sentences.</span>
      <span *ngIf="tmpAddedText >= 0 || wordList.bufferedExactWordsCount > 0" class="addedtext">
        <div *ngIf="tmpAddedText > 0" class="paragraph" i18n="@@EditGlossary_YouHaveAddedWordsInfo">
          You've added {{ tmpAddedText }} {tmpAddedText, plural, =1 {word} other {words}} to the glossary!
        </div>
        <div *ngIf="tmpAddedText === 0" i18n="@@EditGlossary_AllWordsRemoved">
          The words you have added are already in the this glossary.
        </div>
        <ng-container *ngIf="wordList.bufferedExactWordsCount > 0">
          <p i18n="@@EditGlossary_WeHaveRemovedText">
            We have removed all the words already in the AppWriters standard glossary.
          </p>
          <button mat-button
                  color="primary"
                  (click)="keepExactMatches()"
                  [class.disabled]="disabled"
                  i18n="@@EditGlossary_btnKeepStandardWords">
            Keep words
          </button>
        </ng-container>
      </span>

      <h5 i18n="@@EditGlossary_AddWordsHeader">Add words to the glossary</h5>
      <div>
        <mat-form-field class="full-width">
          <textarea autofocus
                    [disabled]="wordsLoading"
                    [focus]
                    [(ngModel)]="text"
                    autoGrow
                    maxRows="3"
                    minRows="1"
                    matInput
                    placeholder="Insert text"
                    i18n-placeholder="@@EditGlossary_InsertText"></textarea>
        </mat-form-field>
      </div>
      <div class="right-align">
        <button mat-button
                color="primary"
                (click)="addText()"
                [disabled]="!text || isLoading"
                i18n="@@EditGlossary_btnAddToGlossary">
          Add to glossary
        </button>
      </div>
    </div>
    <div class="grid-tile">
      <ng-container *ngTemplateOutlet="wordListTemplate"></ng-container>
    </div>
  </div>
  <div class="error" *ngIf="unexpectedError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
</ng-template>

<ng-template #step3>
  <div class="grid">
    <div class="grid-tile">
      <p *ngIf="wordList.words.length > 0"
          i18n="@@EditGlossary_UnwantedInfo">
        If you have unwanted words in the glossary you can remove them here.
      </p>
      <div class="paragraph" *ngIf="wordList.exactMatchesCount > 0">
        <div i18n="@@EditGlossary_ThreeStarInfo">
          *** = Words that are exact duplicates of words that already exist in the AppWriter's default glossary.
        </div>
        <button mat-button color="primary" (click)="removeThreeStarWords()">
          <span i18n="@@EditGlossary_btnRemoveThreeStars">Remove these words from this glossary</span>
        </button>
      </div>
      <div class="paragraph" *ngIf="wordList.duplicatesInDefaultCount > 0">
        <div i18n="@@EditGlossary_TwoStarInfo">
          ** = Words that already exists in AppWriter's standard glossary, but in a different case combination.
        </div>
        <button mat-button color="primary" (click)="removeTwoStarWords()">
          <span i18n="@@EditGlossary_btnRemoveTwoStars">Remove these words from this glossary</span>
        </button>
      </div>
      <div class="paragraph" *ngIf="wordList.duplicatesInGlossaryCount > 0">
        <div i18n="@@EditGlossary_OneStarInfo">
          * = Words that already exists in this glossary, but in a different case combination.
        </div>
        <button mat-button color="primary" (click)="removeOneStarWords()">
          <span i18n="@@EditGlossary_btnRemoveOneStars">Remove these words from this glossary</span>
        </button>
      </div>
    </div>
    <div class="grid-tile">
      <ng-container *ngTemplateOutlet="wordListTemplate"></ng-container>
    </div>
  </div>
  <div class="error" *ngIf="unexpectedError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
</ng-template>

<ng-template #step4>
  <div class="grid">
    <div class="grid-tile">
      <div class="paragraph" *ngIf="name !== glossary.name" i18n="@@EditGlossary_EditedInfo">
        You've changed the name of the glossary to {{ name }}
      </div>
      <div class="paragraph" *ngIf="wordList.removedWordsCount > 0">
        <div i18n="@@EditGlossary_WordsRemovedInfo">
          You've removed {{wordList.removedWordsCount}} {wordList.removedWordsCount, plural, =1 {word} other {words}} from the glossary
        </div>
        <div>
          <span class="link" (click)="clearRemovedWords()" [class.disabled]="disabled" i18n="@@EditGlossary_btnUndo">
            Undo
          </span>
        </div>
      </div>
      <div class="paragraph" *ngIf="wordList.addedWordsCount > 0">
        <div i18n="@@EditGlossary_AddedWordsInfo">
          You've added {{wordList.addedWordsCount}} {wordList.addedWordsCount, plural, =1 {word} other {words}} to the glossary
        </div>
        <div>
          <span class="link" (click)="clearAddedWords()" [class.disabled]="disabled" i18n="@@EditGlossary_btnUndo">
            Undo
          </span>
        </div>
      </div>
      <div class="error" *ngIf="!!errorMessage">{{ errorMessage }}</div>
    </div>
    <div class="grid-tile">
      <ng-container *ngTemplateOutlet="wordListTemplate"></ng-container>
    </div>
  </div>
  <div class="error" *ngIf="unexpectedError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
</ng-template>

<mat-dialog-content>
  <mat-stepper #horizontalStepper *ngIf="!isPhone" [linear]="false" (selectionChange)="onStepperChange($event.selectedIndex, false)">
    <mat-step [stepControl]="metadataForm">
      <ng-template matStepLabel i18n="@@EditGlossary_StepperTitlrName">Name the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button mat-button matDialogClose i18n="@@EditGlossary_btnCancel" [disabled]="disabled && !unexpectedError">Cancel</button>
        <button mat-button mat-raised-button color="primary" matStepperNext i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_AddNewWords">Add new words</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" (click)="onAddNext()" i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_RemoveTitle">Remove words</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" matStepperNext i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_Save">Save</ng-template>
      <ng-container *ngTemplateOutlet="step4"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" (click)="submit()" i18n="@@EditGlossary_btnSave" [disabled]="(disabled || !name || !language || isLoading)">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
  <mat-stepper orientation="vertical" #verticalStepper *ngIf="isPhone" [linear]="false" (selectionChange)="onStepperChange($event.selectedIndex, false)">
    <mat-step [stepControl]="metadataForm">
      <ng-template matStepLabel i18n="@@EditGlossary_StepperTitlrName">Name the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button mat-button matDialogClose i18n="@@EditGlossary_btnCancel" [disabled]="disabled && !unexpectedError">Cancel</button>
        <button mat-button mat-raised-button color="primary" matStepperNext i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_AddNewWords">Add new words</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" (click)="onAddNext()" i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_RemoveTitle">Remove words</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" matStepperNext i18n="@@EditGlossary_btnNext" [disabled]="(disabled || !name || !language || isLoading)">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel i18n="@@EditGlossary_Save">Save</ng-template>
      <ng-container *ngTemplateOutlet="step4"></ng-container>
      <div class="actions">
        <button mat-button matStepperPrevious i18n="@@EditGlossary_btnBack" [disabled]="disabled && !unexpectedError">Back</button>
        <button mat-button mat-raised-button color="primary" (click)="submit()" i18n="@@EditGlossary_btnSave" [disabled]="(disabled || !name || !language || isLoading)">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
