import { HttpClient } from '@angular/common/http';
import { IArea, IChart, IChartOrigin, DataTable, IDataCell, IDataColumn, CellType, IChartLink, IDataGroup, IDataRow } from './../../models/stats';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IStatsService } from '../../interfaces/istats.service';
import { CollectionService } from '../collection.service';
import { Observable } from 'rxjs';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { environment } from '../../../environments/environment';
import { IPage } from '../../models/stats';
import * as moment from 'moment';
import * as querystring from 'query-string';
import { map } from 'rxjs/operators';

export class Page implements IPage {
  constructor(
    private _collectionService: CollectionService,
    private _httpClient: HttpClient,
    public id: string,
    public title: string,
    private _areasLink: string,
    private _supportLanguage: boolean,
    private _timePeriodSupported: boolean,
    public language?: string
  ) {}

  public isLanguageSupported(): boolean {
    return this._supportLanguage;
  }

  public isTimePeriodSupported(): boolean {
    return this._timePeriodSupported;
  }

  public getAreas(language?: string): Observable<IArea[]> {
    return this._collectionService.getCollection(this._areasLink)
      .pipe(map(coll => {
        const areas: IArea[] = [];

        const items = coll.items;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const id = item.getDataAsString('id');
          const pageId = item.getDataAsString('pageId');
          const title = item.getDataAsString('title');
          const isStandalone = item.getDataAsBooleanOrDefault('isStandalone', false);
          const chartsLink = item.getLink('charts')!.href;

          areas.push(new Area(this._collectionService, this._httpClient, id, pageId, title, chartsLink, language || this.language, isStandalone));
        }

        return areas;
      }));
  }
}

export class Area implements IArea {
  constructor(
    private _collectionService: CollectionService,
    private _httpClient: HttpClient,
    public id: string,
    public pageId: string,
    public title: string,
    private _chartsLink: string,
    public language?: string,
    public isStandalone: boolean = false
  ) {}
  
  getCharts(language?: string, zoom?: string): Observable<IChart[]> {
    let url = this._chartsLink;
    let query = {} as IDataQuery;

    const queryIndex = url.indexOf("?");
    if (queryIndex !== -1) {
      let queryString = url.substring(queryIndex + 1);
      query = (querystring.parse(queryString) as any) as IDataQuery;
    }

    if (language) {
      query.lang = language;
    } else if (this.language) {
      query.lang = this.language;
    }

    if (zoom) {
      query.zoom = zoom;
    }

    if (queryIndex === -1) {
      url += '?' + querystring.stringify(query);
    } else {
      url = url.substring(0, queryIndex + 1) + querystring.stringify(query);
    }

    return this._collectionService.getCollection(url)
      .pipe(map(coll => {
        const charts: IChart[] = [];

        const items = coll.items;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];

          let titleLink = item.getLink('title-link');

          let expandLink: IChartLink|undefined;
          let link = item.getLink('link');

          const pageId = item.getDataAsString('pageId');

          if (link) {
            expandLink = {
              text: link.prompt,
              href: link.href.replace(/\/stats\/([a-zA-Z0-9\-\_]+)\/([a-zA-Z0-9\-\_]+)/, '/stats/$1/' + encodeURIComponent(pageId) + '/$2')
            };
          }

          charts.push(new Chart(
            this._collectionService,
            this._httpClient,
            item.getDataAsString('id'),
            item.getDataAsString('areaId'),
            pageId,
            item.getDataAsStringOrUndefined('groupId'),
            item.getDataAsStringOrUndefined('impersonatedUserId'),
            item.getDataAsBoolean('isDefault'),
            item.getDataAsStringOrUndefined('language') || item.getDataAsStringOrUndefined('lang') || "da-DK",
            item.getDataAsInteger('limit'),
            item.getDataAsStringOrUndefined('groupedChartTitle'),
            item.getDataAsString('title'),
            item.getDataAsBoolean('extended'),
            item.getDataAsDate('from'),
            item.getDataAsDate('to'),
            item.getDataAsString('chartType'),
            item.getDataAsBooleanOrDefault('includePieChart', false),
            item.getDataAsString('zoom'),
            item.getLink('data')!.href,
            item.getLink('data-origin')!.href,
            titleLink ? titleLink.href : undefined,
            expandLink
          ));
        }

        return charts;
      }));
  }
}

declare interface IServerChartData {
  title: string;
  labels: string[];
  numberOfColumns?: number;
  columns: {
    name: string|{
      periodName: string;
      period: {
        zoom: string;
        selfHref: string;
        from: string;
        zoomValue: string;
      };
      month: string;
    };
    type: string;
    links: {
      rel: string;
      href: string;
    }[];
    data: string[];
  }[];
  groups?: {
    rows: IServerChartRow[]
  }[];
  rows?: IServerChartRow[];
  links: {
    rel: string;
    prompt: string;
  }[];
}

declare interface IServerChartRow {
  header?: boolean;
  data: string[]|{
    prompt: string;
    href: string;
  }[];
}

declare interface IDataQuery {
  lang: string;
  from: string;
  to: string;
  zoom: string;
}

export class Chart implements IChart {
  constructor(
    private _collectionService: CollectionService,
    private _httpClient: HttpClient,
    public id: string,
    public areaId: string,
    public pageId: string,
    public groupId: string|undefined,
    public impersonatedUserId: string|undefined,
    public isDefault: boolean,
    public language: string,
    public limit: number,
    public groupedChartTitle: string | undefined = undefined,
    public title: string,
    public extended: boolean,
    public startDate: Date,
    public endDate: Date,
    public chartType: string,
    public includePieChart: boolean,
    public zoom: string,
    private _dataLink: string,
    private _dataOriginLink: string,
    private _link?: string,
    public link?: IChartLink
  ) {}

  getTitleLink(): string|undefined {
    return this._link;
  }

  getData(language?: string, zoom?: string, startDate?: Date, endDate?: Date): Observable<DataTable> {
    let link = this._dataLink;
    let query = {} as IDataQuery;

    const queryIndex = link.indexOf("?");
    if (queryIndex !== -1) {
      let queryString = link.substring(queryIndex + 1);
      query = (querystring.parse(queryString) as any) as IDataQuery;
    }

    if (language) {
      query.lang = language;
    }
    if (zoom) {
      query.zoom = zoom;
    }
    if (startDate) {
      query.from = moment(startDate).format("YYYY-MM-DD");
    }
    if (endDate) {
      query.to = moment(endDate).format("YYYY-MM-DD");
    }

    if (queryIndex === -1) {
      link += '?' + querystring.stringify(query);
    } else {
      link = link.substring(0, queryIndex + 1) + querystring.stringify(query);
    }

    return this._httpClient.get<IServerChartData>(environment.collectionUrl + link)
      .pipe(map((json: IServerChartData) => {
        if (!json) return new DataTable();

        const isColumnChart = !!json.labels;

        const groups: IDataGroup[] = [];
        const cols: IDataColumn[] = [];
        let rows: IDataRow[] = [];
        for (let i = 0; i < json.columns.length; i++) {
          const column = json.columns[i];
          if (isColumnChart) {
            const cells: IDataCell[] = [];

            let label: string;
            if (typeof column.name === 'object') {
              label = column.name.periodName;
            } else {
              label = column.name;
            }

            const labelCell: IDataCell = {
              value: label,
              type: CellType.label
            };

            if (typeof column.name === 'object'
              && typeof column.name.period === 'object'
              && column.name.period.zoom === 'week'
              && column.name.period.selfHref) {
              labelCell.href = column.name.period.selfHref;
              labelCell.hrefQuery = {
                week: column.name.period.zoomValue,
                year: moment(
                  new Date(column.name.period.from)
                ).year()
              };
            }

            if (column.links) {
              const links = column.links;
              for (let i = 0; i < links.length; i++) {
                if (links[i].rel === 'user_activity') {
                  labelCell.href = links[i].href;
                }
              }
            }

            cells.push(labelCell);

            for (let i = 0; i < column.data.length; i++) {
              cells.push({
                value: column.data[i],
                type: CellType.value
              });
            }

            if (typeof column.name === 'object') {
              cells.push({
                value: column.name.month,
                type: CellType.annotation
              });
            }

            rows.push({
              cells
            });
          } else {
            cols.push({
              label: column.name as string,
              type: column.type
            });
          }
        }

        if (isColumnChart) {
          for (let i = 0; i < json.labels.length; i++) {
            cols.push({
              label: json.labels[i],
              type: i === 0 ? 'string' : 'number'
            });
          }
        } else if (json.groups) {
          for (const group of json.groups) {
            groups.push({
              rows: this._parseRows(group.rows)
            });
          }
        } else if (json.rows) {
          rows = this._parseRows(json.rows);
        }

        return new DataTable({
          cols: cols,
          rows: rows,
          groups: groups,
          title: json.title,
          numberOfColumns: json.numberOfColumns
        });
      }));
  }

  private _parseRows(rows: IServerChartRow[]): IDataRow[] {
    const rv: IDataRow[] = [];
    for (const row of rows) {
      const data = row.data;
      const cells: IDataCell[] = [];
      for (let i = 0; i < data.length; i++) {
        const cellData = data[i];
        const cell: IDataCell = {
          value: undefined,
          type: i === 0 ? CellType.label : CellType.value
        };

        if (typeof cellData === 'object') {
          cell.value = cellData.prompt;
          if (cellData.href) {
            cell.href = cellData.href;
          }
        } else {
          cell.value = cellData;
        }

        cells.push(cell);
      }

      rv.push({
        isHeader: !!row.header,
        cells
      });
    }

    return rv;
  }

  getDataOrigin(): Observable<IChartOrigin> {
    return this._httpClient.get<IChartOrigin>(environment.collectionUrl + this._dataOriginLink);
  }
}

@Injectable()
export class StatsCollectionService implements IStatsService {
  constructor(
    private _collectionService: CollectionService,
    private _httpClient: HttpClient,
    private router: Router,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService
  ) {}

  getPage(url: string, language?: string): Observable<IPage> {
    // The server doesn't have subpages therefore we're cheating a bit here.
    url = url.replace(/\/stats\/([a-zA-Z0-9\-\_]+)\/([a-zA-Z0-9\-\_]+)\/([a-zA-Z0-9\-\_]+)/, '/stats/$1/$3');

    return this._collectionService.getCollection(url)
      .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
      .pipe(map(coll => {
        const items = coll.items;
        if (items.length === 0) throw new Error("Empty stats page");
        const item = items[0];
        
        const id = item.getDataAsString('id');
        const title = item.getDataAsString('title');
        const timePeriodSupported = item.getDataAsBooleanOrDefault('supportsTimePeriod', true);
        const link = item.getLink('areas')!.href;

        return new Page(this._collectionService, this._httpClient, id, title, link, !!item.getLink('supported-languages'), timePeriodSupported, language);
      }));
  }
}