<ng-template #step1>
  <form [formGroup]="metadataForm">
    <div class="half-width">
      <mat-form-field class="full-width" hideRequiredMarker="true">
        <input
          matInput
          formControlName="name"
          placeholder="Name of the glossary"
          i18n-placeholder="@@CreateGlossary_Name"
          required>
      </mat-form-field>
    </div>
    <div class="half-width">
      <mat-form-field class="full-width" hideRequiredMarker="true">
        <mat-select
          formControlName="language"
          placeholder="Language"
          i18n-placeholder="@@CreateGlossary_Language">
          <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="half-width">
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="description"
          placeholder="Description"
          i18n-placeholder="@@CreateGlossary_Description">
      </mat-form-field>
    </div>
    <!--<div class="half-width">
      <mat-checkbox
        formControlName="public"
        i18n="@@CreateGlossary_Public">Public</mat-checkbox>
      <div i18n="@@CreateGlossary_PublicDescription">
        If a glossary is public it can be shared with groups and subscribed to
        by all other AppWriter users.
      </div>
    </div>-->
  </form>
</ng-template>

<ng-template #step2>
  <mat-form-field class="full-width fill-height">
    <textarea
      matInput
      autoGrow
      maxRows="10"
      minRows="1"
      autofocus
      [focus]
      [(ngModel)]="text"
      i18n-placeholder="@@CreateGlossary_PasteText"
      placeholder="Insert text from the clipboard"></textarea>
  </mat-form-field>
</ng-template>

<ng-template #step3>
  <div class="grid1" *ngIf="isLoading">
    <mat-progress-spinner
      class="loading"
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
    <span class="loading__text" i18n="@@ParseTextLoadingText">Please wait while we are analysing the text. This will take up to a minute.</span>
  </div>
  <div class="error" *ngIf="unexpectedParseError" i18n="@@UnexpectedError">An unexpected error occurred.</div>
  <div class="grid" *ngIf="!isLoading && !unexpectedParseError">
    <div class="grid-tile">
      <div *ngIf="wordList.words.length === 0" i18n="@@CreateGlossary_NoWordsAdded">
        No words have been added to the glossary.
      </div>
      <div *ngIf="wordList.words.length > 0" i18n="@@CreateGlossary_WordCount">
        You have added {{ wordList.words.length }} {wordList.words.length, plural, =1 {word} other {words}} to the glossary!
      </div>

      <div class="addedtext">
        <div class="paragraph" *ngIf="wordList.bufferedExactWordsCount > 0">
          <div i18n="@@CreateGlossary_WeHaveRemovedText">
            We have removed all the words already in the AppWriters standard glossary.
          </div>
          <button mat-button
                  color="primary"
                  (click)="keepExactMatches()"
                  [class.disabled]="disabled"
                  i18n="@@CreateGlossary_btnKeepStandardWords">
            Keep words
          </button>
        </div>
        <div class="paragraph" *ngIf="wordList.exactMatchesCount > 0">
          <div i18n="@@CreateGlossary_ThreeStarInfo">
            *** = Words that are exact duplicates of words that already exist in the AppWriter's default glossary.
          </div>
          <button mat-button color="primary" (click)="removeThreeStarWords()">
            <span i18n="@@CreateGlossary_btnRemoveThreeStars">Remove these words from this glossary</span>
          </button>
        </div>
        <div class="paragraph" *ngIf="wordList.duplicatesInDefaultCount > 0">
          <div i18n="@@CreateGlossary_TwoStarInfo">
            ** = Words that already exists in AppWriter's standard glossary, but in a different case combination.
          </div>
          <button mat-button color="primary" (click)="removeTwoStarWords()">
            <span i18n="@@CreateGlossary_btnRemoveTwoStars">Remove these words from this glossary</span>
          </button>
        </div>
        <div class="paragraph" *ngIf="wordList.duplicatesInGlossaryCount > 0">
          <div i18n="@@CreateGlossary_OneStarInfo">
            * = Words that already exists in this glossary, but in a different case combination.
          </div>
          <button mat-button color="primary" (click)="removeOneStarWords()">
            <span i18n="@@CreateGlossary_btnRemoveOneStars">Remove these words from this glossary</span>
          </button>
        </div>
      </div>
      <div class="error" *ngIf="!!errorMessage">{{ errorMessage }}</div>
    </div>
    <div class="grid-tile">
      <h4 *ngIf="wordList.words.length > 0" i18n="@@CreateGlossary_WordsHeader">Words in the glossary</h4>
      <app-virtual-scroll
          *ngIf="wordList.words.length > 0"
          [update]="wordList.onWordChanges"
          [items]="wordList.words">
        <ng-template #item let-item let-row="row">
          <div class="word" [class.odd]="row % 2 === 0">
            <span>{{ item.text }}</span>
            <span *ngIf="item.isExactMatch()"
                  class="three-star-word"
                  title="Words that are exact duplicates of words that already exist in the AppWriter's default glossary."
                  i18n-title="@@GlossaryItemsList_ThreeStarInfo">***</span>
            <span *ngIf="item.isCaseInsensitiveMatchInDefault()"
                  class="two-star-word"
                  title="Words that already exists in AppWriter's standard glossary, but in a different case combination."
                  i18n-title="@@GlossaryItemsList_TwoStarInfo">**</span>
            <span *ngIf="item.isCaseInsensitiveMatchInGlossary()"
                  class="one-star-word"
                  title="Words that already exists in this glossary, but in a different case combination."
                  i18n-title="@@GlossaryItemsList_OneStarInfo">*</span>
            <button mat-icon-button color="primary" (click)="removeWord(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-template>
      </app-virtual-scroll>
    </div>
  </div>
</ng-template>

<mat-dialog-content>
  <mat-stepper #horizontalStepper *ngIf="!isPhone" [linear]="true" (selectionChange)="onStepperChange(horizontalStepper, $event.selectedIndex)">
    <mat-step [stepControl]="metadataStepControl">
      <ng-template matStepLabel i18n="@@EditGlossary_StepperTitlrName">Name the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button
          mat-button
          matDialogClose
          i18n="@@CreateGlossary_btnCancel"
          [disabled]="disabled || isLoading">Cancel</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@CreateGlossary_btnNext"
          [disabled]="disabled || !name || !language || isLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="textStepControl">
      <ng-template matStepLabel i18n="@@CreateGlossary_StepperTitle">Fill the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@CreateGlossary_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@CreateGlossary_btnNext"
          [disabled]="disabled || !name || !language || isLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="saveStepControl">
      <ng-template matStepLabel i18n="@@CreateGlossary_SaveTitle">Save</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@CreateGlossary_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          (click)="submit()"
          i18n="@@CreateGlossary_btnSave"
          [disabled]="disabled || !name || !language || isLoading">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
  <mat-stepper orientation="vertical" #verticalStepper *ngIf="isPhone" [linear]="true" (selectionChange)="onStepperChange(verticalStepper, $event.selectedIndex)">
    <mat-step [stepControl]="metadataStepControl">
      <ng-template matStepLabel i18n="@@EditGlossary_StepperTitlrName">Name the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step1"></ng-container>
      <div class="actions">
        <button
          mat-button
          matDialogClose
          i18n="@@CreateGlossary_btnCancel"
          [disabled]="disabled || isLoading">Cancel</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@CreateGlossary_btnNext"
          [disabled]="disabled || !name || !language || isLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="textStepControl">
      <ng-template matStepLabel i18n="@@CreateGlossary_StepperTitle">Fill the glossary</ng-template>
      <ng-container *ngTemplateOutlet="step2"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@CreateGlossary_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          matStepperNext
          i18n="@@CreateGlossary_btnNext"
          [disabled]="disabled || !name || !language || isLoading">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="saveStepControl">
      <ng-template matStepLabel i18n="@@CreateGlossary_SaveTitle">Save</ng-template>
      <ng-container *ngTemplateOutlet="step3"></ng-container>
      <div class="actions">
        <button
          mat-button
          matStepperPrevious
          i18n="@@CreateGlossary_btnBack"
          [disabled]="disabled || isLoading">Back</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          (click)="submit()"
          i18n="@@CreateGlossary_btnSave"
          [disabled]="disabled || !name || !language || isLoading">Save</button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
