import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-visible-text',
  templateUrl: './visible-text.component.html',
  styleUrls: ['./visible-text.component.scss']
})
export class VisibleTextComponent implements OnInit, OnChanges {
  private _outerWidth = 0;
  private _outerHeight = 0;

  private _innerWidth = 0;
  private _innerHeight = 0;

  @Input('value')
  public value?: string;

  @Input('visible')
  public visible = false;

  @ViewChild('valueRef', { static: true })
  public valueElement?: ElementRef;

  constructor(
    public hostElement: ElementRef
  ) {}

  isVisible(): boolean {
    return this.isWidthVisible();
  }

  isWidthVisible(): boolean {
    const width = this._outerWidth;
    const innerWidth = this._innerWidth;

    return width >= innerWidth;
  }

  isHeightVisible(): boolean {
    const height = this._outerHeight;
    const innerHeight = this._innerHeight;

    return height >= innerHeight;
  }

  updateVisibility() {
    if (this.visible && this.isHeightVisible()) {
      this.hostElement.nativeElement.style.visibility = "";
    } else {
      this.hostElement.nativeElement.style.visibility = "hidden";
    }
  }

  updateCache() {
    if (this._outerWidth && this._outerHeight && this._innerWidth && this._innerHeight) {
      return;
    }

    this._outerWidth = this.hostElement.nativeElement.offsetWidth;
    this._outerHeight = this.hostElement.nativeElement.offsetHeight;

    if (this.valueElement) {
      this._innerWidth = this.valueElement.nativeElement.offsetWidth;
      this._innerHeight = this.valueElement.nativeElement.offsetHeight;
    }
  }

  ngOnInit() {
    this.updateCache();
    this.updateVisibility();
  }

  ngOnChanges() {
    this.updateCache();

    this.updateVisibility();
  }
}
