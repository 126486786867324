import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Application } from '../models/account';
import { IAccountService } from '../interfaces/iaccount.service';
import { IAccountServiceToken } from '../interfaces/iaccount.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class AccountApplicationsResolver implements Resolve<Application[]> {
  constructor(
    @Inject(IAccountServiceToken) private accountService: IAccountService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Application[]> {
    return this.accountService.getApplications();
  }
}
