<app-header></app-header>

<div class="introduction" *ngIf="!isGroupView()" i18n="@@Glossaries_Introduction">
  Here you can administer the glossaries you have in AppWriter. You can search
  for and subscribe to more or you can create your own.
</div>

<div class="introduction introduction--group" *ngIf="isGroupView()" i18n="@@Glossaries_GroupIntroduction">
  Here you can administer the glossaries that's subscribed to the members of the
  group. As administrator you can enable and disable the glossaries for the
  members and subscribe new glossaries to them.
</div>

<ng-container *ngIf="isGroupView()">
  <ng-container *ngIf="groupViewGlossaries.length > 0">
    <app-glossary-list (onDelete)="onDeleteGlossary($event)" [glossaries]="groupViewGlossaries" [showOwnerColumn]="true" [showSubscribersColumn]="true" [showUnsubscribeFromGroup]="true"></app-glossary-list>
  </ng-container>
  <div *ngIf="groupViewGlossaries.length === 0" i18n="@@Glossaries_NoGroupGlossaries">No glossaries have been shared with group.</div>
</ng-container>

<div class="actions" *ngIf="isGroupView()">
  <button mat-button color="primary" (click)="addGlossariesToGroup()">
    <mat-icon>add</mat-icon>
    <span i18n="@@Glossaries_AddNewGlossariesToTheGroup">Add new glossaries to the group</span>
  </button>
</div>

<ng-container *ngIf="!isGroupView()">
  <h1 class="subpage" i18n="@@Glossaries_SubscribedGlossaries">Subscribed glossaries</h1>
  <ng-container *ngIf="subscribedGlossaries.length > 0">
    <app-glossary-list (onDelete)="onDeleteGlossary($event)" [glossaries]="subscribedGlossaries" [showOwnerColumn]="true" [showSubscribersColumn]="true" [showUnsubscribe]="true"></app-glossary-list>
  </ng-container>
  <div *ngIf="subscribedGlossaries.length === 0" i18n="@@Glossaries_NoSubscribedGlossaries">You aren't subscribed to any glossaries.</div>

  <div class="actions">
    <a mat-button color="primary" routerLink="./search">
      <mat-icon>add</mat-icon>
      <span i18n="@@Glossaries_SearchForPublicGlossaries">Search for public glossaries</span>
    </a>
  </div>

  <ng-container *ngIf="groupGlossaries.length > 0">
    <h1 class="subpage" i18n="@@Glossaries_glossariesFromGroups">Glossaries from groups you're a member of</h1>
    <app-glossary-list (onDelete)="onDeleteGlossary($event)" [glossaries]="groupGlossaries" [showGroupColumn]="true"></app-glossary-list>
  </ng-container>

  <h1 class="subpage" i18n="@@Glossaries_OwnGlossaries">Own glossaries</h1>
  <ng-container *ngIf="myGlossaries.length > 0">
    <app-glossary-list (onDelete)="onDeleteGlossary($event)" [glossaries]="myGlossaries" [onlyEdit]="true" [showPublicColumn]="false" [showEdit]="true" [showDelete]="true"></app-glossary-list>
  </ng-container>
  <div *ngIf="myGlossaries.length === 0" i18n="@@Glossaries_NoMyGlossaries">You haven't created any glossaries.</div>

  <div class="actions">
    <button mat-button color="primary" (click)="openCreateGlossaryDialog()">
      <mat-icon>add</mat-icon>
      <span i18n="@@Glossaries_CreateNew">Create new</span>
    </button>
  </div>
</ng-container>