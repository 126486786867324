import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-glossary-delete-confirm-dialog',
  templateUrl: './glossary-delete-confirm-dialog.component.html',
  styleUrls: ['./glossary-delete-confirm-dialog.component.scss']
})
export class GlossaryDeleteConfirmDialogComponent {
  public deleted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GlossaryDeleteConfirmDialogComponent>
  ) {}
  
  delete() {
    this.deleted = true;
    this.dialogRef.close();
  }
}
