<h1 mat-dialog-title i18n="@@DatePeriod_Header">Pick another date period</h1>
<mat-dialog-content>
  <div class="flex">
    <div>
      <mat-form-field>
        <input matInput [(ngModel)]="fromInput" [matDatepicker]="fromDate" placeholder="From date" i18n-placeholder="@@DatePeriod_FromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput [(ngModel)]="toInput" [matDatepicker]="toDate" placeholder="To date" i18n-placeholder="@@DatePeriod_ToDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancel()" type="button" i18n="@@DatePeriod_btnCancel">Cancel</button>
  <button mat-raised-button (click)="submit()" color="primary" [disabled]="!valid" i18n="@@DatePeriod_btnSetPeriod">Set period</button>
</mat-dialog-actions>