<a [routerLink]="href" *ngIf="active">
  <div class="card-outer">
    <ng-container *ngTemplateOutlet="card"></ng-container>
  </div>
</a>
<div
  class="card-outer disabled"
  [class.upcoming]="upcoming"
  [matTooltip]="stateMessage"
  *ngIf="!active">
  <div class="upcoming__text" *ngIf="upcoming" i18n="@@NavigationCard_CommingSoon">Coming soon</div>
  <ng-container *ngTemplateOutlet="card"></ng-container>
</div>

<ng-template #card>
  <mat-card>
    <mat-icon [svgIcon]="icon"></mat-icon>
    <div class="title">
      {{ name }}
    </div>
    <div class="separator"></div>
    <mat-card-content>
      {{ description }}
    </mat-card-content>
  </mat-card>
</ng-template>