<table class="table-card">
  <thead>
    <tr>
      <th *ngFor="let column of columns; let i = index"
          [class.column__fill]="i === 0"
          [class.column]="i > 0">
        {{ column.label }}
      </th>
    </tr>
  </thead>
  <ng-container *ngFor="let row of rows; let i = index">
    <tbody class="table-card__group">
      <tr>
        <td *ngFor="let cell of row.cells">
          <a [routerLink]="cell.href" [queryParams]="cell.hrefQuery" *ngIf="cell.href">{{ cell.value }}</a>
          <span *ngIf="!cell.href">{{ cell.value }}</span>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr class="table-card__spacing" *ngIf="i < rows.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>