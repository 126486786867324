import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Language } from '../models/language';
import { ILanguageService } from '../interfaces/ilanguage.service';
import { ILanguageServiceToken } from '../interfaces/ilanguage.service.token';

import { Observable } from 'rxjs';
import { concat, map, reduce } from 'rxjs/operators';

@Injectable()
export class LanguagesStatsResolver implements Resolve<Language[]> {
  constructor(
    @Inject(ILanguageServiceToken) private languageService: ILanguageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Language[]> {
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.languageService.getProfileLanguages()
      .pipe(concat(this.languageService.getTextAnalyzerLanguages(groupId, userId)))
      .pipe(reduce((x, y) => x.concat(y)))
      .pipe(map(x => x.filter((x, y, z) => z.map(a => a.code).indexOf(x.code) === y)))
      .pipe(map(x => x.sort((a, b) => a.name.localeCompare(b.name))));
  }
}
