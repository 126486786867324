
import {from as observableFrom,  Observable ,  Subscription ,  Subject } from 'rxjs';

import {mergeMap, map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Category, Rule } from '../../models/spelling';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../../interfaces/ispelling.service.token';






@Component({
  selector: 'app-phonetic-spelling-recommend-dialog',
  templateUrl: './phonetic-spelling-recommend-dialog.component.html',
  styleUrls: ['./phonetic-spelling-recommend-dialog.component.scss']
})
export class PhoneticSpellingRecommendDialogComponent implements OnInit, OnDestroy {
  public categories: Category[] = [];
  public expanded: boolean[] = [];
  public rules: Rule[][] = [];
  public originalRules: Rule[][] = [];

  public loading: boolean = false;
  public disabled: boolean = false;
  
  private subscription?: Subscription;
  private updateSubscription?: Subscription;
  public onUpdate: Subject<Rule[]> = new Subject();
  
  constructor(
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService,
    @Inject(MAT_DIALOG_DATA) categories: Category[],
    public dialogRef: MatDialogRef<PhoneticSpellingRecommendDialogComponent>
  ) {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i].clone();
      category.indeterminate = false;
      category.enabled = category.recommended;
      this.categories.push(category);
      this.expanded.push(category.indeterminate);
    }
  }

  ngOnInit() {
    this.loading = true;
    this.disabled = true;

    this.subscription = observableFrom(this.categories).pipe(
      mergeMap((category: Category, index: number) => {
        return this.spellingService.getRulesByCategory(category).pipe(
        map((rules: Rule[]) => {
          this.originalRules[index] = rules;
          this.rules[index] = [];
          for (let i = 0; i < rules.length; i++) {
            const rule = rules[i].clone();
            rule.enabled = rule.recommended;
            if(rule.enabled === true){
              this.categories[index].indeterminate = true;
            }
            this.rules[index].push(rule);
          }
        }));
      }))
      .subscribe(undefined,
        err => {
          console.error(err);
          // Handle error here, should probably only show an error message
        }, () => {
          this.loading = false;
          this.disabled = false;
        }
      );
  }
  
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
      this.updateSubscription = undefined;
    }
  }

  updateCategory(category: Category) {
    const index = this.categories.indexOf(category);
    const rules = this.rules[index];

    category.indeterminate = false;

    for (let i = 0; i < rules.length; i++) {
      rules[i].enabled = category.enabled;
    }
  }

  updateRule(category: Category, rule: Rule) {
    const index = this.categories.indexOf(category);
    const rules = this.rules[index];

    let indeterminate = false;
    for (let i = 0; i < rules.length; i++) {
      if (rule.enabled !== rules[i].enabled) {
        indeterminate = true;
        break;
      }
    }

    category.enabled = rule.enabled;
    category.indeterminate = indeterminate;
  }
  
  update() {
    if (this.disabled) return;
    this.disabled = true;
    
    const rules: Rule[] = [];
    for (let i = 0; i < this.rules.length; i++) {
      for (let j = 0; j < this.rules[i].length; j++) {
        if (this.rules[i][j].enabled !== this.originalRules[i][j].enabled) {
          rules.push(this.rules[i][j]);
        }
      }
    }
    
    // We don't need to do anything if nothing has changed.
    if (rules.length === 0) {
      this.dialogRef.close();
      return;
    }
    
    this.updateSubscription = observableFrom(rules).pipe(
      mergeMap((rule: Rule) => {
        return this.spellingService.updateRuleEnabled(rule);
      }))
      .subscribe(undefined, () => {
        this.disabled = false;
        // Handle error
      }, () => {
        this.onUpdate.next(rules);
        this.onUpdate.complete();

        this.dialogRef.close();
      });
  }
}
