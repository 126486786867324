import { ILocalization } from "app/interfaces/ILocalization";

export class Localization_nbNO implements ILocalization {
  public getPageXOfY(x: number, y: number) {
    return `Side ${x} av ${y}`;
  }

  public getItemsPerPage() {
    return "Tegn per side:";
  }

  public getNextPage() {
    return "Neste side";
  }

  public getPreviousPage() {
    return "Forrige side";
  }

  public getErrorType() {
    return "Feiltype";
  }

  public getPercentage() {
    return "Prosent av feiltyper";
  }

  public getAmount() {
    return "Antall feil";
  }
}