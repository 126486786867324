<ng-template #reportRow let-item="item">
  <tbody class="card">
    <tr>
      <td class="card__title">
        <a [routerLink]="'./' + item.id">{{ item.name }}</a>
      </td>
      <td>
        {{ getLanguageByCode(item.language) }}
      </td>
      <td>
        {{ item.mostCommonErrorType }}
      </td>
      <td>
        <span *ngIf="item.completedAnalysis" i18n="@@TxtAnalyserUpload_Completed">Completed</span>
        <ng-container *ngIf="!item.completedAnalysis">
          <span *ngIf="item.errorOnAnalysis !== undefined" i18n="@@TxtAnalyserUpload_Error">Error ({{ item.errorOnAnalysis }})</span>
          <span *ngIf="item.errorOnAnalysis === undefined" i18n="@@TxtAnalyserUpload_Running">Running</span>
        </ng-container>
      </td>
      <td class="card__description">
        {{ item.created }}
      </td>
    </tr>
  </tbody>
</ng-template>

<app-header></app-header>

<h3 i18n="@@TxtAnalyserUpload_Upload">Upload</h3>

<div class="description" i18n="@@TxtAnalyserUpload_Description">
  Upload documents for analysis here
</div>

<button mat-button color="primary" (click)="openUploadDialog()"  i18n="@@TxtAnalyserUpload_UploadNewBtn">Upload new document</button>

<hr>

<h3 i18n="@@TxtAnalyserUpload_LatestReport">Latest reports</h3>

<div class="description" i18n="@@TxtAnalyserUpload_LatestReportDescription">
  View the last five reports
</div>

<table>
  <thead>
    <tr>
      <th i18n="@@TxtAnalyserUpload_LatestDocument">Document</th>
      <th i18n="@@TxtAnalyserUpload_LatestLanguage">Language</th>
      <th i18n="@@TxtAnalyserUpload_LatestCommonErrorType">Most common error type</th>
      <th i18n="@@TxtAnalyserUpload_LatestStatus">Status</th>
      <th i18n="@@TxtAnalyserUpload_LatestUploaded">Uploaded</th>
    </tr>
  </thead>
  <ng-container *ngFor="let item of latestReports; let i = index">
    <ng-container *ngTemplateOutlet="reportRow;context: { item: item }"></ng-container>
    <tbody>
      <tr class="card__spacing" *ngIf="i < latestReports.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>

<div class="actions">
  <a mat-button color="primary" routerLink="./all" i18n="@@TxtAnalyserUpload_ViewAllReports">View all reports</a>
</div>