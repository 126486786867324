import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Country } from '../models/country';
import { ICountryService } from '../interfaces/icountry.service';
import { ICountryServiceToken } from '../interfaces/icountry.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class CountriesResolver implements Resolve<Country[]> {
  constructor(
    @Inject(ICountryServiceToken) private countryService: ICountryService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Country[]> {
    return this.countryService.getCountries();
  }
}
