import { Component, OnInit, Input } from '@angular/core';
import { DataTable, IDataColumn, IDataGroup } from 'app/models/stats';

@Component({
  selector: 'app-stats-grouped-table',
  templateUrl: './stats-grouped-table.component.html',
  styleUrls: ['./stats-grouped-table.component.scss']
})
export class StatsGroupedTableComponent {
  @Input('data')
  public data?: DataTable;

  public get columns(): IDataColumn[] {
    if (!this.data) return [];

    return this.data.cols;
  }

  public get groups(): IDataGroup[] {
    if (!this.data) return [];

    return this.data.groups;
  }
}
