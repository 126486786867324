import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-create-user-success-dialog',
  templateUrl: './create-user-success-dialog.component.html',
  styleUrls: ['./create-user-success-dialog.component.scss']
})
export class CreateUserSuccessDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public email: string,
    private dialogRef: MatDialogRef<CreateUserSuccessDialogComponent>,
    private router: Router
  ) {}

  public close() {
    this.dialogRef.close();
    this.router.navigate(["/login"]);
  }
}
