import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomRule } from '../../models/spelling';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../../interfaces/ispelling.service.token';
import { LingappsError } from '../../services/auth.service';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-phonetic-spelling-edit-dialog',
  templateUrl: './phonetic-spelling-edit-dialog.component.html',
  styleUrls: ['./phonetic-spelling-edit-dialog.component.scss']
})
export class PhoneticSpellingEditDialogComponent {
  public from: string;
  public to: string;
  public example: string;

  public loading: boolean = false;
  public hasError: boolean = false;
  public errorMessage = "";
  public isRtL = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public rule: CustomRule,
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService,
    private dialogRef: MatDialogRef<PhoneticSpellingEditDialogComponent>,
    private direction: Directionality
  ) {
    this.from = rule.from;
    this.to = rule.to;
    this.example = rule.example;
    this.isRtL = direction.value === 'rtl';
  }

  onSubmit() {
    if (this.loading || !this.from || !this.to) return;

    this.loading = true;

    this.hasError = false;
    this.errorMessage = "";

    const rule = this.rule.clone();
    rule.from = this.from;
    rule.to = this.to;
    rule.example = this.example;

    this.spellingService.updateCustomRule(rule)
      .subscribe(x => {
        this.rule.from = x.from;
        this.rule.to = x.to;
        this.rule.example = x.example;
      }, (error: LingappsError) => {
        this.hasError = true;

        this.errorMessage = error.message;

        this.loading = false;
      }, () => {
        this.dialogRef.close();
      });
  }
}
