<h1 mat-dialog-title i18n="@@AccountTransfer_Header">Transfer profile settings</h1>
<form (ngSubmit)="onTransferProfileSettings()" [formGroup]="form" novalidate>
  <mat-dialog-content>
    <mat-form-field class="full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@AccountTransfer_PreviousUsername">Previous username</mat-placeholder>
      <input name="username" matInput formControlName="username">
      <mat-error *ngIf="errorUsername?.PROFILE_USERNAME_NOT_SET" i18n="@@AccountTransfer_UsernameEmpty">Username is empty.</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@AccountTransfer_PreviousPassword">Previous password</mat-placeholder>
      <input name="password" matInput type="password" formControlName="password">
      <mat-error *ngIf="errorPassword?.PROFILE_PASSWORD_NOT_SET" i18n="@@AccountTransfer_PasswordEmpty">Password is empty.</mat-error>
      <mat-error *ngIf="errorPassword?.USER_LOGIN_FAILED" i18n="@@AccountTransfer_LoginFailed">Incorrect username or password.</mat-error>
    </mat-form-field>
    <div class="error" *ngIf="unexpectedError" i18n="@@AccountTransfer_UnexpectedError">An unexpected error occurred.</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="@@AccountTransfer_btnClose">Close</button>
    <button mat-raised-button color="primary" [disabled]="!this.form || this.form!.disabled" i18n="@@AccountTransfer_btnTransfer">Transfer</button>
  </mat-dialog-actions>
</form>