export const environment = {
  production: false,
  application: 'account',
  services: {
    login: "https://services.lingapps.titan.wizkids.dk/user/login",
    logout: "https://services.lingapps.titan.wizkids.dk/user/logout",
    validate: "https://services.lingapps.titan.wizkids.dk/user/validateSession",
    validateUsername: "https://services.lingapps.titan.wizkids.dk/user/usernameAvailable",
    getUserInfo: "https://services.lingapps.titan.wizkids.dk/user/getUserInfo",
    unicLogin: "https://services.lingapps.titan.wizkids.dk/user/unicLogin",
    getLoginUrl: "https://services.lingapps.titan.wizkids.dk/user/getOpenIdLoginUrl",
    createUserByEmail: "https://services.lingapps.titan.wizkids.dk/user/createUserByEmail",
    forgotUsernameOrPassword: "https://services.lingapps.titan.wizkids.dk/user/forgotUsernameOrPassword",
    claimStukLicense: "https://services.lingapps.titan.wizkids.dk/license/claimStukLicense"
  },
  collectionUrl: "https://account-api.titan.wizkids.dk",
  unic: {
    // https://viden.stil.dk/display/INFRA2/SSO+Proxy+snitflade+til+login+og+autentificering
    redirectUrl: "https://account.titan.wizkids.dk/login_unic",

    // md5(environment.services.unicLogin + '?application=account&redirect=' + encodeURIComponent('https://account.wizkids.dk/login_unic') + SECRET)
    auth: "65f8ca68f32bcda3a57592c3cdd51dda"
  },
  google: {
    appId: "338702813688",
    developerKey: "AIzaSyBAFLFUHh8ahH9vja0rRh71qekhBAmyI48",
    clientId: "338702813688-g3s6agq810lo5i25g7jhb1jp2sdun7tu.apps.googleusercontent.com"
  },
  googleAnalytics: {
    measurementId: "G-DGJ11PEP0Z",
    apiSecret: "XT_6JFc8TTqACFT_7TmG3g"
  },
  onedrive: {
    clientId: "aae7b108-c968-4c58-b37f-ea66a60b3e5d"
  }
};
