<app-breadcrumb></app-breadcrumb>
<h1 class="page" i18n="@@Account_YourAccountPageHeader">Your account</h1>

<div class="items">
  <div class="items__row">
    <h2 i18n="@@Account_LanguageRowHeader">Display language</h2>
    <mat-card>
      <mat-card-content [formGroup]="languageForm">
        <mat-form-field class="input-full-width">
          <mat-select formControlName="language" (selectionChange)="onUpdate()" (keyup.enter)="onUpdate()" (keyup)="onUpdateDelay()" placeholder="Language" i18n-placeholder="@@Account_Language">
            <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="items__row">
    <h2 i18n="@@Account_YourLoginInformationRowHeader">Your login information</h2>
    <mat-card>
      <mat-card-content>
        <mat-form-field class="input-full-width">
          <input matInput placeholder="Username" [(ngModel)]="user.username" i18n-placeholder="@@Account_Username" disabled>
        </mat-form-field>
        <mat-form-field class="input-full-width">
          <input matInput placeholder="Password" value="****************" i18n-placeholder="@@Account_Password" type="password" disabled>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="primary" (click)="openPasswordChangeDialog()" i18n="@@Account_btnChangePassword">Change your password</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="items__row" *ngIf="!user.isStukUser()">
    <h2 i18n="@@Account_TransferProfileSettingsRowHeader">Transfer profile settings</h2>
    <mat-card>
      <mat-card-content>
        <div class="input-full-width transfer__text" i18n="@@Account_TransferProfileText">
          If you have an earlier Wizkids/Lingapps/AppWriter login. You can
          transfer your old settings to your current account.
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="primary" (click)="openTransferProfileSettingsDialog()" i18n="@@Account_btnTransfer">Transfer profile settings</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="items__row" *ngIf="user.isStukUser()">
    <h2>Forbind med skole/privatkonto</h2>
    <mat-card>
      <mat-card-content>
        <div class="input-full-width transfer__text">
          Her kan du binde din skole/private AppWriter-bruger sammen med denne
          SPS licens for at dele fagordlister og alle andre indstillinger.
        </div>
      </mat-card-content>
      <mat-card-actions>
        <form [action]="getStukMergeURL()" method="POST" target="_blank" ngNoForm>
          <input type="hidden" name="parameters" [value]="getUserAuthTokenParameters()">
          <button mat-button color="primary" type="submit">Start</button>
        </form>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<h1 class="page" i18n="@@Account_DownloadApplicationsHeader">Download applications</h1>
<div class="applications">
  <a [href]="application.downloadUrl" *ngFor="let application of applications">
    <mat-card>
      <div class="application-img-wrapper">
        <img mat-card-image [src]="application.imageUrl">
      </div>
      <mat-card-title>
        {{ application.name }}
      </mat-card-title>
    </mat-card>
  </a>
</div>
