import { IPage } from './../models/stats';
import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IStatsService } from '../interfaces/istats.service';
import { IStatsServiceToken } from '../interfaces/istats.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class StatsPageResolver implements Resolve<IPage> {
  constructor(
    @Inject(IStatsServiceToken) private statsService: IStatsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPage> {
    return this.statsService.getPage(state.url);
  }
}
