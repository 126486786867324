import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IgnoreDocument } from '../models/txtanalyser/IgnoreDocument';
import { ITxtAnalyserService } from '../interfaces/itxtanalyser.service';
import { ITxtAnalyserServiceToken } from '../interfaces/itxtanalyser.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class TxtAnalyserIgnoreDocumentResolver implements Resolve<IgnoreDocument> {
  constructor(
    @Inject(ITxtAnalyserServiceToken) private txtAnalyserService: ITxtAnalyserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IgnoreDocument> {
    const language = route.paramMap.get('language')!;
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    let documentId: string|undefined = route.paramMap.get('documentId') || undefined;
    if (!documentId)
      throw new Error("Missing document ID");

    return this.txtAnalyserService.getIgnoreDocument(documentId, language, groupId, userId);
  }
}