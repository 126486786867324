import { ILocalization } from "app/interfaces/ILocalization";

export class Localization_daDK implements ILocalization {
  public getPageXOfY(x: number, y: number) {
    return `Side ${x} af ${y}`;
  }

  public getItemsPerPage() {
    return "Visninger per side:";
  }

  public getNextPage() {
    return "Næste side";
  }

  public getPreviousPage() {
    return "Forrige side";
  }

  public getErrorType() {
    return "Kategori";
  }

  public getPercentage() {
    return "Andel af fejl";
  }

  public getAmount() {
    return "Antal";
  }
}