export class IgnoreDocument {
  public constructor(
    public id: string,
    public language: string,

    public name: string,
    public ignores: number,
    
    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}
}