<h1 mat-dialog-title i18n="@@AddGlossaryToGroupPublic_Header">Make glossaries public</h1>
<mat-dialog-content>
  <span i18n="@@AddGlossaryToGroupPublic_Description">
    The glossaries you're about to share will be made public.
  </span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    <span i18n="@@AddGlossaryToGroupPublic_btnCancel">Cancel</span>
  </button>
  <button (click)="accept()" mat-raised-button color="primary">
    <span i18n="@@AddGlossaryToGroupPublic_btnAccept">Accept</span>
  </button>
</mat-dialog-actions>