import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Rule, Category, CustomRule } from '../../models/spelling';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { CollectionService, Collection, Template, Data, Item } from '../collection.service';
import { Observable } from 'rxjs';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { map, flatMap } from 'rxjs/operators';

const URI: string = "/aw/spelling";
const PROFILE_URI: string = "/profile";

@Injectable()
export class SpellingCollectionService implements ISpellingService {
  constructor(
    private collectionService: CollectionService,
    private route: ActivatedRoute,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService
  ) {}

  private toCategoryUri(category: Category): string {
    let uri: string;
    if (category.groupId !== undefined) {
      uri = "/groups/" + encodeURIComponent(category.groupId.toString());
      if (category.impersonatedUserId !== undefined) {
        uri += "/members/" + encodeURIComponent(category.impersonatedUserId.toString());
      }
    } else {
      uri = PROFILE_URI;
    }

    return uri + URI;
  }

  private toRuleUri(rule: Rule): string {
    let uri: string;
    if (rule.groupId !== undefined) {
      uri = "/groups/" + encodeURIComponent(rule.groupId.toString());
      if (rule.impersonatedUserId !== undefined) {
        uri += "/members/" + encodeURIComponent(rule.impersonatedUserId.toString());
      }
    } else {
      uri = PROFILE_URI;
    }

    return uri + URI;
  }

   private toCustomRuleUri(rule: CustomRule): string {
    let uri: string;
    if (rule.groupId !== undefined) {
      uri = "/groups/" + encodeURIComponent(rule.groupId.toString());
      if (rule.impersonatedUserId !== undefined) {
        uri += "/members/" + encodeURIComponent(rule.impersonatedUserId.toString());
      }
    } else {
      uri = PROFILE_URI;
    }

    return uri + URI;
  }

  private _getCustomRule(item: Item) {
    const id = item.getDataAsString("id");
    const example = item.getDataAsString("example");
    const enabled = item.getDataAsBoolean("enabled");
    const language = item.getDataAsString("language");
    const from = item.getDataAsString("from");
    const to = item.getDataAsString("to");

    let groupId: number | undefined;
    let userId: number | undefined;
    if (item.hasData("groupId")) {
      if (item.hasData("userId")) {
        userId = item.getDataAsInteger("userId");
      }
      groupId = item.getDataAsInteger("groupid");
    }

    return new CustomRule(id, language, example, enabled, from, to, groupId, userId);
  }

  getCategories(language: string, groupId?: number, userId?: number): Observable<Category[]> {
    var uri: string = URI + "/" + encodeURIComponent(language);
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + userId + uri;
      }
      uri = "/groups/" + groupId + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map((collection: Collection) => {
      let categories: Category[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        let id = item.getDataAsString("id");
        let name = item.getDataAsString("name");
        let description = item.getDataAsString("description");
        let language = item.getDataAsString("language");
        let enabled = item.getDataAsBoolean("enabled");
        let recommended = item.getDataAsBoolean("recommended");
        let indeterminate = item.getDataAsString("state") === "UNDETERMINED";

        categories.push(
          new Category(
            id, language, name, description, enabled, recommended, indeterminate, item.getDataAsIntegerOrUndefined("groupId"), item.getDataAsIntegerOrUndefined("userId")
          )
        );
      }
      return categories;
    }));
  }

  getRulesByCategory(category: Category): Observable<Rule[]> {
    const uri = this.toCategoryUri(category)
      + "/" + encodeURIComponent(category.language)
      + "/" + encodeURIComponent(category.id) + "/rules";

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map((collection: Collection) => {
      const rules: Rule[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        let item = collection.items[i];

        let id: string = item.getDataAsString("id");
        let name: string = item.getDataAsString("name");
        let example: string = item.getDataAsString("example");
        let enabled: boolean = item.getDataAsBoolean("enabled");
        let category: string = item.getDataAsString("category");
        let language: string = item.getDataAsString("language");
        let recommended: boolean = item.getDataAsBoolean("recommended");
        let from: string = item.getDataAsString("from");
        let to: string = item.getDataAsString("to");
        let indeterminate = item.getDataAsString("state") === "UNDETERMINED";

        rules.push(
          new Rule(
            id, category, language, name, example, enabled, recommended, indeterminate, from,
            to, item.getDataAsIntegerOrUndefined("groupId"), item.getDataAsIntegerOrUndefined("userId")
          )
        );
      }

      return rules;
    }));
  }
  
  getCustomRules(language: string, groupId?: number, userId?: number): Observable<CustomRule[]> {
    let uri: string = URI + "/" + encodeURIComponent(language) + "/customrules";
    if (groupId !== undefined) {
      if (userId !== undefined) {
        uri = "/members/" + encodeURIComponent(userId.toString()) + uri;
      }
      uri = "/groups/" + encodeURIComponent(groupId.toString()) + uri;
    } else {
      uri = PROFILE_URI + uri;
    }

    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map((collection: Collection) => {
      const rules: CustomRule[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        rules.push(this._getCustomRule(collection.items[i]));
      }
      return rules;
    }));
  }

  updateCategoryEnabled(category: Category): Observable<any> {
    const uri = this.toCategoryUri(category)
      + "/" + encodeURIComponent(category.language)
      + "/" + encodeURIComponent(category.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("enabled", category.enabled ? "True" : "False")
    ]);

    return collection.put(template);
  }
  
  updateRuleEnabled(rule: Rule): Observable<any> {
    const uri = this.toRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/"
      + encodeURIComponent(rule.categoryId) + "/rules/"
      + encodeURIComponent(rule.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("enabled", rule.enabled ? "True" : "False")
    ]);

    return collection.put(template);
  }

  updateCustomRuleEnabled(rule: CustomRule): Observable<any> {
    const uri = this.toCustomRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/customrules/"
      + encodeURIComponent(rule.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("enabled", rule.enabled ? "True" : "False")
    ]);

    return collection.put(template);
  }

  createSimpleCustomRule(from: string, to: string, example: string, language: string, groupId?: number, userId?: number): Observable<CustomRule> {
    const snapshot = this.route.snapshot;

    return this.createCustomRule(
      new CustomRule(
        from + ":" + to,
        language,
        example,
        true,
        from,
        to,
        groupId,
        userId
      )
    );
  }

  createCustomRule(rule: CustomRule): Observable<CustomRule> {
    const uri = this.toCustomRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/customrules";
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("id", rule.id),
      new Data("from", rule.from),
      new Data("to", rule.to),
      new Data("example", rule.example),
      new Data("enabled", rule.enabled ? "True" : "False"),
      new Data("language", rule.language)
    ]);

    return collection.post(template)
      .pipe(map(x => JSON.parse(x)))
      .pipe(map(x => Collection.parse(collection.getHttpClient(), x['collection'])))
      .pipe(map(x => {
        const items = x.items;
        console.log(items);
        if (items.length > 0) {
          return this._getCustomRule(items[0]);
        }
        return rule;
      }));
  }

  updateCustomRule(rule: CustomRule): Observable<CustomRule> {
    const uri = this.toCustomRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/customrules/"
      + encodeURIComponent(rule.id);
    const collection = this.collectionService.createCollection(uri);

    const template = new Template([
      new Data("example", rule.example),
      new Data("from", rule.from),
      new Data("to", rule.to),
    ]);

    const newId = rule.from + ":" + rule.to;
    if (newId === rule.id) {
      return collection.put(template)
        .pipe(map(x => JSON.parse(x)))
        .pipe(map(x => Collection.parse(collection.getHttpClient(), x['collection'])))
        .pipe(map(x => {
          const items = x.items;
          console.log(items);
          if (items.length > 0) {
            return this._getCustomRule(items[0]);
          }
          return rule;
        }));
    } else {
      return this.deleteCustomRule(rule)
        .pipe(flatMap(() => {
          rule.id = newId;
          return this.createCustomRule(rule);
        }));
    }
  }

  deleteCustomRule(rule: CustomRule): Observable<CustomRule> {
    const uri = this.toCustomRuleUri(rule)
      + "/" + encodeURIComponent(rule.language) + "/customrules/"
      + encodeURIComponent(rule.id);
    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => rule));
  }
}
