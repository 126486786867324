import { Observable } from 'rxjs';
export interface IFile {
  readonly source: string;
  name: string;
  mimeType: string;

  progress: number | undefined;
  state: IFileState;
  error: any;

  readAsArrayBuffer(): Observable<ArrayBuffer>;
}

export enum IFileState {
  Pending,
  Loading,
  Complete,
  Error
}