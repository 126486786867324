import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IGlossary, Word, EnabledState, getEnabledState, GlossarySearchPage
} from '../../models/glossary';
import { Group } from '../../models/groups';
import { IGlossaryService, ISearchQuery, IGlossaryState } from '../../interfaces/iglossary.service';
import { CollectionService, Collection, Item, Template, Data } from '../collection.service';
import { Observable, from } from 'rxjs';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

const URI: string = "/aw/glossaries";
const PROFILE_URI: string = "/profile";

@Injectable()
export class GlossaryCollectionService implements IGlossaryService {
  constructor(
    private collectionService: CollectionService,
    private route: ActivatedRoute,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService,
    private httpClient: HttpClient
  ) {}

  private toUriGlossary(glossary: IGlossary, includeGlossary: boolean = false): string {
    let uri = this.toUri(glossary.groupId, glossary.impersonatedUserId);
    if (includeGlossary) {
      uri += (glossary.language ? "/" + encodeURIComponent(glossary.language) : "/na")
      + "/" + encodeURIComponent(glossary.id.toString());
    }
    return uri;
  }

  private getBaseUri(groupId?: number, userId?: number): string {
    let uri: string = PROFILE_URI;
    if (groupId) {
      uri = "/groups/" + encodeURIComponent(groupId.toString());
      if (userId) {
        uri += "/members/" + encodeURIComponent(userId.toString());
      }
    }

    return uri;
  }

  private toUri(groupId?: number, userId?: number): string {
    return this.getBaseUri(groupId, userId) + URI;
  }

  private getGlossaryByItem(item: Item): IGlossary|undefined {
    const id = item.getDataAsInteger("id");
    if (id === -1) return undefined;
    const language = item.getDataAsString("language");

    let groupId: number|undefined;
    let groupDisplayName: string|undefined;
    if (item.hasData("groupId")) {
      groupId = item.getDataAsInteger("groupId");
      groupDisplayName = item.getDataAsString("groupDisplayName");
    }

    let impersonatedUserId: number|undefined;
    let impersonatedUserDisplayName: string|undefined;
    if (item.hasData("userId")) {
      impersonatedUserId = item.getDataAsInteger("userId");
      impersonatedUserDisplayName = item.getDataAsString("userDisplayName");
    }

    return {
      id: id,
      language: language,
      groupId: groupId,
      groupDisplayName: groupDisplayName,
      impersonatedUserId: impersonatedUserId,
      impersonatedUserDisplayName: impersonatedUserDisplayName,
      name: item.getDataAsString("name"),
      description: item.getDataAsString("description"),
      targetAudience: item.getDataAsString("targetAudience"),
      subject: item.getDataAsString("subject"),
      enabled: getEnabledState(item.getDataAsString("enabled")),
      subscribed: item.getDataAsBoolean("subscribed"),
      isPublic: item.getDataAsBoolean("public"),
      subscribers: item.getDataAsInteger("subscribers"),
      rating: item.hasData("rating") ? item.getDataAsFloat("rating") : undefined,
      userRating: item.hasData("userRating") ? item.getDataAsFloat("userRating") : undefined,
      ownedByMe: item.getDataAsBoolean("ownedByMe"),
      ownerId: item.getDataAsIntegerOrUndefined("ownerId"),
      ownerDisplayName: item.getDataAsStringOrUndefined("ownerDisplayName"),
      sharedByGroupId: item.getDataAsIntegerOrUndefined("sharedByGroupId"),
      sharedByGroupDisplayName: item.getDataAsStringOrUndefined("sharedByGroupDisplayName")
    };
  }

  getGlossariesPublishers(): Observable<Record<string, string>[]> {
    const uri = '/profile/aw/glossaries/publishers';

    return this.collectionService.getCollection(uri)
      .pipe(
        map(c => {
          return c.items.map(i => i.data.reduce((a, b) => {
            return {...a, ...{[b.name]: b.value}}
          }, {}))
        }),
      );
  }

  getGlossariesTargetAudience(): Observable<Record<string, string>[]> {
    const uri = '/profile/aw/glossaries/targetAudience';

    return this.collectionService.getCollection(uri)
      .pipe(
        map(c => {
          return c.items.map(i => i.data.reduce((a, b) => {
            return {...a, ...{[b.name]: b.value}}
          }, {}))
        }),
      );
  }

  getGlossariesSubjects(): Observable<Record<string, string>[]> {
    const uri = '/profile/aw/glossaries/subjects';

    return this.collectionService.getCollection(uri)
      .pipe(
        map(c => {
          return c.items.map(i => i.data.reduce((a, b) => {
            return {...a, ...{[b.name]: b.value}}
          }, {}))
        }),
      );
  }

  getGlossaries(groupId?: number, userId?: number, includeOnlyOwned: boolean = false): Observable<IGlossary[]> {
    let uri = this.toUri(groupId, userId);
    if (includeOnlyOwned) {
      uri += "?q=owned";
    }
    return this.collectionService.getCollection(uri)
      .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
      .pipe(map((collection: Collection) => {
        const glossaries: IGlossary[] = [];

        for (let i = 0; i < collection.items.length; i++) {
          const item = this.getGlossaryByItem(collection.items[i]);
          if (item !== undefined) {
            glossaries.push(item);
          }
        }

        return glossaries;
      }));
  }

  searchGlossaries(query: ISearchQuery, groupId?: number, userId?: number): Observable<GlossarySearchPage> {
    const uri = this.getBaseUri(groupId, userId) + "/aw/searchGlossaries";
    const queryBuilder: string[] = [];
    if (query.includeOtherUserGlossaries !== undefined) {
      queryBuilder.push("includeOtherUserGlossaries=" + (query.includeOtherUserGlossaries ? "true" : "false"));
    }
    if (query.includePublisherGlossaries !== undefined) {
      queryBuilder.push("includePublisherGlossaries=" + (query.includePublisherGlossaries ? "true" : "false"));
    }
    if (query.query !== undefined) {
      queryBuilder.push("query=" + encodeURIComponent(query.query));
    }
    if (query.pageIndex !== undefined) {
      queryBuilder.push("pageIndex=" + query.pageIndex);
    }
    if (query.pageSize !== undefined) {
      queryBuilder.push("pageSize=" + query.pageSize);
    }
    if (query.targetAudience !== undefined) {
      queryBuilder.push("targetAudience=" + query.targetAudience);
    }
    if (query.subject !== undefined) {
      queryBuilder.push("subject=" + query.subject);
    }
    if (query.language !== undefined) {
      queryBuilder.push('language=' + query.language);
    }
    if (query.publisher !== undefined) {
      queryBuilder.push("publisher=" + query.publisher);
    }

    if (query.sort !== undefined && query.sort.active !== undefined) {
      const map: {[key: string]: string} = {
        name: 'Name',
        language: 'CultureName',
        subscribers: 'SubscriptionsCount',
        ownerDisplayName: 'OwnerName',
        rating: 'Rating'
      };
      if (map.hasOwnProperty(query.sort.active)) {
        queryBuilder.push("orderBy=" + encodeURIComponent(map[query.sort.active]) + (query.sort.direction === "desc" ? "_desc" : ""));
      }
    }

    return this.collectionService.getCollection(uri + (queryBuilder.length > 0 ? "?" + queryBuilder.join("&") : ""))
      .pipe(map(collection => {
        const page = new GlossarySearchPage();

        for (let i = 0; i < collection.items.length; i++) {
          const item = collection.items[i];
          page.totalGlossaries = item.getDataAsInteger("totalItems");

          const gItem = this.getGlossaryByItem(collection.items[i]);

          if (gItem !== undefined) {
            page.glossaries.push(gItem);
          }
        }

        return page;
      }));
  }

  updateGlossary(glossary: IGlossary, state?: IGlossaryState): Observable<IGlossary> {
    const uri = this.toUriGlossary(glossary, true);

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("name", state && state.name !== undefined ? state.name : glossary.name),
      new Data("description", state && state.description !== undefined ? state.description : glossary.description),
      new Data("enabled", state && state.enabled !== undefined ? EnabledState[state.enabled] : EnabledState[glossary.enabled]),
      new Data("language", state && state.language !== undefined ? state.language : glossary.language),
      new Data("public", (state && state.isPublic !== undefined ? state.isPublic : glossary.isPublic) ? "true" : "false"),
      new Data("subscribed", (state && state.subscribed !== undefined ? state.subscribed : glossary.subscribed) ? "true" : "false")
    ]);

    return collection.put(template)
      .pipe(map(() => glossary));
  }

  updateGlossarySubscription(glossary: IGlossary): Observable<IGlossary> {
    const uri = this.toUriGlossary(glossary, true) + "/subscribe";

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("enabled", EnabledState[glossary.enabled]),
      new Data("subscribed", glossary.subscribed ? "true" : "false")
    ]);

    return collection.put(template)
      .pipe(map(() => glossary));
  }

  deleteGlossary(glossary: IGlossary): Observable<IGlossary> {
    const uri = this.toUriGlossary(glossary, true);

    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => glossary));
  }

  createGlossary(name: string, description: string, language: string, isPublic: boolean, groupId?: number, userId?: number): Observable<IGlossary> {
    const collection = this.collectionService.createCollection(this.toUri(groupId, userId));

    const template: Template = new Template([
      new Data("name", name),
      new Data("description", description),
      new Data("enabled", "Enabled"),
      new Data("language", language),
      new Data("public", isPublic ? "true" : "false"),
    ]);

    return collection.post(template)
      .pipe(map(text => {
        const collection = this.collectionService.createCollectionByJsonString(text);
        if (collection.items.length === 0 || !collection.items[0])
          throw new Error("Unable to get created glossary.");
        const item = this.getGlossaryByItem(collection.items[0]);
        if (item === undefined)
          throw new Error("ID of the created glossary is -1.");
        return item;
      }));
  }

  removeWord(word: Word, glossary: IGlossary): Observable<Word> {
    const uri = this.toUriGlossary(glossary, true)
      + "/entries/" + encodeURIComponent(word.text);

    const collection = this.collectionService.createCollection(uri);

    return collection.delete()
      .pipe(map(() => word));
  }

  addWordsToGlossary(glossary: IGlossary, words: Word[]): Observable<IGlossary> {
    const texts: string[] = [];
    for (let i = 0; i < words.length; i++) {
      texts.push(words[i].text);
    }

    const text = texts.join(" ");

    const uri = this.toUriGlossary(glossary, true) + "/addtext";

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("text", text)
    ]);

    return collection.post(template)
      .pipe(map(() => glossary));
  }

  removeWordsFromGlossary(glossary: IGlossary, words: Word[]): Observable<IGlossary> {
    const texts: string[] = [];
    for (let i = 0; i < words.length; i++) {
      texts.push(words[i].text);
    }

    const text = texts.join(" ");

    const uri = this.toUriGlossary(glossary, true) + "/removetext";

    return this.httpClient.post(environment.collectionUrl + uri, { text: text }, { responseType: 'text' })
      .pipe(map(() => glossary));
  }

  getWordsByGlossary(glossary: IGlossary): Observable<Word[]> {
    const uri = this.toUriGlossary(glossary, true) + "/entries";

    return this.collectionService.getCollection(uri)
      .pipe(map((collection) => {
        const words: Word[] = [];
        for (let i = 0; i < collection.items.length; i++) {
          words.push(new Word(
            glossary.id,
            collection.items[i].getDataAsString("word"),
            collection.items[i].getDataAsBoolean("caseInsensitiveMatch"),
            collection.items[i].getDataAsBoolean("exactMatch"),
            collection.items[i].getDataAsBoolean("caseInsensitiveMatchInCurrentGlossary")
          ));
        }

        return words;
      }));
  }

  parseTextToWordsForGlossary(glossary: IGlossary, text: string): Observable<Word[]> {
    if (!text)
      return from([]);

    const uri = this.toUriGlossary(glossary, true) + "/parseText";

    return this.httpClient
      .post(environment.collectionUrl + uri, { "text": text})
      .pipe(map((wordsJson: any) => {
        let words: Word[] = [];
        for (let i = 0; i < wordsJson.length; i++) {
          let item: any = wordsJson[i];

          let word = item['word'];
          let caseInsensitiveMatch = item['caseInsensitiveMatch'];
          let exactMatch = item['exactMatch'];
          let caseInsensitiveMatchInCurrentGlossary = item['caseInsensitiveMatchInCurrentGlossary'];

          words.push(new Word(glossary.id, word, caseInsensitiveMatch, exactMatch, caseInsensitiveMatchInCurrentGlossary));
        }

        return words;
      }));
  }

  parseTextToWordsForTempGlossary(text: string, language: string): Observable<Word[]> {
    const uri = "/profile" + URI
      +  "/" + language
      + "/" + "-1" + "/parseText";

    return this.httpClient
      .post(environment.collectionUrl + uri, { "text": text})
      .pipe(map((wordsJson: any) => {
        let words: Word[] = [];
        for (let i = 0; i < wordsJson.length; i++) {
          let item: any = wordsJson[i];

          let word = item['word'];
          let caseInsensitiveMatch = item['caseInsensitiveMatch'];
          let exactMatch = item['exactMatch'];
          let caseInsensitiveMatchInCurrentGlossary = item['caseInsensitiveMatchInCurrentGlossary'];

          words.push(new Word(-1, word, caseInsensitiveMatch, exactMatch, caseInsensitiveMatchInCurrentGlossary));
        }

        return words;
      }));
  }

   getDefaultWordsByGlossary(glossary: IGlossary): Observable<string[]> {
    const uri = this.toUriGlossary(glossary, true) + "/defaultWords";

    return this.httpClient
      .get(environment.collectionUrl + URI)
      .pipe(map((wordsJson: any) => {
        let defaultWords: string[] = [];
        for (let i = 0; i < wordsJson.length; i++) {
          let word = wordsJson[i];
          defaultWords.push(word);
        }

        return defaultWords;
      }));
  }

 cleanString(text: string): Observable<string> {
    return this.httpClient
      .post(environment.collectionUrl + "/cleanString", { "text": text }, { responseType: 'text' });
  }

  shareGlossaryWithGroup(glossary: IGlossary, group: Group): Observable<Group> {
    if (group.id === undefined)
      throw new Error("Can't share a glossary with an unknown group (groupId is undefined).");
    const uri = this.toUriGlossary(glossary, true) + "/share";

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("glossaryId", glossary.id.toString()),
      new Data("groupId", group.id.toString())
    ]);

    return collection.post(template)
      .pipe(map(() => group));
  }

  rateGlossary(glossary: IGlossary, rating: number): Observable<any> {
    if (glossary.groupId !== undefined && glossary.impersonatedUserId === undefined)
      throw new Error("Group can't rate a glossary");
    const uri = this.toUriGlossary(glossary, true) + "/rating";

    const collection = this.collectionService.createCollection(uri);

    const template: Template = new Template([
      new Data("userRating", rating.toString())
    ]);

    return collection.put(template);
  }
}
