import { PhoneticSpellingsSnackbarComponent, PhoneticSpellingsSnackbarType } from '../phonetic-spellings-snackbar/phonetic-spellings-snackbar.component';
import { Component, Inject, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { detectIE } from '../../utils/utils';
import { Category, Rule, CustomRule } from '../../models/spelling';
import { Language } from '../../models/language';
import { ISpellingService } from '../../interfaces/ispelling.service';
import { ISpellingServiceToken } from '../../interfaces/ispelling.service.token';

import { PhoneticSpellingCreateDialogComponent } from '../phonetic-spelling-create-dialog/phonetic-spelling-create-dialog.component';
import { PhoneticSpellingRecommendDialogComponent } from '../phonetic-spelling-recommend-dialog/phonetic-spelling-recommend-dialog.component';
import { PhoneticSpellingDeleteDialogComponent } from '../phonetic-spelling-delete-dialog/phonetic-spelling-delete-dialog.component';
import { PhoneticSpellingEditDialogComponent } from '../phonetic-spelling-edit-dialog/phonetic-spelling-edit-dialog.component';

import { MediaQueryService } from 'app/services/media-query.service';





interface IRouteData {
  categories: Category[];
  customRules: CustomRule[];
  languages: Language[];
}

interface ICategoryMetadata {
  rules?: Rule[];
  rulesMetadata?: IRuleMetadata[];
  category: Category,
  expanded: boolean;
  loading: boolean;
  enableChanging: boolean;
}

interface IRuleMetadata {
  enableChanging: boolean;
}

@Component({
  selector: 'app-phonetic-spellings',
  templateUrl: './phonetic-spellings.component.html',
  styleUrls: ['./phonetic-spellings.component.scss']
})
export class PhoneticSpellingsComponent implements OnInit, OnDestroy {
  public categoryMetadata: ICategoryMetadata[] = [];
  public categories: Category[] = [];
  public customRules: CustomRule[] = [];
  public customRulesMetadata: IRuleMetadata[] = [];
  public languages: Language[] = [];
  public language: string|undefined;

  public userId?: string;
  public groupId?: string;

  private routeSubscription?: Subscription;
  private opSubscriptions?: Subscription[] = [];

  public isPhone: boolean = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    @Inject(ISpellingServiceToken) private spellingService: ISpellingService,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone
  ) {}
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  ngOnInit() {
    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this.groupId = this.route.snapshot.paramMap.has('groupId') ? this.route.snapshot.paramMap.get('groupId')! : undefined;
    this.userId = this.route.snapshot.paramMap.has('userId') ? this.route.snapshot.paramMap.get('userId')! : undefined;

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.categories = data.categories;
      this.customRules = data.customRules;
      this.languages = data.languages;
      this.language = this.getLanguage();

      this.categoryMetadata = [];
      for (let i = 0; i < this.categories.length; i++) {
        this.categoryMetadata.push(
          {
            rules: undefined,
            category: this.categories[i],
            expanded: false,
            loading: false,
            enableChanging: false
          }
        );
      }

      this.customRulesMetadata = [];
      if (this.customRules) {
        for (let i = 0; i < this.customRules.length; i++) {
          this.customRulesMetadata.push({
            enableChanging: false
          });
        }
      }
    });
  }

  isCategoryRulesLoading(metadata: ICategoryMetadata): boolean {
    if (!metadata.rulesMetadata) return false;
    for (let i = 0; i < metadata.rulesMetadata.length; i++) {
      if (metadata.rulesMetadata[i].enableChanging) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }

    if (this.opSubscriptions) {
      for (let i = 0; i < this.opSubscriptions.length; i++) {
        this.opSubscriptions[i].unsubscribe();
      }
      this.opSubscriptions = undefined;
    }
  }

  toggleExpandCategory(category: Category) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    metadata.expanded = !metadata.expanded;

    if (metadata.rules === undefined) {
      metadata.loading = true;

      let subscription = this.spellingService.getRulesByCategory(category)
        .subscribe((rules: Rule[]) => {
          metadata.rules = rules;
          metadata.rulesMetadata = rules.map(x => {
            return {
              enableChanging: false
            };
          });
        }, (err) => {
          console.error(err);
          if (this.opSubscriptions) {
            let index = this.opSubscriptions.indexOf(subscription);
            if (index !== -1) {
              this.opSubscriptions.splice(index, 1);
              subscription.unsubscribe();
            }
          }
          metadata.loading = false;
        }, () => {
          if (this.opSubscriptions) {
            let index = this.opSubscriptions.indexOf(subscription);
            if (index !== -1) {
              this.opSubscriptions.splice(index, 1);
              subscription.unsubscribe();
            }
          }
          metadata.loading = false;
        });
      if (this.opSubscriptions) {
        this.opSubscriptions.push(subscription);
      }
    }
  }

  getLanguage(): string|undefined {
    if (!this.languages) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        return this.languages[i].code;
      }
    }
    return undefined;
  }

  getFullLanguagePath(language?: string): string|undefined {
    if (!this.languages || !language) return undefined;
    const tokens = this.router.url.split("/");
    if (tokens.length === 0) return undefined;

    const last = tokens[tokens.length - 1];

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === last) {
        tokens[tokens.length - 1] = language;
        break;
      }
    }
    return tokens.join("/");
  }
  
  onLanguageChange(): void {
    this.router.navigate([this.getFullLanguagePath(this.language)]);
  }

  updateCategory(category: Category, checked: boolean) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    metadata.enableChanging = true;

    category.enabled = checked;
    category.indeterminate = false;

    if (metadata.rules) {
      for (let i = 0; i < metadata.rules.length; i++) {
        metadata.rules[i].enabled = checked;
        metadata.rules[i].indeterminate = false;
      }
    }

    let subscription = this.spellingService.updateCategoryEnabled(category)
    .subscribe(undefined, (err) => {
      console.error(err);
      metadata.enableChanging = false;
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    }, () => {
      metadata.enableChanging = false;
      this.snackbar.openFromComponent(PhoneticSpellingsSnackbarComponent, {
        data: category.groupId === undefined
          ? checked ? PhoneticSpellingsSnackbarType.CategoryEnabled : PhoneticSpellingsSnackbarType.CategoryDisabled
          : checked ? PhoneticSpellingsSnackbarType.GroupCategoryEnabled : PhoneticSpellingsSnackbarType.GroupCategoryDisabled,
        duration: 4000
      });

      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    });
    if (this.opSubscriptions) {
      this.opSubscriptions.push(subscription);
    }
  }

  updateRule(category: Category, rule: Rule, checked: boolean) {
    const index: number = this.categories.indexOf(category);
    if (index === -1) throw new Error("Category is not a part of spellings.");
    const metadata: ICategoryMetadata = this.categoryMetadata[index];

    rule.enabled = checked;

    if (metadata.rules) {
      let indeterminate: boolean = false;
      for (let i = 0; i < metadata.rules.length; i++) {
        if (metadata.rules[i].enabled !== checked) {
          indeterminate = true;
          break;
        }
      }

      if (!indeterminate) {
        category.enabled = checked;
      }
      category.indeterminate = indeterminate;
    }

    let ruleMetadata: IRuleMetadata|undefined;
    if (metadata.rules && metadata.rulesMetadata) {
      for (let i = 0; i < metadata.rulesMetadata.length; i++) {
        if (metadata.rules[i] === rule) {
          ruleMetadata = metadata.rulesMetadata[i];
          break;
        }
      }
    }

    if (ruleMetadata) {
      ruleMetadata.enableChanging = true;
    }

    let subscription = this.spellingService.updateRuleEnabled(rule)
    .subscribe(undefined, (err) => {
      console.error(err);
      if (ruleMetadata) {
        ruleMetadata.enableChanging = false;
      }
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    }, () => {
      if (ruleMetadata) {
        ruleMetadata.enableChanging = false;
      }

      this.snackbar.openFromComponent(PhoneticSpellingsSnackbarComponent, {
        data: rule.groupId === undefined
          ? checked ? PhoneticSpellingsSnackbarType.RuleEnabled : PhoneticSpellingsSnackbarType.RuleDisabled
          : checked ? PhoneticSpellingsSnackbarType.GroupRuleEnabled : PhoneticSpellingsSnackbarType.GroupRuleDisabled,
        duration: 4000
      });

      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    });

    if (this.opSubscriptions) {
      this.opSubscriptions.push(subscription);
    }
  }

  updateCustomRule(rule: CustomRule) {
    const index: number = this.customRules.indexOf(rule);
    if (index === -1) throw new Error("Invalid custom rule.");
    const metadata = this.customRulesMetadata[index];

    metadata.enableChanging = true;

    let subscription = this.spellingService.updateCustomRuleEnabled(rule)
    .subscribe(undefined, (err) => {
      console.error(err);
      metadata.enableChanging = false;
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    }, () => {
      metadata.enableChanging = false;
      if (this.opSubscriptions) {
        let index = this.opSubscriptions.indexOf(subscription);
        if (index !== -1) {
          this.opSubscriptions.splice(index, 1);
          subscription.unsubscribe();
        }
      }
    });

    if (this.opSubscriptions) {
      this.opSubscriptions.push(subscription);
    }
  }
  
  openRecommendDialog() {
    const ref = this.dialog.open(PhoneticSpellingRecommendDialogComponent, {
      data: this.categories,
      width: '750px'
    });
    ref.componentInstance.onUpdate.subscribe((rules: Rule[]) => {
      for (let i = 0; i < ref.componentInstance.categories.length; i++) {
        let category = ref.componentInstance.categories[i];
        for (let j = 0; j < this.categories.length; j++) {
          if (this.categories[j].id === category.id) {
            this.categories[j].enabled = category.enabled;
            this.categories[j].indeterminate = category.indeterminate;
          }
        }
      }

      for (let i = 0; i < rules.length; i++) {
        let index: number = -1;
        for (let j = 0; i < this.categories.length; j++) {
          if (rules[i].categoryId === this.categories[j].id) {
            index = j;
            break;
          }
        }
        if (index === -1) continue;
        let metadata: ICategoryMetadata = this.categoryMetadata[index];

        if (metadata.rules) {
          for (let j = 0; j < metadata.rules.length; j++) {
            if (metadata.rules[j].id === rules[i].id) {
              metadata.rules[j].enabled = rules[i].enabled;
            }
          }
        }
      }
    });
  }
  
  createCustomRule() {
    const ref = this.dialog.open(PhoneticSpellingCreateDialogComponent, {
      data: {
        language: this.language,
        groupId: this.groupId,
        userId: this.userId
      },
      width: '750px'
    });
    ref.componentInstance.onCreate.subscribe((rule: CustomRule) => {
      this.customRules.push(rule);
      this.customRulesMetadata.push({
        enableChanging: false
      })
    });
  }
  
  editRule(rule: CustomRule) {
    this.dialog.open(PhoneticSpellingEditDialogComponent, {
      data: rule,
      width: '750px'
    });
  }
  
  deleteRule(rule: CustomRule) {
    const ref = this.dialog.open(PhoneticSpellingDeleteDialogComponent, {
      data: rule,
      width: '750px'
    });
    if (ref.componentInstance.onDelete) {
      ref.componentInstance.onDelete.subscribe(
        (rule: CustomRule) => {
          let index = this.customRules.indexOf(rule);
          if (index !== -1) {
            if(detectIE()){
              // The splice method does not seem to work for IE. Reload the whole page if IE, until we find a better solution
              window.location.reload(); 
            } else {
              this.customRules.splice(index, 1);
              this.customRulesMetadata.splice(index, 1);
            }
          }
        }
      );
    }
  }
}
