export enum EnabledState {
  Enabled,
  Disabled,
  Indeterminate
}

export function getEnabledState(state: string): EnabledState {
  switch (state.toLowerCase()) {
    case "enabled": {
      return EnabledState.Enabled;
    }
    case "disabled": {
      return EnabledState.Disabled;
    }
    case "indeterminate": {
      return EnabledState.Indeterminate;
    }
  }
  throw new Error("Parameter is not a valid EnabledState.");
}

export interface IPermissions {
  edit: boolean;
  delete: boolean;
}

export class GlossarySearchPage {
  public glossaries: IGlossary[] = [];
  public totalGlossaries: number = 0;
}

export interface IGlossary {
  id: number;
  language: string;

  name: string;
  description: string;

  targetAudience: string;
  subject: string;

  enabled: EnabledState;
  subscribed: boolean;
  isPublic: boolean;

  subscribers: number;

  rating?: number;
  userRating?: number;

  ownedByMe: boolean;

  ownerId?: number;
  ownerDisplayName?: string;

  sharedByGroupId?: number;
  sharedByGroupDisplayName?: string;

  groupId?: number;
  groupDisplayName?: string;

  impersonatedUserId?: number;
  impersonatedUserDisplayName?: string;
}

export class Word {
  constructor(
    public glossaryId: number,
    public text: string,
    public caseInsensitiveMatchInDefault: boolean,
    public exactMatch: boolean,
    public caseInsensitiveMatchInGlossary: boolean = false
  ) {}

  isCaseInsensitiveMatchInGlossary(): boolean {
    return this.caseInsensitiveMatchInGlossary
    && !this.isCaseInsensitiveMatchInDefault()
    && !this.isExactMatch();
  }

  isCaseInsensitiveMatchInDefault(): boolean {
    return this.caseInsensitiveMatchInDefault
    && !this.isExactMatch();
  }

  isExactMatch(): boolean {
    return this.exactMatch;
  }
}
