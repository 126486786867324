import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-txtanalyser-errorreporting-snackbar',
  templateUrl: './txtanalyser-errorreporting-snackbar.component.html',
  styleUrls: ['./txtanalyser-errorreporting-snackbar.component.scss']
})
export class TxtAnalyserErrorReportingSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public type: TxtAnalyserErrorreportingSnackbarType
  ) {}

  isSuccess() {
    return this.type === TxtAnalyserErrorreportingSnackbarType.Success;
  }

  isDocumentSuccess() {
    return this.type === TxtAnalyserErrorreportingSnackbarType.DocumentSuccess;
  }

  isIgnoreSuccess() {
    return this.type === TxtAnalyserErrorreportingSnackbarType.IgnoreSuccess;
  }

  isError() {
    return this.type === TxtAnalyserErrorreportingSnackbarType.Error;
  }
}

export enum TxtAnalyserErrorreportingSnackbarType {
  Success,
  DocumentSuccess,
  IgnoreSuccess,
  Error
}