import { Component, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  @Input('rating')
  public get rating(): number|undefined {
    if (this._starActiveIndex !== -1) {
      return this._starActiveIndex/(this.stars - 1);
    }
    return this._rating;
  }
  public set rating(rating: number|undefined) {
    this._rating = rating;
  }
  private _rating?: number = undefined;

  @Input('stars')
  public stars: number = 5;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('disabled')
  public disabled: boolean = false;

  @Input('size')
  public size: string|undefined = undefined;

  @Output('change')
  public change = new EventEmitter<number>();

  get title(): string {
    return Math.round(((this.rating || 0)*(this.stars - 1) + 1)*100)/100 + '/' + this.stars;
  }

  get starsArr(): number[] {
    const items: number[] = [];
    for (let i = 0; i < this.stars; i++) {
      items.push(i);
    }
    return items;
  }

  @ViewChild('border', { read: ElementRef, static: true }) borderRef?: ElementRef;

  private _starWidth: number = 0;
  private _starActiveIndex: number = -1;

  constructor() { }

  get fillWidth(): string {
    let rating = this.rating;
    if (this._starActiveIndex !== -1) {
      rating = this._starActiveIndex/(this.stars - 1);
    }

    if (!this.borderRef || rating === undefined) return "0px";

    const firstStar = this.borderRef.nativeElement.offsetWidth/this.stars;

    return (firstStar + (this.borderRef.nativeElement.offsetWidth - firstStar) * rating) + "px";
  }

  setStarIndex(starIndex: number): void {
    const rating = starIndex/(this.stars - 1);

    this.change.emit(rating);
  }

  setActiveStar(starIndex: number): void {
    this._starActiveIndex = starIndex;
  }
}
