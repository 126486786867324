export class UserDetail {
  constructor(
    public firstName: string,
    public lastName: string,
    public country: string,
    public language: string,
    public phone: string,
    public email: string
  ) {}
}

export class Application {
  constructor(
    public name: string,
    public cleanName: string,
    public imageUrl: string,
    public downloadUrl: string
  ) {}
}