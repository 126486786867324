import { LocaleService } from './locale.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

const localeMap: {[key: string]: string} = {
  'ar': 'ar-EG',
  'da': 'da-DK',
  'de': 'de-DE',
  'en': 'en-GB',
  'nb': 'nb-NO',
  'nl': 'nl-NL',
  'nn': 'nn-NO',
  'sv': 'sv-SE'
};

interface LocaleDocument extends Document {
  locale?: string;
}

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private localeService: LocaleService) {}

  getLocales() {
    const doc = document as LocaleDocument;
    const localeId = this.localeService.getLocale();

    const locales = [];
    if (localeId) {
      locales.push(localeMap[localeId] || localeId);
    }
    if (doc.locale) {
      locales.push(doc.locale);
    }
    if (navigator && navigator.language && locales.indexOf(navigator.language) === -1) {
      locales.push(navigator.language);
    }
    if (locales.indexOf("en-GB") === -1) {
      locales.push("en-GB");
    }
    
    return locales;
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const locales = this.getLocales();

    req = req.clone({
      setHeaders: {
        'Accept-Language': locales.join(",")
      }
    });

    return next.handle(req);
  }
}