import { Component, Input } from '@angular/core';
import { IPieValue } from '../../models/IPieValue';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  public googleData: any = {};

  @Input()
  public set data(data: IPieValue[]) {
    this._data = data;
    this._updateGoogleData();
  }
  public get data(): IPieValue[] {
    return this._data;
  }
  private _data: IPieValue[] = [];

  private _updateGoogleData(): void {
    this.googleData = {
      chartType: 'PieChart',
      dataTable: [
        ['Category', 'Errors'],
        ...this._data.map(x => [x.label, x.value])
      ],
      options: {
        legend: 'none',
        width: 265,
        height: 265,
        chartArea: {
          width: 250,
          height: 250
        }
      }
    };
  }
}