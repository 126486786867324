<div class="unit" *ngIf="!stacked">
  <div class="background">
    <div class="unit-container">
      <span class="unit-text">{{ unit }}</span>
    </div>
    <div *ngFor="let value of axis?.values" class="background__row"></div>
  </div>
</div>
<div class="container">
  <div class="table-background">
    <div class="background">
      <div *ngFor="let value of axis?.values; let even = even; let odd = odd"
          class="background__row" [class.odd]="odd" [class.even]="even"></div>
    </div>
  </div>
  <div class="data-container" #scrollElement (scroll)="isRtL ? tableAxis.style.right = -1 * scrollElement.scrollLeft + 'px' : tableAxis.style.left = scrollElement.scrollLeft + 'px'">
    <div class="table-axis" [style.left.px]="!isRtL ? scrollElement.scrollLeft : null" [style.right.px]="isRtL ? -1 * scrollElement.scrollLeft : null" #tableAxis>
      <div class="background">
        <div *ngFor="let value of axis?.values; let even = even; let odd = odd"
            class="background__row" [class.odd]="odd" [class.even]="even">
          {{ value }}
        </div>
      </div>
    </div>
    <table [class.is-stacked]="stacked" [class.slim]="isSlim()" [class.slim--annotations]="isSlim() && hasAnnotations()">
      <tbody>
        <tr>
          <td class="axis" #axisRef>
            <div class="background">
              <div *ngFor="let value of axis?.values; let even = even; let odd = odd"
                  class="background__row" [class.odd]="odd" [class.even]="even">
                {{ value }}
              </div>
            </div>
          </td>
          <td *ngFor="let cells of rowsPercentage; let i = index" class="bar__wrapper">
            <div class="bar__group">
              <ng-container *ngFor="let cell of cells">
                <div *ngIf="isCellValueType(cell)"
                    [style.height]="cell.value*100 + '%'"
                    [class]="'bar bar--column-' + (cell.index === numberOfColumns ? 'end' : (cell.index - 1))"
                    (mouseover)="setHoverCell(cell, i, $event.currentTarget)"
                    (mouseout)="clearHoverCell()">
                  <app-visible-text *ngIf="annotation" class="bar__annotation" [visible]="isColumnAxisVisible()" [value]="getValue(i, cell.index)"></app-visible-text>
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td class="column" *ngFor="let cells of rowsPercentage" [style.height]="getAxisHeight() + 'px'">
            <a class="column__link" [routerLink]="cells[0].href" [queryParams]="getQueryParams(cells[0])" *ngIf="cells[0].href">
              <div *ngFor="let text of getLinesOfValue(cells[0].value)">{{ text }}</div>
            </a>
            <div class="column__text" *ngIf="!cells[0].href">
              <div *ngFor="let text of getLinesOfValue(cells[0].value)">{{ text }}</div>
            </div>
            <div class="column__annotation" *ngIf="hasCellAnnotationType(cells)">{{ getFirstCellAnnotation(cells)!.value }}</div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="legends" *ngIf="hasLegend()" [class.is-stacked]="stacked">
  <div *ngFor="let column of data?.cols | slice:1; let i = index" class="legend">
    <div [class]="'legend__indicator legend--' + (i + 1 === numberOfColumns ? 'end' : i)"></div>
    <span>{{ column.label }}</span>
  </div>
</div>

<div class="bar-popup__positioner"
     *ngIf="hoverCellVisible"
     [style.left.px]="hoverCellLeft"
     [style.top.px]="hoverCellTop">
  <mat-card>
    <mat-card-title>
      {{ hoverCellLabel }}
    </mat-card-title>
    <mat-card-content>
      {{ hoverCellValue }}
    </mat-card-content>
  </mat-card>
</div>
