export class Rule {
  constructor(
    public id: string,
    public categoryId: string,
    public language: string,
    
    public name: string,
    public example: string,
    public enabled: boolean,
    public recommended: boolean,
    public indeterminate: boolean,
    public from: string,
    public to: string,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number

  ) {}

  clone(): Rule {
    return new Rule(this.id, this.categoryId, this.language, this.name,
      this.example, this.enabled, this.recommended, this.indeterminate, this.from, this.to,
      this.groupId, this.impersonatedUserId);
  }
}

export class CustomRule {
  constructor(
    public id: string,
    public language: string,

    public example: string,
    public enabled: boolean,
    public from: string,
    public to: string,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}

  clone(): CustomRule {
    return new CustomRule(this.id, this.language, this.example, this.enabled, this.from, this.to, this.groupId, this.impersonatedUserId);
  }
}

export class Category {
  constructor(
    public id: string,
    public language: string,

    public name: string,
    public description: string,
    public enabled: boolean,
    public recommended: boolean,
    public indeterminate: boolean,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number
  ) {}

  clone(): Category {
    return new Category(this.id, this.language, this.name, this.description, this.enabled,
      this.recommended, this.indeterminate, this.groupId, this.impersonatedUserId);
  }
}