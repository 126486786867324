<app-header></app-header>

<mat-form-field>
  <mat-label i18n="@@ViewUploadedDocument_document">Document:</mat-label>
  <mat-select [value]="selectedDocument" (selectionChange)="onDocumentChange($event)">
    <mat-option *ngFor="let item of documents" [value]="item.id">{{ item.name }}</mat-option>
  </mat-select>
</mat-form-field>

<div class="grid" #grid>
  <div class="grid-item grid-item--content">
    <mat-card>
      <mat-card-title>{{ currentDocumentTitle }}</mat-card-title>
      <mat-card-content>
        <app-readonly-document [text]="text" [tokens]="tokens" [clickableElements]="clickableElements" (selected)="onSelected($event)"></app-readonly-document>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="grid-item grid-item--details">
    <mat-card #error>
      <mat-card-title>
        <ng-container *ngIf="selectedErrorType">
          {{ selectedErrorType!.subCategory }}
        </ng-container>
        <ng-container *ngIf="!selectedErrorType" i18n="@@ViewUploadedDocument_NoErrorSelected">
          No error selected
        </ng-container>
      </mat-card-title>
      <mat-card-content>
        <span *ngIf="selectedErrorType" [appMarked]="selectedErrorType!.description"></span>
      </mat-card-content>
    </mat-card>
  </div>
</div>