<app-header></app-header>

<div class="subheader">
  <span *ngIf="name">{{ name }}</span>
  <span *ngIf="!name" class="name--none" i18n="@@TxtAnalyserUploadDocument_NoName">No name</span>
  <a [routerLink]="'./document/' + firstDocumentId" i18n="@@TxtAnalyserUploadDocument_ViewDocument">View document</a></div>

<ng-container *ngIf="!job!.completedAnalysis">
  <div *ngIf="isErrorOnAnalysis" class="analysis-status" i18n="@@TxtAnalyserUploadDocument_ErrorDescription">
    An internal error occurred while analyzing document ({{ job!.errorOnAnalysis }}).
  </div>
  <div *ngIf="!isErrorOnAnalysis" class="analysis-status" i18n="@@TxtAnalyserUploadDocument_StillRunning">
    Analysis is still running
  </div>
</ng-container>

<ng-container *ngIf="job!.completedAnalysis">
  <div class="grid" *ngIf="result!.categories.length > 0">
    <div class="grid-item">
      <mat-card>
        <mat-card-title i18n="@@TxtAnalyserUploadDocument_ErrorType">
          Error type
        </mat-card-title>
        <mat-card-content>
          <div class="grid">
            <div class="grid-item">
              <app-stats-table [data]="overviewTableData"></app-stats-table>
            </div>
            <div class="grid-item">
              <app-pie-chart [data]="overviewPieData"></app-pie-chart>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <h1 class="subpage" i18n="@@TxtAnalyserUploadDocument_Details">Details</h1>
  <div *ngIf="result!.categories.length === 0">
    <span i18n="@@TxtAnalyserUploadDocument_NoErrors">No errors were found in the documents</span>
  </div>
  <table *ngIf="result!.categories.length > 0">
    <ng-container *ngFor="let category of result!.categories; let i = index">
      <thead>
        <tr>
          <th i18n="@@TxtAnalyserUploadDocument_Category">Category</th>
          <th i18n="@@TxtAnalyserUploadDocument_ProportionOfErrors">Error rate</th>
          <th i18n="@@TxtAnalyserUploadDocument_AmountOfErrors">Number of errors</th>
        </tr>
      </thead>
      <tbody class="card">
        <tr class="table__category">
          <td>{{ category.category }}</td>
          <td>{{ category.percentage }}</td>
          <td>{{ category.totalErrors }}</td>
        </tr>
        <tr *ngFor="let error of category.errors" class="table__subcategory">
          <td>{{ error.subCategory }}</td>
          <td>{{ error.percentage }}</td>
          <td>{{ error.totalErrors }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr class="card-spacing" *ngIf="i < result!.categories.length - 1">
          <td></td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</ng-container>