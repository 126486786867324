import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, Inject, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { IGlossaryService } from '../../interfaces/iglossary.service';
import { GlossarySearchDataSource } from '../../models/GlossarySearchDataSource';
import { IGlossaryServiceToken } from 'app/interfaces/iglossary.service.token';
import { IGlossary, EnabledState } from 'app/models/glossary';
import { Language } from 'app/models/language';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaQueryService } from '../../services/media-query.service';
import { GlossariesSearchSnackbarComponent, GlossariesSearchSnackbarType } from '../glossaries-search-snackbar/glossaries-search-snackbar.component';
import { GlossaryViewDialogComponent } from '../glossary-view-dialog/glossary-view-dialog.component';

interface IRouteData {
  languages: Language[];
}

@Component({
  selector: 'app-glossaries-search',
  templateUrl: './glossaries-search.component.html',
  styleUrls: ['./glossaries-search.component.scss']
})
export class GlossariesSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  private routeSubscription: Subscription|undefined = undefined;

  public targetAudiences: Record<string, string>[] = [];
  public subjects: Record<string, string>[] = [];
  public publishers: Record<string, string>[] = [] ;

  public get displayedColumns(): string[] {
    if (this.isRatingMaxWidth) {
      return ['name', 'language', 'ownerDisplayName', 'subscribed'];
    } else {
      return [
        'name',
        'description',
        'language',
        'targetAudience',
        'subject', 'subscribers',
        'ownerDisplayName',
        /*"rating", */
        'subscribed'
      ];
    }
  };

  public dataSource?: GlossarySearchDataSource;

  public languages: Language[] = [];
  public groupId?: number;
  public userId?: number;

  public subscribeCheckboxDisabled: boolean[] = [];

  @ViewChild(MatSort, { static: true }) private _sort?: MatSort;

  public isPhone = false;
  public isRatingMaxWidth = false;
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _ratingMaxWidthListener = (matches: boolean) => this._onRatingMaxWidthListener(matches);

  constructor(
    @Inject(IGlossaryServiceToken) private service: IGlossaryService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    private snackbar: MatSnackBar,
  ) {
    service.getGlossariesPublishers().subscribe(publishers => this.publishers = publishers);
    service.getGlossariesTargetAudience().subscribe(targets => this.targetAudiences = targets);
    service.getGlossariesSubjects().subscribe(subjects => this.subjects = subjects);
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this.route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this.route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this.route.snapshot.paramMap.get('userId')!, 10);
    }
    this.dataSource = new GlossarySearchDataSource(this.service, this.groupId, this.userId);

    this.routeSubscription = this.route.data.subscribe(value => {
      const data = value as IRouteData;
      this.languages = data.languages;
    });

    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this._mediaQueryService.listen('(max-width: 920px)', this._ratingMaxWidthListener);
    this.isRatingMaxWidth = this._mediaQueryService.matchMedia('(max-width: 920px)');
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this._sort;
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
  }

  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }

  private _onRatingMaxWidthListener(matches: boolean) {
    this._zone.run(() => {
      this.isRatingMaxWidth = matches;
    });
  }

  getLanguageByCode(code: string): string|undefined {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code === code) {
        return this.languages[i].name;
      }
    }
    return undefined;
  }

  getSubjectByCode(value: string): string {
    for (const subject of this.subjects) {
      if (subject.value === value) {
        return subject.name;
      }
    }
    return value;
  }

  getTargetAudienceByCode(value: string): string {
    for (const target of this.targetAudiences) {
      if (target.value === value) {
        return target.name;
      }
    }
    return value;
  }

  viewGlossary(glossary: IGlossary) {
    this.dialog.open(GlossaryViewDialogComponent, {
      width: '750px',
      data: {
        glossary: glossary,
        languages: this.languages
      }
    });
  }

  setSubscribed(glossary: IGlossary, subscribed: boolean): void {
    const originalSubscribed = glossary.subscribed;
    glossary.subscribed = subscribed;
    glossary.enabled = EnabledState.Enabled;
    this.subscribeCheckboxDisabled[glossary.id] = true;

    this.service.updateGlossarySubscription(glossary).subscribe(undefined, () => {
      glossary.subscribed = originalSubscribed;
      delete this.subscribeCheckboxDisabled[glossary.id];
    }, () => {
      this.snackbar.openFromComponent(GlossariesSearchSnackbarComponent, {
        data: glossary.groupId !== undefined
          ? (subscribed ? GlossariesSearchSnackbarType.GroupSubscribe : GlossariesSearchSnackbarType.GroupUnsubscribe)
          : (subscribed ? GlossariesSearchSnackbarType.Subscribe : GlossariesSearchSnackbarType.Unsubscribe),
        duration: 4000
      });

      delete this.subscribeCheckboxDisabled[glossary.id];
    });
  }
}
