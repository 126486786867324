import { Component, OnInit, Inject, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { Subscription } from 'rxjs';
import { MediaQueryService } from '../../services/media-query.service';
import { ITxtAnalyserReportJobResult } from '../../models/txtanalyser/ReportJobResult';
import { DataTable, IDataColumn, IDataCell, CellType } from '../../models/stats';
import { IPieValue } from '../../models/IPieValue';
import { ITxtAnalyserReportJob } from '../../models/txtanalyser/ReportJob';
import { ILocalization } from 'app/interfaces/ILocalization';
import { ITxtAnalyserReportDocument } from 'app/models/txtanalyser/ITxtAnalyserReportDocument';

interface IRouteData {
  result: ITxtAnalyserReportJobResult;
  job: ITxtAnalyserReportJob;
  documents: ITxtAnalyserReportDocument[];
}

@Component({
  selector: 'app-txtanalyser-upload-document',
  templateUrl: './txtanalyser-upload-document.component.html',
  styleUrls: ['./txtanalyser-upload-document.component.scss']
})
export class TxtAnalyserUploadDocumentComponent implements OnInit, OnDestroy {
  public isPhone: boolean = false;

  public set result(result: ITxtAnalyserReportJobResult | undefined) {
    if (result === this._result) return;

    const cols: IDataColumn[] = [
      { type: '', label: this._localization.getErrorType() },
      { type: '', label: this._localization.getPercentage() },
      { type: '', label: this._localization.getAmount() }
    ];

    this._result = result;
    if (this.result) {
      this.result.categories = this.result.categories
        .sort((a, b) => b.totalErrors - a.totalErrors);
      this.overviewPieData = this.result.categories
        .map(x => ({ label: x.category, value: x.totalErrors }));
      this.overviewTableData = new DataTable({
        cols,
        rows: this.result.categories.map(x => ({
          cells: [
            {
              value: x.category,
              type: CellType.label
            }, {
              value: x.percentage,
              type: CellType.value
            }, {
              value: x.totalErrors,
              type: CellType.value
            }
          ]
        })),
        groups: []
      });
    } else {
      this.overviewTableData = new DataTable({
        cols,
        rows: [],
        groups: []
      });
      this.overviewPieData = [];
    }
  }
  public get result(): ITxtAnalyserReportJobResult | undefined {
    return this._result;
  }
  private _result?: ITxtAnalyserReportJobResult;

  public get name(): string | undefined {
    if (!this._result) return undefined;

    return this._result.name;
  }

  public job?: ITxtAnalyserReportJob;

  public overviewTableData: DataTable = new DataTable({
    cols: [
      { type: '', label: 'Error type' },
      { type: '', label: 'Percentage' },
      { type: '', label: 'Amount' }
    ],
    rows: [],
    groups: []
  });
  public overviewPieData: IPieValue[] = [];

  public get isErrorOnAnalysis(): boolean {
    if (!this.job) return false;
    return this.job.errorOnAnalysis !== undefined;
  }

  public firstDocumentId = "";

  public groupId?: number;
  public userId?: number;
  
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _routeSubscription: Subscription|undefined = undefined;

  constructor(
    private _route: ActivatedRoute,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    @Inject(ITxtAnalyserServiceToken) private service: ITxtAnalyserService,
    @Inject(ILocalization) private _localization: ILocalization
  ) { }

  public ngOnInit() {
    if (this._route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this._route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this._route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this._route.snapshot.paramMap.get('userId')!, 10);
    }

    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this._routeSubscription = this._route.data.subscribe(value => {
      const data = value as IRouteData;
      this.result = data.result;
      this.job = data.job;
      this.firstDocumentId = data.documents[0].id;
    });
  }

  public ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;
    });
  }
}
