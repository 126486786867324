export class Rule {
  constructor(
    public id: string,
    public categoryId: string,
    public language: string,
    
    public name: string,
    public description: string,
    public enabled: boolean,
    public indeterminate: boolean,

    // Does this glossary belong to a group
    public groupId?: number,

    // Is this glossary impersonated
    public impersonatedUserId?: number

  ) {}

  clone(): Rule {
    return new Rule(this.id, this.categoryId, this.language, this.name,
      this.description, this.enabled, this.indeterminate,
      this.groupId, this.impersonatedUserId);
  }
}