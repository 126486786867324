import { AsyncSubject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-txtanalyser-document-ignores-delete-dialog',
  templateUrl: './txtanalyser-document-ignores-delete-dialog.component.html',
  styleUrls: ['./txtanalyser-document-ignores-delete-dialog.component.scss']
})
export class TxtAnalyserDocumentIgnoresDeleteDialogComponent implements OnDestroy {
  public onDelete = new AsyncSubject<boolean>();

  constructor(
    private _dialogRef: MatDialogRef<TxtAnalyserDocumentIgnoresDeleteDialogComponent>
  ) { }

  public delete() {
    this.onDelete.next(true);
    this.onDelete.complete();

    this._dialogRef.close();
  }

  public ngOnDestroy() {
    this.onDelete.unsubscribe();
  }
}
