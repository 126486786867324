import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import * as querystring from 'query-string';

export interface IBreadcrumb {
  label: string;
  url: string;
  unknown: boolean;
  data?: string[];
  query: any;
  excludeLocalization?: boolean;
}

export interface IBreadcrumbObject {
  breadcrumbs: IBreadcrumb[];
  currentPage: IBreadcrumb|undefined;
}

const endpoints = [
  "spelling", "join", "categories", "ignores"
];

const hiddenRoutes = [
  "members", "join"
];

/**
 * If one of the breadcrumbs is found it will skip the next breadcrumb.
 * /profile/stats/en-GB/activity -> profile, stats, activity
 */
const skipNext = [
  'stats', "errorreporting"
];

interface IPath {
  [key: string]: IPathDetail;
}

interface IPathDetail {
  i18n: string;
  paths?: IPath;
  endOfPath?: boolean;
}

@Injectable()
export class BreadcrumbService {
  private _labels: string[] = [];
  private _currentLabel: string | undefined;
  private _currentLabelExcludeLocalization: boolean = false;

  public staticKeys: {[key: string]: string} = {
    root: "root",
    account: "account",
    profile: "profile",
    groups: "groups",
    templates: "templates",
    aw: "aw",
    stats: "stats",
    glossaries: "glossaries",
    spelling: "spelling",
    activity: "activity",
    errors: "errors",
    predictions: "predictions",
    all_predictions: "all_predictions",
    all_predictions_chronological: "all_predictions_chronological",
    year_week: "year_week",
    search: "search",
    categories: "categories",
    errorreporting: "errorreporting",
    ignores: "ignores",
    sentences: "sentences",
    errors_details_ta: "errors_details_ta",
    errors_details_aw: "errors_details_aw"
  };

  public pathNames: IPath = {
    "ta": {
      "i18n": "ta",
      "paths": {
        "upload": {
          "i18n": "ta/upload",
          "paths": {
            "*": {
              "i18n": "ta/upload/result",
              "paths": {
                "document": {
                  "i18n": "ta/upload/result/document",
                  "endOfPath": true
                },
                "all": {
                  "i18n": "ta/upload/result/all"
                }
              }
            }
          }
        }
      }
    }
  };

  private _updateBreadcrumbsWithPaths(breadcrumbs: IBreadcrumb[]): IBreadcrumb[] {
    let currentPath = this.pathNames;
    for (let i = 0; i < breadcrumbs.length; i++) {
      const breadcrumb = breadcrumbs[i];
      const detail = this._getNextPath(breadcrumb.label, currentPath);
      if (detail) {
        breadcrumb.label = detail.i18n;
        if (detail.paths) {
          currentPath = detail.paths;
        }
        if (detail.endOfPath) {
          breadcrumbs = breadcrumbs.slice(0, i + 1);
          break;
        }
      }
    }
    return breadcrumbs;
  }

  private _getNextPath(token: string, path: IPath): IPathDetail | undefined {
    for (const key in path) {
      if (key === "*" || key === token) {
        return path[key];
      }
    }
    return undefined;
  }

  constructor(
    private router: Router
  ) { }

  setCurrentLabel(label: string | undefined, excludeLocalization: boolean = true): void {
    this._currentLabel = label;
    this._currentLabelExcludeLocalization = excludeLocalization;
  }

  setLabels(labels: string[]) {
    this._labels = labels;
  }

  getLabels(): string[] {
    return this._labels;
  }

  getBreadcrumbs(): IBreadcrumbObject {
    let breadcrumbs: IBreadcrumb[] = [];

    let routerUrl = this.router.url;
    let query: { week?: string, year?: string } = {};

    const queryIndex = routerUrl.indexOf("?");
    if (queryIndex !== -1) {
      const queryString = routerUrl.substring(queryIndex + 1);
      query = querystring.parse(queryString);

      routerUrl = routerUrl.substring(0, queryIndex);
    }
    if (routerUrl === "/") routerUrl = "";

    let urlparts : string[] = routerUrl.split("/");
    let lastLabel: string|undefined = undefined;
    for (let i = 0; i < urlparts.length; i++) {
      let label: string = "" + urlparts[i];
      if (urlparts[i] === "") {
        label = "root";
      }
      let url: string = "";
      for (let j = 0; j <= i; j++) {
        url += "/" + urlparts[j];
      }

      if (hiddenRoutes.indexOf(urlparts[i]) !== -1) {
        if (endpoints.indexOf(urlparts[i]) !== -1) break;
        continue;
      }

      const j = breadcrumbs.length - 1;
      if (j >= 0 && lastLabel !== undefined && skipNext.indexOf(lastLabel) !== -1) {
        breadcrumbs[j].url = url;
        const bQuery = Object.assign({}, query);
        if (bQuery) {
          if (typeof bQuery['week'] === 'string') {
            delete bQuery['week'];
          }
          if (typeof bQuery['year'] === 'string') {
            delete bQuery['year'];
          }
        }
        breadcrumbs[j].query = bQuery;
        lastLabel = label;
        continue;
      }
      lastLabel = label;

      let breadcrumb: IBreadcrumb = {
        label: label,
        url: url,
        unknown: !this.staticKeys.hasOwnProperty(label),
        query: {}
      };

      breadcrumbs.push(breadcrumb);

      if (endpoints.indexOf(urlparts[i]) !== -1) break;
    }
    if (typeof query.year === 'string' && typeof query.week === 'string') {
      breadcrumbs.push({
        label: 'year_week',
        query: query,
        url: routerUrl,
        unknown: false,
        data: [query.year, query.week]
      });
    }

    breadcrumbs = this._updateBreadcrumbsWithPaths(breadcrumbs);

    let currentPage: IBreadcrumb|undefined = breadcrumbs.pop();
    if (this._currentLabel && currentPage) {
      currentPage.label = this._currentLabel;
      currentPage.excludeLocalization = this._currentLabelExcludeLocalization;
    }

    return {
      breadcrumbs: breadcrumbs,
      currentPage: currentPage
    };
  }
}
