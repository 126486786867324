<div class="breadcrumbs">
  <span *ngFor="let breadcrumb of breadcrumbs" class="breadcrumbs-items">
    <a [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.query">
      <app-page-title [key]="breadcrumb.label" [data]="currentPage!.data" [keyIndex]="getUnknownKeyIndex(breadcrumb)"></app-page-title>
    </a>
    <span class="breadcrumbs-slash"> / </span> 
  </span>
  <span class="breadcrumbs-current">
    <ng-container *ngIf="currentPage!.excludeLocalization">{{currentPage!.label}}</ng-container>
    <app-page-title
      *ngIf="!currentPage!.excludeLocalization"
      [key]="currentPage!.label"
      [data]="currentPage!.data"
      [keyIndex]="getUnknownKeyIndex(currentPage)"
    ></app-page-title>
  </span>
</div>