<div mat-dialog-title i18n="@@JoinGroup_Header">
  Join group
</div>

<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <ng-container *ngIf="!group">
      <mat-form-field class="input-full-width" hideRequiredMarker="true">
        <mat-placeholder i18n="@@JoinGroup_Token">Group token</mat-placeholder>
        <input matInput name="token" formControlName="token">
        <mat-error *ngIf="tokenError?.empty" i18="@@JoinGroup_EnterToken"n>Enter the group token</mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="group">
      <p i18n="@@JoinGroup_YouAreAboutToJoin">You're about to join the group <b>{{ group.name }}</b></p>
      <div>
        <span i18n="@@JoinGroup_ByJoining">
          By joining the group you're giving permissions to members with
          sufficient permissions to access the following:
        </span>
        <ul>
          <li i18n="@@JoinGroup_ViewBasic">View your basic account information</li>
          <li i18n="@@JoinGroup_ViewStats">View your statistics</li>
        </ul>
      </div>
    </ng-container>
    <div class="error" *ngIf="unexpectedError" i18n="@@JoinGroup_UnexpectedError">An unexpected error occurred.</div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type="button" (click)="onClose()" i18n="@@JoinGroup_btnCancel">Cancel</button>
    <button mat-raised-button color="primary" *ngIf="!group" [disabled]="this.form.disabled">
      <span i18n="@@JoinGroup_btnJoin">Join</span>
    </button>
    <button mat-raised-button color="primary" *ngIf="group" [disabled]="this.form.disabled">
      <span i18n="@@JoinGroup_btnAccept">Accept</span>
    </button>
  </mat-dialog-actions>
</form>