import { CreateUserSuccessDialogComponent } from './../create-user-success-dialog/create-user-success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageNavigationProgressService } from '../../services/page-navigation-progress.service';
import { AuthService, LingappsError } from '../../services/auth.service';
import { FormControl, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

function errorValidator(error: {[key: string]: boolean}): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    let rError = false;
    for (let key in error) {
      if (!error[key]) continue;
      rError = true;
      break;
    }
    
    return { 'customError': rError };
  };
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public errors: {[key: string]: boolean} = {};
  public form?: FormGroup;

  constructor(
    private pageNavigationProgress: PageNavigationProgressService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', errorValidator(this.errors))
    });
    
    this.pageNavigationProgress.complete();
  }
  
  private validateEmail(email: string): boolean {
    return /^.+@.+$/.test(email);
  }
  
  createUser() {
    if (!this.form || this.form.disabled) return;
    this.form.disable();
    
    for (let key in this.errors) {
      this.errors[key] = false;
    }
    
    let email = this.form.get('email')!.value;
    
    if (email === "") {
      this.errors["USER_EMAIL_NOT_SET"] = true;
      this.form.enable();
      return;
    }
    
    let trimmedEmail = email.trim();
    if (!this.validateEmail(trimmedEmail)) {
      this.errors['USER_EMAIL_INVALID'] = true;
      this.form.enable();
      return;
    }
    
    this.authService.createUserByEmail(trimmedEmail)
    .subscribe(() => {
      this.dialog.open(CreateUserSuccessDialogComponent, {
        data: trimmedEmail,
        disableClose: true,
        width: '750px'
      });
    }, (err: LingappsError) => {
      this.errors[err.code] = true;
      if (this.form) {
        this.form.enable();
      }
    }, () => {
      if (this.form) {
        this.form.enable();
      }
    });
  }

}
