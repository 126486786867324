import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Language } from '../models/language';
import { ILanguageService } from '../interfaces/ilanguage.service';
import { ILanguageServiceToken } from '../interfaces/ilanguage.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class LanguagesTextAnalyzerResolver implements Resolve<Language[]> {
  constructor(
    @Inject(ILanguageServiceToken) private languageService: ILanguageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Language[]> {
    let groupId: number|undefined = undefined;
    if (route.paramMap.has('groupId')) {
      groupId = parseInt(route.paramMap.get('groupId')!, 10);
    }

    let userId: number|undefined = undefined;
    if (route.paramMap.has('userId')) {
      userId = parseInt(route.paramMap.get('userId')!, 10);
    }

    return this.languageService.getTextAnalyzerLanguages(groupId, userId);
  }
}
