<ng-container [ngSwitch]="errorCode">
  <span
    *ngSwitchCase="'401_authenticated'"
    i18n="@@ErrorDialog_401Authenticated">Your session has expired. Please log in again.</span>
  <span
    *ngSwitchCase="'401'"
    i18n="@@ErrorDialog_401">You don't have access to this page.</span>
  <span
    *ngSwitchCase="'404'"
    i18n="@@ErrorDialog_404">This page couldn't be found.</span>
  <span
    *ngSwitchCase="'500'"
    i18n="@@ErrorDialog_500">An internal server error occurred.</span>
  <span
    *ngSwitchDefault
    i18n="@@ErrorDialog_unknown">{{ errorCode }} An unknown error occurred.</span>
</ng-container>