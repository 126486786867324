import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-phonetic-spellings-snackbar',
  templateUrl: './phonetic-spellings-snackbar.component.html',
  styleUrls: ['./phonetic-spellings-snackbar.component.scss']
})
export class PhoneticSpellingsSnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public type: PhoneticSpellingsSnackbarType
  ) {
    
  }

  isCategoryEnabled() {
    return this.type === PhoneticSpellingsSnackbarType.CategoryEnabled;
  }

  isCategoryDisabled() {
    return this.type === PhoneticSpellingsSnackbarType.CategoryDisabled;
  }

  isRuleEnabled() {
    return this.type === PhoneticSpellingsSnackbarType.RuleEnabled;
  }

  isRuleDisabled() {
    return this.type === PhoneticSpellingsSnackbarType.RuleDisabled;
  }

  isGroupCategoryEnabled() {
    return this.type === PhoneticSpellingsSnackbarType.GroupCategoryEnabled;
  }

  isGroupCategoryDisabled() {
    return this.type === PhoneticSpellingsSnackbarType.GroupCategoryDisabled;
  }

  isGroupRuleEnabled() {
    return this.type === PhoneticSpellingsSnackbarType.GroupRuleEnabled;
  }

  isGroupRuleDisabled() {
    return this.type === PhoneticSpellingsSnackbarType.GroupRuleDisabled;
  }
}

export enum PhoneticSpellingsSnackbarType {
  CategoryEnabled,
  CategoryDisabled,
  RuleEnabled,
  RuleDisabled,
  GroupCategoryEnabled,
  GroupCategoryDisabled,
  GroupRuleEnabled,
  GroupRuleDisabled
}