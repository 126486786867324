export class User {
  constructor(
    public id: string|undefined,
    public email: string|undefined,
    public firstName?: string,
    public lastName?: string,
    public name? : string
  ) {
    if(this.firstName || this.lastName) {
      this.name = this.firstName + ' '+ this.lastName;
    } else {
      this.name = this.email;
    }
  }
}