<app-header></app-header>

<h2 class="no-margin" i18n="@@PhoneticSpellings_Language">Language</h2>
<mat-form-field class="language">
  <mat-select (selectionChange)="onLanguageChange()" [(ngModel)]="language">
    <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
  </mat-select>
</mat-form-field>

<h1 i18n="@@PhoneticSpellings_TypicalMistakes" class="subpage">Typical mistakes</h1>
<table>
  <thead *ngIf="!isPhone">
    <tr>
      <th></th>
      <th i18n="@@PhoneticSpellings_ErrorType">Error type</th>
      <th class="header__example" i18n="@@PhoneticSpellings_Example">Example</th>
      <th></th>
    </tr>
  </thead>
  <ng-container *ngFor="let category of categories; let i = index">
    <tbody class="card">
      <tr>
        <td class="card__toggle">
          <mat-checkbox *ngIf="!categoryMetadata[i].enableChanging && !isCategoryRulesLoading(categoryMetadata[i])" [checked]="category.enabled" [indeterminate]="category.indeterminate" (change)="updateCategory(category, $event.checked)"></mat-checkbox>
          <mat-spinner diameter="20" *ngIf="categoryMetadata[i].enableChanging || isCategoryRulesLoading(categoryMetadata[i])"></mat-spinner>
        </td>
        <td class="card__title">
          <span (click)="toggleExpandCategory(category)">{{ category.name }}</span>
        </td>
        <td class="card-description" *ngIf="!isPhone">
          {{ category.description }}
        </td>
        <td class="card-actions">
          <button mat-icon-button color="primary" (click)="toggleExpandCategory(category)">
            <mat-icon *ngIf="categoryMetadata[i].expanded" class="expand-icon">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!categoryMetadata[i].expanded" class="expand-icon">keyboard_arrow_down</mat-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="categoryMetadata[i].loading" class="loading-bar">
        <td [attr.colspan]="isPhone ? 3 : 4">
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </td>
      </tr>
      <ng-container *ngIf="categoryMetadata[i].expanded">
        <ng-container *ngFor="let rule of categoryMetadata[i].rules; let j = index">
          <tr class="card-rule">
            <td class="card__toggle">
              <mat-checkbox *ngIf="!categoryMetadata[i].rulesMetadata[j].enableChanging && !categoryMetadata[i].enableChanging" [checked]="rule.enabled" [indeterminate]="rule.indeterminate" (change)="updateRule(category, rule, $event.checked)"></mat-checkbox>
              <mat-spinner diameter="20" *ngIf="categoryMetadata[i].rulesMetadata[j].enableChanging || categoryMetadata[i].enableChanging"></mat-spinner>
            </td>
            <td class="card__title">
              {{ rule.from }}:{{ rule.to }}
            </td>
            <td class="card-description" *ngIf="!isPhone">
              {{ rule.example }}
            </td>
            <td class="card-actions"></td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tbody>
      <tr class="card-spacing" *ngIf="i < categories.length - 1">
        <td></td>
      </tr>
    </tbody>
  </ng-container>
</table>

<ng-container *ngIf="!!customRules">
  <div class="actions recommend">
    <button mat-button color="primary" (click)="openRecommendDialog()">
      <span i18n="@@PhoneticSpellings_btnRecommend">Recommend phonetic spelling</span>
    </button>
  </div>

  <h1 i18n="@@PhoneticSpellings_Userdefined" class="subpage">Userdefined mistakes</h1>
  <table *ngIf="customRules.length > 0">
    <thead>
      <tr>
        <th></th>
        <th i18n="@@PhoneticSpellings_ErrorType">Error type</th>
        <th></th>
      </tr>
    </thead>
    <ng-container *ngFor="let rule of customRules; let i = index">
      <tbody class="card">
        <tr>
          <td class="card__toggle">
            <mat-checkbox *ngIf="!customRulesMetadata[i].enableChanging" [(ngModel)]="rule.enabled" (change)="updateCustomRule(rule)"></mat-checkbox>
            <mat-spinner diameter="20" *ngIf="customRulesMetadata[i].enableChanging"></mat-spinner>
          </td>
          <td class="card__title">
            {{ rule.from }}:{{ rule.to }}
          </td>
          <td class="card-actions">
            <button mat-button color="primary" (click)="editRule(rule)" class="lt-xsmall">
              <mat-icon>edit</mat-icon>
              <span i18n="@@PhoneticSpellings_btnEdit">Edit</span>
            </button>
            <button mat-button color="primary" (click)="deleteRule(rule)" class="lt-xsmall">
              <mat-icon>delete</mat-icon>
              <span i18n="@@PhoneticSpellings_btnDelete">Delete</span>
            </button>
            <button mat-icon-button color="primary" (click)="editRule(rule)" class="st-xsmall-inline">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="deleteRule(rule)" class="st-xsmall-inline">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr class="card-spacing" *ngIf="i < customRules.length - 1">
          <td></td>
        </tr>
      </tbody>
    </ng-container>
  </table>
  
  <div class="paragraph" *ngIf="customRules.length === 0" i18n="@@PhoneticSpellings_CreateOwnInfo">You can create your own rules here.</div>
  <div class="actions">
    <button mat-button color="primary" (click)="createCustomRule()">
      <mat-icon>add</mat-icon>
      <span i18n="@@PhoneticSpellings_btnCreate">Create new</span>
    </button>
  </div>
</ng-container>