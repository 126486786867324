import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFile, IFileState } from '../../models/txtanalyser/IFIle';

@Component({
  selector: 'app-txtanalyser-upload-file',
  templateUrl: './txtanalyser-upload-file.component.html',
  styleUrls: ['./txtanalyser-upload-file.component.scss']
})
export class TxtAnalyserUploadFileComponent {
  @Input()
  public file: IFile | undefined;

  @Input()
  public disabled = false;

  @Output()
  public remove = new EventEmitter<IFile | undefined>();

  public get content(): string | undefined {
    return this._content;
  }
  private _content: string | undefined;

  public get name(): string {
    if (!this.file) return "";

    return this.file.name;
  }

  public get progress(): number {
    if (!this.file || !this.file.progress) return 0;

    return this.file.progress;
  }

  public get isPending() {
    if (!this.file) return true;

    return this.file.state === IFileState.Pending;
  }

  public get isLoading() {
    if (!this.file) return false;

    return this.file.state === IFileState.Loading;
  }

  public get isComplete() {
    if (!this.file) return false;

    return this.file.state === IFileState.Complete;
  }

  public get isError() {
    if (!this.file) return false;

    return this.file.state === IFileState.Error;
  }
  
  constructor() { }

  public getSourceIconClassName(): string | undefined {
    if (!this.file) return undefined;

    switch (this.file.source) {
      case "GoogleDrive":
        return "icon-source icon-google-drive";
      case "OneDrive":
        return "icon-source icon-one-drive";
      case "LocalFile":
      default:
        return "icon-source icon-local";
    }
  }
}
