<div mat-dialog-title i18n="@@RenameGroup_Header">
  Rename group
</div>

<form (ngSubmit)="editGroup()" [formGroup]="form" novalidate>
  <mat-dialog-content>
    <mat-form-field class="input-full-width" hideRequiredMarker="true">
      <mat-placeholder i18n="@@RenameGroup_Name">Group name</mat-placeholder>
      <input matInput name="name" formControlName="name">
      <mat-error *ngIf="error?.empty" i18n="@@RenameGroup_EnterGroupName">Enter a group name</mat-error>
    </mat-form-field>
    <div *ngIf="unexpectedError" class="error" i18n="@@RenameGroup_UnexpectedError">An unexpected error occurred.</div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="@@RenameGroup_btnCancel">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.disabled" i18n="@@RenameGroup_btnRename">Rename</button>
  </mat-dialog-actions>
</form>