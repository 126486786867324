<mat-dialog-content>
  <h1 i18n="@@AddGlossaryToGroup_Header">Add glossary to group</h1>

  <div class="header">
    <span i18n="@@AddGlossaryToGroup_YourGlossaries">Your glossaries</span>
    <span i18n="@@AddGlossaryToGroup_Language">Language</span>
    <!--<span i18n="@@AddGlossaryToGroup_Public">Public</span>-->
    <span class="actions"></span>
  </div>
  <div class="view">     
    <div class="shadow"></div>    
    <div class="content">
      <mat-progress-bar color="primary" mode="indeterminate" *ngIf="glossariesLoading"></mat-progress-bar>
      <div class="error" *ngIf="unexpectedGroupsError" i18n="@@AddGlossaryToGroup_UnexpectedError">An unexpected error occurred.</div>
      <label [id]="'selectGlossaryLabel' + i" *ngFor="let glossary of glossaries; let i = index; let odd = odd" class="glossary" [class.odd]="odd">
        <span class="group__label">{{ glossary.name }}</span>
        <span>{{ getLanguageByCode(glossary.language) }}</span>
        <!--<span *ngIf="glossary.isPublic" i18n="@@AddGlossaryToGroup_Yes">Yes</span>
        <span *ngIf="!glossary.isPublic" i18n="@@AddGlossaryToGroup_No">No</span>-->
        <span class="actions">
          <mat-checkbox labelPosition="before" [(ngModel)]="glossariesData[i].selected" [aria-labelledby]="'selectGlossaryLabel' + i" i18n="@@AddGlossaryToGroup_btnSelect">Select</mat-checkbox>
        </span>
      </label>
    </div>
  </div>
  <div class="search">
    <a (click)="close()" [routerLink]="searchLink" mat-button color="primary" i18n="@@AddGlossaryToGroup_SearchFor">Search for and subscribe public glossaries to the group</a>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    <span i18n="@@AddGlossaryToGroup_btnCancel">Cancel</span>
  </button>
  <button (click)="add()" [disabled]="disabled" mat-raised-button color="primary">
    <span i18n="@@AddGlossaryToGroup_btnAdd">Add</span>
  </button>
</mat-dialog-actions>