import { Injectable } from '@angular/core';
import { NavigationLink, NavigationState, getNavigationStateByKey } from '../../models/navigation';
import { INavigationService } from '../../interfaces/inavigation.service';
import { CollectionService, Collection, Item } from '../collection.service';
import { Observable } from 'rxjs';
import { BreadcrumbMiddlewareService } from '../breadcrumb.middleware.service';
import { map } from 'rxjs/operators';

@Injectable()
export class NavigationCollectionService implements INavigationService {
  constructor(
    private collectionService: CollectionService,
    private breadcrumbMiddleware: BreadcrumbMiddlewareService
  ) {}

  /** @override */
  getLinks(uri: string): Observable<NavigationLink[]> {
    return this.collectionService.getCollection(uri)
    .pipe(map(collection => this.breadcrumbMiddleware.middleware(collection)))
    .pipe(map((collection: Collection) => {
      const links: NavigationLink[] = [];
      for (let i = 0; i < collection.items.length; i++) {
        const item: Item = collection.items[i];

        const name = item.getDataAsString("name");
        const description = item.getDataAsString("description");
        let state: NavigationState = NavigationState.ACTIVE;
        if (item.hasData("state")) {
          state = getNavigationStateByKey(item.getDataAsString("state"));
        }
        const stateMessage = item.getDataAsStringOrUndefined("stateMessage");

        const href = item.links[0].href;
        const rel = item.links[0].rel;

        links.push(new NavigationLink(name, description, href, state, stateMessage, rel));
      }

      return links;
    }));
  }
}
