import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ILocalization } from 'app/interfaces/ILocalization';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {
  constructor(
    @Inject(ILocalization) localization: ILocalization,
  ) {
    super();

    this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      const totalPages = Math.ceil(length/pageSize);
      return localization.getPageXOfY(Math.min(page + 1, totalPages), totalPages);
    };
    this.itemsPerPageLabel = localization.getItemsPerPage();
    this.nextPageLabel = localization.getNextPage();
    this.previousPageLabel = localization.getPreviousPage();
  }
}