import { Observable } from 'rxjs';

export interface IMathSolverSettingsService {
  getSettings(): Observable<IMathSolverSettingItems[]>;
  setSettings(request: IMathSolverSettings): Observable<void>;
}

export interface IMathSolverSettings {
  allowSolve: boolean;
}

export interface IMathSolverSettingItems {
  name: string,
  allowed: boolean,
  indeterminate: boolean
}

export enum MathSolverSetting {
  ALLOW_SOLVE = 'allowSolve',
}
