import { Injectable } from '@angular/core';

@Injectable()
export class MediaQueryService {
  private _listeners: {[key: string]: [(matches: boolean) => void, (mql: MediaQueryListEvent) => void, MediaQueryList][]} = {};

  matchMedia(query: string): boolean {
    return window.matchMedia(query).matches;
  }

  listen(query: string, callback: (matches: boolean) => void) {
    const mql: MediaQueryList = window.matchMedia(query);
    const listener = (mql: MediaQueryListEvent): void => callback(mql.matches);

    if (!this._listeners[query]) {
      this._listeners[query] = [];
    } else {
      for (let i = 0; i < this._listeners[query].length; i++) {
        if (this._listeners[query][i][0] === callback) return;
      }
    }
    this._listeners[query].push([callback, listener, mql]);

    mql.addListener(listener);
  }

  unlisten(query: string, callback: (matches: boolean) => void) {
    if (!this._listeners[query]) return;
    for (let i = 0; i < this._listeners[query].length; i++) {
      if (this._listeners[query][i][0] === callback) {
        this._listeners[query][i][2].removeListener(this._listeners[query][i][1]);
        this._listeners[query].splice(i, 1);
        break;
      }
    }
  }
}
