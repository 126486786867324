import { IToken } from './../readonly-document/readonly-document.component';
import { Component, OnInit, NgZone, Inject, ViewChild, ElementRef, HostListener, Renderer2, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaQueryService } from '../../services/media-query.service';
import { ITxtAnalyserServiceToken } from '../../interfaces/itxtanalyser.service.token';
import { ITxtAnalyserService } from '../../interfaces/itxtanalyser.service';
import { ITxtAnalyserReportDocumentText } from '../../models/txtanalyser/ReportDocumentText';
import { ITxtAnalyserReportDocumentErrorType } from '../../models/txtanalyser/ReportDocumentErrorType';
import { MatSelectChange } from '@angular/material/select';
import { DOCUMENT } from '@angular/common';
import { ITxtAnalyserReportDocument } from 'app/models/txtanalyser/ITxtAnalyserReportDocument';
import { ITxtAnalyserReportJob } from 'app/models/txtanalyser/ReportJob';

interface IRouteData {
  text: ITxtAnalyserReportDocumentText;
  errorTypes: ITxtAnalyserReportDocumentErrorType[];
  job: ITxtAnalyserReportJob;
  documents: ITxtAnalyserReportDocument[];
}

@Component({
  selector: 'app-txtanalyser-upload-view-document',
  templateUrl: './txtanalyser-upload-view-document.component.html',
  styleUrls: ['./txtanalyser-upload-view-document.component.scss']
})
export class TxtAnalyserUploadViewDocumentComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public isPhone: boolean = false;

  private _textResult?: ITxtAnalyserReportDocumentText;

  public get errorTypes(): ITxtAnalyserReportDocumentErrorType[] {
    return this._errorTypes;
  }
  public set errorTypes(errorTypes: ITxtAnalyserReportDocumentErrorType[]) {
    this._errorTypes = errorTypes;

    this.tokens = errorTypes.map(x => {
      return {
        startIndex: x.startIndex,
        endIndex: x.endIndex
      };
    });
  }
  private _errorTypes: ITxtAnalyserReportDocumentErrorType[] = [];

  public job?: ITxtAnalyserReportJob;
  public documents: ITxtAnalyserReportDocument[] = [];

  public get isErrorOnAnalysis(): boolean {
    if (!this.job) return false;
    return this.job.errorOnAnalysis !== undefined;
  }

  public get text(): string {
    if (!this._textResult) return "";

    return this._textResult.text;
  }
  public tokens: IToken[] = [];

  public groupId?: number;
  public userId?: number;

  public selectedErrorIndex = -1;

  public clickableElements: Node[] = [];

  public get currentDocumentTitle(): string | undefined {
    const id = this.selectedDocument;
    for (const document of this.documents) {
      if (document.id === id) {
        return document.name;
      }
    }

    return undefined;
  }

  public get selectedErrorType(): ITxtAnalyserReportDocumentErrorType | undefined {
    if (this.selectedErrorIndex === -1) return undefined;

    return this.errorTypes[this.selectedErrorIndex];
  }

  public get selectedDocument(): string {
    return this._route.snapshot.paramMap.get('documentId')!;
  }
  
  private _phoneListener = (matches: boolean) => this._onPhoneMedia(matches);
  private _routeSubscription: Subscription|undefined = undefined;

  @ViewChild("grid", { read: ElementRef, static: true })
  private _gridElementRef?: ElementRef<HTMLElement>;

  @ViewChild("error", { read: ElementRef, static: true })
  private _errorElementRef?: ElementRef<HTMLElement>;

  private _errorCardSticky = false;
  private _lastErrorCardHeight: number | undefined;
  private _scrollTop = 0;

  private _selectedErrorIndexDirty = false;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _mediaQueryService: MediaQueryService,
    private _zone: NgZone,
    @Inject(ITxtAnalyserServiceToken) private service: ITxtAnalyserService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  public onSelected(token: IToken): void {
    const index = this.tokens.indexOf(token);
    this._selectedErrorIndexDirty = this.selectedErrorIndex !== index;
    this.selectedErrorIndex = index;
  }

  public onDocumentChange(e: MatSelectChange): void {
    const documentId = e.value;
    if (this._route.snapshot.paramMap.has('documentId')) {
      this._router.navigate([ "../" + documentId ], { relativeTo: this._route });
    } else {
      this._router.navigate([ "./" + documentId ], { relativeTo: this._route });
    }
  }

  public ngOnInit() {
    if (this._route.snapshot.paramMap.has('groupId')) {
      this.groupId = parseInt(this._route.snapshot.paramMap.get('groupId')!, 10);
    }
    if (this._route.snapshot.paramMap.has('userId')) {
      this.userId = parseInt(this._route.snapshot.paramMap.get('userId')!, 10);
    }

    this._mediaQueryService.listen('(max-width: 600px)', this._phoneListener);
    this.isPhone = this._mediaQueryService.matchMedia('(max-width: 600px)');

    this._routeSubscription = this._route.data.subscribe(value => {
      const data = value as IRouteData;
      this.errorTypes = data.errorTypes;
      this.job = data.job;
      this.documents = data.documents;
      this._textResult = data.text;
    });
  }

  public ngAfterViewInit() {
    if (this._errorElementRef) {
      this.clickableElements = [
        this._errorElementRef.nativeElement
      ];
    }
  }

  public ngAfterViewChecked() {
    if (this._selectedErrorIndexDirty) {
      this._selectedErrorIndexDirty = false;

      this._updateSticky();
      this._updateErrorDescriptionHeight();
    }
  }

  public ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    this._mediaQueryService.unlisten('(max-width: 600px)', this._phoneListener);
  }
  
  private _onPhoneMedia(matches: boolean) {
    this._zone.run(() => {
      this.isPhone = matches;

      this._updateSticky();
      this._updateErrorDescriptionHeight();
    });
  }

  @HostListener('window:scroll', ['$event'])
  private _onWindowScroll(e: Event) {
    if (!e.target || !this._gridElementRef || !this._errorElementRef) return;
    const node = e.target as Document;

    let scrollTop = 0;
    if (node.scrollingElement) {
      scrollTop = node.scrollingElement.scrollTop;
    } else if (node.documentElement) {
      scrollTop = node.documentElement.scrollTop;
    }

    this._scrollTop = scrollTop;
    
    this._updateSticky();
    this._updateErrorDescriptionHeight();
  }

  private _updateSticky() {
    if (!this._gridElementRef || !this._errorElementRef) return;
    const currentTop = this._gridElementRef.nativeElement.offsetTop;
    const scrollTop = this._scrollTop;

    let stickyActivator = 80;
    if (this.isPhone) {
      stickyActivator = 68;
    }

    this._errorCardSticky = scrollTop > currentTop - stickyActivator;

    if (this._errorCardSticky) {
      this.renderer.addClass(this._errorElementRef.nativeElement, "sticky--enabled");
    } else {
      this.renderer.removeClass(this._errorElementRef.nativeElement, "sticky--enabled");
    }
  }

  private _updateErrorDescriptionHeight() {
    if (!this._errorElementRef) return;
    const parentNode = this.renderer.parentNode(this._errorElementRef.nativeElement) as Node | null;

    const height = this._errorElementRef.nativeElement.offsetHeight;

    let diff = 0;
    if (this._lastErrorCardHeight !== undefined) {
      diff = height - this._lastErrorCardHeight;
    }
    
    this._lastErrorCardHeight = height;

    if (this._errorCardSticky) {
      this.renderer.setStyle(parentNode, "height", this._errorElementRef.nativeElement.offsetHeight + "px");
    } else {
      this.renderer.removeStyle(parentNode, "height");
    }

    if (diff !== 0 && this.isPhone) {
      const newScrollTop = this._scrollTop + diff;
      this._scrollTop = newScrollTop;

      if (this._document.scrollingElement) {
        this._document.scrollingElement.scrollTop = newScrollTop;
      } else if (this._document.documentElement) {
        this._document.documentElement.scrollTop = newScrollTop;
      }
    }
  }
}
