import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Language } from '../models/language';
import { ILanguageService } from '../interfaces/ilanguage.service';
import { ILanguageServiceToken } from '../interfaces/ilanguage.service.token';

import { Observable } from 'rxjs';

@Injectable()
export class LanguagesProfileResolver implements Resolve<Language[]> {
  constructor(
    @Inject(ILanguageServiceToken) private languageService: ILanguageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Language[]> {
    return this.languageService.getProfileLanguages();
  }
}
