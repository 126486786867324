import { Component, Inject, OnInit } from '@angular/core';
import { MathSolverSettingsService } from '../../services/collection/mathsolver-settings.service';
import { IMathSolverSettingItems, IMathSolverSettingsService, MathSolverSetting } from '../../interfaces/imathsolver-settings.service';
import { MathSolverSettingsServiceToken } from '../../interfaces/imathsolver-settings.service.token';

@Component({
  selector: 'app-mathsolver-setting',
  templateUrl: './mathsolver.component.html',
  providers: [MathSolverSettingsService]
})

export class MathSolverComponent implements OnInit {
  public allowSolve: IMathSolverSettingItems = {
    name: 'allowSolve',
    allowed: false,
    indeterminate: false
  };

  constructor(
     @Inject(MathSolverSettingsServiceToken) private service: IMathSolverSettingsService,
  ) {}

  ngOnInit() {
    this.setDefaultSettings()
  }

  setDefaultSettings() {
    this.service.getSettings().subscribe((settings) => {
      settings.forEach(setting => {
        if (setting.name === MathSolverSetting.ALLOW_SOLVE) {
          this.allowSolve = setting
        }
      })
    })
  }

   onChange(checked: boolean) {
     this.service.setSettings({[MathSolverSetting.ALLOW_SOLVE]: checked}).subscribe();
  }
}
