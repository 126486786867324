<h1 *ngIf="!(glossary.groupId && !glossary.impersonatedUserId)" mat-dialog-title i18n="@@DeleteGlossary_DeleteHeader">Delete glossary</h1>
<h1 *ngIf="(glossary.groupId && !glossary.impersonatedUserId)" mat-dialog-title i18n="@@DeleteGlossary_RemoveHeader">Remove glossary</h1>
<mat-dialog-content>
  <div *ngIf="!(glossary.groupId && !glossary.impersonatedUserId)" class="description" i18n="@@DeleteGlossary_WarnDelete">You're about to delete the glossary "{{ glossary.name }}"</div>
  <div *ngIf="(glossary.groupId && !glossary.impersonatedUserId)" class="description" i18n="@@DeleteGlossary_WarnRemove">You're about to remove the glossary "{{ glossary.name }}"</div>
  <div class="error" *ngIf="!glossary.groupId && glossary.subscribers > 0" i18n="@@DeleteGlossary_WarnSubscribers">
    {{ glossary.subscribers }} {glossary.subscribers, plural, =1 {subscriber} other {subscribers}}
    will no longer have access to this glossary if it's deleted.
  </div>
  <div *ngIf="error" class="error">
    <span i18n="@@DeleteGlossary_UnexpectedError">An unexpected error occurred.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button" i18n="@@DeleteGlossary_btnCancel">Cancel</button>
  <button *ngIf="!(glossary.groupId && !glossary.impersonatedUserId)" mat-raised-button color="primary" (click)="delete()" [disabled]="disabled" i18n="@@DeleteGlossary_btnDelete">Delete</button>
  <button *ngIf="(glossary.groupId && !glossary.impersonatedUserId)" mat-raised-button color="primary" (click)="delete()" [disabled]="disabled" i18n="@@DeleteGlossary_btnRemove">Remove</button>  
</mat-dialog-actions>